/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "recharts";
import { apiUrl, fligUrl, UopUrl } from "../../Helpers/Config";
import backarrow from "../../../common/images/back-arrow-front.svg";
import "./GeneralInfo.scss";
import Select from "react-select";
import closeImg from "../../../common/images/close-icon.svg";

import axios from "axios";
import cookie from "react-cookies";

import { GET_CUSTOMER_DETAIL } from "../../../actions";
import SubHeader from "../SubHeader";
import CustomEditor from "../CustomEditor/CustomEditor";
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    let packageID = "";
    let allowedSection = ["general-info"];
    let newPackage = true;
    let postedSection = [];
    let productType = "";
    if (
      this.props?.location?.state?.packageID !== undefined ||
      this.props?.location?.state?.creator_package_id !== undefined
    ) {
      packageID =
        this.props?.location?.state?.packageID ??
        this.props?.location?.state?.creator_package_id;
      allowedSection.push("add-photos");
    }

    if (this.props?.location?.state?.has_itinerary === "yes") {
      allowedSection.push("itinerary-list");
    }
    if (this.props?.location?.state?.has_availability === "yes") {
      allowedSection.push("availability");
    }
    if (this.props?.location?.state?.has_pricing === "yes") {
      allowedSection.push("pricing");
    }
    if (this.props?.location?.state?.has_pricing === "yes") {
      allowedSection.push("terms");
    }

    if (this.props?.location?.state?.allowedSection !== undefined) {
      allowedSection = this.props?.location?.state?.allowedSection;
    }
    if (this.props?.location?.state?.newPackage !== undefined) {
      newPackage = this.props?.location?.state?.newPackage;
    }
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    if (this.props?.location?.state?.productType !== undefined) {
      productType = this.props?.location?.state?.productType;
    }
    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      creator: [],
      destination: "Singapore",
      latitude: "1.3544924",
      longitude: "103.9886015",
      location:
        "60 Airport Blvd., Singapore Changi Airport (SIN), Singapore 819643",
      airportID: 6036,
      airportCode: "SIN",
      countryCode: 1,
      cityId: 1,
      destinationList: [],
      packageTitle: "",
      packageHighlights: [""],
      packageIncluded: [""],
      packageExcluded: [""],
      duration: 1,
      keywords: "",
      travellerType: "",
      packageType: "",
      tourLanguage: "",
      disableBtn: false,
      keyword_list: [],
      isMultiDest: false,
      destinationCityList: [""],
      keyword_error: "",
      description: "",
      thumbnail: "",
      additionalImage: [],
      videos: [],
      selectedTab: 1,
      packageID: packageID,
      errors: {},
      allowedSection: allowedSection,
      cityList: [],
      newPackage: newPackage,
      postedSection: postedSection,
      includedSeason: [],
      isFormEdited: false,
      productType: productType,
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    } */
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    this.fetchCities();
    let posted = this.state.postedSection.includes("general-info");
    if (!this.state.newPackage || posted) {
      this.getPackageDetails();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }

  getPackageDetails = async () => {
    try {
      const { packageID } = this.state;
      const res = await axios.get(
        `${UopUrl}/package?id=${packageID}`,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );

      // console.log(res);
      if (res.data?.length > 0) {
        this.setState(
          {
            packageID: res.data[0].creator_package_id,
            destination: res.data[0].creator_package_city,
            airportID: res.data[0].creator_package_country_id,
            packageTitle: res.data[0].creator_package_name,
            packageHighlights: res.data[0].creator_package_highlights,
            packageIncluded:
              res.data[0].creator_uop_package_included ||
              this.state.packageIncluded,
            packageExcluded:
              res.data[0].creator_uop_package_excluded ||
              this.state.packageExcluded,
            duration: res.data[0].creator_package_total_days,
            // productType:
            //   res.data[0].creator_package_total_days === 1 && "dayTour",
            countryCode: res.data[0].creator_package_country_id,
            airportCode: res.data[0].creator_package_airport_id,
            cityId: res.data[0].creator_package_city_id,
            travellerType: res.data[0].creator_package_travelling,
            packageType: res.data[0].creator_package_category,
            tourLanguage: res.data[0].creator_package_tour_opertaing_language,
            keyword_list: res.data[0].creator_package_keywords?.split(","),
            isMultiDest:
              res.data[0].creator_package_multiple_destination?.length > 0,
            destinationCityList: res.data[0]
              .creator_package_multiple_destination || [""],
            description: res.data[0].creator_package_description,
            countryCode: res.data[0].creator_package_country_id,
            airportCode: res.data[0].creator_package_airport_code,
            airportID: res.data[0].creator_package_airport_id,
            cityId: res.data[0].creator_package_city_id,
            location: res.data[0].creator_package_location,
            latitude: res.data[0].creator_package_latitude,
            longitude: res.data[0].creator_package_longitude,
          },
          () => this.getItineraryData()
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  getItineraryData = async () => {
    try {
      const { packageID } = this.state;
      await axios
        .get(`${UopUrl}/availability?id=${packageID}`, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          if (res.data !== undefined && res.data.length > 0) {
            {
              let includedSeason = [];
              res.data.map((slot) => {
                if (!includedSeason.includes(slot.season)) {
                  includedSeason.push(slot.season);
                }
              });
              this.setState({ includedSeason: includedSeason });
            }
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  fetchCities = async () => {
    try {
      const res = await axios.get(`${apiUrl}settings/getCityList`, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      });
      // console.log(res)
      if (res.data.result_set !== undefined) {
        this.getCities(res.data.result_set);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (name, e) => {
    try {
      let { value, checked } = e.target;
      let errors = {};
      errors[name] = "";
      if (name === "isMultiDest") {
        if (checked === false) {
          this.setState({ destinationCityList: [] });
        }
        this.setState({ [name]: checked, isFormEdited: true });
      } else if (name === "duration") {
        this.setState({
          [name]: value.replace(/[^\s\S]/g, ""),
          errors,
          isFormEdited: true,
        });
      } else if (name === "destination") {
        value = this.state.cityList.find((option) =>
          option.label.includes(value)
        );
        let splitValue = value.value.split("~");

        this.setState({
          destination: splitValue[8],
          location: splitValue[0],
          countryCode: splitValue[9],
          latitude: splitValue[3],
          longitude: splitValue[4],
          airportCode: splitValue[1],
          airportID: splitValue[2],
          cityId: splitValue[7],
          isFormEdited: true,
        });
      } else {
        this.setState({
          [name]: value.replace(/[^\s\S]/g, ""),
          errors,
          isFormEdited: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getCities = (result_set) => {
    var cityList = [];
    if (Object.keys(result_set).length > 0) {
      Object.keys(result_set).map((item) => {
        var selectedCountry = result_set[item];
        selectedCountry.city.map((city) => {
          cityList.push({
            label: city.city_name + ", " + city.country_name,
            value:
              city.airport_address +
              "~" +
              city.airport_code +
              "~" +
              city.airport_id +
              "~" +
              city.airport_latitude +
              "~" +
              city.airport_longitude +
              "~" +
              city.airport_name +
              "~" +
              city.city_code +
              "~" +
              city.city_id +
              "~" +
              city.city_name +
              "~" +
              city.country_code +
              "~" +
              city.country_name +
              "~" +
              city.webbeds_city_code,
          });
        });
      });
    }
    this.setState(
      {
        cityList: cityList,
      },
      function () {}
    );
  };

  handleEditorChange = (event, editor) => {
    var value = editor.getData();
    // const wordLimit = 150;
    // const charLimit = 300;
    // const sanitizedValue = value.replace(/[^a-zA-Z0-9\s<>/]/g, "");
    // const words = sanitizedValue.split(/\s+/).length;
    // if (words <= wordLimit && sanitizedValue.length <= charLimit) {
    //   const truncatedValue = sanitizedValue
    //     .split(/\s+/)
    //     .slice(0, wordLimit)
    //     .join(" ");
    this.setState({ description: value, isFormEdited: true });
    // }
  };

  addHighlights = (event, index, name) => {
    try {
      const { value } = event.target;
      this.setState(
        (prevState) => {
          // Get a copy of the array from state
          const product = [...prevState[name]];

          // Get the value from the event and apply any desired transformations
          const newValue = value.replace(/[^\s\S]/g, "");

          // Update the specific element within the array
          product[index] = newValue;

          // Return the updated state
          return {
            [name]: product,
            isFormEdited: true,
          };
        },
        () => console.log(this.state[name])
      );
    } catch (err) {
      console.log(err);
    }
  };

  handleRemove = (index, name) => {
    try {
      const list = [...this.state[name]];
      let minimumValue = 1;
      if (index < minimumValue) {
        this.setState({ disableBtn: true });
      } else {
        list.splice(index, 1);
        this.setState({ [name]: list, isFormEdited: true });
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleAdd = (index, name) => {
    try {
      this.setState((prevState) => ({
        [name]: [
          ...prevState[name].slice(0, index + 1),
          "",
          ...prevState[name].slice(index + 1),
        ],
      }));
    } catch (err) {
      console.log(err);
    }
  };

  setKeyWords() {
    try {
      const { keywords, keyword_list } = this.state;
      if (keywords !== "" && keyword_list.length < 5) {
        var keyword_lists = keyword_list;
        keyword_lists.push(keywords);
        this.setState({
          keyword_list: keyword_lists,
          keywords: "",
          isFormEdited: true,
        });
      } else {
        this.setState({ keyword_error: "maximum 5 words allowed" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  removeKeyWord(removeID) {
    try {
      var keywordlist = [];
      if (this.state.keyword_list.length > 0) {
        this.state.keyword_list.map((item, index) => {
          if (removeID !== index) {
            keywordlist.push(item);
          }
        });
      }
      this.setState({
        keyword_list: keywordlist,
        keyword_error: "",
        isFormEdited: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  postPackageDetails = async () => {
    const {keyword_list}=this.state;
    try {
      const errors = this.validateForm();
      this.setState({ isFormEdited: false });
      if (Object.keys(errors).length > 0) {
        this.setState({ errors }, () => {
          if (
            Object.keys(errors).length === 1 &&
            errors.description !== undefined
          ) {
            this.handleSelectedTab(2);
          }
        });
      } else {
        const keywrds = keyword_list.join(',');
        var packageDetails = {
          creator_id: this.state.CUserId,
          creator_package_name: this.state.packageTitle,
          creator_package_description: this.state.description,
          creator_package_travelling: this.state.travellerType,
          creator_package_category: this.state.packageType,
          creator_package_city: this.state.destination,
          creator_package_country_id: this.state.countryCode,
          creator_package_airport_code: this.state.airportCode,
          creator_package_airport_id: this.state.airportID,
          creator_package_city_id: this.state.cityId,
          creator_package_country_code: this.state.countryCode,
          creator_package_keywords: keywrds,
          creator_package_total_days: this.state.duration,
          creator_package_source: "uop",
          creator_package_tour_opertaing_language: this.state.tourLanguage,
          creator_package_highlights: this.state.packageHighlights,
          creator_uop_package_included: this.state.packageIncluded,
          creator_uop_package_excluded: this.state.packageExcluded,
          creator_package_cities: this.state.destinationCityList,
          creator_package_multiple_destination: this.state.destinationCityList,
          creator_package_location: this.state.location,
          creator_package_latitude: this.state.latitude,
          creator_package_longitude: this.state.longitude,
          creator_package_created_ip: 1,
        };
        let res;
        if (this.state.packageID === "") {
          res = await axios.post(
            `${UopUrl}/package`,
            packageDetails,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
          );
        } else {
          console.log("packageID", this.state.packageID);
          packageDetails["creator_package_id"] = this.state.packageID;
          res = await axios.put(
            `${UopUrl}/package/update`,
            packageDetails,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
          );
        }

        console.log(res);
        if (res.status === 200) {
          let allowedSection = [...this.state.allowedSection];
          let postedSection = [...this.state.postedSection];
          if (!allowedSection.includes("add-photos")) {
            allowedSection.push("add-photos");
          }
          if (!postedSection.includes("general-info")) {
            postedSection.push("general-info");
          }
          this.setState(
            {
              allowedSection: allowedSection,
              postedSection: postedSection,
              packageID:
                res.data?.rows?.creator_package_id || this.state.packageID,
            },
            () => {
              let packageDetails = this.props.location.state || {};
              packageDetails["packageID"] = this.state.packageID;
              packageDetails["allowedSection"] = this.state.allowedSection;
              packageDetails["postedSection"] = this.state.postedSection;
              packageDetails["duration"] = this.state.duration;
              packageDetails["destList"] = this.state.destinationCityList;
              packageDetails["productType"] = this.state.productType;
              // additionalImage: this.state.additionalImage,
              // videos: this.state.videos,

              this.props.history.push({
                pathname: "/myaccount/add-photos",
                state: packageDetails,
              });
            }
          );
        } else {
          this.openPopup("failed-popup");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  openPopup = (src) => {
    $.magnificPopup.open({
      items: {
        src: `#${src}`,
      },
      type: "inline",
      midClick: true,
    });
  };

  closePopup = () => {
    $.magnificPopup.close();
  };

  handleSelectedTab = (num) => {
    this.setState({ selectedTab: num });
  };

  validateForm = () => {
    const {
      packageTitle,
      destination,
      packageType,
      tourLanguage,
      travellerType,
      isMultiDest,
      destinationCityList,
      duration,
      description,
      packageHighlights,
      packageExcluded,
      packageIncluded,
    } = this.state;
    const errors = {};

    if (packageTitle.trim() === "") {
      errors.title = "title required";
    }
    if (destination.trim() === "") {
      errors.destination = "destination required";
    }
    console.log(packageType, "packageType");
    /* if (packageType === "") {
      errors.packageType = "package type required";
    } */
    if (tourLanguage.trim() === "") {
      errors.tourLanguage = "tour language required";
    }
    /* if (travellerType.trim() === "") {
      errors.travellerType = "traveller type required";
    } */
    if (isMultiDest && destinationCityList[0]?.trim() === "") {
      errors.destinationCityList = "city list required";
    }
    if (packageHighlights[0].trim() === "") {
      errors.packageHighlights = "package highlights required";
    }
    if (packageExcluded[0].trim() === "") {
      errors.packageExcluded = "package Excluded required";
    }
    if (packageIncluded[0].trim() === "") {
      errors.packageIncluded = "package Included required";
    }
    if (duration > 30) {
      errors.duration = "duration should not exceed 30";
    } else if (!/^\d+$/.test(duration)) {
      errors.duration = "special characters are not allowed";
    }
    if (description.trim() === "") {
      errors.description = "description required";
    }

    return errors;
  };

  render() {
    const { allowedSection, errors } = this.state;
    let stateValues = Object(this.props?.location?.state);
    stateValues["duration"] = this.state.duration;
    stateValues["includedSeason"] = this.state.includedSeason;
    return (
      <>
        {/* <Topmenu {...this.props} currentpage="dashboard" /> */}

        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <section className="main-blue-bg generalinfo-main">
                <div className="container-full">
                  <SubHeader
                    allowedSection={allowedSection}
                    triggerAction={this.postPackageDetails}
                    stateValues={stateValues}
                  />
                  <div className="generalinfo-main__content">
                    <div className="generalinfo-main__content__tabs">
                      <div
                        className={`generalinfo-main__content__tabs__tab ${
                          this.state.selectedTab === 1 ? "active" : ""
                        }`}
                        onClick={() => this.handleSelectedTab(1)}
                      >
                        Info
                      </div>
                      <div
                        className={`generalinfo-main__content__tabs__tab ${
                          this.state.selectedTab === 2 ? "active" : ""
                        }`}
                        onClick={() => this.handleSelectedTab(2)}
                      >
                        Package description
                      </div>
                    </div>
                    {this.state.selectedTab === 1 ? (
                      <div className="billboard">
                        <div className="billboard-lhs">
                          <div className="form-group">
                            <label className="control-label">
                              Package title <em>*</em>
                            </label>
                            <input
                              placeholder="Ex: 7days bali explorer"
                              type="text"
                              value={this.state.packageTitle}
                              onChange={(e) =>
                                this.handleChange("packageTitle", e)
                              }
                            />
                            {errors.title && (
                              <p style={{ color: "red" }}>{errors.title}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="control-label">Keywords</label>
                            <input
                              placeholder="Ex: Family (press enter for multiple)"
                              type="text"
                              value={this.state.keywords}
                              onChange={(e) => this.handleChange("keywords", e)}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  this.setKeyWords();
                                }
                              }}
                            />
                            {this.state.keyword_list.length > 0 && (
                              <ul className="product-middle-uldiv">
                                {this.state.keyword_list.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item}{" "}
                                      <a
                                        href={void 0}
                                        onClick={this.removeKeyWord.bind(
                                          this,
                                          index
                                        )}
                                      >
                                        <img src={closeImg} />
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                            {this.state.keyword_error !== "" &&
                              this.state.keyword_error}
                          </div>
                          {/* <div className="form-group big-textarea">
                        <label className="control-label">
                          Package description <em>*</em>
                        </label>
                        <CustomEditor />
                        <textarea
                          id="textarea"
                          name="description"
                          placeholder="Write about your package here.."
                          value={this.state.description}
                          onChange={(e) => this.handleChange("description", e)}
                          // disabled={textAreaDisbled}
                        ></textarea>
                        <div className="unique-package-info">
                          Please describe your package in detail. You can write
                          description anywhere between 150 to 300 words.
                        </div>
                      </div> */}

                          <div className="form-group soc-add">
                            <label className="control-label">
                              Package highlights <em>*</em>
                            </label>
                            {this.state.packageHighlights.map((item, index) => (
                              <>
                                <div className="pHightlights">
                                  <input
                                    placeholder="Describe your package uniqueness."
                                    type="text"
                                    value={item}
                                    onChange={(e) =>
                                      this.addHighlights(
                                        e,
                                        index,
                                        "packageHighlights"
                                      )
                                    }
                                  />
                                  <div className="highlight-btn">
                                    <a
                                      className="button soc-btn"
                                      onClick={() =>
                                        this.handleRemove(
                                          index,
                                          "packageHighlights"
                                        )
                                      }
                                    >
                                      -
                                    </a>
                                    <a
                                      className="button soc-btn"
                                      onClick={() =>
                                        this.handleAdd(
                                          index,
                                          "packageHighlights"
                                        )
                                      }
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                                <br />
                              </>
                            ))}
                            {errors.packageHighlights && (
                              <p style={{ color: "red" }}>
                                {errors.packageHighlights}
                              </p>
                            )}
                          </div>
                          {this.state.isMultiDest && (
                            <div className="form-group soc-add">
                              <label className="control-label">
                                Destination List <em>*</em>
                              </label>
                              {this.state.destinationCityList.map(
                                (item, index) => (
                                  <>
                                    <div className="pHightlights">
                                      <input
                                        placeholder="Ex: Bali."
                                        type="text"
                                        value={item}
                                        onChange={(e) =>
                                          this.addHighlights(
                                            e,
                                            index,
                                            "destinationCityList"
                                          )
                                        }
                                      />
                                      <div className="highlight-btn">
                                        <a
                                          className="button soc-btn"
                                          onClick={() =>
                                            this.handleRemove(
                                              index,
                                              "destinationCityList"
                                            )
                                          }
                                        >
                                          -
                                        </a>
                                        <a
                                          className="button soc-btn"
                                          onClick={() =>
                                            this.handleAdd(
                                              index,
                                              "destinationCityList"
                                            )
                                          }
                                        >
                                          +
                                        </a>
                                      </div>
                                    </div>
                                    <br />
                                  </>
                                )
                              )}
                              {errors.destinationCityList && (
                                <p style={{ color: "red" }}>
                                  {errors.destinationCityList}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="billboard-rhs">
                          <div className="billboard-top">
                            <div className="fg-half">
                              <div className="form-group multiple-dest">
                                <input
                                  type="checkbox"
                                  checked={this.state.isMultiDest}
                                  onChange={(e) =>
                                    this.handleChange("isMultiDest", e)
                                  }
                                />
                                <label className="control-label">
                                  Multiple destinations
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Destination <em>*</em>
                                </label>

                                <select
                                  value={
                                    this.state.cityList.find((option) =>
                                      option.label.includes(
                                        this.state.destination
                                      )
                                    )?.label
                                  }
                                  onChange={(e) =>
                                    this.handleChange("destination", e)
                                  }
                                  className="form-control"
                                >
                                  {this.state.cityList.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>

                                {errors.destination && (
                                  <p style={{ color: "red" }}>
                                    {errors.destination}
                                  </p>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Traveller type
                                </label>
                                <select
                                  name="selectedGender"
                                  value={this.state.travellerType}
                                  onChange={(e) =>
                                    this.handleChange("travellerType", e)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Family">Family</option>
                                  <option value="Couple">Couple</option>
                                  <option value="Friends">Friends</option>
                                  <option value="Solo">Solo</option>
                                </select>
                                {/*  {errors.travellerType && (
                                  <p style={{ color: "red" }}>
                                    {errors.travellerType}
                                  </p>
                                )} */}
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Package type
                                </label>
                                <select
                                  name="selectedGender"
                                  value={this.state.packageType}
                                  onChange={(e) =>
                                    this.handleChange("packageType", e)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="1">Attraction</option>
                                  <option value="2">
                                    Entertainment
                                  </option>
                                  <option value="5">
                                    Things to do
                                  </option>
                                  <option value="6">Fun</option>
                                </select>
                                {/*    {errors.packageType && (
                                  <p style={{ color: "red" }}>
                                    {errors.packageType}
                                  </p>
                                )} */}
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  Duration <em>*</em>
                                </label>
                                <input
                                  placeholder="Ex: 7"
                                  type="text"
                                  value={this.state.duration}
                                  onChange={(e) =>
                                    this.handleChange("duration", e)
                                  }
                                  readOnly={
                                    this.state.productType === "dayTour"
                                  }
                                />
                                {errors.duration && (
                                  <p style={{ color: "red" }}>
                                    {errors.duration}
                                  </p>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Tour operating language <em>*</em>
                                </label>
                                <select
                                  name="selectedGender"
                                  value={this.state.tourLanguage}
                                  onChange={(e) =>
                                    this.handleChange("tourLanguage", e)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="english">English</option>
                                  <option value="mandarin">Mandarin</option>
                                  <option value="malay">Malay</option>
                                  <option value="tamil">Tamil</option>
                                  <option value="malay">Telugu</option>
                                </select>
                                {errors.tourLanguage && (
                                  <p style={{ color: "red" }}>
                                    {errors.tourLanguage}
                                  </p>
                                )}
                              </div>
                              <div className="form-group soc-add">
                                <label className="control-label">
                                  Package included <em>*</em>
                                </label>
                                {this.state.packageIncluded?.map(
                                  (item, index) => (
                                    <>
                                      <div className="pHightlights">
                                        <input
                                          placeholder="Describe your package uniqueness."
                                          type="text"
                                          value={item}
                                          onChange={(e) =>
                                            this.addHighlights(
                                              e,
                                              index,
                                              "packageIncluded"
                                            )
                                          }
                                        />

                                        <div className="highlight-btn">
                                          <a
                                            className="button soc-btn"
                                            onClick={() =>
                                              this.handleRemove(
                                                index,
                                                "packageIncluded"
                                              )
                                            }
                                          >
                                            -
                                          </a>
                                          <a
                                            className="button soc-btn"
                                            onClick={() =>
                                              this.handleAdd(
                                                index,
                                                "packageIncluded"
                                              )
                                            }
                                          >
                                            +
                                          </a>
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  )
                                )}
                                {errors.packageIncluded && (
                                  <p style={{ color: "red" }}>
                                    {errors.packageIncluded}
                                  </p>
                                )}
                              </div>
                              <div className="form-group soc-add">
                                <label className="control-label">
                                  Package excluded <em>*</em>
                                </label>
                                {this.state.packageExcluded?.map(
                                  (item, index) => (
                                    <>
                                      <div className="pHightlights">
                                        <input
                                          placeholder="Describe your package uniqueness."
                                          type="text"
                                          value={item}
                                          onChange={(e) =>
                                            this.addHighlights(
                                              e,
                                              index,
                                              "packageExcluded"
                                            )
                                          }
                                        />
                                        <div className="highlight-btn">
                                          <a
                                            className="button soc-btn"
                                            onClick={() =>
                                              this.handleRemove(
                                                index,
                                                "packageExcluded"
                                              )
                                            }
                                          >
                                            -
                                          </a>
                                          <a
                                            className="button soc-btn"
                                            onClick={() =>
                                              this.handleAdd(
                                                index,
                                                "packageExcluded"
                                              )
                                            }
                                          >
                                            +
                                          </a>
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  )
                                )}
                                {errors.packageExcluded && (
                                  <p style={{ color: "red" }}>
                                    {errors.packageExcluded}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="billboard-btm">
                            {/* <div className="form-group soc-add">
                              <label className="control-label">
                                Package highlights <em>*</em>
                              </label>
                              <input
                                placeholder="Describe your package uniqueness."
                                type="text"
                                value=""
                              />
                              <a className="button soc-btn">+</a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group big-textarea">
                        <label className="control-label">
                          Package description <em>*</em>
                        </label>
                        <CustomEditor
                          value={this.state.description}
                          onChange={this.handleEditorChange}
                        />
                        {errors.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}
            <div id="failed-popup" className="mfp-hide common-popup">
              <div className="custom_alert textcenter">
                <h2 className="text-uppercase">Failed</h2>
                <p>Post failed to save. please try again </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <UnsavedChangesWarning
          message="You have unsaved changes. Are you sure you want to leave?"
          isFormEdited={this.state.isFormEdited}
        />
      </>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
