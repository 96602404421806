/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import "./VacationSummary.scss";
import {
  InfoOutlined as InfoIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Footer from "../Layout/Footer";
import { Button, Divider } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import cookie from "react-cookies";
import { showPriceValue } from "../Helpers/SettingHelper";
var base64 = require("base-64");
class VacationSummary extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.state, "this.props(VacationSummary)");
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";

    var searchdata = "";
    var checkinTime = "";
    var end_date = "";
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      checkinTime = searchdata.checkinTime;
      end_date = searchdata.end_date;
    } else {
      this.props.history.push("/");
    }
    var packageSlug = "";
    if (
      this.props.location.state.packageSlug !== "" &&
      typeof this.props.location.state.packageSlug !== undefined &&
      typeof this.props.location.state.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.location.state.packageSlug;
    } else {
      this.props.history.push("/");
    }
    var ownpackage = false;
    if (packageSlug === "create-own-package") {
      ownpackage = true;
    }

    var flightList =
      this.props.location.state.flightList !== "" &&
      typeof this.props.location.state.flightList !== undefined &&
      typeof this.props.location.state.flightList !== "undefined"
        ? this.props.location.state.flightList
        : [];

    var selectedflight =
      this.props.location.state.selectedflight !== "" &&
      typeof this.props.location.state.selectedflight !== undefined &&
      typeof this.props.location.state.selectedflight !== "undefined"
        ? this.props.location.state.selectedflight
        : "";
    var flightPrice =
      this.props.location.state.flightPrice !== "" &&
      typeof this.props.location.state.flightPrice !== undefined &&
      typeof this.props.location.state.flightPrice !== "undefined"
        ? this.props.location.state.flightPrice
        : "";

    var addFlight =
      this.props.location.state.addFlight !== "" &&
      typeof this.props.location.state.addFlight !== undefined &&
      typeof this.props.location.state.addFlight !== "undefined"
        ? this.props.location.state.addFlight
        : "No";

    var selectedHotelID =
      this.props.location.state.selectedHotelID !== "" &&
      typeof this.props.location.state.selectedHotelID !== undefined &&
      typeof this.props.location.state.selectedHotelID !== "undefined"
        ? this.props.location.state.selectedHotelID
        : "";

    var selectedHotel =
      this.props.location.state.selectedHotel !== "" &&
      typeof this.props.location.state.selectedHotel !== undefined &&
      typeof this.props.location.state.selectedHotel !== "undefined"
        ? this.props.location.state.selectedHotel
        : [];

    var selectedRoom =
      this.props.location.state.selectedRoom !== "" &&
      typeof this.props.location.state.selectedRoom !== undefined &&
      typeof this.props.location.state.selectedRoom !== "undefined"
        ? this.props.location.state.selectedRoom
        : "";

    var activities =
      this.props.location.state.activities !== "" &&
      typeof this.props.location.state.activities !== undefined &&
      typeof this.props.location.state.activities !== "undefined"
        ? this.props.location.state.activities
        : [];
    var oldactivities =
      this.props.location.state.oldactivities !== "" &&
      typeof this.props.location.state.oldactivities !== undefined &&
      typeof this.props.location.state.oldactivities !== "undefined"
        ? this.props.location.state.oldactivities
        : [];

    var removeFlight =
      this.props.location.state.removeFlight !== "" &&
      typeof this.props.location.state.removeFlight !== undefined &&
      typeof this.props.location.state.removeFlight !== "undefined"
        ? this.props.location.state.removeFlight
        : "No";

    var transferList =
      this.props.location.state.transferList !== "" &&
      typeof this.props.location.state.transferList !== undefined &&
      typeof this.props.location.state.transferList !== "undefined"
        ? this.props.location.state.transferList
        : [];

    var selectedTransfer =
      this.props.location.state.selectedTransfer !== "" &&
      typeof this.props.location.state.selectedTransfer !== undefined &&
      typeof this.props.location.state.selectedTransfer !== "undefined"
        ? this.props.location.state.selectedTransfer
        : "";

    var reserveTransfer =
      this.props.location.state.reserveTransfer !== "" &&
      typeof this.props.location.state.reserveTransfer !== undefined &&
      typeof this.props.location.state.reserveTransfer !== "undefined"
        ? this.props.location.state.reserveTransfer
        : [];

    var stayList =
      this.props.location.state.stayList !== "" &&
      typeof this.props.location.state.stayList !== undefined &&
      typeof this.props.location.state.stayList !== "undefined"
        ? this.props.location.state.stayList
        : [];

    var addTransfer =
      this.props.location.state.addTransfer !== "" &&
      typeof this.props.location.state.addTransfer !== undefined &&
      typeof this.props.location.state.addTransfer !== "undefined"
        ? this.props.location.state.addTransfer
        : "No";
    var emptyFlight =
      this.props.location.state.emptyFlight !== "" &&
      typeof this.props.location.state.emptyFlight !== undefined &&
      typeof this.props.location.state.emptyFlight !== "undefined"
        ? this.props.location.state.emptyFlight
        : "Yes";

    var removetransfer =
      this.props.location.state.removetransfer !== "" &&
      typeof this.props.location.state.removetransfer !== undefined &&
      typeof this.props.location.state.removetransfer !== "undefined"
        ? this.props.location.state.removetransfer
        : "No";

    var emptyhotel =
      this.props.location.state.emptyhotel !== "" &&
      typeof this.props.location.state.emptyhotel !== undefined &&
      typeof this.props.location.state.emptyhotel !== "undefined"
        ? this.props.location.state.emptyhotel
        : "Yes";
    var emptytransfer =
      this.props.location.state.emptytransfer !== "" &&
      typeof this.props.location.state.emptytransfer !== undefined &&
      typeof this.props.location.state.emptytransfer !== "undefined"
        ? this.props.location.state.emptytransfer
        : "Yes";
    var removedLastActivity =
      this.props.location.state.removedLastActivity !== "" &&
      typeof this.props.location.state.removedLastActivity !== undefined &&
      typeof this.props.location.state.removedLastActivity !== "undefined"
        ? this.props.location.state.removedLastActivity
        : false;

    var ArrivalDateFormat =
      this.props.location.state.ArrivalDateFormat !== "" &&
      typeof this.props.location.state.ArrivalDateFormat !== undefined &&
      typeof this.props.location.state.ArrivalDateFormat !== "undefined"
        ? this.props.location.state.ArrivalDateFormat
        : "";

    var packagedetails =
      this.props.location.state.packagedetails !== "" &&
      typeof this.props.location.state.packagedetails !== undefined &&
      typeof this.props.location.state.packagedetails !== "undefined"
        ? this.props.location.state.packagedetails
        : "";
    var totalActvitiesPrice =
      this.props.location.state.totalActvitiesPrice !== "" &&
      typeof this.props.location.state.totalActvitiesPrice !== undefined &&
      typeof this.props.location.state.totalActvitiesPrice !== "undefined"
        ? this.props.location.state.totalActvitiesPrice
        : "";
    var grandTotal =
      this.props.location.state.grandTotal !== "" &&
      typeof this.props.location.state.grandTotal !== undefined &&
      typeof this.props.location.state.grandTotal !== "undefined"
        ? this.props.location.state.grandTotal
        : "";
    var subTotal =
      this.props.location.state.subTotal !== "" &&
      typeof this.props.location.state.subTotal !== undefined &&
      typeof this.props.location.state.subTotal !== "undefined"
        ? this.props.location.state.subTotal
        : "";
    var totalFlightPrice =
      this.props.location.state.totalFlightPrice !== "" &&
      typeof this.props.location.state.totalFlightPrice !== undefined &&
      typeof this.props.location.state.totalFlightPrice !== "undefined"
        ? this.props.location.state.totalFlightPrice
        : "";
    var totalhotelPrice =
      this.props.location.state.totalhotelPrice !== "" &&
      typeof this.props.location.state.totalhotelPrice !== undefined &&
      typeof this.props.location.state.totalhotelPrice !== "undefined"
        ? this.props.location.state.totalhotelPrice
        : "";
    var transferPrice =
      this.props.location.state.transferPrice !== "" &&
      typeof this.props.location.state.transferPrice !== undefined &&
      typeof this.props.location.state.transferPrice !== "undefined"
        ? this.props.location.state.transferPrice
        : "";
    var emptyActivity =
      this.props.location.state.emptyActivity !== "" &&
      typeof this.props.location.state.emptyActivity !== undefined &&
      typeof this.props.location.state.emptyActivity !== "undefined"
        ? this.props.location.state.emptyActivity
        : "Yes";
    var flightPriceType = "";
    if (
      this.props.location.state.flightPriceType !== "" &&
      typeof this.props.location.state.flightPriceType !== undefined &&
      typeof this.props.location.state.flightPriceType !== "undefined"
    ) {
      flightPriceType = this.props.location.state.flightPriceType;
    }
    var viewSelectedFlight =
      this.props.location.state.viewSelectedFlight !== "" &&
      typeof this.props.location.state.viewSelectedFlight !== undefined &&
      typeof this.props.location.state.viewSelectedFlight !== "undefined"
        ? this.props.location.state.viewSelectedFlight
        : false;
    var ttdOnly = false;
    if (
      this.props.location.state.searchdata.ttdOnly !== "" &&
      typeof this.props.location.state.searchdata.ttdOnly !== undefined &&
      typeof this.props.location.state.searchdata.ttdOnly !== "undefined"
    ) {
      ttdOnly = this.props.location.state.searchdata.ttdOnly;
    }
    this.state = {
      userID: userID,
      /* Search Data & Package Details */
      searchdata: searchdata,
      packageSlug: packageSlug,
      packageDetails: packagedetails,
      end_date: end_date,
      /* Fligh */
      flightList: flightList,
      selectedflight: selectedflight,
      flightPrice: flightPrice,
      addFlight: addFlight,
      removeFlight: removeFlight,
      viewSelectedFlight: viewSelectedFlight,
      flightPriceType: flightPriceType,
      /* Activities */
      activities: activities,
      oldactivities: oldactivities,
      removedLastActivity: removedLastActivity,
      ArrivalDateFormat: ArrivalDateFormat,
      emptyActivity: emptyActivity,
      /* Hotel */
      selectedHotel: selectedHotel,
      selectedHotelID: selectedHotelID,
      stayList: stayList,
      selectedRoom: selectedRoom,
      /* Transfer */
      transferList: transferList,
      selectedTransfer: selectedTransfer,
      reserveTransfer: reserveTransfer,
      addTransfer: addTransfer,
      removetransfer: removetransfer,
      /*Empmty Status*/
      emptyFlight: emptyFlight,
      emptyhotel: emptyhotel,
      emptytransfer: emptytransfer,
      ownpackage: ownpackage,
      /*prices */
      totalActvitiesPrice: totalActvitiesPrice,
      totalFlightPrice: totalFlightPrice,
      totalhotelPrice: totalhotelPrice,
      transferPrice: transferPrice,
      grandTotal: grandTotal,
      subTotal: subTotal,
      ttdOnly: ttdOnly,
      activities_count: 0,

      finalDropoffTime1: "",
      transferdate1: "",
      finalDropoffTime2: "",
      transferdate2: "",

      isFlight: false,
      isActivities: false,
      isVacation: true,

      showFareBreakdown: false,
      isMobile: window.innerWidth <= 480,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
    };
  }

  componentDidMount() {
    this.updateActivityCount();
    this.calculateFinalDropoffTimes();
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activities !== this.state.activities) {
      this.updateCount();
    }
  }
  updateActivityCount() {
    var count = 0;
    this.state.activities.map((item, index) =>
      item.activities.map((activity, timeindex) => {
        console.log(activity, "activity");
        if (activity !== "") {
          count++;
        }
      })
    );
    console.log(count);
    this.setState({ activities_count: count });
  }

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  continuebooknow() {
    var error = 0;
    var message = "";
    /* if (this.state.addFlight === "No" && this.state.removeFlight === "No") {
      message += "<p>Please add flight</p>";
      error++;
    } */
    if (this.state.packageSlug !== "create-own-package") {
      if (
        this.state.selectedHotelID === "" &&
        this.state.packageSlug !== "create-own-package" &&
        this.state.ttdOnly === false
      ) {
        message += "<p>Please select any one hotel</p>";
        error++;
      }
    }
    /* if (this.state.selectedTransfer === "") {
    message += "<p>Please add transfer</p>";
    error++;
  } */

    /*  var emptyActivity = "Yes";
    var removeActivityList = [];
    if (this.state.activities.length > 0) {
      this.state.activities.map((item) => {
        if (item.activities.length > 0) {
          emptyActivity = "No";
          item.activities.map((actvityItem) => {
            if (actvityItem !== "") {
              if (actvityItem.activity_availablity !== "Yes") {
                removeActivityList.push(
                  actvityItem.products_name +
                  "(" +
                  actvityItem.package_name +
                  ")"
                );
              }
            }
          });
        }
      });
    }
    if (removeActivityList.length > 0) {
      this.setState({ removeActivityList: removeActivityList }, function () {
        $.magnificPopup.open({
          items: {
            src: "#activity-not-avail-alert",
          },
          type: "inline",
        });
      });
      return false;
    } */

    if (error === 0) {
      var searchdata = this.state.searchdata;
      var packageDetails = this.state.packageDetails;

      if (this.state.packageSlug === "create-own-package") {
        packageDetails = {};
        packageDetails["discount"] = "";
      }

      /*  searchdata["end_date"] = this.state.end_date;
      searchdata["start_date"] = this.state.start_date;
      searchdata["adults"] = this.state.adults;
      searchdata["child"] = this.state.child;
      searchdata["infant"] = this.state.infant;
      searchdata["room_count"] = this.state.room_count; */
      var bookingdetails = {
        /* Search Data & Package Details */
        searchdata: this.state.searchdata,
        packageSlug: this.state.packageSlug,
        packagedetails: packageDetails,
        /* Fligh */
        flightList: this.state.flightList,
        selectedflight: this.state.selectedflight,
        flightPrice: this.state.flightPrice,
        flightcheapestPrice: this.state.flightcheapestPrice,
        flightPriceType: this.state.flightPriceType,
        addFlight: this.state.addFlight,
        removeFlight: this.state.removeFlight,
        viewSelectedFlight: this.state.viewSelectedFlight,
        /* Activities */
        activities: this.state.activities,
        oldactivities: this.state.oldactivities,
        removedLastActivity: this.state.removedLastActivity,
        ArrivalDateFormat: this.state.ArrivalDateFormat,
        /* Hotel */
        selectedHotel: this.state.selectedHotel,
        selectedHotelID: this.state.selectedHotelID,
        stayList: this.state.stayList,
        selectedHotel: this.state.selectedHotel,
        selectedRoom: this.state.selectedRoom,
        /* Transfer */
        transferList: this.state.transferList,
        selectedTransfer: this.state.selectedTransfer,
        reserveTransfer: this.state.reserveTransfer,
        addTransfer: this.state.addTransfer,
        removetransfer: this.state.removetransfer,
        /*Empmty Status*/
        emptyFlight: this.state.emptyFlight,
        emptyhotel: this.state.emptyhotel,
        emptytransfer: this.state.emptytransfer,
        emptyActivity: this.state.emptyActivity,
        ttdOnly: this.state.ttdOnly,
        /*prices*/
        totalActvitiesPrice: this.state.totalActvitiesPrice,
        totalFlightPrice: this.state.totalFlightPrice,
        totalhotelPrice: this.state.totalhotelPrice,
        transferPrice: this.state.transferPrice,
        subTotal: this.state.subTotal,
        grandTotal: this.state.grandTotal,
      };
      if (bookingdetails) {
        localStorage.setItem("bookingdetails", JSON.stringify(bookingdetails));
      }
      if (this.state.totalAvailctvities > 0) {
        this.props.history.push({
          pathname: "/" + this.state.packageSlug + "/package-activities",
          state: bookingdetails,
        });
      } else {
        if (this.state.userID === "") {
          this.setState({ triggerLogin: true, continuestep: true });
        } else {
          this.props.history.push({
            pathname: "/booking",
            state: bookingdetails,
          });
        }
      }
    } else {
      showAlert("Error", message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  editPackage() {
    var bookingdetails = {
      /* Search Data & Package Details */
      searchdata: this.state.searchdata,
      packageSlug: this.state.packageSlug,
      packagedetails: this.state.packageDetails,
      /* Flight */
      flightList: this.state.flightList,
      selectedflight: this.state.selectedflight,
      flightPrice: this.state.flightPrice,
      flightcheapestPrice: this.state.flightcheapestPrice,
      flightPriceType: this.state.flightPriceType,
      addFlight: this.state.addFlight,
      removeFlight: this.state.removeFlight,
      viewSelectedFlight: this.state.viewSelectedFlight,
      /* Activities */
      activities: this.state.activities,
      oldactivities: this.state.oldactivities,
      removedLastActivity: this.state.removedLastActivity,
      ArrivalDateFormat: this.state.ArrivalDateFormat,
      /* Hotel */
      selectedHotel: this.state.selectedHotel,
      selectedHotelID: base64.encode(this.state.selectedHotelID),
      stayList: this.state.stayList,
      selectedHotel: this.state.selectedHotel,
      selectedRoom: this.state.selectedRoom,
      /* Transfer */
      transferList: this.state.transferList,
      selectedTransfer: this.state.selectedTransfer,
      reserveTransfer: this.state.reserveTransfer,
      addTransfer: this.state.addTransfer,
      removetransfer: this.state.removetransfer,
      /*Empmty Status*/
      emptyFlight: this.state.emptyFlight,
      emptyhotel: this.state.emptyhotel,
      emptytransfer: this.state.emptytransfer,
      /* emptyActivity: this.state.emptyActivity, */
      ttdOnly: this.state.ttdOnly,
      /*prices*/
      totalActvitiesPrice: this.state.totalActvitiesPrice,
      totalFlightPrice: this.state.totalFlightPrice,
      totalhotelPrice: this.state.totalhotelPrice,
      transferPrice: this.state.transferPrice,
      subTotal: this.state.subTotal,
      grandTotal: this.state.grandTotal,
    };
    this.props.history.push({
      pathname: "/search-package/" + this.state.packageSlug,
      state: bookingdetails,
    });
    console.log("/search-package/" + this.state.packageSlug, "path name");
  }
  convertDateFormat(dateString) {
    var [day, month, year] = dateString.split("/");
    const date = new Date(year, month - 1, day);
    const monthAbbr = date.toLocaleString("default", { month: "short" });
    const yearShort = date.getFullYear().toString().slice(-2);
    return `${day} ${monthAbbr} ${yearShort}`;
  }
  calculateFinalDropoffTimes = () => {
    if (this.state.addTransfer === "Yes") {
      /* var dropoffTime1 = this.state.flightPrice?.onwayInfo[
        this.state.flightPrice?.onwayInfo?.length - 1
      ]?.ArrivalTime

      var dropoffHours1 = parseInt(dropoffTime1.substring(0, 2), 10);
      var dropoffMinutes1 = parseInt(dropoffTime1.substring(3), 10);
      var durationMin1 = parseInt(
        this.state.transferList[this.state.selectedTransfer]?.duration_min
      );
      var finalHours1 =
        dropoffHours1 +
        Math.floor((dropoffMinutes1 + parseInt(durationMin1)) / 60);
      var finalMinutes1 = (dropoffMinutes1 + durationMin1) % 60;
      finalHours1 = finalHours1 % 24; */

      var originalTime =
        this.state.flightPrice?.onwayInfo[
          this.state.flightPrice?.onwayInfo?.length - 1
        ]?.ArrivalTime;
      var hours = parseInt(originalTime.substring(0, 2), 10);
      var minutes = parseInt(originalTime.substring(3), 10);
      var additionalMinutes = parseInt(
        this.state.transferList[this.state.selectedTransfer]?.duration_min
      );
      var totalMinutes = hours * 60 + minutes + additionalMinutes;
      var finalHours = Math.floor(totalMinutes / 60);
      var finalMinutes = totalMinutes % 60;
      var finalTime =
        ("0" + finalHours).slice(-2) + ":" + ("0" + finalMinutes).slice(-2);

      var dropoffTime2 = this.state.reserveTransfer?.pickuptime_for_journey2;
      var dropoffHours2 = parseInt(dropoffTime2.substring(0, 2), 10);
      var dropoffMinutes2 = parseInt(dropoffTime2.substring(2), 10);
      var durationMin2 = parseInt(
        this.state.transferList[this.state.selectedTransfer]?.duration_min
      );
      var finalHours2 =
        dropoffHours2 +
        Math.floor((dropoffMinutes2 + parseInt(durationMin2)) / 60);
      var finalMinutes2 = (dropoffMinutes2 + durationMin2) % 60;
      finalHours2 = finalHours2 % 24;

      var transferdate1 = this.convertDateFormat(
        this.state.reserveTransfer?.pickupdate_for_journey1
      );
      var transferdate2 = this.convertDateFormat(
        this.state.reserveTransfer?.pickupdate_for_journey2
      );
      this.setState({
        finalDropoffTime1: /* `${finalHours1
          .toString()
          .padStart(2, "0")}:${finalMinutes1.toString().padStart(2, "0")}` */ finalTime,
        transferdate1: transferdate1,
        finalDropoffTime2: `${finalHours2
          .toString()
          .padStart(2, "0")}:${finalMinutes2.toString().padStart(2, "0")}`,
        transferdate2: transferdate2,
      });
    }
  };
  render() {
    console.log(this.state, "state values");
    return (
      <>
        <Header
          {...this.props}
          pagestate={this.state}
          sateValChange={this.sateValChange}
        />
        <div className="container">
          <div className="vacationSummary">
            <div className="vacationSummary__heading">
              Your vacation summary
            </div>
            <div className="vacationSummary__subHead">
              Carefully read your summary before booking
            </div>
            <div className="vacationSummary__row">
              <div className="vacationSummary__row__col1">
                {this.state.addFlight === "Yes" && (
                  <div className="vacationSummary__row__col1__box">
                    <div className="vacationSummary__row__col1__box__row1">
                      <div className="vacationSummary__row__col1__box__row1__title">
                        Your flight for the vacation{" "}
                        <span>
                          {this.state.flightPrice?.returnInfo?.length > 0
                            ? "Flight round trip"
                            : "One Way"}
                        </span>
                      </div>
                      <div
                        className="vacationSummary__row__col1__box__row1__edit"
                        onClick={this.editPackage.bind(this)}
                      >
                        <EditIcon />
                        Edit
                      </div>
                    </div>
                    <div className="vacationSummary__row__col1__box__row2">
                      {this.state.flightPrice?.onwayInfo?.length > 0 && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Onward information
                          </div>
                          <ul>
                            <li>
                              Depature from{" "}
                              {
                                this.state.flightPrice?.onwayInfo[0]
                                  ?.DepartureAirportLocation
                              }{" "}
                              at{" "}
                              {
                                this.state.flightPrice?.onwayInfo[0]
                                  ?.DepartureTime
                              }{" "}
                              on{" "}
                              {
                                this.state.flightPrice?.onwayInfo[0]
                                  ?.DepartureDate
                              }
                            </li>
                            <li>
                              Arrival at{" "}
                              {
                                this.state.flightPrice?.onwayInfo[
                                  this.state.flightPrice?.onwayInfo?.length - 1
                                ]?.ArrivalAirportLocation
                              }{" "}
                              at{" "}
                              {
                                this.state.flightPrice?.onwayInfo[
                                  this.state.flightPrice?.onwayInfo?.length - 1
                                ]?.ArrivalTime
                              }{" "}
                              on{" "}
                              {
                                this.state.flightPrice?.onwayInfo[
                                  this.state.flightPrice?.onwayInfo?.length - 1
                                ]?.ArrivalDate
                              }
                            </li>
                            <li>
                              {this.state.flightPrice?.onwayInfo?.length === 1
                                ? "Direct Flight"
                                : this.state.flightPrice?.onwayInfo?.map(
                                    (stop, index) => (
                                      <div key={index}>
                                        {index <
                                          this.state.flightPrice?.onwayInfo
                                            ?.length -
                                            1 && `Stop ${index + 1}:`}{" "}
                                        {index <
                                          this.state.flightPrice?.onwayInfo
                                            .length -
                                            1 &&
                                          ` at ${this.state.flightPrice?.onwayInfo[index]?.ArrivalAirportLocation}.`}
                                      </div>
                                    )
                                  )}
                            </li>
                            <li>
                              Journey Time :{" "}
                              {this.state.flightPrice?.JourneyDuration}
                            </li>
                            <li>
                              {
                                this.state.flightPrice?.onwayInfo[0]
                                  ?.OperatingCarrieName
                              }{" "}
                            </li>
                          </ul>
                        </div>
                      )}
                      {this.state.flightPrice?.returnInfo?.length > 0 && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Return information
                          </div>
                          <ul>
                            <li>
                              Depature from{" "}
                              {
                                this.state.flightPrice?.returnInfo[0]
                                  ?.DepartureAirportLocation
                              }{" "}
                              at{" "}
                              {
                                this.state.flightPrice?.returnInfo[0]
                                  ?.DepartureTime
                              }{" "}
                              on{" "}
                              {
                                this.state.flightPrice?.returnInfo[0]
                                  ?.DepartureDate
                              }
                            </li>
                            <li>
                              Arrival at{" "}
                              {
                                this.state.flightPrice?.returnInfo[
                                  this.state.flightPrice?.returnInfo?.length - 1
                                ]?.ArrivalAirportLocation
                              }{" "}
                              at{" "}
                              {
                                this.state.flightPrice?.returnInfo[
                                  this.state.flightPrice?.returnInfo?.length - 1
                                ]?.ArrivalTime
                              }{" "}
                              on{" "}
                              {
                                this.state.flightPrice?.returnInfo[
                                  this.state.flightPrice?.returnInfo?.length - 1
                                ]?.ArrivalDate
                              }
                            </li>
                            <li>
                              {this.state.flightPrice?.returnInfo?.length === 1
                                ? "Direct Flight"
                                : this.state.flightPrice?.returnInfo?.map(
                                    (stop, index) => (
                                      <div key={index}>
                                        {index <
                                          this.state.flightPrice?.returnInfo
                                            ?.length -
                                            1 && `Stop ${index + 1}:`}{" "}
                                        {index <
                                          this.state.flightPrice.returnInfo
                                            .length -
                                            1 &&
                                          ` at ${this.state.flightPrice?.returnInfo[index]?.ArrivalAirportLocation}.`}
                                      </div>
                                    )
                                  )}
                            </li>
                            <li>
                              Journey Time :{" "}
                              {this.state.flightPrice?.returnJourneyDuration}
                            </li>
                            <li>
                              {
                                this.state.flightPrice?.returnInfo[0]
                                  ?.OperatingCarrieName
                              }{" "}
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.flightPrice?.totalPayAmount,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}{" "}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                )}
                {this.state.selectedRoom?.length > 0 && (
                  <div className="vacationSummary__row__col1__box">
                    <div className="vacationSummary__row__col1__box__row1">
                      <div className="vacationSummary__row__col1__box__row1__title">
                        Your stay for the vacation{" "}
                        {this.state.selectedRoom?.length > 1 ? (
                          <span> Multi Stay</span>
                        ) : (
                          <span>Single Stay</span>
                        )}
                      </div>
                      <div
                        className="vacationSummary__row__col1__box__row1__edit"
                        onClick={this.editPackage.bind(this)}
                      >
                        <EditIcon /> Edit
                      </div>
                    </div>
                    {this.state.selectedHotel &&
                      this.state.selectedRoom.length > 0 && (
                        <div className="vacationSummary__row__col1__box__row2">
                          <div className="vacationSummary__row__col1__box__row2__col">
                            <div className="vacationSummary__row__col1__box__row2__col__title">
                              Stay information{" "}
                            </div>
                            <ul>
                              <li>{this.state.selectedHotel?.hotel_name}.</li>
                              <li>{this.state.selectedHotel?.address}.</li>
                              <li>
                                {this.state.selectedRoom?.map(
                                  (room, indexRoom) => (
                                    <div key={indexRoom}>
                                      Room {indexRoom + 1} :
                                      {this.state.selectedHotel?.room_info.map(
                                        (item, indexHotel) =>
                                          indexRoom === indexHotel &&
                                          item.map(
                                            (hotelRoom) =>
                                              hotelRoom.room_type_code ===
                                                room?.room_type_code && (
                                                <span>
                                                  {" "}
                                                  {hotelRoom.room_type_name}
                                                  {hotelRoom?.room_charges[0]
                                                    ?.rate_description && (
                                                    <>
                                                      {" "}
                                                      with{" "}
                                                      {
                                                        hotelRoom
                                                          .room_charges[0]
                                                          .rate_description
                                                      }
                                                    </>
                                                  )}
                                                </span>
                                              )
                                          )
                                      )}
                                    </div>
                                  )
                                )}
                              </li>
                              <li>
                                Check In at{" "}
                                {this.state.selectedHotel?.hotel_check_in} on{" "}
                                {new Date(
                                  this.state.searchdata?.start_date
                                ).toLocaleDateString("en-IN", {
                                  day: "numeric",
                                  month: "short",
                                  year: "2-digit",
                                })}{" "}
                              </li>
                              <li>
                                Check Out at{" "}
                                {this.state.selectedHotel?.hotel_check_out} on{" "}
                                {new Date(
                                  this.state.searchdata?.end_date
                                ).toLocaleDateString("en-IN", {
                                  day: "numeric",
                                  month: "short",
                                  year: "2-digit",
                                })}{" "}
                              </li>
                            </ul>
                          </div>
                          {this.state.selectedHotel?.hotel_amenities && (
                            <div className="vacationSummary__row__col1__box__row2__col">
                              <div className="vacationSummary__row__col1__box__row2__col__title">
                                Amenities{" "}
                              </div>
                              <ul>
                                {this.state.selectedHotel?.hotel_amenities
                                  ?.split("#@#")
                                  .slice(0, 5)
                                  .map((amenity, index) => (
                                    <li key={index}>{amenity}</li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.totalhotelPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                )}
                {this.state.totalActvitiesPrice > 0 && (
                  <div className="vacationSummary__row__col1__box">
                    <div className="vacationSummary__row__col1__box__row1">
                      <div className="vacationSummary__row__col1__box__row1__title">
                        Your vacation plan{" "}
                        <span>{this.state.activities_count} Things to do</span>
                      </div>
                      <div
                        className="vacationSummary__row__col1__box__row1__edit"
                        onClick={this.editPackage.bind(this)}
                      >
                        <EditIcon /> Edit
                      </div>
                    </div>
                    <div className="vacationSummary__row__col1__box__row2">
                      {this.state.activities.map((item, index) =>
                        item.activities.map(
                          (activity, timeindex) =>
                            activity !== "" && (
                              <div className="vacationSummary__row__col1__box__row2__col">
                                <React.Fragment key={timeindex}>
                                  <div className="vacationSummary__row__col1__box__row2__col__title">
                                    Day {item.day} -{" "}
                                    {new Date(item.date).toLocaleDateString(
                                      "en-IN",
                                      {
                                        day: "numeric",
                                        month: "short",
                                        year: "2-digit",
                                      }
                                    )}{" "}
                                    -{timeindex === 0 && <span>Morning</span>}
                                    {timeindex === 1 && <span>Afternoon</span>}
                                    {timeindex === 2 && <span>Evening</span>}
                                  </div>
                                  <ul>
                                    {activity.package_name &&
                                      activity.products_name && (
                                        <li>
                                          {activity?.products_name}-
                                          {activity?.package_name}
                                        </li>
                                      )}
                                    {activity.distance && (
                                      <li>
                                        Distance from stay is{" "}
                                        {activity?.distance}
                                      </li>
                                    )}
                                    {activity.guided && (
                                      <li>Guided : {activity?.guided}</li>
                                    )}
                                    {activity.ttd_duration && (
                                      <li>
                                        Duration : {activity?.ttd_duration}
                                      </li>
                                    )}
                                  </ul>
                                </React.Fragment>
                              </div>
                            )
                        )
                      )}
                    </div>
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.totalActvitiesPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                )}
                {this.state.addTransfer === "Yes" && (
                  <div className="vacationSummary__row__col1__box">
                    <div className="vacationSummary__row__col1__box__row1">
                      <div className="vacationSummary__row__col1__box__row1__title">
                        Your airport transfer{" "}
                        <span>
                          {this.state.flightPrice?.returnInfo?.length > 0 &&
                          this.state.addTransfer === "Yes"
                            ? "Airport transfer round trip"
                            : "One Way"}
                        </span>
                      </div>
                      <div
                        className="vacationSummary__row__col1__box__row1__edit"
                        onClick={this.editPackage.bind(this)}
                      >
                        <EditIcon /> Edit
                      </div>
                    </div>
                    <div className="vacationSummary__row__col1__box__row2">
                      <div className="vacationSummary__row__col1__box__row2__col">
                        <div className="vacationSummary__row__col1__box__row2__col__title">
                          Onward information
                        </div>
                        <ul>
                          <li>
                            Pick up at{" "}
                            {
                              this.state.flightPrice.onwayInfo[
                                this.state.flightPrice.onwayInfo.length - 1
                              ]?.ArrivalAirportLocation
                            }{" "}
                            at{" "}
                            {
                              this.state.flightPrice?.onwayInfo[
                                this.state.flightPrice?.onwayInfo?.length - 1
                              ]?.ArrivalTime
                            }{" "}
                            on{" "}
                            {
                              this.state.flightPrice?.onwayInfo[
                                this.state.flightPrice?.onwayInfo?.length - 1
                              ]?.ArrivalDate
                            }
                          </li>
                          <li>
                            Drop off at {this.state.selectedHotel?.hotel_name}{" "}
                            at {this.state.finalDropoffTime1} on{" "}
                            {this.state.transferdate1}
                          </li>
                          <li>
                            {
                              this.state.transferList[
                                this.state.selectedTransfer
                              ]?.vehicle
                            }{" "}
                            vehicle
                          </li>
                          <li>
                            Estimated journey{" "}
                            {
                              this.state.transferList[
                                this.state.selectedTransfer
                              ]?.duration_min
                            }{" "}
                            min
                          </li>
                          <li>
                            Estimated distance{" "}
                            {
                              this.state.transferList[
                                this.state.selectedTransfer
                              ]?.distance_km
                            }{" "}
                            km
                          </li>
                        </ul>
                      </div>
                      {this.state.flightPrice.returnInfo.length > 0 && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Return information
                          </div>
                          <ul>
                            <li>
                              Pick up at {this.state.selectedHotel?.hotel_name}{" "}
                              at{" "}
                              {this.state.reserveTransfer?.pickuptime_for_journey2?.slice(
                                0,
                                2
                              )}
                              :
                              {this.state.reserveTransfer?.pickuptime_for_journey2?.slice(
                                2
                              )}{" "}
                              on {this.state.transferdate2}
                            </li>
                            <li>
                              Drop off at{" "}
                              {
                                this.state.flightPrice.onwayInfo[
                                  this.state.flightPrice.onwayInfo.length - 1
                                ]?.ArrivalAirportLocation
                              }{" "}
                              at {this.state.finalDropoffTime2} on{" "}
                              {this.state.transferdate2}
                            </li>
                            <li>
                              {
                                this.state.transferList[
                                  this.state.selectedTransfer
                                ]?.vehicle
                              }{" "}
                              vehicle
                            </li>
                            <li>
                              Estimated journey{" "}
                              {
                                this.state.transferList[
                                  this.state.selectedTransfer
                                ]?.duration_min
                              }{" "}
                              min
                            </li>
                            <li>
                              Estimated distance{" "}
                              {
                                this.state.transferList[
                                  this.state.selectedTransfer
                                ]?.distance_km
                              }{" "}
                              km
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.transferPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                )}
              </div>
              {!this.state.isMobile && (
                <div className="vacationSummary__row__col2">
                  <>
                    <div className="detail-bottom-rhs-price rhs-white-box">
                      <h5>Price details</h5>
                      <ul>
                        {this.state.totalFlightPrice > 0 &&
                          this.state.addFlight === "Yes" && (
                            <li>
                              <span className="service-content">
                                <strong>Flight fee</strong>
                              </span>
                              <span className="service-amount">
                                <strong>
                                  {showPriceValue(
                                    this.state.totalFlightPrice,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>
                              </span>
                            </li>
                          )}
                        {this.state.totalhotelPrice > 0 && (
                          <li>
                            <span className="service-content">
                              <strong>Hotel fee</strong>
                            </span>
                            <span className="service-amount">
                              <strong>
                                {showPriceValue(
                                  this.state.totalhotelPrice,
                                  this.state.currentCurrency,
                                  this.state.currencyRate
                                )}
                              </strong>
                            </span>
                          </li>
                        )}
                        {this.state.transferPrice > 0 && (
                          <li>
                            <span className="service-content">
                              <strong>Transfer fee</strong>
                            </span>
                            <span className="service-amount">
                              <strong>
                                {showPriceValue(
                                  this.state.transferPrice,
                                  this.state.currentCurrency,
                                  this.state.currencyRate
                                )}
                              </strong>
                            </span>
                          </li>
                        )}
                        {this.state.totalActvitiesPrice > 0 && (
                          <li>
                            <span className="service-content">
                              <strong>Things to do fee</strong>
                            </span>
                            {this.state.totalActvitiesPrice > 0 && (
                              <span className="service-amount">
                                <strong>
                                  {showPriceValue(
                                    this.state.totalActvitiesPrice,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>
                              </span>
                            )}
                          </li>
                        )}
                        <ul className="sub-taxes">
                          <li>
                            <span className="service-content">
                              Platform fee
                            </span>
                            <span className="service-amount">
                              {showPriceValue(
                                10,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </span>
                          </li>
                        </ul>

                        {/* {discountDetails !== "" && (
                          <ul className="sub-taxes">
                            <li>
                              <span className="service-content">
                                {discountDetails.title}
                              </span>
                              <span className="service-amount">
                                -
                                {showPriceValue(
                                  discountDetails.amount,
                          this.state.currentCurrency,
                          this.state.currencyRate
                                )}
                              </span>
                            </li>
                          </ul>
                        )} */}

                        {parseFloat(this.state.grandTotal) > 0 && (
                          <li>
                            <span className="service-content">Total</span>
                            <span className="service-amount">
                              {showPriceValue(
                                this.state.grandTotal,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="vacationSummary__row__col2__info">
                      <InfoIcon />
                      Prices are updated frequently to avoid booking conflict.{" "}
                    </div>
                    <div className="booknow-btm">
                      <a
                        className={"button"}
                        href={void 0}
                        onClick={this.continuebooknow.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>

              <div className="row">
                <div className="col-1">
                  <div className="title">
                    Total package <span>fare</span>
                  </div>
                  {parseFloat(this.state.grandTotal) > 0 && (
                    <div className="price">
                      {" "}
                      {showPriceValue(
                        this.state.grandTotal,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </div>
                  )}
                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>{" "}
                </div>
                <div className="col-2">
                  <Button
                    className={"action"}
                    onClick={this.continuebooknow.bind(this)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              {this.state.totalFlightPrice > 0 &&
                this.state.addFlight === "Yes" && (
                  <li>
                    <span className="service-content">
                      <strong>Flight fee</strong>
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          this.state.totalFlightPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </li>
                )}

              {this.state.totalhotelPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Hotel fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalhotelPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.transferPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Transfer fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.transferPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.totalActvitiesPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Things to do fee</strong>
                  </span>
                  {this.state.totalActvitiesPrice > 0 && (
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          this.state.totalActvitiesPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  )}
                </li>
              )}

              <li>
                <span className="service-content">Platform fee</span>
                <span className="service-amount">
                  {showPriceValue(
                    10,
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )}
                </span>
              </li>
              {parseFloat(this.state.grandTotal) > 0 && (
                <li>
                  <span className="service-content">Total</span>
                  <span className="service-amount">
                    {showPriceValue(
                      this.state.grandTotal,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </span>
                </li>
              )}
            </ul>
            <Divider />
            {parseFloat(this.state.grandTotal) > 0 && (
              <div className="details">
                <div className="title">
                  Total <span>fare</span>
                </div>
                <div className="col-2">
                  <div className="price">
                    {showPriceValue(
                      this.state?.grandTotal,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="action">
              <Button
                className={"btn"}
                onClick={() => {
                  this.setState({ showFareBreakdown: false });
                  this.continuebooknow();
                }}
              >
                Continue{" "}
              </Button>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

export default VacationSummary;
