import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "./ChildAgeDialog.scss";

const ChildAgeDialog = (props) => {
  const {
    onClose,
    open,
    childAgeOptions,
    setSelectedIndex,
    selectedIndex,
    onChangeChildAge,
  } = props;

  const handleClose = () => {
    setSelectedIndex(null);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      disablePortal
      className="dialog-container"
    >
      <DialogTitle>Select child {parseInt(selectedIndex) + 1} age</DialogTitle>
      <div className="dialog-content">
        {childAgeOptions.map((option, index) => (
          <div
            className="option"
            key={index}
            onClick={() => {
              onChangeChildAge(selectedIndex, option?.value);
              handleClose();
            }}
          >
            {option?.label}
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default ChildAgeDialog;
