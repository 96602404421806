/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import abt from "../../common/images/helpbg.jpg";
import { Link } from "react-router-dom";
import Axios from "axios";
import { HelpApiUrl } from "../Helpers/Config";

class Aboutus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentType: "helpCenter",
    };
  }
  componentDidMount() {
    this.loadHelpLine();
  }

  loadHelpLine() {
    Axios.get(
      HelpApiUrl +
        `blog-posts?page=1&limit=10&contentType=${this.state.contentType}`
    ).then((response) => {
      console.log(response.data,"response");
    });
    // .then((res) => {
    //   // hideLoader("myaccount_update", "class");
    //   if (res.data.status === "ok") {
    //     showAlert("Success", res.data.message);

    //   }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="abt-banner rel">
          <img src={abt} alt="backgroundimg" />
          <div className="topics-serch">
            <h2>How can we help you?</h2>
            <div className="search-topics">
              <input type="text" placeholder="Search the Topics " />
            </div>
          </div>
        </div>
        <div className="help-content-topics">
          <div className="container">
            <div className="help-content-inner">
              <div className="hci-lhs">
                <ul>
                  <li>
                    <a href="#">Sed a nunc quis</a>{" "}
                  </li>
                  <li>
                    <a href="#">Lorem ipsum dolor sit amet</a>{" "}
                  </li>
                  <li>
                    <a href="#">Vestibulum arcu sem</a>{" "}
                  </li>
                  <li>
                    <a href="#">Orci varius natoque penatibus</a>{" "}
                  </li>
                  <li>
                    <a href="#">Nunc tempus condimentum</a>{" "}
                  </li>
                  <li>
                    <a href="#">Aenean quis quam eu ipsum mattis laoreet</a>{" "}
                  </li>
                  <li>
                    <a href="#">Curabitur dictum magna ut ullamcorper</a>{" "}
                  </li>
                  <li>
                    <a href="#">Aliquam non tristique libero</a>{" "}
                  </li>
                  <li>
                    <a href="#">Vivamus finibus risus convallis turpis</a>{" "}
                  </li>
                  <li>
                    <a href="#">Mauris sed velit blandit nibh finibus</a>{" "}
                  </li>
                </ul>
              </div>
              <div className="hci-rhs">
                <div className="main-titl">
                  <h2>About Fizota</h2>
                  <p>Mauris urna quam, convallis at dui id</p>
                </div>
                <div className="main-question">
                  <ul>
                    <Link to={"/helpcenter-detail"}>
                      <li>
                        <a href="#">Sed a nunc quis ?</a>{" "}
                      </li>
                    </Link>
                    <li>
                      <a href="#">Lorem ipsum dolor sit amet ?</a>{" "}
                    </li>
                    <li>
                      <a href="#">Vestibulum arcu sem ?</a>{" "}
                    </li>
                    <li>
                      <a href="#">Orci varius natoque penatibus ?</a>{" "}
                    </li>
                    <li>
                      <a href="#">Nunc tempus condimentum ?</a>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

export default Aboutus;
