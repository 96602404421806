/* eslint-disable */

import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import logo from "../common/images/logo.svg";
import { apiUrl, projectTitle } from "../components/Helpers/Config";
import "./admin.css";

export default class AdminLogin extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      password: "",
      verified: false,
    };
  }

  handleLogin = () => {
    const { password } = this.state;
    var formData = new FormData();
    formData.append("password", password);
    axios
      .post(apiUrl + "package/getPackage", formData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === "ok") {
          this.setState({ verified: true }, () => {
            var verified = this.state.verified;
            this.props.history.push("/packagedashboard", verified);
          });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();
    return (
      <>
        <header className="admin-header">
          <div className="container flex">
            <div className="header-lhs">
              <Link to={"/"}>
                {" "}
                <img src={logo} alt="logo" />{" "}
              </Link>
            </div>
          </div>
        </header>
        <body>
          <div className="admin-body admin-login-body">
            <div className="admin-body-container">
              <div className="admin-login-box">
                <h4 className="ad-lg-mrg">Enter your fizota admin password</h4>
                <input
                  type="password"
                  className="ad-lg-mrg"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                />
                <button className="ad-lg-mrg" onClick={this.handleLogin}>
                  Login
                </button>
                <p className="">
                  * this is fizota team admin login
                  <br /> please contact the tech team for more information
                </p>
              </div>
            </div>
          </div>
        </body>
        <div className="admin-footer">
          <div className="admin-container">
            <div className="admin-logo">
              <Link to="/" title="ChopChop" className="">
                {" "}
                <img src={logo} alt="ChopChop" />{" "}
              </Link>
            </div>
            <p className="admin-footer-p">
              We empower humans to inspire <br />
              discover and explore exoeriences
              <br />
              around the world
            </p>
          </div>

          <div className="admin-container">
            <div>
              <p>
                Copyright {yearSp} {projectTitle}.
              </p>
              <p>
                Made with <i className="fa fa-heart" aria-hidden="true"></i> by
                Fizota team
                <br />© 2023 Airvays Technologies Pte.Ltd All Rights Reserved
              </p>
            </div>
          </div>

          <div className="admin-container">
            {" "}
            <p>Travel Agent: TA03466</p>
          </div>
        </div>
      </>
    );
  }
}
