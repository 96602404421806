/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_BOOKINGHISTORY, SET_BOOKINGHISTORY } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetBookingHistory = function* () {
  yield takeEvery(GET_BOOKINGHISTORY, workerGetBookingHistory);
};

function* workerGetBookingHistory({ params }) {
  try {
    const uri = apiUrl + "booking/myBooking?" + params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BOOKINGHISTORY, value: resultArr });
  } catch {
    console.log("Get Booking history Failed");
  }
}
