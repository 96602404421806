import React, { useState } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import {
  Dialog,
  Button,
  CircularProgress,
  FormControl,
  Slide,
  Fade,
  Modal,
  Box,
} from "@mui/material";
import "./PackageLead.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ProfileIcon from "../../../common/images/profile-icon-pkg-lead.svg";
import MoonIcon from "../../../common/images/moon-icon-pkg-lead.svg";
import CalendarIcon from "../../../common/images/calendar-icon-pkg-lead.svg";
import PhoneInput from "react-phone-input-2";
import { landingPageapiUrl } from "../../Helpers/Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
var qs = require("qs");
var isMobile = window.innerWidth <= 480;
const Transition = React.forwardRef(function Transition(props, ref) {
  return isMobile ? (
    <Slide direction="up" ref={ref} {...props} />
  ) : (
    <Fade ref={ref} {...props} />
  );
});

const style = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "hidden",
};

const PackageLead = (props) => {
  const { open, handleClose, data, viewPackage, locationData } = props;
  const [popupError, setPopupError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClosePopup = async () => {
    await setPopupError(false);
    await handleClose();
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleViewPackage = (item) => {
    viewPackage(item);
  };

  const PostPackageLead = () => {
    if (validateEmail(state.email)) {
      setIsLoading(true);
      var postObject = {
        package_id: data.creator_package_id,
        package_title: data.creator_package_slug,
        email: state.email,
        phone_number: state.phoneNumber,
      };
      axios
        .post(
          landingPageapiUrl + "package/package_lead",
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            toast(res.data.message, { type: "success" });
            handleClose();
            setState({
              email: "",
              phoneNumber: "",
            });
            handleViewPackage(data);
          } else if (res.data.status === "error") {
            setPopupError(true);
          } else {
            toast(res.data.message, { type: "error" });
          }
          setIsLoading(false);
        });
    } else {
      setPopupError(true);
    }
  };

  const formatedDate = (fdate, ldate) => {
    const date1 = new Date(fdate);
    const date2 = new Date(ldate);
    const firstDate = date1.getDate();
    const lastDate = String(date2.getDate()).padStart(2, "0");
    const lastYear = String(date2.getFullYear()).slice(-2);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const lastMonth = monthNames[date2.getMonth()];
    return `${firstDate} - ${lastDate} ${lastMonth} ${lastYear}`;
  };
  const alternateDate = (days) => {
    return new Date().setDate(new Date().getDate() + days);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClosePopup}
        TransitionComponent={Transition}
        keepMounted
        closeAfterTransition
      >
        <Transition in={open} timeout={300}>
          <Box className="packageLead_cntnr" sx={style}>
            <div
              className="packageLead_cntnr__close"
              onClick={handleClosePopup}
            >
              <CloseIcon />
            </div>
            <div
              className="packageLead_cntnr__row1"
              style={{
                background: `linear-gradient(rgba(3, 3, 3, 0.7), rgba(0, 0, 0, 0.5), rgba(27, 27, 27, 0), rgba(34, 34, 34, 0.1)),url(${
                  isMobile
                    ? data.creator_package_thumbnail_mobile
                    : data.creator_package_thumbnail_web
                })center / cover`,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="packageLead_cntnr__row1__col1">
                <h4>{data.creator_package_name}</h4>
                {console.log(data, "data")}
                <div className="packageLead_cntnr__row1__col1__details">
                  <div
                    className="packageLead_cntnr__row1__col1__details__content
                "
                  >
                    <img src={ProfileIcon} alt="No Image" />
                    {locationData?.adults > 0 ? (
                      locationData?.adults > 1 ? (
                        <p>Adults {locationData?.adults}</p>
                      ) : (
                        <p>Adult {locationData?.adults}</p>
                      )
                    ) : (
                      <></>
                    )}
                    {locationData?.child > 0 ? (
                      locationData?.child > 1 ? (
                        <p>Childs {locationData?.child}</p>
                      ) : (
                        <p>Child {locationData?.child}</p>
                      )
                    ) : (
                      <></>
                    )}
                    {locationData?.infant > 0 ? (
                      locationData?.infant > 1 ? (
                        <p>Infants {locationData?.aduinfantlts}</p>
                      ) : (
                        <p>Infant {locationData?.infant}</p>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="packageLead_cntnr__row1__col1__details__content
                "
                  >
                    <img src={MoonIcon} alt="No Image" />
                    <p>Days {data.creator_package_total_days}</p>
                  </div>
                  <div
                    className="packageLead_cntnr__row1__col1__details__content
                "
                  >
                    <img src={CalendarIcon} alt="No Image" />
                    <p>
                      {formatedDate(
                        locationData?.start_date === ""
                          ? alternateDate(15)
                          : locationData.start_date,
                        locationData?.end_date === ""
                          ? alternateDate(22)
                          : locationData.end_date
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="packageLead_cntnr__row2">
              <div className="packageLead_cntnr__row2__col1">
                <h4>
                  One More Step to <span>Vacation Magic!</span>
                </h4>
              </div>
              <div className="packageLead_cntnr__row2__col2">
                <input
                  id="email-input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={setState.email}
                  onChange={handleChange}
                />
              </div>
              <div className="packageLead_cntnr__errMsg">
                {popupError ? (
                  state.email === "" ? (
                    <p>Email is required.</p>
                  ) : !validateEmail(state.email) ? (
                    <p>Please enter a valid email address.</p>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="packageLead_cntnr__row2__col3">
                <FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="phone"
                >
                  <input type="hidden" id="countryCode" />
                  <PhoneInput
                    country={"sg"}
                    value={state.phoneNumber}
                    onChange={(phone, data, event, formattedValue) => {
                      setState((prevState) => ({
                        ...prevState,
                        phoneNumber: formattedValue,
                      }));
                    }}
                  />
                </FormControl>
              </div>
              <div className="packageLead_cntnr__row2__col4">
                <p>
                  By clicking continue you agree to our
                  <span>
                    <Link to={"/terms-and-conditions"} target="_blank">
                      {" "}
                      Terms & Conditions{" "}
                    </Link>
                    ,{" "}
                    <Link to={"/privacy-policy"} target="_blank">
                      Privacy Policy{" "}
                    </Link>
                    .
                  </span>
                </p>
              </div>
              <div className="packageLead_cntnr__row2__col5">
                <Button
                  className="continue-btn"
                  variant="contained"
                  onClick={PostPackageLead}
                  onClose={handleClosePopup}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress className="spinner" size={25} />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </div>
          </Box>
        </Transition>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default PackageLead;
