/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Topmenu from "../Layout/Topmenu";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CREATOR_DETAIL } from "../../actions";
import uparrow from "../../common/images/arrow-up.svg";
import axios from "axios";
import { apiUrl } from "../Helpers/Config";
var base64 = require("base-64");
class Earnings extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    this.state = {
      CUserId: CUserId,
      creatordetail: "",
      selectedOption: "7days",
      chartData: [],
      commission: [],
      booking: [],
      transaction: [],
      transcation_status: [],
      Bookingpopup: "",
      thanDays: "than last 7 days",
      activeTab: "tab-1",
      popupTitle: "",
    };
    if (cookie.load("CUserId") == "" || cookie.load("CUserId") == undefined) {
      this.props.history.push("/");
    } else {
      var params = "creator_id=" + base64.encode(cookie.load("CUserId"));
      this.props.getCreatorDetail(params);
    }
  }

  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      this.fetchData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
  }

  viewearingdetils(creator) {
    console.log("creator earning");
    axios
      .get(
        apiUrl +
          "dashboard/commissiondetails/commissiondetails?commission_creator_id=" +
          creator.commission_creator_id +
          "&commission_booking_id=" +
          creator.commission_booking_id +
          "&days=" +
          this.state.selectedOption,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              Bookingpopup: response.data,
              popupTitle: creator.package_name,
            },
            function () {
              $.magnificPopup.open({
                items: {
                  src: "#earning-details",
                },
                type: "inline",
              });
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  fetchData() {
    axios
      .get(
        apiUrl +
          "dashboard/transactions?creator_id=" +
          this.state.CUserId +
          "&days=" +
          this.state.selectedOption,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              commission: response.data,
              booking: response.data.booking_details,
              transaction: response.data.transactions,
              transcation_status: response.data.transaction_stat,
            }
            // function () {
            //   if ($(".scroll-new").length > 0) {
            //     setTimeout(function () {
            //       $(".scroll-new").mCustomScrollbar();
            //     }, 1000);
            //   }
            // }
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  BookingPopup() {
    axios
      .get(
        apiUrl + "dashboard/commissiondetails",
        {
          params: {
            commission_creator_id: this.state.CUserId,
            commission_booking_id: 30,
          },
        },
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data, "creator");
          this.setState({
            Bookingpopup: response.data,
          });
          console.log(this.state.Booking, "response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  handleButtonClick(option) {
    this.setState({ selectedOption: option });
    if (option === "7days") {
      this.setState({
        thanDays: "than last 7 days",
      });
    } else if (option === "30days") {
      this.setState({
        thanDays: "than last 30 days",
      });
    } else if (option === "12months") {
      this.setState({
        thanDays: "than last 12 months",
      });
    }
  }
  changeTab = (tabId) => {
    this.setState({ activeTab: tabId }, function () {
      if ($(".scroll-new").length > 0) {
        setTimeout(function () {
          $(".scroll-new").mCustomScrollbar();
        }, 100);
      }
    });
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  render() {
    const Bookingperct =
      parseFloat(this.state.transcation_status.booking_percentage_diff) > 0;

    const Revenueperct =
      parseFloat(this.state.transcation_status.revenue_percentage_diff) > 0;

    const Payoutperct =
      parseFloat(this.state.transcation_status.payout_percentage_diff) > 0;

    return (
      <>
        <div>
          <div className="title-merge-btn">
            <h3 className="color-header">
              Your <span>earnings</span>
            </h3>
            <div className="three-merge-btn">
              <button
                className={`${
                  this.state.selectedOption === "7days" ? "active" : ""
                }`}
                onClick={() => this.handleButtonClick("7days")}
              >
                Week
              </button>
              <button
                className={`${
                  this.state.selectedOption === "30days" ? "active" : ""
                }`}
                onClick={() => this.handleButtonClick("30days")}
              >
                Month
              </button>
              <button
                className={`${
                  this.state.selectedOption === "12months" ? "active" : ""
                }`}
                onClick={() => this.handleButtonClick("12months")}
              >
                Year
              </button>
            </div>
          </div>
          <div className="four-box-graph">
            <div
              className={`fbg-inner  ${
                Revenueperct ? "up-box" : "down-bx"
              } active`}
            >
              <h6>Total Revenue</h6>
              <p>
                {" "}
                {this.state.transcation_status.total_revenue
                  ? this.state.transcation_status.total_revenue
                  : "NA"}
              </p>
              <span>
                <strong>
                  {Math.abs(
                    parseFloat(
                      this.state.transcation_status.revenue_percentage_diff
                    ).toFixed(2)
                  )}
                  % <img src={uparrow} alt="Arrow" />
                </strong>{" "}
                {this.state.thanDays}{" "}
              </span>
            </div>{" "}
            <div className={`fbg-inner ${Bookingperct ? "up-box" : "down-bx"}`}>
              {" "}
              <h6>Total Booking</h6>
              <p> {this.state.transcation_status.total_booking}</p>
              <span>
                <strong>
                  {Math.abs(
                    parseFloat(
                      this.state.transcation_status.booking_percentage_diff
                    ).toFixed(2)
                  )}
                  % <img src={uparrow} alt="Arrow" />
                </strong>{" "}
                {this.state.thanDays}
              </span>
            </div>{" "}
            <div className={`fbg-inner ${Payoutperct ? "up-box" : "down-bx"}`}>
              {" "}
              <h6>Total Payout</h6>
              <p>{this.state.transcation_status.total_payout} </p>
              <span>
                <strong>
                  {Math.abs(
                    parseFloat(
                      this.state.transcation_status.payout_percentage_diff
                    ).toFixed(2)
                  )}
                  % <img src={uparrow} alt="Arrow" />
                </strong>{" "}
                {this.state.thanDays}
              </span>
            </div>{" "}
            <div className="fbg-inner">
              <h6>Pendings Payout</h6>
              <p>$ {this.state.transcation_status.pending_payout} </p>
              {/* <span>
                  <strong>
                    4.3% <img src={uparrow} alt="Arrow" />
                  </strong>{" "}
                  {this.state.thanDays}{" "}
                </span> */}
            </div>{" "}
          </div>
          <div className="earning-main">
            <div className="earning-rboxes"> </div>
            <div className="earning-details">
              <div className="earning-tab-nav">
                <ul>
                  <li
                    className={this.state.activeTab === "tab-1" ? "active" : ""}
                  >
                    <a onClick={() => this.changeTab("tab-1")}>Transactions</a>
                  </li>
                  {/* <li
                    className={
                      this.state.activeTab === "tab-2" ? "active" : ""
                    }
                  >
                    <a onClick={() => this.changeTab("tab-2")}>
                      Bookings
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="earning-tab-content">
                {this.state.activeTab === "tab-1" && (
                  <div className="etab btab" id="tab-2">
                    <div class="tearn-container">
                      <div class="table-head">
                        <div class="table-row table-row-title">
                          <li class="item-cell">Transaction Date</li>
                          <li class="item-cell">Reference</li>
                          <li class="item-cell">Amount</li>
                          <li class="item-cell">Status</li>
                        </div>
                      </div>
                      {this.state.transaction.length > 0 ? (
                        <div className="ctable-scroll scroll-new">
                          {this.state.transaction.map((item, index) => {
                            return (
                              <div class="table-body" key={index}>
                                <div class="table-row">
                                  <li class="item-cell">
                                    {item.transaction_date}
                                  </li>
                                  <li class="item-cell">
                                    {item.reference_number}
                                  </li>
                                  <li class="item-cell">
                                    SGD ${item.transaction_amount}
                                  </li>
                                  <li
                                    class={`item-cell ${
                                      item.transaction_status === "Paid"
                                        ? "grn-color"
                                        : "red-clr"
                                    }`}
                                  >
                                    {item.transaction_status}
                                  </li>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div class="table-body">No Record Found</div>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="etab" id="tab-2">
                  {this.state.activeTab === "tab-2" && (
                    <div className="etab" id="tab-1">
                      <div class="tearn-container">
                        <div class="table-head">
                          <div class="table-row table-row-title">
                            <li class="item-cell">Booking date</li>
                            <li class="item-cell">Package detail</li>
                            <li class="item-cell">Amount</li>
                            <li class="item-cell">TTD Amount</li>

                            <li class="item-cell">Status</li>
                            <li class="item-cell">Action</li>

                          </div>
                        </div>
                        {this.state.booking.length > 0 ? (
                          <div className="ctable-scroll scroll-new">
                            {this.state.booking.map((item, index) => {
                              return (

                                <div class="table-body" key={index}>
                                  <div class="table-row">
                                    <li class="item-cell">
                                      {item.booking_date}
                                    </li>
                                    <li class="item-cell">
                                      {item.package_name}{" "}
                                    </li>
                                    <li class="item-cell">
                                      SGD $
                                      {item.booking_commission_amount}
                                    </li>
                                    <li class="item-cell">SGD $ 41</li>

                                    <li class="item-cell grn-color">
                                      {item.booking_status}
                                    </li>
                                    <li class="item-cell">
                                      <a href="#" className="earn-action">
                                        <a
                                          href={void 0}
                                          className="earn-action"
                                          onClick={this.viewearingdetils.bind(
                                            this,
                                            item
                                          )}
                                        >
                                          View details
                                        </a>{" "}
                                      </a>{" "}

                                    </li>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="table-body">No Record Found</div>
                        )}
                      </div>
                    </div>
                  )}

                </div> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.Bookingpopup && (
          <div
            id="earning-details"
            className="mfp-hide common-popup earnpop-details"
          >
            <div className="ed-header">
              <h3>{this.state.popupTitle}</h3>
              <h4>Payout breakdown</h4>
            </div>
            <div className="ed-body">
              <ul>
                <li>
                  <span>Stay commission</span>
                  <strong>
                    S$ {this.state.Bookingpopup.commission_details.stay}
                  </strong>
                </li>
                <li>
                  <span>Things to do commission</span>
                  <strong>
                    S$ {this.state.Bookingpopup.commission_details.things_to_do}
                  </strong>
                </li>
                <li className="p-charge">
                  <span>Payout charges</span>
                  <strong>
                    S$
                    {this.state.Bookingpopup.commission_details.payout_charges}
                  </strong>
                </li>
                <li>
                  <span>Total payout</span>
                  <strong>
                    $ {this.state.Bookingpopup.commission_details.total_payout}
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Earnings);
