/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  apiUrl,
  headerconfig,
  googleMapsKey,
} from "../Helpers/Config";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import ContentLoader from "react-content-loader";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import bcal from "../../common/images/calender-banner.svg";
import bpin from "../../common/images/map-pin.svg";
import bprofile from "../../common/images/banner-profile.svg";
import barrow from "../../common/images/bluearrow.svg";
import avholder from "../../common/images/placeholder.jpg";
import filter from "../../common/images/filter.svg";
import cardbg from "../../common/images/card-image.jpg";
import medal from "../../common/images/medal.svg";
import facebook from "../../common/images/facebook.svg";
import insta from "../../common/images/instagram.svg";
import bb1 from "../../common/images/singapore-banner.jpg";
import bb2 from "../../common/images/map-top.jpg";
import { format, add } from "date-fns";
import {
  showPriceValue,
  checkingDiscount,
  showAlert,
} from "../Helpers/SettingHelper";
import { Country, State, City } from "country-state-city";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CREATOR_DETAIL } from "../../actions";
import { Helmet } from "react-helmet";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import FilterSheet from "../Customerpackage/FilterSheet";

var qs = require("qs");

const priceFilterOptions = [
  {
    label: "Below S$500",
    value: "500-",
  },
  {
    label: "S$500 - S$1000",
    value: "500-1000",
  },
  {
    label: "S$2000 - S$5000",
    value: "2000-5000",
  },
  {
    label: "Above S$5000",
    value: "-5000",
  },
];
const daysFilter = [
  {
    label: "2 to 5 days",
    value: "2-5",
  },
  {
    label: "6 to 8 days",
    value: "6-8",
  },
  {
    label: "9 to 12 days",
    value: "9-12",
  },
  {
    label: "13 to 15 days",
    value: "13-15",
  },
];
const getMonthYearFormat = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month}-${year}`;
};

const getNextYearMonths = () => {
  const today = new Date();
  const nextYear = today.getFullYear();

  const nextYearDates = [];
  for (let i = 0; i < 12; i++) {
    const nextMonthDate = new Date(nextYear, today.getMonth() + i, 1);
    const formattedDate = getMonthYearFormat(nextMonthDate);
    nextYearDates.push({
      label: formattedDate.split("-")[0],
      value: formattedDate,
    });
  }

  return nextYearDates;
};

const nextYearMonths = getNextYearMonths();

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "props");
    var cityName = "";
    var countryName = "";
    if (
      this.props.match.params !== "" &&
      this.props.match.params !== undefined &&
      this.props.match.params !== "undefined"
    ) {
      cityName = this.props.match.params.cityName;

      countryName = this.props.match.params.countryName;
    }
    this.helmetdata = [
      {
        title: "Customized Singapore Tour Packages in 2024 - Fizota",
        name: "Singapore",
        description:
          "Fizota.com presents unbeatable offers on Singapore tour packages. Choose from a variety of customized holiday packages in Singapore and discover the beauty of Singapore tourism. Plan your dream holidays in Singapore now!",
        keywords:
          "holiday packages in singapore, singapore tour packages, singapore tourism, holidays in singapore, singapore vacation packages all inclusive, singapore vacation packages, cheapest singapore tour package, best singapore vacation packages, singapore tour package 2024, vacations to go singapore, singapore holiday packages family, singapore tour packages for couple, singapore honeymoon tour packages",
      },
      {
        title:
          "Bali Vacation Packages for Couples in 2024 & Honeymoon Tours for Couples - Fizota",
        name: "Bali",
        description:
          "Fizota.com invites you to indulge in romance with our all-inclusive Bali honeymoon tour packages. Enjoy your holidays in Bali on a tailor-made vacation packages for couple. Book now!",
        keywords:
          "bali vacation package, bali honeymoon tour packages, bali vacation packages, bali vacation packages all inclusive, bali tour packages for couple, vacation package to bali, bali holiday packages all inclusive, bali tour package itinerary, bali tour package price for couple, holidays in bali, bali tourism, bali tour package 2024, best bali vacation packages",
      },
      {
        title: "Tokyo Tour Packages 2024 | Japan Tourism - Fizota",
        name: "Tokyo",
        description:
          "Fizota.com offers exclusive Japan vacation packages for 2024. Plan your dream getaway with Tokyo tour packages and seize the essence of a perfect holiday. Book now!",
        title:
          "japan vacation package, vacations to go japan, japan holiday package 2024, tokyo tour packages 2024, tokyo tourism package, tour packages tokyo, tokyo tourist packages, package tours to tokyo",
      },
      {
        title:
          "All-Inclusive Thailand Vacation Packages 2024 | Bangkok Tourism and Family Holidays - Fizota",
        name: "Bangkok",
        description:
          "Fizota.com invites to create lasting memories with our Thailand holiday packages 2024. From family getaways to Bangkok honeymoon packages, experience the best of vacations in Thailand. Book today!",
        keywords:
          "thailand vacation package, thailand vacation packages, thailand vacation packages all inclusive, thailand holiday packages all inclusive, thailand family holiday packages all inclusive, vacations in thailand all inclusive, thailand holiday packages family, thailand holiday packages 2024, bangkok vacation packages 2024, bangkok tourism, bangkok honeymoon packages",
      },
      {
        title:
          "Osaka Vacation Packages for Couples | Osaka Tours 2024 - Fizota",
        name: "Osaka",
        description:
          "Fizota.com offers unbeatable deals on Osaka tour packages. Plan your trip to Osaka with us and select from a variety of affordable and customized holiday packages. Book now for an unforgettable experience!",
        keywords:
          "japan vacation packages, japan tour packages for couple, vacation package in japan, osaka tour packages, osaka vacation packages, osaka holiday packages 2024",
      },
      // Add more objects as needed
    ];
    this.state = {
      cityName: cityName,
      locationData: {},
      flagUrl: "",
      searchResult: [],
      destinationCountry: countryName,
      destinationCity: cityName,
      search_days: "2-5",
      start_date: "",
      display_package: "",
      packageLoading: true,
      count: "",
      cityCode: 0,
      wishlist: [],
      country_List: [],
      cityList: [],
      isMobile: window.innerWidth <= 480,
      selectedcityhelmet: "",
      selectedcityhelmettitle: "",
      selectedcityhelmetdiscription: "",
      selectedcityhelmetkeyword: "",
      helmetCountries: ["Singapore", "Bali", "Tokyo", "Bangkok", "Osaka"],
      category: ["Couple", "Solo", "Friends", "Family"],
      categorylist: [],
      packageType: "uop",
      helmetCountryPresent: "",
      priceFilter: null,
      filterResult: [],
      wish: [],
      addedWishlist: [],
      uop: true,
      selectedMonth: "",
      displayFilter: "",
      openSortSheet: false,
      startPrice: "",
      endPrice: "",
      maxPrice: "",
    };
  }

  async componentDidMount() {
    const helmetCountryPresent = this.state.helmetCountries.includes(
      this.state.cityName
    );
    this.fetchLocationData();
    this.searchPackage();
    this.loadCity();
    this.loadFilter();
    if (helmetCountryPresent === true) {
      this.helmethandler();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.search_days !== this.state.search_days) {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
        this.displayPackage();
      });
    }
    if (
      prevState?.categorylist?.length !== this.state.categorylist?.length ||
      prevState?.priceFilter !== this.state.priceFilter
    ) {
      this.handleApplyFilter();
    }
    if (prevState?.packageType !== this.state.packageType) {
      this.searchPackage();
    }
  }

  loadRatingstar(points, type = "") {
    if (type === "hotel") {
      if (points === "Economy*") {
        points = "1.0";
      } else if (points === "Budget **") {
        points = "2.0";
      } else if (points === "Standard ***") {
        points = "3.0";
      } else if (points === "Superior ****") {
        points = "4.0";
      } else if (points === "Luxury *****") {
        points = "5.0";
      } else if (points === "Serviced Apartment") {
        points = "0.0";
      } else if (points === "Unrated") {
        points = "0.0";
      }
    }

    if (points === "" || points === null) {
      points = "0.0";
    }
    if (
      points !== "" &&
      points !== null &&
      typeof points !== undefined &&
      typeof points !== "undefined"
    ) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }

  applyFilter(item) {
    var packages = false;
    if (
      Math.round(this.state.startPrice) > 0 &&
      Math.round(item.creator_package_price) < Math.round(this.state.startPrice)
    ) {
      packages = false;
    }
    if (
      Math.round(this.state.endPrice) > 0 &&
      Math.round(item.creator_package_price) > Math.round(this.state.endPrice)
    ) {
      packages = false;
    }

    if (this.state.priceFilter) {
      if (
        Number(this.state.priceFilter?.split("-")[0]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) === 0
      ) {
        packages = true;
      }
      if (
        Number(this.state.priceFilter?.split("-")[1]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) === 0
      ) {
        packages = true;
      }

      if (
        Number(this.state.priceFilter?.split("-")[0]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) !== 0 &&
        Number(this.state.priceFilter?.split("-")[1]) !== 0
      ) {
        packages = true;
      }
    } else {
      packages = true;
    }
    var category = true;
    if (this.state.categorylist.length > 0) {
      if (
        this.state.categorylist.indexOf(item.creator_package_travelling) === -1
      ) {
        category = false;
      }
    }
    if (packages && category) {
      return true;
    } else {
      return false;
    }
  }

  handleDaysFilter(val) {
    this.setState({ search_days: val });
  }

  handlePriceFilter(val) {
    this.setState({ priceFilter: this.state.priceFilter === val ? null : val });
  }

  fetchLocationData() {
    axios
      .get(
        apiUrl + "package/getImages?city_name=" + this.state.destinationCity,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "error") {
          this.props.history.push("/404");
        }
        if (res.data.status === "ok") {
          this.setState({
            locationData: res.data.result_set,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  async fetchFlag(cityName) {
    try {
      const response = await axios(
        `https://restcountries.com/v3.1/name/${cityName}`
      );
      const data = response.data;
      const country = data[0];

      if (country && country.flags) {
        const flagUrl = country.flags.svg;
        this.setState({ flagUrl });

        console.log("Flag URL:", flagUrl);
      } else {
        console.error("Country not found or no flag data available.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
    if (this.state.destinationCity !== nextProps.match.params.cityName) {
      this.setState(
        {
          destinationCity: nextProps.match.params.cityName,
          destinationCountry: nextProps.match.params.countryName,
        },
        function () {
          this.searchPackage();
          this.fetchLocationData();
          this.loadCity();
        }
      );
    }
  }

  loadCity = async () => {
    try {
      const response = await axios.get(
        apiUrl + "settings/getCityList",
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );
      const { status, result_set } = response.data;
      const { destinationCountry, cityName } = this.state;
      if (status === "ok") {
        this.setState({
          country_List: response.data.result_set,
        });
        for (const [key, value] of Object.entries(result_set)) {
          // console.log(key, value);
          if (value.country.toLowerCase() === destinationCountry) {
            value.city.map((cityItem, cityIndex) => {
              // console.log(cityIndex, cityItem);
              if (cityItem.city_name.toLowerCase() === cityName) {
                this.setState({ cityCode: cityItem.webbeds_city_code });
              }
            });
          }
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  searchPackage() {
    var no_of_people = [];

    no_of_people.push({ people: "Adult", quantity: 1 });
    if (this.state.child > 0) {
      no_of_people.push({ people: "Child", quantity: this.state.child });
    }
    if (this.state.infant > 0) {
      no_of_people.push({ people: "Infant", quantity: this.state.infant });
    }
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    // var newstartdata = format(startdate, "yyyy-MM-dd");

    var postObject = {
      source: "BLR",
      destination:
        this.props.location.state?.airport_code !== ""
          ? this.props.location.state?.airport_code
          : this.state.locationData.airport_code,
      return_source:
        this.props.location.state?.airport_code !== ""
          ? this.props.location.state?.airport_code
          : this.state.locationData.airport_code,
      return_destination: "BLR",
      country: this.state.destinationCountry,
      city: this.state.destinationCity,
      trip_date: formattedDate,
      search_days: this.state.search_days,
      trip_type: "Return",
      no_of_people: JSON.stringify(no_of_people),
      trip_count: 2,
      customer_id: cookie.load("UserId"),
      packageType: this.state.packageType,
    };
    axios
      .post(
        apiUrl + "package/searchPackage",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        var wishlist = [...this.state.wish];

        if (res.data.status === "ok") {
          const wishlistCustomers = res.data.result_set.packages.filter(
            (customer) => customer.wishlist === "yes"
          );
          const wishlistCustomerIds = wishlistCustomers.map(
            (customer) => customer.creator_package_id
          );
          wishlist.push(wishlistCustomerIds);
          let packages = res.data.result_set.packages.filter(
            (pkg) => pkg.creator_package_source === this.state.packageType
          );
          this.processPackages(packages);

          this.setState(
            {
              wish: wishlist,
              searchResult: res.data.result_set.packages,
              filterResult: res.data.result_set.packages,
            },
            function () {
              this.displayPackage();
            }
          );
        } else {
          showAlert("Error", "No packages available");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.setState({ packageLoading: false });
        }
      })
      .catch((err) => {
        showAlert("Error", "Something went wrong, please try again later");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }, this.setState({ packageLoading: false }));
  }
  UopsearchPackage(storeInSearchResult = true) {
    var no_of_people = [];
    if (this.state.adults > 0) {
      no_of_people.push({ people: "Adult", quantity: this.state.adults });
    }
    if (this.state.child > 0) {
      no_of_people.push({ people: "Child", quantity: this.state.child });
    }
    if (this.state.infant > 0) {
      no_of_people.push({ people: "Infant", quantity: this.state.infant });
    }
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    // var newstartdata = format(startdate, "yyyy-MM-dd");

    var uoppostObject = {
      source: "BLR",
      destination:
        this.props.location.state?.airport_code !== ""
          ? this.props.location.state?.airport_code
          : this.state.locationData.airport_code,
      return_source:
        this.props.location.state?.airport_code !== ""
          ? this.props.location.state?.airport_code
          : this.state.locationData.airport_code,
      return_destination: "BLR",
      country: this.state.destinationCountry,
      city: this.state.destinationCity,
      search_days: this.state.search_days,
      trip_type: "Return",
      no_of_people: JSON.stringify(no_of_people),
      selectedMonth: this.state.selectedMonth,
      trip_count: 2,
      customer_id: cookie.load("UserId"),
      packageType: "uop",
    };
    axios
      .post(
        apiUrl + "package/searchPackage",
        qs.stringify(uoppostObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        var wishlist = [...this.state.wish];

        if (res.data.status === "ok") {
          const wishlistCustomers = res.data.result_set.packages.filter(
            (customer) => customer.wishlist === "yes"
          );
          const wishlistCustomerIds = wishlistCustomers.map(
            (customer) => customer.creator_package_id
          );
          wishlist.push(wishlistCustomerIds);
          this.setState(
            {
              wish: wishlist,
              searchResult: res.data.result_set.packages,
              filterResult: res.data.result_set.packages,
            },
            function () {
              this.displayPackage();
            }
          );
        } else {
          showAlert("Error", "Something went wrong, please try again later");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((err) => {
        showAlert("Error", "Something went wrong, please try again later");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
  }

  displayPackage() {
    var display_package = "";
    if (this.state.searchResult && this.state.searchResult.length > 0) {
      display_package =
        this.state.searchResult.length > 0 ? (
          <div
            className={`${
              this.state.isMobile ? "traveler-main-package-list" : ""
            }`}
          >
            <ul
              className={`${
                this.state.isMobile ? "ml-view package-ul" : "package-ul1"
              }`}
            >
              <span className="av-result">
                {this.state.filterResult.length} packages available
              </span>
              {this.state.filterResult.map((item, index) => {
                var discount = checkingDiscount(
                  item.discount,
                  item.creator_package_price
                );
                var displyAmount = item.creator_package_price;
                if (discount !== "") {
                  displyAmount =
                    parseFloat(item.creator_package_price) -
                    parseFloat(discount.amount);
                }
                var package_vacation_included = [];
                if (item?.creator_package_source === "uop") {
                  package_vacation_included = item?.creator_package_included
                    ? item?.creator_package_included.split(",")
                    : [];
                }
                return this.state.isMobile ? (
                  <li key={index}>
                    <div
                      className="product-list-parent"
                      // onClick={
                      //   this.state.isMobile && this.viewPackage.bind(this, item)
                      // }
                      onClick={this.viewPackage.bind(this, item)}
                    >
                      <div className="product-img">
                        <a
                          className="like"
                          onClick={() => this.addToWishlist(item)}
                        >
                          {this.state.wish[0].includes(
                            item.creator_package_id
                          ) ? (
                            <img src={heartfill} alt="heart" />
                          ) : (
                            <img src={heart} alt="heart" />
                          )}
                        </a>
                        <a
                          className="pt-img"
                          href={void 0}
                          onClick={this.viewPackage.bind(this, item)}
                        >
                          <img
                            src={
                              this.state.isMobile
                                ? item?.creator_package_thumbnail_mobile ||
                                  actinfo1
                                : item?.creator_package_thumbnail_web ||
                                  actinfo1
                            }
                            alt="actinfo"
                            loading="lazy"
                          />
                        </a>
                        <div className="ptd-day">
                          {" "}
                          {item.creator_package_total_days} Days
                        </div>
                        {discount !== "" && (
                          <span className="package-discount">
                            {showPriceValue(discount.amount)} Off
                          </span>
                        )}
                      </div>
                      <div className="product-description">
                        <div className="tags-row">
                          <div className="new-tags">
                            <div className="c-tags">
                              {item.creator_package_travelling}
                            </div>
                            <div className="e-tags">{item.category_title}</div>
                          </div>
                          <div className="days-col">
                            {item.creator_package_total_days} Days,{" "}
                            {item.creator_package_total_days - 1} Nights
                          </div>
                        </div>
                        <div className="product-description-title">
                          <div className="title-col">
                            <h5>
                              <a
                                href={void 0}
                                onClick={this.viewPackage.bind(this, item)}
                              >
                                {item.creator_package_name}
                              </a>{" "}
                            </h5>
                          </div>
                          {item.creator_package_rating !== "" &&
                            item.creator_package_rating !== null && (
                              <div className="review-col pdr-lhs">
                                <span>
                                  <strong>{item.creator_package_rating}</strong>{" "}
                                  ({item.creator_package_total_rating}) Reviews
                                </span>
                                <div className="star-rating">
                                  {/* {this.loadRatingstar(item.creator_package_rating)} */}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="product-created-content">
                          <div className="created-av">
                            <img
                              src={
                                item.creator_image !== "" &&
                                item.creator_image !== null
                                  ? item.creator_image
                                  : avholder
                              }
                              alt={item.creator_image}
                            />
                          </div>
                          <div className="created-av-title">
                            <h4>
                              <span>
                                {item.creator_package_source === "uop"
                                  ? "Operated by"
                                  : "Created by"}
                              </span>

                              {item.creator_handle_name}
                            </h4>
                            <div className="creator-badge">
                              <span>
                                <img src={medal} alt="Medal" />
                              </span>{" "}
                              <strong>
                                Level <em>01</em>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="sites-content">
                          <ul>
                            {item?.creator_package_included
                              ?.split(",")
                              ?.map((item, index) => (
                                <li key={index}>
                                  <figure>
                                    <img src={flight} alt="flight" />{" "}
                                  </figure>
                                  <figcaption>{item}</figcaption>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="footer-main">
                          <div className="product-description-review">
                            <div className="pdr-lhs">
                              <div
                                className={`package-source ${
                                  item?.creator_package_source === "uop"
                                    ? "bg-clr1"
                                    : "bg-clr"
                                }`}
                              >
                                {item?.creator_package_source === "uop"
                                  ? "Curated"
                                  : "Customisable"}
                              </div>
                            </div>
                            {item?.creator_package_source === "uop" && (
                              <div className="pdr-rhs">
                                {discount !== "" ? (
                                  <>
                                    <span className="package-original-amt">
                                      {showPriceValue(
                                        item.creator_package_price
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <em>Starts from</em>
                                )}
                                <strong>{showPriceValue(displyAmount)}</strong>{" "}
                              </div>
                            )}
                          </div>
                          <div className="cp-main">
                            <Button
                              variant="contained"
                              onClick={this.viewPackage.bind(this, item)}
                            >
                              {item?.creator_package_source === "uop"
                                ? "View package"
                                : "Check price"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li key={index}>
                    <div
                      className="product-list-parent"
                      // onClick={
                      //   this.state.isMobile && this.viewPackage.bind(this, item)
                      // }
                      /*     onClick={this.viewPackage.bind(this, item)} */
                    >
                      <div className="product-img">
                        <a
                          className="pt-img"
                          href={void 0}
                          onClick={this.viewPackage.bind(this, item)}
                        >
                          <img
                            src={
                              this.state.isMobile
                                ? item?.creator_package_thumbnail_mobile ||
                                  actinfo1
                                : item?.creator_package_thumbnail_web ||
                                  actinfo1
                            }
                            alt="actinfo"
                            loading="lazy"
                          />
                        </a>
                        <div className="ptd-day">
                          {" "}
                          {item.creator_package_total_days} Days
                        </div>
                        {discount !== "" && (
                          <div className="package-discount">
                            {showPriceValue(discount.amount)} Off
                          </div>
                        )}
                      </div>
                      <div className="product-description">
                        <div className="new-tags">
                          <div className="c-tags">
                            {item.creator_package_travelling}
                          </div>
                          <div className="e-tags">{item.category_title}</div>
                        </div>
                        <a
                          className="like"
                          onClick={() => this.addToWishlist(item)}
                        >
                          {this.state.wish[0].includes(
                            item.creator_package_id
                          ) ? (
                            <img src={heartfill} alt="Filled Heart" />
                          ) : (
                            <img src={heart} alt="Empty Heart" />
                          )}
                        </a>
                        <div className="product-description-title">
                          <h5>
                            <a
                              href={void 0}
                              onClick={this.viewPackage.bind(this, item)}
                            >
                              {item.creator_package_name}
                            </a>{" "}
                          </h5>
                        </div>
                        <div className="product-created-content">
                          <div className="created-av">
                            <img
                              src={
                                item.creator_image !== "" &&
                                item.creator_image !== null
                                  ? item.creator_image
                                  : avholder
                              }
                              alt={item.creator_image}
                            />
                          </div>
                          <div className="created-av-title">
                            <h4>
                              <span>
                                {item.creator_package_source === "uop"
                                  ? "Operated by"
                                  : "Created by"}
                              </span>

                              {item.creator_handle_name}
                            </h4>
                            <div className="creator-badge">
                              <span>
                                <img src={medal} alt="Medal" />
                              </span>{" "}
                              <strong>
                                Level <em>01</em>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="m-row">
                          <div className="col1">
                            <div className="sites-content">
                              <ul>
                                {item?.creator_package_included
                                  ?.split(",")
                                  ?.map((item, index) => (
                                    <li key={index}>
                                      <figure>
                                        <img src={flight} alt="flight" />{" "}
                                      </figure>
                                      <figcaption>{item}</figcaption>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                          <div className="col3"></div>
                          {item?.creator_package_source === "uop" && (
                            <div className="col2">
                              {discount !== "" ? (
                                <div>
                                  <span className="package-discount">
                                    {showPriceValue(discount.amount)} Off
                                  </span>
                                </div>
                              ) : null}
                              <div className="price-row">
                                <em>Starts from</em>
                                {discount !== "" ? (
                                  <div className="package-original-amt">
                                    {showPriceValue(item.creator_package_price)}
                                  </div>
                                ) : null}
                                <strong>{showPriceValue(displyAmount)}</strong>{" "}
                              </div>
                              <span className="per-adult"> Per Adult</span>
                            </div>
                          )}
                        </div>

                        <div className="product-description-review">
                          <div className="pdr-lhs">
                            {item.creator_package_rating !== "" &&
                              item.creator_package_rating !== null && (
                                <span>
                                  <strong>{item.creator_package_rating}</strong>{" "}
                                  ({item.creator_package_total_rating}) Reviews
                                </span>
                              )}
                            <div className="star-rating">
                              {item.creator_package_id === "382" ? ( //618
                                <span> {this.loadRatingstar("4")} 4/5 (1)</span>
                              ) : (
                                this.loadRatingstar(item.creator_package_rating)
                              )}
                            </div>
                          </div>
                          <div className="pdr-rhs">
                            <a
                              className="button button-fill"
                              onClick={this.viewPackage.bind(this, item)}
                            >
                              {item?.creator_package_source === "uop"
                                ? "View package"
                                : "Check price"}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          "NO PACKAGES FOUND"
        );
    }
    this.setState({ display_package: display_package, packageLoading: false });
  }

  viewPackage(item) {
    var selected_City = "";
    Object.values(this.state.country_List).forEach((country) => {
      var countryDetails = Object.values(country);
      if (countryDetails[0] === this.state.destinationCountry) {
        countryDetails[1].map((city) => {
          if (city.city_name === this.state.destinationCity) {
            selected_City = city;
          }
        });
      }
    });
    var start_date = new Date();
    start_date.setDate(start_date.getDate() + 15);
    var end_date = new Date(
      format(
        add(start_date, {
          days: parseInt(item.creator_package_total_days) + 1,
        }),
        "yyyy-MM-dd"
      )
    );
    var searchdata = {
      creator_package_total_days: item.creator_package_total_days,
      adults: 1,
      airportLat: selected_City.airport_latitude,
      airportLon: selected_City.airport_longitude,
      checkinTime: "",
      child: "",
      creator_package_source: item.creator_package_source,
      destinationCity: selected_City.city_name,
      destinationCountry: selected_City.country_name,
      destinationLat: selected_City.airport_latitude,
      destinationLon: selected_City.airport_longitude,
      destinationWebbedsCity: selected_City.webbeds_city_code,
      destination_airport_address: selected_City.airport_address,
      destination_airport_code: selected_City.airport_code,
      destination_airport_id: selected_City.airport_id,
      destination_airport_lat: selected_City.airport_latitude,
      destination_airport_lon: selected_City.airport_longitude,
      end_date: end_date,
      images: item.creator_package_gallery,
      infant: "",
      location_destination: selected_City.airport_address,
      location_source:
        "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
      room_count: 1,
      search_days: "2-5",
      sourceCity: "",
      sourceCountry: "India",
      sourceLat: "12.994112",
      sourceLon: "80.1708668",
      source_airport_address: "BLR",
      source_airport_code: "BLR",
      source_airport_id: "2800",
      source_airport_lat: "12.994112",
      source_airport_lon: "80.1708668",
      start_date: start_date,
      thumbnail: item.creator_package_thumbnail_web,
      transfer_trip_type: "Return",
      trip_type: "Return",
      packageType: item?.creator_package_source,
    };

    var postObject = {
      creator_id: item.creator_id,
      package_id: item.creator_package_id,
    };

    axios
      .post(
        apiUrl + "package/packageClick",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var packagedetails = {
            searchdata: searchdata,
            hotel_check_in_time: item.hotel_check_in_time,
            creator_package_total_days: item.creator_package_total_days,
          };
          this.props.history.push({
            pathname: "/search-package/" + item.creator_package_slug,
            state: packagedetails,
          });
        } else {
          console.log("ClickApi response", res.data);
        }
      });
  }

  addToWishlist = (item) => {
    if (this.state.UserId !== "") {
      var wishlist = [...this.state.wish];
      var itemIndex = wishlist[0].indexOf(item.creator_package_id);
      if (itemIndex === -1) {
        wishlist[0].push(item.creator_package_id);
      }
      if (itemIndex !== -1) {
        wishlist[0].splice(itemIndex, 1);
      }
      var addedWishlist = [...this.state.addedWishlist];
      var formData = new FormData();
      formData.append("customer_id", cookie.load("UserId"));
      formData.append("package_id", item.creator_package_id);
      axios
        .post(apiUrl + "package/addToPackageWishlist", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((response) => {
          if (response.data.status === "ok") {
            var index = addedWishlist.findIndex(
              (removedItem) =>
                removedItem.creator_package_id === item.creator_package_id
            );
            addedWishlist.splice(index, 1);
            this.setState(
              {
                addedWishlist: addedWishlist,
                wish: wishlist,
              },
              function () {
                this.displayPackage();
                // this.addwishlist(item);
              }
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (cookie.load("UserId") !== "") {
        this.setState({ wishlistitem: item }, function () {
          $.magnificPopup.open({
            items: {
              src: "#customer-login-popup",
            },
            type: "inline",
            closeOnBgClick: false,
          });
        });
      }
    }
  };

  handleChange(type, event) {
    if (type === "category") {
      var categorylist = [...this.state.categorylist];
      if (event?.target) {
        if (event.target.checked === true) {
          categorylist.push(event.target.value);
        } else {
          var index = categorylist.indexOf(event.target.value);
          if (index > -1) {
            categorylist.splice(index, 1);
          }
        }
      } else {
        var index = categorylist.indexOf(event);
        if (index > -1) {
          categorylist.splice(index, 1);
        } else {
          categorylist.push(event);
        }
      }
      this.setState({ categorylist: categorylist });
    } else if (type === "sortOption") {
      this.setState({ sortOption: event.target.value }, () => {
        this.handleApplyFilter();
      });
    }
  }

  handleApplyFilter() {
    this.setState({ packageLoading: true });

    const filteredPackages = this.state.searchResult.filter((item) => {
      return this.applyFilter(item);
    });

    const sortedPackages = [...filteredPackages];

    if (this.state.sortOption === "lowToHigh") {
      sortedPackages.sort(
        (a, b) => a.creator_package_price - b.creator_package_price
      );
    } else if (this.state.sortOption === "highToLow") {
      sortedPackages.sort(
        (a, b) => b.creator_package_price - a.creator_package_price
      );
    }

    var minPrice = Math.min(
      ...sortedPackages.map((item) => item.creator_package_price)
    );
    var maxPrice = Math.max(
      ...sortedPackages.map((item) => item.creator_package_price)
    );
    var count = 0;
    if (this.state.categorylist.length > 0) {
      count++;
    }
    if (this.state.startPrice !== 0) {
      if (
        Math.round(this.state.startPrice) !== Math.round(minPrice) &&
        Math.round(this.state.endPrice) !== Math.round(maxPrice)
      ) {
        count++;
      }
    }

    this.setState(
      { count: count, startPrice: minPrice, endPrice: maxPrice },
      function () {
        this.loadFilter();
      }
    );

    this.setState({ filterResult: sortedPackages }, function () {
      this.displayPackage();
    });
    $.magnificPopup.close({
      items: {
        src: "#filter-stick-popup",
      },
      type: "inline",
    });
  }
  helmethandler() {
    const helmetCountryPresent = this.state.helmetCountries.includes(
      this.state.cityName
    );
    if (helmetCountryPresent) {
      const selectedData = this.helmetdata.find(
        (item) => item.name === this.state.destinationCity
      );
      this.setState({
        selectedcityhelmet: selectedData,
        selectedcityhelmettitle: selectedData.title,
        selectedcityhelmetdiscription: selectedData.description,
        selectedcityhelmetkeyword: selectedData.keywords,
        helmetCountryPresent: helmetCountryPresent,
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  monthFilter = (month) => {
    this.setState({ packageLoading: true, selectedMonth: month }, () =>
      this.UopsearchPackage()
    );
  };

  handlepackageType(packageType) {
    this.setState(
      { packageLoading: true, packageType: packageType },

      () => {
        this.searchPackage();
      }
    );
  }

  openfilter() {
    if (this.state.isMobile) {
      this.setState({ openFilterSheet: true });
    } else {
      $.magnificPopup.open({
        items: {
          src: "#filter-stick-popup",
        },

        type: "inline",
      });
    }
  }

  handleClearAll() {
    var { searchResult } = this.state;

    var minPrice = Math.min(
      ...searchResult.map((item) => item.creator_package_price)
    );

    var maxPrice = Math.max(
      ...searchResult.map((item) => item.creator_package_price)
    );

    this.setState(
      {
        startPrice: minPrice,

        endPrice: maxPrice,

        categorylist: [],

        count: "",

        filterResult: this.state?.searchResult,
      },

      function () {
        this.loadFilter();

        this.displayPackage();

        var sliderElement = $("#rang");

        if (sliderElement.data("ionRangeSlider")) {
          sliderElement.data("ionRangeSlider").update({
            from: minPrice,

            to: maxPrice,
          });
        } else {
          console.warn("ionRangeSlider not initialized on #rang element.");
        }
      }
    );

    var checkboxes = document.querySelectorAll(".tag_filter");

    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    try {
      var slider = $("#rang").data("ionRangeSlider");

      if (slider) {
        slider.update({
          from: minPrice,

          to: maxPrice,
        });
      } else {
        console.warn("ionRangeSlider not initialized on #rang element.");
      }
    } catch (error) {
      console.error("Error updating ionRangeSlider:", error);
    }
  }

  loadFilter() {
    var displayfilter = (
      <div className="package-top-filters">
        <div className="ptf-options">
          <ul>
            {/* <li>

              <a href={void 0} onClick={this.openfilter.bind(this)}>

                <div className="active-options">

                  {this.state.count ? this.state.count : 0}

                </div>{" "}

                <span>Category</span>

              </a>

            </li> */}

            <li>
              <a href={void 0} onClick={this.openfilter.bind(this)}>
                {this.state.count > 0 && (
                  <div className="active-options">{this.state.count}</div>
                )}

                <span>
                  Filters <img src={filter} alt="filter" />
                </span>
              </a>
            </li>
          </ul>
        </div>

        {this.state.isMobile && (
          <div className="ptf-options">
            <ul>
              <li>
                <a
                  href={void 0}
                  onClick={() => this.setState({ openSortSheet: true })}
                >
                  <span>Sort By</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );

    this.setState({
      displayFilter: displayfilter,
    });
  }
  processPackages(packages) {
    var wishlist = [...this.state.wish];
    var minPrice = Math.min(
      ...packages.map((item) => item.creator_package_price)
    );
    var maxPrice = Math.max(
      ...packages.map((item) => item.creator_package_price)
    );
    const wishlistCustomers = packages.filter(
      (customer) => customer.wishlist === "yes"
    );
    const wishlistCustomerIds = wishlistCustomers.map(
      (customer) => customer.creator_package_id
    );
    wishlist.push(wishlistCustomerIds);

    this.setState(
      {
        wish: wishlist,
        startPrice: minPrice,
        endPrice: maxPrice,
        maxPrice: maxPrice,
      },
      function () {
        this.loadFilter();
      }
    );

    var currentThis = this;
    $(() => {
      $("#rang").ionRangeSlider({
        min: currentThis.state.startPrice,
        max: currentThis.state.endPrice,
        from: currentThis.state.startPrice,
        to: currentThis.state.endPrice,
        type: "double",
        grid_num: 10,
        onFinish: function (data) {
          currentThis.setState({ packageLoading: true });
          currentThis.setState(
            { startPrice: data.from, endPrice: data.to },
            function () {
              currentThis.loadFilter();
              currentThis.displayPackage();
            }
          );
          setTimeout(() => {
            currentThis.setState({ packageLoading: false });
          }, 2000);
        },
      });
    });

    this.setState(
      {
        searchResult: packages,
        filterResult: packages,
      },
      function () {
        this.displayPackage();
        this.handleApplyFilter();
        this.handleClearAll();
      }
    );
  }

  render() {
    var creator_packages = this.state.creator_packages;
    const iframeSrc = `https://www.google.com/maps/embed/v1/place?key=${googleMapsKey}&q=${encodeURIComponent(
      this.state.destinationCity
    )}`;
    return (
      <>
        {this.state.helmetCountryPresent && (
          <Helmet>
            <title>{this.state.selectedcityhelmettitle}</title>
            {this.state.destinationCountry === "" ? (
              <div>
                {" "}
                <link
                  rel="canonical"
                  href={`https://www.fizota.com/packages/${this.state.cityName}`}
                />
                {/* <meta
                  property="og:url"
                  content={`https://www.fizota.com/packages/${this.state.cityName}`}
                /> */}
              </div>
            ) : (
              <div>
                {" "}
                <link
                  rel="canonical"
                  href={`https://www.fizota.com/packages/${this.state.destinationCountry}/${this.state.cityName}`}
                />
                {/* <meta
                  property="og:url"
                  content={`https://www.fizota.com/packages/${this.state.destinationCountry}/${this.state.cityName}`}
                /> */}
              </div>
            )}

            <meta
              name="keywords"
              content={this.state.selectedcityhelmetkeyword}
            />
            <meta
              name="description"
              content={this.state.selectedcityhelmetdiscription}
            />
          </Helmet>
        )}

        <Header {...this.props} sateValChange={this.sateValChange} />

        <section className="map-banner-parent">
          <div className="banner-map">
            <div className="bm-lhs">
              <img src={this.state.locationData.large_image} alt="" />
              <div className="pl-name">
                <h2>{this.state.destinationCity}</h2>
                <p>{this.state.locationData.description}</p>
              </div>
            </div>
            {!this.state.isMobile && (
              <div className="bm-rhs">
                <div className="bmr-top">
                  <img src={this.state.locationData.small_image} alt="" />
                </div>
                <div className="bmr-btm">
                  <iframe
                    src={iframeSrc}
                    width="100%"
                    height="100%"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            )}
          </div>
          <div className="bread-crumbs">
            <div className="container">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href={void 0}>{this.state.destinationCity}</a>
                </li>
                {/* <li>{this.state.productdetail.products_name}</li> */}
              </ul>
            </div>
          </div>
        </section>

        <section className="innersection">
          <div className="container">
            <div className="traveler-main-package">
              <h1 className="color-header">
                Best {this.state.destinationCity}'s Vacation
                <span> Packages</span>
              </h1>
              <div className="package-top-filters color-head-merge a">
                <div className="category-filter">
                  <div
                    className={`category-box ${
                      this.state.packageType === "uop" ? "active" : ""
                    }`}
                    onClick={() => this.handlepackageType("uop")}
                  >
                    <div className="ctitle">Curated</div>

                    <div className="cdesp">
                      Curated packages
                      <br /> from Top creators
                    </div>
                  </div>

                  <div
                    className={`category-box ${
                      this.state.packageType === "vacation" ? "active" : ""
                    }`}
                    onClick={() => this.handlepackageType("vacation")}
                  >
                    <div className="ctitle">Customizable</div>

                    <div className="cdesp">
                      Hyper Customise your
                      <br /> vacation package
                    </div>
                  </div>
                </div>

                {this.state.isMobile && this.state.displayFilter}

                {/* {!this.state.isMobile && (
                  <div className="package-top-btn">
                    <a
                      className="button button-fill"
                      href={void 0}

                      // onClick={this.createOwnPackage.bind(this)}
                    >
                      Create Your Own Package
                    </a>
                  </div>
                )} */}
              </div>
              <div className="package-list-row">
                {!this.state.isMobile && (
                  <div className="left-col">
                    <div className="filter-main-sticky">
                      <div className="filter-title">Filters</div>
                      <div className="filter-head">Category</div>
                      <div className="fliter-option-row">
                        <FormGroup>
                          {this.state.category?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { color: "#4258BF" },
                                  }}
                                  // checked={
                                  //   this.state.priceFilter === item?.value
                                  // }
                                  onChange={() =>
                                    this.handleChange("category", item)
                                  }
                                />
                              }
                              label={item}
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <div className="filter-head">Days</div>
                      <div className="fliter-option-row">
                        <FormGroup>
                          {daysFilter?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { color: "#4258BF" },
                                  }}
                                  checked={
                                    this.state.search_days === item?.value
                                  }
                                  onChange={() =>
                                    this.handleDaysFilter(item?.value)
                                  }
                                />
                              }
                              label={item?.label}
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <br />
                      <div className="filter-head">Price</div>
                      <div className="fliter-option-row">
                        <FormGroup>
                          {priceFilterOptions?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { color: "#4258BF" },
                                  }}
                                  checked={
                                    this.state.priceFilter === item?.value
                                  }
                                  onChange={() =>
                                    this.handlePriceFilter(item?.value)
                                  }
                                />
                              }
                              label={item?.label}
                            />
                          ))}
                        </FormGroup>
                      </div>
                      {this.state.uop === true && (
                        <>
                          <br />
                          <div className="filter-head">
                            Months{" "}
                            <span
                              onClick={() =>
                                this.setState({
                                  showMoreMonths: !this.state.showMoreMonths,
                                })
                              }
                              className="showMoreBtn"
                            >
                              {this.state.showMoreMonths
                                ? "Show less"
                                : "Show more"}
                            </span>
                          </div>
                          <div className="fliter-option-row">
                            <FormGroup>
                              {nextYearMonths?.map((item, index) => {
                                return (!this.state.showMoreMonths &&
                                  index < 5) ||
                                  this.state.showMoreMonths ? (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            color: "#4258BF",
                                          },
                                        }}
                                        checked={
                                          this.state.selectedMonth ===
                                          item?.value
                                        }
                                        onChange={() =>
                                          this.monthFilter(item?.value)
                                        }
                                      />
                                    }
                                    label={item?.label}
                                  />
                                ) : null;
                              })}
                            </FormGroup>
                          </div>
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className={`${this.state.isMobile ? "col" : "right-col"}`}>
                  {this.state.packageLoading === true ? (
                    <div className="search-loader">
                      {Array(1, 2, 3).map((item, index) => {
                        return (
                          <ContentLoader
                            viewBox={`0 0 ${
                              this.state.isMobile ? "380" : "100%"
                            } 280`}
                            height={280}
                            width={`${this.state.isMobile ? "380" : "100%"}`}
                            speed={3}
                            key={index}
                          >
                            <rect
                              x="3"
                              y="3"
                              rx="10"
                              ry="10"
                              width={`${this.state.isMobile ? "380" : "100%"}`}
                              height="180"
                            />
                            <rect
                              x="6"
                              y="190"
                              rx="0"
                              ry="0"
                              width={`${this.state.isMobile ? "320" : "60%"}`}
                              height="20"
                            />
                            <rect
                              x="4"
                              y="215"
                              rx="0"
                              ry="0"
                              width="320"
                              height="20"
                            />
                            <rect
                              x="4"
                              y="242"
                              rx="0"
                              ry="0"
                              width="380"
                              height="20"
                            />
                          </ContentLoader>
                        );
                      })}
                    </div>
                  ) : (
                    <div> {this.state.display_package}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          id="filter-stick-popup"
          className="mfp-hide common-popup filter-stick-popup"
        >
          <div className="cp-header textcenter">
            <h3>Filters</h3>
            <a
              className="date-package-popup"
              href={void 0}
              // onClick={this.closeDate.bind(this)}
            ></a>
          </div>
          <div className="filter-body">
            <div className="filter-row pricerange-option">
              <h5>Price Range</h5>
              <p>
                {showPriceValue(Math.ceil(this.state.startPrice))} to{" "}
                {showPriceValue(Math.ceil(this.state.endPrice))}+
                <input
                  type="text"
                  id="rang"
                  name="rang"
                  defaultValue=""
                  data-min={Math.round(this.state.startPrice)}
                  data-max={Math.round(this.state.endPrice)}
                  data-from={this.state.startPrice}
                  data-to={this.state.endPrice}
                  data-type="double"
                  data-prefix="SGD$"
                  data-grid="false"
                  data-grid-num="10"
                />
              </p>
              <div></div>
            </div>
            <div className="filter-row">
              <h5>categories</h5>
              {this.state.category.map((item, index) => {
                return (
                  <ul className="filter-row-ul" key={index}>
                    <li key={index}>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        id={"category" + index}
                        value={item}
                        onChange={this.handleChange.bind(this, "category")}
                      />
                      <label htmlFor={"category_" + index}>{item}</label>
                    </li>
                  </ul>
                );
              })}
            </div>

            {/*   <div className="filter-row">
              <h5>Options</h5>
              <ul className="filter-row-ul">
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
              </ul>
            </div>
            */}
          </div>
          <div className="filter-action">
            <a
              href={void 0}
              className="button"
              onClick={this.handleApplyFilter.bind(this)}
            >
              {" "}
              Apply
            </a>
            <a
              href={void 0}
              className="button ghost-button"
              onClick={this.handleClearAll.bind(this)}
            >
              {" "}
              Clear All
            </a>
          </div>
        </div>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <FilterSheet
            state={this.state}
            currentThis={this}
            categorylist={this.state.categorylist}
            handleChange={(type, event) => this.handleChange(type, event)}
            handleApplyFilter={this.handleApplyFilter.bind(this)}
            handleClearAll={this.handleClearAll.bind(this)}
            daysFilter={daysFilter}
            search_days={this.state.search_days}
            handleDaysFilter={this.handleDaysFilter.bind(this)}
            nextYearMonths={nextYearMonths}
            closeFilterSheet={() => this.setState({ openFilterSheet: false })}
          />
        </BottomSheet>

        <BottomSheet
          open={this.state.openSortSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openSortSheet: false })}
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="searchContainer__bottomSheetMain__container__sourceAir">
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === ""
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "" },
                  });

                  this.setState({ openSortSheet: false });
                }}
              >
                None
              </div>

              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "lowToHigh"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "lowToHigh" },
                  });

                  this.setState({ openSortSheet: false });
                }}
              >
                Price: Low to High
              </div>

              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "highToLow"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "highToLow" },
                  });

                  this.setState({ openSortSheet: false });
                }}
              >
                Price: High to Low
              </div>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
