/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Topmenu from "../Layout/Topmenu";
import { showAlert } from "../Helpers/SettingHelper";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CREATOR_DETAIL, GET_UPLOAD_FILES } from "../../actions";
import { apiUrl } from "../Helpers/Config";
import PhoneInput from "react-phone-input-2";
import { showLoader } from "../Helpers/SettingHelper";
import SideBarCreator from "./SideBarCreator/SideBarCreator";
import eyeopen from "../../common/images/eye-open1.svg";
import eyeclose from "../../common/images/eye-slash.svg";
import bcrypt from "bcryptjs";
var base64 = require("base-64");
const socialMediaOptions = [
  "Facebook",
  "TikTok",
  "YouTube",
  "Website",
  "Instagram",
];
var creatorTypes = [
  "Social media influencer",
  "Travel blogger",
  "Travel agent",
  "Tour operator",
];
var genderOption = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "U", label: "Others" },
];
const deactivationReasons = [
  "I have another Fizota account",
  "I have concerns with Fizota's policy",
  "I have better alternative platform",
  "The platform is complicated to use",
  "I have problem with commissions",
  "Fizota's support is not great.",
];

class Listpromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: "",
      creatordetail: "",
      creator_name: "",
      creator_email: "",
      creator_handlename: "",
      description: "",
      selectedCountry: "",
      selectedCity: "",
      selectedCreatorType: "",
      countries: Country.getAllCountries(),
      countryCities: [],
      /*   creator_phone:"", */
      creator_phone_code: "",
      creator_cover_image: "",
      selectedReason: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isAgreedForChangePassword: false,
      isAgreedForDeactivation: false,
      updated_phone: "",
      error_creator_name: false,
      error_creator_handlename: false,
      error_creator_handlename_msg: "",
      error_description_msg: "",
      error_description: false,
      error_creator_email: false,
      error_creator_email_msg: "",
      error_selectedCountry: false,
      error_selectedCreatorType: false,
      error_selectedCity: false,
      error_cover_image: false,
      error_cover_image_msg: "",
      error_currentPassword: false,
      error_newPassword: false,
      error_newPassword_msg: "",
      error_confirmPassword: false,
      error_confirmPassword_msg: "",
      error_selectedReason: false,
      error_creator_phone: false,
      error_currentPassword_msg: "",
      current_password_matched: false,
      creator_facebook_link: "",
      creator_youtube_link: "",
      creator_tiktok_link: "",
      creator_instagram_link: "",
      creator_website_link: "",
      socialMediaSets: [
        {
          platform: "",
          link: "",
          isPlatformValid: false,
          isLinkValid: false,
        },
      ],
      error_isAgreedForChangePassword: false,
      error_isAgreedForDeactivation: false,
      error_deactivationPassword_msg: "",
      error_deactivationPassword: false,
      passwordType: "password",
      deactivatebutton: false,
      msgPop: "",
      uploadfiles: "",
      error_creator_link: false,
      error_creator_link_msg: "",
      error_creator_media_msg: "",
      error_creator_media: true,
      newPassword: "",
      showPassword: false,
      newwPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
    };

    this.uploadCoverImage = this.uploadCoverImage.bind(this);
    this.handleAgreementChange = this.handleAgreementChange.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.toggleShowNewPassword = this.toggleShowNewPassword.bind(this);
    this.toggleShowConfirmPassword = this.toggleShowConfirmPassword.bind(this);

    if (cookie.load("CUserId") == "" || cookie.load("CUserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "creator_id=" + base64.encode(cookie.load("CUserId"));
      this.props.getCreatorDetail(params);
    }
  }
  componentDidMount() {
    this.loadData();
  }
  loadData() {
    const creatorId = base64.encode(cookie.load("CUserId"));
    const creatorDetailUrl = `${apiUrl}creators/creatordetail?creator_id=${creatorId}`;

    axios
      .get(creatorDetailUrl, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var result_set = res.data.result_set;
          console.log(result_set, "result_set");
          this.setState({
            creatorData: result_set,
            passwordToCompare: result_set.creator_password || "",
            creator_name: result_set.creator_name || "",
            creator_email: result_set.creator_email || "",
            creator_handlename: result_set.creator_handle_name || "",
            description: result_set.creator_description || "",
            selectedCountry: result_set.creator_country || "",
            selectedCity: result_set.creator_city || "",
            selectedCreatorType: result_set.creator_type || "",
            creator_phone_code: result_set.creator_phone_code || "",
            creator_cover_image: result_set.creator_coverimage || "",
            updated_phone: result_set.creator_phone || "",
            selectedGender: result_set.creator_gender || "",
            creator_facebook_link: result_set.creator_facebook_link || "",
            creator_instagram_link: result_set.creator_instagram_link || "",
            creator_website_link: result_set.creator_website_link || "",
            creator_youtube_link: result_set.creator_Youtube_link || "",
            creator_tiktok_link: result_set.creator_tiktok_link || "",
          });
          var countries = Country.getAllCountries();
          this.setState({
            countries: countries,
          });
          var country = countries.find(
            (country, index) => country.name === this.state.selectedCountry
          );

          var countryCities = City.getCitiesOfCountry(country.isoCode);

          this.setState({ countries: countries, countryCities: countryCities });
        } else {
          console.log("getcreatorDetails failed in mount");
        }
      })
      .catch((error) => {
        console.log(error, "api request error");
      });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.uploadfiles, "next props");
    if (
      this.state?.creator_cover_image !== nextProps.uploadfiles.web &&
      typeof nextProps.uploadfiles.web !== undefined &&
      typeof nextProps.uploadfiles.web !== "undefined"
    ) {
      this.setState({
        creator_cover_image: nextProps.uploadfiles.web,
        error_cover_image: false,
        error_cover_image_msg: "",
      });
    }
    if (this.state.creatordetail !== nextProps.creatordetail) {
      console.log(nextProps.creatordetail, "nextprps");
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
  }
  /* var trendoption = {
    items: 2,
    loop: true,
    dots: false,
    nav: false,
    margin: 40,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      580: {
        items: 2,
        margin: 30,
      },
      980: {
        margin: 40,
      },
    },
  }; */
  isHandleNameUnique = async (creator_handle_name) => {
    console.log(creator_handle_name, " [isHandleNameUnique]");
    var formData = new FormData();
    formData.append("creator_handle_name", creator_handle_name);
    try {
      const response = await axios.post(
        apiUrl + "creators/handlenameAvailability",
        formData,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );
      console.log(response, "responseee");
      if (response.data.status === "error") {
        return response.data.message;
      }

      return null;
    } catch (error) {
      return "error checking";
    }
  };
  handleChange = async (event) => {
    var { name, value } = event.target;
    var error_creator_name = false;
    var error_creator_handlename = false;
    var error_creator_handlename_msg = "";
    var error_description_msg = "";
    var error_description = false;
    var error_creator_email = false;
    var error_creator_email_msg = "";
    var error_selectedCountry = false;
    var error_selectedCreatorType = false;
    var error_selectedCity = false;
    var error_currentPassword = false;
    var error_newPassword = false;
    var error_newPassword_msg = "";
    var error_confirmPassword = false;
    var error_confirmPassword_msg = "";
    var error_selectedReason = false;
    var error_selectedGender = false;
    var error_currentPassword_msg = "";
    var current_password_matched = this.state.current_password_matched;

    if (name === "creator_name" && value.trim() === "") {
      error_creator_name = true;
    }
    if (name === "creator_handlename" && value.trim() === "") {
      error_creator_handlename = true;
      error_creator_handlename_msg = "This field is required";
    }
    /*   if (name === "creator_handlename") {
      const handleNameValidationMessage = await this.isHandleNameUnique(value);
      if (handleNameValidationMessage) {
        error_creator_handlename = true;
        error_creator_handlename_msg =
          "sorry,this handle name is already taken";
      }
    } */
    if (name === "description") {
      if (value.trim() === "") {
        error_description = true;
        error_description_msg = "This field is required";
      }
      /* else if (value.trim().length < 150 || value.trim().length > 300) {
        error_description = true;
        error_description_msg =
          "Please enter a description between 150 and 300 characters.";
      } */
    }
    if (name === "creator_email") {
      if (value.trim() === "") {
        error_creator_email = true;
        error_creator_email_msg = "This field is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error_creator_email = true;
        error_creator_email_msg = "Please enter a valid email address";
      }
    }
    if (name === "selectedCountry") {
      if (value === "") {
        error_selectedCountry = true;
      } else {
        const country = this.state.countries.find(
          (country) => country.name === value
        );
        console.log(country, "contry country country");
        if (country) {
          const countryCities = City.getCitiesOfCountry(country.isoCode);

          this.setState({
            selectedCountry: value,
            countryCities: countryCities,
            selectedCity: countryCities.length === 0 && value,
          });
        }
      }
    }
    if (name === "selectedCreatorType" && value === "") {
      error_selectedCreatorType = true;
    }
    if (name === "selectedCity" && value === "") {
      error_selectedCity = true;
    }
    if (name === "currentPassword" && value.trim() === "") {
      error_currentPassword = true;
      error_currentPassword_msg = "This field is required";
      current_password_matched = false;
    } else if (name === "currentPassword" && value.trim() !== "") {
      var storedHash = this.state.passwordToCompare;
      var isPasswordCorrect = bcrypt.compareSync(value, storedHash);

      if (!isPasswordCorrect) {
        error_currentPassword = true;
        error_currentPassword_msg = "Password is not correct";
        current_password_matched = false;
      } else if (isPasswordCorrect) {
        error_currentPassword = false;
        current_password_matched = true;
      }
    }

    if (name === "newPassword" && value.trim() === "") {
      error_newPassword = true;
      error_newPassword_msg = "This field is required";
    }
    if (name === "selectedGender" && value.trim() === "") {
      error_selectedGender = true;
    }

    if (name === "confirmPassword") {
      if (value.trim() === "") {
        error_confirmPassword = true;
        error_confirmPassword_msg = "This field is required";
      } else if (value !== this.state.newPassword) {
        error_confirmPassword = true;
        error_confirmPassword_msg = "Passwords do not match";
      }
    }
    if (name === "selectedReason" && value === "") {
      error_selectedReason = true;
    }

    this.setState({
      [name]: value,
      error_creator_name: error_creator_name,
      error_creator_handlename: error_creator_handlename,
      error_creator_handlename_msg: error_creator_handlename_msg,
      error_description: error_description,
      error_description_msg: error_description_msg,
      error_creator_email: error_creator_email,
      error_creator_email_msg: error_creator_email_msg,
      error_selectedCountry: error_selectedCountry,
      error_selectedCreatorType: error_selectedCreatorType,
      error_selectedCity: error_selectedCity,
      error_currentPassword: error_currentPassword,
      error_newPassword: error_newPassword,
      error_newPassword_msg: error_newPassword_msg,
      error_confirmPassword: error_confirmPassword,
      error_confirmPassword_msg: error_confirmPassword_msg,
      error_selectedReason: error_selectedReason,
      error_currentPassword_msg: error_currentPassword_msg,
      current_password_matched: current_password_matched,
      error_selectedGender: error_selectedGender,
    });
  };
  async uploadCoverImage(event) {
    var selectedFile = event.target.files[0];
    /*   if (!selectedFile) {
      this.setState({
        creator_cover_image: "",
        error_cover_image: true,
        error_cover_image_msg: "Please select a cover image",
      });
      return;
    } */

    var formData = new FormData();
    formData.append("file", selectedFile);

    // Await the upload function and handle the response
    const uploadedFileUrl = await this.props.GetUploadFiles(formData);
    console.log(uploadedFileUrl, "uploadedFileUrl");
    // Update the state with the uploaded file URL and validate in the callback
    this.setState({ creator_cover_image: uploadedFileUrl });
  }

  handleAgreementChange(event) {
    const { name, checked } = event.target;
    var error_isAgreedForChangePassword = false;
    var error_isAgreedForDeactivation = false;

    if (name === "isAgreedForChangePassword" && checked === false) {
      error_isAgreedForChangePassword = true;
    }
    if (name === "isAgreedForDeactivation" && checked === false) {
      error_isAgreedForDeactivation = true;
    }

    this.setState({
      [name]: checked,
      error_isAgreedForChangePassword: error_isAgreedForChangePassword,
      error_isAgreedForDeactivation: error_isAgreedForDeactivation,
    });
  }
  setPhone(phone, data) {
    const phoneNumberRegex = /^[0-9\s+\-()]{7,20}$/;

    this.setState({
      updated_phone: phone,
      creator_phone_code: "+" + data.dialCode,
      error_creator_phone: !phoneNumberRegex.test(phone),
    });
  }

  validateCoverImage = () => {
    if (!this.state.creator_cover_image) {
      return "Please upload a cover image";
    }
  };

  handleSocialMediaChange = (index, event) => {
    const { socialMediaSets } = this.state;
    const updatedSets = [...socialMediaSets];
    updatedSets[index].platform = event.target.value;
    updatedSets[index].isPlatformValid = event.target.value !== "";

    this.setState({
      socialMediaSets: updatedSets,
    });
  };

  handleLinkChange = (index, event) => {
    const { socialMediaSets } = this.state;
    const updatedSets = [...socialMediaSets];
    updatedSets[index].link = event.target.value;
    updatedSets[index].isLinkValid = event.target.value !== "";

    this.setState({
      socialMediaSets: updatedSets,
    });
    for (let i = 0; i < this.state.socialMediaSets.length; i++) {
      var set = this.state.socialMediaSets[i];
      var platform = set.platform.toLowerCase();
      var link = set.link;

      if (platform === "facebook") {
        this.setState({
          creator_facebook_link: link || "",
        });
      } else if (platform === "youtube") {
        this.setState({
          creator_youtube_link: link || "",
        });
      } else if (platform === "tiktok") {
        this.setState({
          creator_tiktok_link: link || "",
        });
      } else if (platform === "instagram") {
        this.setState({
          creator_instagram_link: link || "",
        });
      } else if (platform === "website") {
        this.setState({
          creator_website_link: link || "",
        });
      }
    }
  };

  handleAddButtonClick = () => {
    const { socialMediaSets } = this.state;

    this.setState({
      socialMediaSets: [
        ...socialMediaSets,
        {
          platform: "",
          link: "",
        },
      ],
    });
  };

  handleRemoveButtonClick = (indexToRemove) => {
    const { socialMediaSets } = this.state;

    const updatedSets = [...socialMediaSets];
    updatedSets.splice(indexToRemove, 1);

    this.setState({
      socialMediaSets: updatedSets,
    });
  };

  openPopUp = () => {
    let errorCount = 0;

    if (!this.state.selectedReason) {
      this.setState({ error_selectedReason: true });
      errorCount++;
    }

    if (!this.state.isAgreedForDeactivation) {
      this.setState({ error_isAgreedForDeactivation: true });
      errorCount++;
    }

    if (errorCount > 0) {
      return;
    }

    $.magnificPopup.open({
      items: {
        src: "#deactiveConfirm",
      },
      type: "inline",
      showCloseBtn: true,
      midClick: true,
      closeOnBgClick: false,
    });
  };

  handleChangePassword = () => {
    var error = 0;

    if (!this.state.isAgreedForChangePassword) {
      error++;
      this.setState({ error_isAgreedForChangePassword: true });
    }

    if (!this.state.current_password_matched) {
      error++;
      this.setState({
        error_currentPassword: true,
        error_currentPassword_msg: "This cannot be empty",
      });
    }

    if (
      this.state.newPassword.trim() === "" ||
      this.state.confirmPassword.trim() === ""
    ) {
      error++;
      this.setState({
        error_newPassword: true,
        error_newPassword_msg: "This cannot be empty",
        error_confirmPassword: true,
        error_confirmPassword_msg: "This cannot be empty",
        // error_confirmPassword_msg: "Please enter both passwords correctly",
      });
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      error++;
      this.setState({
        error_confirmPassword: true,
        error_confirmPassword_msg: "Passwords do not match",
      });
    } else if (this.state.newPassword.length < 8) {
      error++;
      this.setState({
        error_newPassword: true,
        error_newPassword_msg: "password should be minimum 8 digits",
      });
    } else if (this.state.confirmPassword.length < 8) {
      error++;
      this.setState({
        error_confirmPassword: true,
        error_confirmPassword_msg: "password should be minimum 8 digits",
      });
    }

    if (error === 0) {
      var formData = new FormData();
      formData.append("refrence", base64.encode(cookie.load("CUserId")));
      formData.append("oldpassword", base64.encode(this.state.currentPassword));
      formData.append("password", base64.encode(this.state.newPassword));
      formData.append(
        "confirmpassword",
        base64.encode(this.state.confirmPassword)
      );

      axios
        .post(apiUrl + "creators/changepassword", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({
              msgPop: res.data.message,
            });
            showAlert("success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: "#alert_popup",
              },
              type: "inline",
              showCloseBtn: false,
              closeOnBgClick: false,
            });
          } else {
            showAlert("Alert", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        })
        .catch((error) => {
          console.error("Error changing password:", error);
        });
    }
  };

  handleChangeDeact = (event) => {
    var { name, value } = event.target;
    var error_deactivationPassword = false;
    var error_deactivationPassword_msg = "";
    var deactivatebutton = false;
    if (name === "deactivationPassword" && value.trim() === "") {
      error_deactivationPassword = true;
      error_deactivationPassword_msg = "This field is required";
    } else if (name === "deactivationPassword" && value.trim() !== "") {
      var storedHash = this.state.creatorData.creator_password;
      var isPasswordCorrect = bcrypt.compareSync(value, storedHash);

      if (!isPasswordCorrect) {
        error_deactivationPassword = true;
        deactivatebutton = false;
        error_deactivationPassword_msg = "Password is not correct";
      } else if (isPasswordCorrect) {
        deactivatebutton = true;
      }
    }
    this.setState({
      [name]: value,
      error_deactivationPassword_msg: error_deactivationPassword_msg,
      error_deactivationPassword: error_deactivationPassword,
      deactivatebutton: deactivatebutton,
    });
  };
  handleDeactivateClick = async () => {
    var error = 0;
    if (!this.state.isAgreedForDeactivation) {
      error++;
      this.setState({ error_isAgreedForDeactivation: true });
    }

    if (!this.state.selectedReason) {
      error++;
      this.setState({
        error_selectedReason: true,
      });
    }
    if (error === 0) {
      showLoader("processDeactivate");
      var formData = new FormData();
      formData.append("creator_id", base64.encode(cookie.load("CUserId")));
      formData.append("creator_status", "D");
      formData.append("deactivate_reason", this.state.selectedReason);

      axios
        .post(apiUrl + "creators/deactivation", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            cookie.remove("CUserId", { path: "/logout" });
            this.props.history.push("/logout");
          } else {
            showAlert("Alert", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
          console.log(res.data.message);
        })
        .catch((error) => {
          console.error("Error changing password:", error);
        });
    }
  };
  handleSaveChanges = async () => {
    /*   console.log(this.state.socialMediaSets, "social media");
    var creator_facebook_link = "";
    var creator_youtube_link = "";
    var creator_tiktok_link = "";
    var creator_instagram_link = "";
    var creator_website_link = "";
    for (let i = 0; i < this.state.socialMediaSets.length; i++) {
      var set = this.state.socialMediaSets[i];
      var platform = set.platform.toLowerCase();
      var link = set.link;

      if (platform === "facebook") {
        this.setState({
          creator_facebook_link: link || "",
        });
      } else if (platform === "youtube") {
        this.setState({
          creator_youtube_link: link || "",
        });
      } else if (platform === "tiktok") {
        this.setState({
          creator_tiktok_link: link || "",
        });
      } else if (platform === "instagram") {
        this.setState({
          creator_instagram_link: link || "",
        });
      } else if (platform === "website") {
        this.setState({
          creator_website_link: link || "",
        });
      }
    }
    console.log(
      this.state.creator_facebook_link,
      this.state.creator_youtube_link,
      this.state.creator_tiktok_link,
      this.state.creator_instagram_link,
      this.state.creator_website_link,
      "social medaia channels"
    ); */
    var error = 0;
    if (!this.state.creator_name) {
      error++;
      this.setState({ error_creator_name: true });
    }
    if (!this.state.creator_email) {
      error++;
      this.setState({
        error_creator_email: true,
        error_creator_email_msg: "This field is required",
      });
    } else if (!/\S+@\S+\.\S+/.test(this.state.creator_email)) {
      error++;
      this.setState({
        error_creator_email: true,
        error_creator_email_msg: "Please enter a valid email address",
      });
    }
    if (!this.state.creator_handlename) {
      error++;
      this.setState({ error_creator_handlename: true });
    }
    if (!this.state.description) {
      error++;
      this.setState({
        error_description: true,
        error_description_msg: "This field is required",
      });
    } else if (
      this.state.description.length < 2 ||
      this.state.description.length > 250
    ) {
      error++;
      this.setState({
        error_description: true,
        error_description_msg:
          "Please enter a description between 2 and 250 characters.",
      });
    }
    if (!this.state.selectedCountry) {
      error++;
      this.setState({ error_selectedCountry: true });
    }
    if (!this.state.selectedCreatorType) {
      error++;
      this.setState({ error_selectedCreatorType: true });
    }
    if (!this.state.selectedCity) {
      error++;
      this.setState({ error_selectedCity: true });
    }
    if (!this.state.selectedGender) {
      error++;
      this.setState({ error_selectedGender: true });
    }
    if (!this.state.updated_phone) {
      error++;
      this.setState({ error_creator_phone: true });
    }
    if (!this.state.creator_cover_image) {
      error++;
      this.setState({
        error_cover_image: true,
        error_cover_image_msg: "Please upload a cover image",
      });
    }
    for (let i = 0; i < this.state.socialMediaSets.length; i++) {
      var set = this.state.socialMediaSets[i];
      var platform = set.platform.toLowerCase();
      var link = set.link;

      if (
        !link &&
        (platform === "facebook" ||
          platform === "youtube" ||
          platform === "tiktok" ||
          platform === "instagram" ||
          platform === "website")
      ) {
        // error++;
        var updatedSets = [...this.state.socialMediaSets];
        updatedSets[i].isLinkValid = false;
        this.setState({ socialMediaSets: updatedSets });
      }
      // if (set) {
      //   if (set.platform !== "") {
      //     if (set.link === "") {
      //       error++;
      //       this.setState({
      //         error_creator_link: true,
      //         error_creator_link_msg: "This field is required",
      //       });
      //     }
      //   }
      // }
    }

    if (error === 0) {
      var formData = new FormData();
      formData.append("creator_id", base64.encode(cookie.load("CUserId")));
      formData.append("creator_email", this.state.creator_email);
      formData.append("creator_phone", this.state.updated_phone);
      formData.append("creator_name", this.state.creator_name);
      formData.append("creator_gender", this.state.selectedGender);
      formData.append("creator_handle_name", this.state.creator_handlename);
      formData.append("creator_phone_code", this.state.creator_phone_code);
      formData.append("creator_type", this.state.selectedCreatorType);
      formData.append("creator_city", this.state.selectedCity);
      formData.append("creator_country", this.state.selectedCountry);
      formData.append("creator_description", this.state.description);
      formData.append(
        "creator_instagram_link",
        this.state.creator_instagram_link
      );
      formData.append("creator_tiktok_link", this.state.creator_tiktok_link);
      formData.append(
        "creator_facebook_link",
        this.state.creator_facebook_link
      );
      formData.append("creator_website_link", this.state.creator_website_link);
      formData.append("creator_Youtube_link", this.state.creator_youtube_link);
      formData.append("creator_coverimage", this.state.creator_cover_image);

      axios
        .post(apiUrl + "creators/updateprofile", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);

            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          } else {
            showAlert("Alert", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
          console.log(res.data.message);
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
        });
    }
  };
  isPlatformSelected = (platform, currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (this.state.socialMediaSets[i].platform === platform) {
        return true;
      }
    }
    return false;
  };
  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }
  openPopup(popupID) {
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      midClick: true,
    });
  }
  Closepopup(index) {
    $.magnificPopup.close({
      items: {
        src: "#alert_popup",
      },
      type: "inline",
      showCloseBtn: false,
      midClick: true,
      closeOnBgClick: false,
    });
    if (index === "password_updated") {
      cookie.remove("CUserId", { path: "/" });
      this.props.history.push("/");
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  toggleShowPassword() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }
  toggleShowNewPassword() {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword,
    }));
  }

  toggleShowConfirmPassword() {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }
  render() {
    console.log(this.state, "state values");
    return (
      <>
        <section className="innersection">
          <div className="container">
            <SideBarCreator pageName="creatordetails-edit" />
            <div className="admin-profile creator-profile">
              <div className="admin-profile-rhs">
                <div className="unique-package creator-details-edit">
                  <div className="title-with-nav">
                    <div className="title-with-description">
                      <h2>
                        Creator <span> details</span>
                      </h2>
                    </div>
                  </div>
                  <div className="unique-package-lhs">
                    <div className="form-group">
                      <label className="control-label">
                        Full Name <em>*</em>
                      </label>
                      <input
                        type="text"
                        name="creator_name"
                        placeholder="Full Name"
                        value={this.state.creator_name}
                        onChange={this.handleChange}
                      />
                      {this.state.error_creator_name === true && (
                        <div className="error">This field is required</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        Creator handle Name <em>*</em>
                      </label>
                      <input
                        type="text"
                        name="creator_handlename"
                        placeholder="Name"
                        value={this.state.creator_handlename}
                        onChange={this.handleChange}
                      />
                      {this.state.error_creator_handlename && (
                        <div className="error">
                          {this.state.error_creator_handlename_msg}
                        </div>
                      )}
                    </div>

                    <div className="form-group height-txt">
                      <label className="control-label">
                        Describe yourself <em>*</em>
                      </label>
                      <textarea
                        id="textarea"
                        name="description"
                        placeholder="Write short description about you.."
                        value={this.state.description}
                        onChange={this.handleChange}
                      ></textarea>
                      {this.state.error_description && (
                        <div className="error">
                          {this.state.error_description_msg}
                        </div>
                      )}
                      <div className="unique-package-info">
                        Please describe yourself between 2 to 250 words.
                      </div>
                    </div>
                  </div>
                  <div className="unique-package-rhs">
                    {/* <div className="form-group">
                      <label className="control-label">
                        Email <em>*</em>
                      </label>
                      <input
                        type="text"
                        name="creator_email"
                        placeholder="Email"
                        value={this.state.creator_email}
                        onChange={this.handleChange}
                      />
                      {this.state.error_creator_email && (
                        <div className="error">
                          {this.state.error_creator_email_msg}
                        </div>
                      )}
                    </div> */}
                    <div className="fg-half">
                      <div className="form-group">
                        <label className="control-label">
                          Country <em>*</em>
                        </label>
                        <select
                          name="selectedCountry"
                          value={this.state.selectedCountry}
                          onChange={this.handleChange}
                        >
                          <option value="">Select a country</option>
                          {this.state.countries.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        {this.state.error_selectedCountry && (
                          <div className="error">Please select a country</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Creator type <em>*</em>
                        </label>
                        <select
                          name="selectedCreatorType"
                          value={this.state.selectedCreatorType}
                          onChange={this.handleChange}
                        >
                          <option value="">Select a creator type</option>
                          {creatorTypes.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        {this.state.error_selectedCreatorType && (
                          <div className="error">
                            Please select a creator type
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="fg-half">
                      <div className="form-group">
                        <label className="control-label">
                          City <em>*</em>
                        </label>
                        <select
                          name="selectedCity"
                          value={this.state.selectedCity}
                          onChange={this.handleChange}
                        >
                          {/*  <option value="">Select a city</option>
                      {console.log(this.state.countryCities, "changed")}
                      {this.state.countryCities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {city.name}
                        </option>
                      ))} */}

                          <option value="">Select a city</option>
                          {this.state.countryCities.length === 0 &&
                          this.state.selectedCountry ? (
                            <option value={this.state.selectedCountry}>
                              {this.state.selectedCountry}
                            </option>
                          ) : (
                            this.state.selectedCountry &&
                            this.state.countryCities.map((city, index) => (
                              <option key={index} value={city.name}>
                                {city.name}
                              </option>
                            ))
                          )}
                        </select>
                        {this.state.error_selectedCity && (
                          <div className="error">Please select a city</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Phone <em>*</em>
                        </label>
                        <PhoneInput
                          value={this.state.updated_phone}
                          country={"sg"}
                          onChange={(phone, data, event, formattedValue) => {
                            this.setPhone(formattedValue, data);
                          }}
                        />
                        {this.state.error_creator_phone && (
                          <div className="error">
                            Please enter a valid phone number
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Gender <em>*</em>
                        </label>

                        <select
                          name="selectedGender"
                          value={this.state.selectedGender}
                          onChange={this.handleChange}
                        >
                          <option value="">Select gender</option>
                          {genderOption.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {this.state.error_selectedGender && (
                          <div className="error">Please select a gender</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group upload-custm">
                      <label className="control-label">
                        Cover image <em>*</em>
                      </label>
                      <div className="covers-custm">
                        <input
                          type="file"
                          className="form-control input-focus"
                          placeholder="Upload your cover image"
                          name="cover_image"
                          onChange={this.uploadCoverImage.bind(this)}
                        />
                        {this.state.error_cover_image && (
                          <div className="error">
                            {this.state.error_cover_image_msg}
                          </div>
                        )}
                        <span>
                          Please upload image with minimum 1080x1080 resolution.
                        </span>
                        <div className="gallerybig-img">
                          {this.state.creator_cover_image !== "" && (
                            <img src={this.state.creator_cover_image} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="social-profile-details">
                    <div className="title-with-description">
                      <h2>
                        Social profile <span> details</span>
                      </h2>
                    </div>
                    {this.state.socialMediaSets.map((set, index) => (
                      <div className="soc-off">
                        <div className="unique-package-lhs" key={index}>
                          <div className="form-group soc-add">
                            <label className="control-label">
                              Social media channels {/* <em>*</em> */}
                  {/* </label>
                            <select
                              name="socialMediaChannel"
                              value={set.platform}
                              onChange={(event) =>
                                this.handleSocialMediaChange(index, event)
                              }
                            >
                              <option value="">Select</option>
                              {socialMediaOptions.map((option, optionIndex) => (
                                <option
                                  key={optionIndex}
                                  value={option}
                                  disabled={this.isPlatformSelected(
                                    option,
                                    index
                                  )}
                                >
                                  {option}
                                </option>
                              ))}
                            </select> */}
                  {/* {!set.isPlatformValid && (
                          <div className="error">This field is required</div>
                        )} */}
                  {/* <a
                              href={void 0}
                              className="button soc-btn"
                              onClick={this.handleAddButtonClick}
                            >
                              +
                            </a>
                            {index > 0 && (
                              <a
                                href={void 0}
                                className="button soc-btn remove"
                                onClick={() =>
                                  this.handleRemoveButtonClick(index)
                                }
                              >
                                -
                              </a>
                            )}
                          </div>
                        </div>
                        {set && (
                          <div className="form-group">
                            <label className="control-label"> */}
                  {/* {set.platform} link {/* <em>*</em> 
                            </label>
                            <input
                              name="ChannelLink"
                              type="text"
                              placeholder="Link"
                              value={set.link}
                              onChange={(event) =>
                                this.handleLinkChange(index, event)
                              }
                            /> */}
                  {/*  {!set.isLinkValid && (
                          <div className="error">This field is required</div>
                        )} */}
                  {/* </div>
                        )}
                      </div>
                    ))}
                    {this.state.error_creator_link && (
                      <div className="error">
                        {this.state.error_creator_link_msg}
                      </div>
                    )}
                  </div> */}
                  <div className="without-chk-save">
                    <p>
                      <span>*</span> By clicking "Save changes" you agree, that
                      you have understood the consequences of changing your
                      creator profile details as it may affect your package
                      performances, commissions and payouts.
                    </p>
                    <a
                      href={void 0}
                      className="button"
                      onClick={this.handleSaveChanges}
                    >
                      Save changes
                    </a>
                  </div>
                </div>
                <div className="password-con-box">
                  <div className="title-with-description">
                    <h2>
                      Change <span> password</span>
                    </h2>
                  </div>
                  <div className="pwd-body-form">
                    <div className="form-group">
                      <label className="control-label">
                        Current password <em>*</em>
                      </label>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        name="currentPassword"
                        value={this.state.currentPassword}
                        placeholder="Enter your current password"
                        onChange={this.handleChange}
                        disabled={this.state.current_password_matched}
                      />
                      {this.state.error_currentPassword && (
                        <div className="error">
                          {this.state.error_currentPassword_msg}
                        </div>
                      )}
                      {this.state.current_password_matched && (
                        <div>
                          <span>&#10004;</span>
                        </div>
                      )}
                    </div>
                    <button type="button" onClick={this.toggleShowPassword}>
                      {this.state.showPassword ? "Hide" : "Show"}
                    </button>
                    <div className="form-group">
                      <label className="control-label">
                        New password <em>*</em>
                      </label>
                      <input
                        type={this.state.showNewPassword ? "text" : "password"}
                        name="newPassword"
                        value={this.state.newPassword}
                        placeholder="Enter your new password"
                        onChange={this.handleChange}
                        disabled={!this.state.current_password_matched}
                      />
                      <button
                        type="button"
                        onClick={this.toggleShowNewPassword}
                      >
                        {this.state.showNewPassword ? "Hide" : "Show"}
                      </button>
                      {this.state.error_newPassword && (
                        <div className="error">
                          {this.state.error_newPassword_msg}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        Confirm password <em>*</em>
                      </label>
                      <input
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        placeholder="Confirm your new password"
                        onChange={this.handleChange}
                        disabled={!this.state.current_password_matched}
                      />
                      {this.state.error_confirmPassword && (
                        <div className="error">
                          {this.state.error_confirmPassword_msg}
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={this.toggleShowConfirmPassword}
                      >
                        {this.state.showConfirmPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>
                  <div className="with-chk-save">
                    <p>
                      <input
                        type="checkbox"
                        name="isAgreedForChangePassword"
                        checked={this.state.isAgreedForChangePassword}
                        onChange={this.handleAgreementChange}
                      />

                      <span>
                        By changing your account password you agree, that you
                        have understood the consequences. And you agree to the{" "}
                        <a href={void 0}>
                          Terms and Conditions, Privacy policy.
                        </a>
                      </span>
                    </p>
                    <a
                      href={void 0}
                      className="button dis-button"
                      onClick={this.handleChangePassword}
                    >
                      Change password
                    </a>
                  </div>
                  {this.state.error_isAgreedForChangePassword && (
                    <div className="error">This field is required</div>
                  )}
                </div>
                <div className="password-con-box">
                  <div className="title-with-description">
                    <h2>
                      Account <span> deactivation</span>
                    </h2>
                  </div>
                  <div className="deactive-form">
                    <div className="deactive-form-txt">
                      <h6>What happens when you deactivate your account?</h6>
                      <ul>
                        <li>
                          Your account and packages will not be shown on Fizota
                          platform anymore.
                        </li>
                        <li>
                          You will not be able to create packages and receive
                          commission anymore.
                        </li>
                        <li>
                          You will not be able to re-activate the same account
                          again.
                        </li>
                      </ul>
                    </div>
                    <div className="form-group">
                      <label className="control-label">
                        I want to deactivate my account because..
                      </label>

                      <select
                        name="selectedReason"
                        value={this.state.selectedReason}
                        onChange={this.handleChange}
                      >
                        <option value="">Select a reason</option>
                        {deactivationReasons.map((reason, index) => (
                          <option key={index} value={reason}>
                            {reason}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.error_selectedReason && (
                    <div className="error">Please select a reason</div>
                  )}

                  <div className="with-chk-save">
                    <p>
                      <input
                        type="checkbox"
                        name="isAgreedForDeactivation"
                        checked={this.state.isAgreedForDeactivation}
                        onChange={this.handleAgreementChange}
                      />

                      <span>
                        By deactivating your account you agree, that you have
                        understood the consequences. And you agree to the
                        <a href={void 0}>
                          Terms and Conditions, Privacy policy.
                        </a>
                      </span>
                    </p>
                    <a
                      href={void 0}
                      className="button dis-button"
                      onClick={this.openPopUp}
                    >
                      Deactivate account
                    </a>
                  </div>
                  {this.state.error_isAgreedForDeactivation && (
                    <div className="error">This field is required</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="deactiveConfirm" className="mfp-hide common-popup textcenter">
          <h4>
            To confirm deactivation, enter your creator account password below!
          </h4>
          <div className="pt-crm-form">
            <div className="form-group">
              <label className="control-label">
                Enter password <em>*</em>
              </label>
              <input
                type={this.state.passwordType}
                name="deactivationPassword"
                value={this.state.deactivationPassword}
                placeholder="Enter your current password"
                onChange={this.handleChangeDeact}
              />
              <span
                className={
                  this.state.passwordType === "text" ? "active" : "posab"
                }
              >
                <i
                  className="fa fa-eye-slash"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={this.showhidePWD.bind(this)}
                ></i>
              </span>
              {this.state.error_deactivationPassword === true && (
                <div className="error">
                  {this.state.error_deactivationPassword_msg}
                </div>
              )}
            </div>
            {/* <span className={this.state.passwordType === "text" ? "active" : ""}>
            <i
              className="fa fa-eye-slash"
              aria-hidden="true"
              onClick={this.showhidePWD.bind(this)}
            ></i>
            
            <i
              className="fa fa-eye"
              aria-hidden="true"
              onClick={this.showhidePWD.bind(this)}
            ></i>
          </span> */}
            <a
              href={void 0}
              className={
                this.state.deactivatebutton === true
                  ? "button"
                  : "button debutton"
              }
              id="processDeactivate"
              onClick={
                this.state.deactivatebutton === true
                  ? this.handleDeactivateClick
                  : null
              }
            >
              Deactivate
            </a>
          </div>
          <div className="small-by">
            <span>*</span> By clicking "Deactivate" you agree, that you have
            understood the consequences of changing your creator profile details
            as it may affect your package performances, commissions and payouts.
          </div>
        </div>
        <div id="alert_popup" className="mfp-hide common-popup">
          <div className="cp-header textcenter">
            <div className="cp-body">
              <p> {this.state.msgPop}</p>
              <button onClick={this.Closepopup.bind(this, "password_updated")}>
                Ok
              </button>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var creatdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      creatdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  var uploadfiles = Array();
  if (Object.keys(state.uploadfiles).length > 0) {
    if (state.uploadfiles[0].status === "ok") {
      uploadfiles = state.uploadfiles[0];
    }
  }
  return {
    creatordetail: creatdetailArr,
    common: common,
    uploadfiles: uploadfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
