/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FAVOURITELIST, SET_FAVOURITELIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetFavouritelist = function* () {
  yield takeEvery(GET_FAVOURITELIST, workerGetFavouritelist);
};

function* workerGetFavouritelist() {
  try {
    const uri =
      apiUrl + "products/favourite_list?customer_id=" + cookie.load("UserId");
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FAVOURITELIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Favourite List Failed");
  }
}
