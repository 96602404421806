/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  apiUrl,
  headerconfig,
  apiglobaltixUrl,
  apiglobaltixMediaUrl,
  UopUrl,
} from "../Helpers/Config";
import ContentLoader from "react-content-loader";
import searchicon from "../../common/images/search.svg";
import fgrey from "../../common/images/filter-grey.svg";
import addplus from "../../common/images/add-plus.svg";
import editpen from "../../common/images/edit-pen.svg";
import actinfo1 from "../../common/images/act-info1.jpg";
import axios from "axios";
import RadialBarChart from "./components/RadialBarChart";
import cookie from "react-cookies";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import ModalPopup from "./ModalPopup/ModalPopup";
import { Menu, MenuItem, Pagination } from "@mui/material";
var base64 = require("base-64");
class Listpromo extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      packageDetails: [],
      triggerAddproduct: false,
      triggerPackCreation: false,
      triggercontinue: false,
      filterQuery: "",
      packageSource: "",
      filteredPackage: [],
      anchorEl: null,
      airport: [],
      selectedairport: [],
      numberofdays: "",
      whomgoing: [],
      address: "",
      packagecountry: "",
      packageCity: "",
      pacakgeLat: "",
      packageLon: "",
      error_address: false,
      error_numberofdays: false,
      error_whomgoing: false,
      error_selectedairport: false,
      packageLoading: true,
      cityList: [],
      selectedpackagecity: [],
      airport_id: "",
      isOpen: false,
      packageList: [],
      pageLimitAndSkip: {
        skip: 0,
        limit: 10,
      },
      hasMore: true,
      dataCount: 5,
      packageTitle: "",
      packageID: "",
    };
  }
  componentDidMount() {
    this.loadCity();
    this.getPackageDetails();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState?.pageLimitAndSkip?.skip !== this.state.pageLimitAndSkip.skip
    ) {
      this.getPackageDetails();
    }
    if (prevState.filterQuery !== this.state.filterQuery) {
      this.setState({
        // packageDetails: [],
        pageLimitAndSkip: {
          skip: 0,
          limit: 10,
        },
      });
    }
  }

  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }

  getFormattedDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}`;
  };

  getPackageDetails = async () => {
    try {
      const { CUserId, pageLimitAndSkip, packageSource, packageTitle } =
        this.state;
      const packDetails = {
        id: CUserId,
        skip: pageLimitAndSkip.skip,
        pageSize: pageLimitAndSkip.limit,
        source: packageSource,
        packageTitle: packageTitle,
      };
      await axios
        .post(`${UopUrl}/package/details`, packDetails, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.setState({
              dataCount: Math.ceil(res.data.count / 10),
              packageDetails: res.data.data,
            });
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  handleFilter = (event) => {
    const { value } = event.target;

    if (value !== "") {
      this.setState({ packageTitle: value, filterQuery: value }, () => {
        if (value.length >= 3) {
          this.getPackageDetails();
        }
      });
    }
    // else if (typeof value === number) {
    //   this.setState({ packageID: value, filterQuery: value }, () =>
    //     this.getPackageDetails()
    //   );
    // }
    else {
      this.setState(
        { packageID: value, packageTitle: value, filterQuery: value },
        () => this.getPackageDetails()
      );
    }
  };

  handlePopup = (action) => {
    this.setState({ triggerAddproduct: action });
  };

  editPackage = (item, id, type) => {
    var packagedetails = {
      creator_id: item?.creator_id,
      creator_package_id: item?.creator_package_id,
      creator_package_name: item?.creator_package_source,
      creator_package_status: item?.creator_package_status,
      creator_package_thumbnail_web: item?.creator_package_thumbnail_web,
      creator_package_travelling: item?.creator_package_travelling,
      guide_included: item?.guide_included,
      transfer_included: item?.transfer_included,
      newPackage: false,
      has_itinerary: item.has_itinerary,
      has_availability: item.has_availability,
      has_pricing: item.has_pricing,
      includedSeason: item.includedSeason,
      destList: item.creator_package_multiple_destination || [""],
    };
    if (type === "vacation") {
      this.props.history.push({
        pathname: "/edit-package/" + base64.encode(item?.creator_package_id),
        state: packagedetails,
      });
    } else if (type === "uop") {
      this.props.history.push({
        pathname: "/myaccount/general-info",
        state: packagedetails,
      });
    }
  };

  handleFilterOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseFilter = (event) => {
    this.setState({ anchorEl: null });
  };

  handleFilterClose = (value) => {
    this.setState(
      {
        anchorEl: null,
        packageSource: value,
      },
      () => this.getPackageDetails()
    );
  };

  openPackage = () => {
    this.setState({ triggerPackCreation: true });
  };
  continuePackage = () => {
    // if (this.state.triggerPackCreation) {
    this.setState(
      {
        triggerAddproduct: false,
      },
      () => {
        this.setState({ triggerPackCreation: false, triggercontinue: true });
      }
    );
    // }
  };
  handlePackage = () => {
    this.props.history.push("/myaccount/create-package");
  };
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  onChange(name, value) {
    if (name === "selectedpackagecity") {
      var splitValue = value.value.split("~");
      console.log(splitValue, "splitValuesplitValue");
      this.setState({
        [name]: value,
        address: splitValue[0],
        packagecountry: splitValue[10],
        pacakgeLat: splitValue[3],
        packageLon: splitValue[4],
        packageCity: splitValue[8],
        airport_id: splitValue[2],
      });
    } else {
      this.setState({ [name]: value });
    }
  }
  createPackage() {
    var error = 0;
    if (this.state.address === "") {
      error++;
      this.setState({ error_address: true });
    }
    if (
      this.state.numberofdays === "" ||
      !/^[0-9]*$/.test(this.state.numberofdays)
    ) {
      error++;
      this.setState({ error_numberofdays: true });
    } else {
      const numberOfDays = parseInt(this.state.numberofdays, 10);

      if (numberOfDays < 2 || numberOfDays > 15) {
        error++;
        this.setState({ error_numberofdays: true });
      } else {
        this.setState({ error_numberofdays: false });
      }
    }
    if (Object.keys(this.state.whomgoing).length === 0) {
      error++;
      this.setState({ error_whomgoing: true });
    }
    if (error === 0) {
      var packagedetails = {
        address: this.state.address,
        packagecountry: this.state.packagecountry,
        packageCity: this.state.packageCity,
        pacakgeLat: this.state.pacakgeLat,
        packageLon: this.state.packageLon,
        numberofdays: this.state.numberofdays,
        whomgoing: this.state.whomgoing,
        selectedairport: { value: this.state.airport_id },
      };
      $.magnificPopup.close();
      this.props.history.push({
        pathname: "/myaccount/create-package",
        state: packagedetails,
      });
    }
  }
  loadCity() {
    axios.get(apiUrl + "settings/getCityList", {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }).then((res) => {
      if (res.data.status === "ok") {
        var cityList = [];
        if (Object.keys(res.data.result_set).length > 0) {
          Object.keys(res.data.result_set).map((item) => {
            var selectedCountry = res.data.result_set[item];
            selectedCountry.city.map((city) => {
              cityList.push({
                label: city.city_name + ", " + city.country_name,
                value:
                  city.airport_address +
                  "~" +
                  city.airport_code +
                  "~" +
                  city.airport_id +
                  "~" +
                  city.airport_latitude +
                  "~" +
                  city.airport_longitude +
                  "~" +
                  city.airport_name +
                  "~" +
                  city.city_code +
                  "~" +
                  city.city_id +
                  "~" +
                  city.city_name +
                  "~" +
                  city.country_code +
                  "~" +
                  city.country_name +
                  "~" +
                  city.webbeds_city_code,
              });
            });
          });
        }
        this.setState(
          {
            cityList: cityList,
          },
          function () {
            // console.log(cityList, "citylist");
          }
        );
      }
    });
  }

  fetchData() {
    this.setState({
      pageLimitAndSkip: {
        skip:
          this.state.pageLimitAndSkip?.skip +
          this.state.pageLimitAndSkip?.limit,
        limit: 10,
      },
    });
  }

  handlePageChange(ev, num) {
    this.setState({
      pageLimitAndSkip: {
        skip: this.state.pageLimitAndSkip?.limit * (num - 1),
        limit: 10,
      },
    });
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const { packageDetails, filterQuery, filteredPackage } = this.state;
    return (
      <>
        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <div>
                <div className="search-and-add">
                  <div className="search-and-add-filter">
                    <div className="search-and-add-form">
                      <img src={searchicon} className="search-lft" />
                      <input
                        type="text"
                        placeholder="Search products by Name, Id.."
                        onChange={this.handleFilter}
                      />
                    </div>
                    <div className="search-and-filter">
                      <a onClick={this.handleFilterOpen}>
                        <img src={fgrey} />
                        {this.state.packageSource !== ""
                          ? this.state.packageSource
                          : "Filter"}
                      </a>
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={this.state.anchorEl}
                        open={open}
                        onClose={this.handleCloseFilter}
                        keepMounted
                      >
                        <MenuItem
                          onClick={() => this.handleFilterClose("")}
                          sx={{ fontSize: "14px", fontFamily: "poppins" }}
                        >
                          Select
                        </MenuItem>
                        <MenuItem
                          onClick={() => this.handleFilterClose("vacation")}
                          sx={{ fontSize: "14px", fontFamily: "poppins" }}
                        >
                          Normal
                        </MenuItem>
                        <MenuItem
                          onClick={() => this.handleFilterClose("uop")}
                          sx={{ fontSize: "14px", fontFamily: "poppins" }}
                        >
                          UOP
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <div className="search-and-add-btn">
                    <a
                      className="button ghost-button"
                      onClick={() => this.handlePopup(true)}
                    >
                      <img src={addplus} /> Add Product
                    </a>
                  </div>
                </div>
                <div className="my-product-listing">
                  <ul>
                    <li className="header-row">
                      <div className="col1">Image</div>
                      <div className="col2">
                        <div className="col3">Product</div>
                        <div className="col4">ID</div>
                        <div className="col4">Clicks</div>
                        <div className="col4">Type</div>
                        <div className="col4">Revenue</div>
                        <div className="col4">Status</div>
                        <div className="col4">Action</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="my-product-listing">
                  {packageDetails?.length > 0 ? (
                    <ul>
                      {packageDetails.map((item, i) => (
                        <li key={i}>
                          <div className="my-pro-img">
                            <figure>
                              <img
                                src={
                                  item.creator_package_thumbnail_web !== "" &&
                                  item.creator_package_thumbnail_web !== null &&
                                  item.creator_package_thumbnail_web !==
                                    undefined &&
                                  item.creator_package_thumbnail_web !==
                                    "undefined"
                                    ? item.creator_package_thumbnail_web
                                    : actinfo1
                                }
                              />
                            </figure>
                          </div>
                          <div className="my-pro-maindesc">
                            <div className="my-pro-desc">
                              <p>{item.creator_package_name}</p>
                              <ul>
                                {item?.transfer_included === "true" && (
                                  <li>transfer</li>
                                )}
                                {item?.guide_included === "true" && (
                                  <li>Guided</li>
                                )}
                                {item?.food_included === "yes" && (
                                  <li>Food included</li>
                                )}
                              </ul>
                            </div>
                            <div className="my-pro-id">
                              {item.creator_package_id}
                            </div>
                            {/*   <div className="my-pro-score">
                            <RadialBarChart value={90} />
                          </div> */}
                            <div className="my-pro-id">
                              {item.creator_package_total_click}
                            </div>
                            <div className="my-pro-type">
                              {item.creator_package_source}
                            </div>
                            <div className="my-pro-revenue">
                              <span>SGD {item.creator_package_price}</span>
                            </div>
                            <div className="my-pro-state">
                              <span
                                className={`live-box ${
                                  item.creator_package_status !== "A"
                                    ? "pending"
                                    : ""
                                }`}
                              >
                                {item.creator_package_status === "A"
                                  ? "Active"
                                  : "Pending"}
                              </span>
                            </div>
                            <div className="my-pro-action">
                              <a
                                onClick={() =>
                                  this.editPackage(
                                    item,
                                    item.creator_package_id,
                                    item.creator_package_source
                                  )
                                }
                              >
                                <img src={editpen} />
                                Edit
                              </a>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="search-loader">
                      {Array(1, 2, 3, 4).map((item, index) => {
                        return (
                          <ContentLoader
                            viewBox={`0 0 ${
                              this.state.isMobile ? "380" : "100%"
                            } 280`}
                            height={280}
                            width={`${this.state.isMobile ? "380" : "100%"}`}
                            speed={3}
                            key={index}
                          >
                            <rect
                              x="3"
                              y="3"
                              rx="10"
                              ry="10"
                              width={`${this.state.isMobile ? "380" : "100%"}`}
                              height="180"
                            />
                            <rect
                              x="6"
                              y="190"
                              rx="0"
                              ry="0"
                              width={`${this.state.isMobile ? "320" : "60%"}`}
                              height="20"
                            />
                            <rect
                              x="4"
                              y="215"
                              rx="0"
                              ry="0"
                              width="320"
                              height="20"
                            />
                            <rect
                              x="4"
                              y="242"
                              rx="0"
                              ry="0"
                              width="380"
                              height="20"
                            />
                          </ContentLoader>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="pagination-main">
                  <Pagination
                    count={this.state.dataCount}
                    showFirstButton
                    showLastButton
                    onChange={(ev, num) => this.handlePageChange(ev, num)}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <ModalPopup
          type="addProduct"
          state={this.state}
          open={this.state.triggerAddproduct}
          handleClose={() => this.handlePopup(false)}
          openPackage={this.openPackage}
          continuePackage={this.continuePackage}
          handleChange={this.handleChange}
          onChange={(name, value) => this.onChange(name, value)}
          createPackage={this.createPackage.bind(this)}
        />
        <ModalPopup
          state={this.state}
          type="packCreation"
          open={this.state.triggercontinue}
          handleClose={() => this.setState({ triggercontinue: false })}
          continuePackage={this.continuePackage}
          onChange={(name, value) => this.onChange(name, value)}
          cityList={this.state.cityList}
          handleChange={this.handleChange.bind(this)}
          createPackage={this.createPackage.bind(this)}
        />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
