/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_MYSTIFLYREVALIDATE, SET_MYSTIFLYREVALIDATE } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMystiflyRevalidate = function* () {
  yield takeEvery(GET_MYSTIFLYREVALIDATE, workerGetMystiflyRevalidate);
};

function* workerGetMystiflyRevalidate({ formPayload }) {
  try {
    const result = yield call(getMystiflyRevalidate, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MYSTIFLYREVALIDATE, value: resultArr });
  } catch {
    showAlert('Error', "Something went wrong, please try again later")
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });

    console.log("login failed");
  }
}

function getMystiflyRevalidate(formPayload) {
  return Axios.post(
    apiUrl + "package/revalidatePrice",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }
  );
}
