/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import pplaceholder from "../../common/images/product-noimage.jpg";
import {
  apiUrl,
  mediaUrl,
  apiglobaltixUrl,
} from "../Helpers/Config";
import { GET_FUNTHINGS, GET_FAVOURITE } from "../../actions";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
var Parser = require("html-react-parser");
var qs = require("qs");
class Promo extends Component {
  constructor(props) {
    super(props);
    var funthingslug = "";
    if (
      this.props.match.params.funthingslug !== "" &&
      typeof this.props.match.params.funthingslug !== undefined &&
      typeof this.props.match.params.funthingslug !== "undefined"
    ) {
      funthingslug = this.props.match.params.funthingslug;
    } else {
      this.props.history.push("/");
    }
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      funthingslug: funthingslug,
      UserId: UserId,
      funthings: "",
    };
  }
  componentDidMount() {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var params = "fun_thing_slug=" + this.state.funthingslug;
    if (this.state.UserId !== "") {
      params += "&customer_id=" + this.state.UserId;
    }
    if (tz !== "" && tz !== null) {
      var split = tz.split("/");
      params += "&country=" + split[1].toLocaleLowerCase();
    }
    this.props.getFunThings(params);
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.funthingslist !== this.state.funthingslist) {
      if (PropsData.funthingslist.length > 0) {
        this.setState({ funthings: PropsData.funthingslist[0] });
      }
    }
    if (this.state.addfavloading === "loading") {
      if (PropsData.favourite !== undefined) {
        this.showMessage(PropsData.favourite[0]);
      }
    }
  }

  loadPromotions() {
    axios
      .get(
        apiUrl +
          "booking/getpromo?promotion_slug=" +
          this.props.match.params.promoslug,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ promotionDetails: res.data.result_set[0] });
        } else {
          this.props.history.push("/");
        }
      });
  }

  displayProductList(productlist) {
    var product_List = "";
    if (productlist.length > 0) {
      product_List = productlist.map((item, index) => {
        return (
          <li key={index} id={"pro_" + item.products_id}>
            <div className="product-img">
              <a
                href={void 0}
                className={item.favourite === "Y" ? "like active" : "like"}
                onClick={this.addRemoveFavourite.bind(this, item.products_id)}
              >
                {" "}
                <img src={heart} alt="heart" /> <img src={heartfill} alt="heartfill" />
              </a>
              <Link
                to={"/product-details/" + item.product_slug}
                className="pt-img"
              >
                <img
                  src={
                    item.product_thumbnail !== "" &&
                    item.product_thumbnail !== null
                      ? apiglobaltixUrl + "image?name=" + item.product_thumbnail
                      : pplaceholder
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="product-description">
              <div className="product-description-title">
                <h5>
                  <Link to={"/product-details/" + item.product_slug}>
                    {item.products_name}
                  </Link>{" "}
                </h5>
              </div>
              {item.product_category_name !== "" &&
                item.product_category_name !== null && (
                  <div className="product-description-tag">
                    <span>{item.product_category_name}</span>
                  </div>
                )}
              <div className="product-description-review">
                <div className="pdr-lhs">
                  {item.product_rating !== "" &&
                    item.product_rating !== null && (
                      <span>
                        <strong>{item.product_rating}</strong> (
                        {item.product_total_review}) Reviews
                      </span>
                    )}
                  <div className="star-rating">
                    {this.loadRatingstar(item.product_rating)}
                  </div>
                </div>
                <div className="pdr-rhs">
                  <span>From</span>
                  <strong>SGD ${item.product_payable_amount}</strong>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }
    return product_List;
  }
  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return <i className="fa fa-star-half-o" aria-hidden="true"></i>;
          } else {
            return <i className="fa fa-star" aria-hidden="true"></i>;
          }
        }
      });
    }
  }
  addRemoveFavourite(products_id) {
    if (this.state.UserId !== "") {
      var postObject = {
        favourite_customer_id: this.state.UserId,
        favourite_product_id: products_id,
      };
      this.setState({ addfavloading: "loading" });
      this.props.getFavouritelist(qs.stringify(postObject));
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }
  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var result = response.result_set;
      if (result.addremovetype === "A") {
        $("#pro_" + result.product_id + " .like").addClass("active");
      } else {
        $("#pro_" + result.product_id + " .like").removeClass("active");
      }
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />

        {this.state.funthings !== "" ? (
          <>
            <div className={"promo-banner " + this.state.funthingslug}>
              <div className="container">
                <div className="promo-banner-lhs">
                  {this.state.funthings.fun_thing_short_description !== "" &&
                    this.state.funthings.fun_thing_short_description !== null &&
                    Parser(this.state.funthings.fun_thing_short_description)}
                </div>
                <div className="promo-banner-rhs">
                  {
                    <img
                      src={
                        mediaUrl +
                        "funthings/" +
                        this.state.funthings.fun_thing_banner
                      }
                      alt=""
                    />
                  }
                  <span className="hidden-tag">Hidden</span>
                </div>
              </div>
            </div>
            <div className="bread-crumbs-other bread-crumbs">
              <div className="container">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>{stripslashes(this.state.funthings.fun_thing_title)}</li>
                </ul>
              </div>
            </div>

            <section className="innersection">
              <div className="container">
                <div className="expolre-promo">
                  <div className="expolre-list">
                    <h4>
                      {stripslashes(this.state.funthings.fun_thing_title)}
                    </h4>
                    {this.state.funthings.fun_thing_long_description !== "" &&
                      this.state.funthings.fun_thing_long_description !==
                        null &&
                      Parser(this.state.funthings.fun_thing_long_description)}
                  </div>
                  {this.state.funthings.products.length > 0 && (
                    <ul>
                      {this.displayProductList(this.state.funthings.products)}
                    </ul>
                  )}
                  <div className="more-coming textcenter">
                    More coming soon...
                  </div>
                </div>
              </div>
            </section>
            {/* this.state.promotionDetails.promotion_trems !== "" &&
              this.state.promotionDetails.promotion_trems !== null && (
                <div className="termsection">
                  <div className="container">
                    {Parser(this.state.promotionDetails.promotion_trems)}
                  </div>
                </div>
              ) */}
          </>
        ) : (
          <div id="dvLoading">
            <div className="loader triangle">
              <svg viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  stroke="black"
                  stroke-width="3"
                  fill="transparent"
                />
              </svg>
            </div>
          </div>
        )}
        <Footer {...this.props} />
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  var funthingsArr = Array();
  if (Object.keys(state.funthings).length > 0) {
    if (state.funthings[0].status === "ok") {
      funthingsArr = state.funthings[0].result_set;
    }
  }

  return {
    funthingslist: funthingsArr,
    favourite: state.favourite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFunThings: (params) => {
      dispatch({ type: GET_FUNTHINGS, params });
    },
    getFavouritelist: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Promo);
