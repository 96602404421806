/* eslint-disable */
import React, { Component } from "react";
import DestinationIcon from "../../common/images/Destination.svg";
import affordableIcon from "../../common/images/affordable.svg";
import CalendarIcon from "../../common/images/Calendar.svg";
import destim from "../../common/images/europe.jpg";
import customizeIcon from "../../common/images/customize.svg";
import curatedIcon from "../../common/images/curated.svg";
import moment from "moment";
import { Button } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DateRange } from "react-date-range";

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityList: this.props.cityList,
      searchCityList: [],
      selectedDestination: false,
      selectedDestinationdetails: "",
      opendestinationList: false,
      searchkeywords: "",
      start_date: "",
      end_date: "",
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    };
  }

  componentDidMount() {
    var $_this = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".content-search-parent *, .date-popup-menu *")) {
        $_this.setState({ opendestinationList: false, showDatePopup: false });
      }
    });
  }

  filterSearchCity() {
    var searchCityList = [];
    var searchCountryList = [];
    var cityList = this.props.cityList;
    if (Object.keys(cityList).length > 0) {
      Object.keys(cityList).map((item) => {
        var selectedCountry = cityList[item];
        if (selectedCountry.city.length > 0) {
          selectedCountry.city.map((cityItem) => {
            if (cityItem.package_available === "Y") {
              if (
                cityItem.city_name
                  .toLowerCase()
                  .indexOf(this.state.searchkeywords.toLowerCase()) >= 0 ||
                cityItem.country_name
                  .toLowerCase()
                  .indexOf(this.state.searchkeywords.toLowerCase()) >= 0 ||
                this.state.searchkeywords === ""
              ) {
                if (searchCountryList.indexOf(cityItem.country_name) < 0) {
                  searchCountryList.push(cityItem.country_name);
                }
                searchCityList.push({
                  airport_address: cityItem.airport_address,
                  airport_code: cityItem.airport_code,
                  airport_id: cityItem.airport_id,
                  airport_latitude: cityItem.airport_latitude,
                  airport_longitude: cityItem.airport_longitude,
                  airport_name: cityItem.airport_name,
                  city_code: cityItem.city_code,
                  city_id: cityItem.city_id,
                  city_name: cityItem.city_name,
                  country_code: cityItem.country_code,
                  country_name: cityItem.country_name,
                  images: cityItem.images,
                  thumbnail: cityItem.thumbnail,
                  webbeds_city_code: cityItem.webbeds_city_code,
                });
              }
            }
          });
        }
      });
    }
    this.setState(
      { searchCityList: searchCityList, destinationList: searchCountryList },
      function () {
        if ($(".mCustomScrollbar").length > 0) {
          $(".mCustomScrollbar").mCustomScrollbar();
          $(".vacation-bg .search-results").show().fadeIn();
        }
      }
    );
  }
  handleTextChange(type, event) {
    if (type === "city") {
      this.setState(
        { searchkeywords: event.target.value, selectedDestinationdetails: "" },
        function () {
          this.filterSearchCity();
        }
      );
    }
  }

  selectNewDestination(item) {
    console.log(item, "itemitemitem");
    this.setState(
      {
        selectedDestinationdetails: item,
        opendestinationList: false,
        selectedDestination: true,
        error_location_source: false,
        searchkeywords: item.city_name,
      },
      () => {
        this.props.selectNewDestination(item, 3);
      }
    );
  }
  handleSelectDateRangeSelectRange(ranges) {
    this.props.handleSelectDateRangeSelectRange(ranges);
    this.setState(
      {
        selectionRange: ranges?.selection,
        start_date: ranges?.selection?.startDate,
        end_date: ranges?.selection?.endDate,
      },
      function () {
        if (
          ranges?.selection?.startDate !== "" &&
          ranges?.selection?.endDate !== ""
        ) {
          if (ranges?.selection?.startDate !== ranges?.selection?.endDate) {
            setTimeout(() => {
              this.setState({
                showDatePopup: false,
                error_start_date: false,
                opendestinationList: false,
              });
            }, 2000);
          }
        }
      }
    );
  }

  render() {
    return (
      <section className="step-banner-rounded vacation-bg">
        <div className="container">
          <div className="hero-content">
            <div className="hero-content-top">
              <h1>
                Customize and book your
                <br /> Next vacation.
              </h1>
            </div>
            <div className="content-search-parent">
              <div className="row">
                <div className="col1">
                  <img src={DestinationIcon} alt="" />
                  <input
                    type="text"
                    autoFocus={this.state.inputFocused}
                    className="dd-text"
                    placeholder="Choose your destination"
                    value={this.state.searchkeywords}
                    onClick={() => {
                      this.setState(
                        {
                          opendestinationList: !this.state.opendestinationList,
                          showDatePopup: false,
                        },
                        () => {
                          this.filterSearchCity();
                        }
                      );
                    }}
                    onChange={this.handleTextChange.bind(this, "city")}
                  />
                  {this.state.error_location_source === true && (
                    <span class="error">Please Select Your destination</span>
                  )}
                </div>
                <div className="divider"></div>
                <div className="col1">
                  <img src={CalendarIcon} alt="" />
                  <div
                    onClick={() => {
                      this.setState({
                        showDatePopup: !this.state.showDatePopup,
                        opendestinationList: false,
                      });
                    }}
                  >
                    {this.state.start_date !== ""
                      ? moment(this.state.start_date).format("DD MMM YYYY") +
                        " to " +
                        moment(this.state.end_date).format("DD MMM YYYY")
                      : "Choose your vacation date"}
                  </div>
                  {this.state.error_start_date === true && (
                    <span class="error">Please Select Your vacation date</span>
                  )}
                </div>
                <div className="col2">
                  <Button
                    variant="contained"
                    className="exploreBtn"
                    onClick={this.props.continueExplore.bind(this)}
                  >
                    Explore
                  </Button>
                </div>
              </div>
              {this.state.opendestinationList === true && (
                <div className="search-results" style={{ display: "none" }}>
                  <ArrowDropUpIcon />
                  <div className="search-with-padd">
                    <div className="search-with-img-result mCustomScrollbar">
                      <ul>
                        {this.state.searchCityList.map((item, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={void 0}
                                onClick={this.selectNewDestination.bind(
                                  this,
                                  item,
                                  3
                                )}
                              >
                                <img
                                  src={
                                    item.thumbnail !== "" &&
                                    item.thumbnail !== null
                                      ? item.thumbnail
                                      : item.images !== "" &&
                                        item.images !== null
                                      ? item.images
                                      : destim
                                  }
                                />
                                <span>
                                  <strong>{item.city_name}</strong>
                                  <em>{item.country_name}</em>
                                </span>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showDatePopup ? (
                <div className="date-popup-menu">
                  <ArrowDropUpIcon />
                  <div className="step-content-calender textcenter">
                    <DateRange
                      showDateDisplay={false}
                      startDatePlaceholder={"Start Date"}
                      endDatePlaceholder={"End Date"}
                      editableDateInputs={false}
                      moveRangeOnFirstSelection={false}
                      ranges={[this.state.selectionRange]}
                      onChange={this.handleSelectDateRangeSelectRange.bind(
                        this
                      )}
                      rangeColors={["#4258bf"]}
                      months={2}
                      minDate={new Date()}
                      direction="horizontal"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="info-list-main">
              <div className="row">
                <div className="col">
                  <div className="c-row">
                    <div>
                      <img src={customizeIcon} alt="" />
                    </div>
                    <div className="c-col">
                      <div className="text1">Hyper Customize</div>
                      <div className="text2">Your vacation online.</div>
                    </div>
                  </div>
                </div>
                <div className="col1">
                  <div className="c-row">
                    <div>
                      <img src={curatedIcon} alt="" />
                    </div>
                    <div className="c-col">
                      <div className="text1">Curated Packages</div>
                      <div className="text2">
                        From top creators from top destinations
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col1">
                  <div className="c-row">
                    <div>
                      <img src={affordableIcon} alt="" />
                    </div>
                    <div className="c-col">
                      <div className="text1">All Inclusive Packages</div>
                      <div className="text2">
                        Explore affordable itineraries
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SearchForm;
