/* eslint-disable */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import logo from "../common/images/logo.svg";
import { apiUrl, projectTitle } from "../components/Helpers/Config";
import axios from "axios";

export default class PackageDashboard extends Component {
  constructor(props) {
    super(props);
    console.log("PackageDashboard", this.props);
    var verified =
      this.props.location.state !== undefined
        ? this.props.location.state
        : false;
    if (!verified) {
      this.props.history.push("/admin");
    }
    this.state = {
      packageDetail: {},
      verified: verified,
      action: "",
    };
  }
  componentDidMount() {
    this.getPackage();
  }

  getPackage = () => {
    var formData = new FormData();
    formData.append("password", "fizota@02%!");
    axios
      .post(apiUrl + "package/getPackage", formData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ packageDetail: res.data.data });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClick = () => {
    this.getPackage();
  };

  approvePackage = (Cid, Pid) => {
    var formData = new FormData();
    formData.append("creator_id", Cid);
    formData.append("package_id", Pid);
    axios
      .post(apiUrl + "package/approvePackage", formData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ action: "accept" }, () => {
            $.magnificPopup.open({
              items: {
                src: "#popup",
              },
              type: "inline",
            });
          });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  rejectPackage = (Cid, Pid) => {
    var formData = new FormData();
    formData.append("creator_id", Cid);
    formData.append("package_id", Pid);
    axios
      .post(apiUrl + "package/rejectPackage", formData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ action: "reject" }, () => {
            $.magnificPopup.open({
              items: {
                src: "#popup",
              },
              type: "inline",
            });
          });
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();
    var packageDetail = this.state.packageDetail;
    return (
      <>
        <header className="admin-header">
          <div className="container flex">
            <div className="header-lhs">
              <Link to={"/"}>
                {" "}
                <img src={logo} alt="logo" />{" "}
              </Link>
            </div>
            <div className="header-rhs">
              <div className="navigation">
                <a
                  href={void 0}
                  className="button"
                  onClick={() => {
                    this.setState({ verified: false }, () =>
                      this.props.history.push("/")
                    );
                  }}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </header>
        <body className="admin-body">
          <div className="admin-container">
            <h4 className="admin-headline-mrg">
              Creator's <span> packages</span>
            </h4>
            <table className="admin-table">
              <thead className="admin-thead">
                <tr className="admin-thead-row">
                  <th className="ad-date-cell">Date</th>
                  <th className="ad-pack-cell">Package title</th>
                  <th className="ad-pid-cell">Package id</th>
                  <th className="ad-creator-cell">Creator</th>
                  <th className="ad-cid-cell">Creator id</th>
                  <th className="ad-status-cell">Status</th>
                  <th className="ad-action-cell">Action</th>
                </tr>
              </thead>
              <tbody className="admin-tbody">
                {packageDetail.length > 0 && (
                  <>
                    {packageDetail.map((row) => (
                      <tr key={row.id} className="admin-tbody-row">
                        <td>
                          {row.creator_package_created_on.substring(0, 10)}
                        </td>
                        <td>{row.creator_package_name}</td>
                        <td>{row.creator_package_id}</td>
                        <td>{row.creator_name}</td>
                        <td>{row.creator_id}</td>
                        <td>{row.creator_package_status}</td>
                        <td className="admin-td-btn">
                          <a href={void 0}
                            onClick={() =>
                              this.approvePackage(
                                row.creator_id,
                                row.creator_package_id
                              )
                            }
                            style={{ color: "green" }}
                          >
                            Approve
                          </a>
                          <a href={void 0}
                            onClick={() =>
                              this.rejectPackage(
                                row.creator_id,
                                row.creator_package_id
                              )
                            }
                            style={{ color: "red" }}
                          >
                            Reject
                          </a>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div
            id="popup"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <h2 className="text-uppercase">
                      {this.state.action === "accept"
                        ? "Package Accepted"
                        : "Package Rejected"}
                    </h2>
                    <div className="alt_btns">
                      <a
                        href={void 0}
                        className="button popup-modal-dismiss disbl_href_action"
                        onClick={this.handleClick}
                      >
                        Ok
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
        <div className="admin-footer">
          <div className="admin-container">
            <div className="admin-logo">
              <Link to="/" title="ChopChop" className="">
                {" "}
                <img src={logo} alt="ChopChop" />{" "}
              </Link>
            </div>
            <p className="admin-footer-p">
              We empower humans to inspire <br />
              discover and explore exoeriences
              <br />
              around the world
            </p>
          </div>

          <div className="admin-container">
            <div>
              <p>
                Copyright {yearSp} {projectTitle}.
              </p>
              <p>
                Made with <i className="fa fa-heart" aria-hidden="true"></i> by
                Fizota team
                <br />© 2023 Airvays Technologies Pte.Ltd All Rights Reserved
              </p>
            </div>
          </div>

          <div className="admin-container">
            {" "}
            <p>Travel Agent: TA03466</p>
          </div>
        </div>
      </>
    );
  }
}
