/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import { GET_UPLOAD_FILES } from "../../actions";
import { apiUrl } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  stripslashes,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Topmenu from "../Layout/Topmenu";
import closeImg from "../../common/images/close-icon.svg";
var qs = require("qs");
class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    var searchField,
      numberofdays,
      packagecountry,
      packageCity,
      address,
      pacakgeLat,
      packageLon = "";
    var whomgoing = [];
    var selectedairport = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      searchField = this.props.location.state;
      if (searchField !== "") {
        numberofdays = searchField.numberofdays;
        packagecountry = searchField.packagecountry;
        packageCity = searchField.packageCity;
        whomgoing = searchField.whomgoing;
        selectedairport = searchField.selectedairport;
        address = searchField.address;
        pacakgeLat = searchField.pacakgeLat;
        packageLon = searchField.packageLon;
      }
    }
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    var editpackage = false;
    var packageID = "";
    if (this.props.match.path === "/edit-package/:packageID") {
      editpackage = true;
      if (
        this.props.match.params.packageID !== "" &&
        typeof this.props.match.params.packageID !== undefined &&
        typeof this.props.match.params.packageID !== "undefined"
      ) {
        packageID = this.props.match.params.packageID;
      }
    }
    var newPackage = true;
    if (
      this.props.location.state?.newPackage !== "" &&
      typeof this.props.location.state?.newPackage !== undefined &&
      typeof this.props.location.state?.newPackage !== "undefined"
    ) {
      newPackage = this.props.location.state.newPackage;
    }

    this.state = {
      CUserId: CUserId,
      editpackage: editpackage,
      packageID: packageID,
      searchField: searchField,
      numberofdays: numberofdays,
      packagecountry: packagecountry,
      packageCity: packageCity,
      address: address,
      pacakgeLat: pacakgeLat,
      packageLon: packageLon,
      whomgoing: whomgoing,
      selectedairport: selectedairport,
      package_name: "",
      categoryList: [],
      selectedcategory: [],
      keywords: "",
      keyword_list: [],
      package_description: "",
      includedList: [
        "Flight round trip (add-on)",
        "Stay",
        "Airport transfer round trip (add-on)",
      ],      error_package_name: false,
      error_number_of_days: false,
      error_selectedcategory: false,
      error_package_description: false,
      thumbnail_image: "",
      cover_image: "",
      cover_image_mobile: "",
      gallery_image: [],
      gallery_image_mobile: [],
      videoContent: "",
      webbeds_city_code: "",
      uploadfiles: "",
      purpose_coveimage: false,
      purpose_galleryimage: false,
      unsavedChanges: true,
      newPackage: newPackage,
    };
  }
  componentDidMount() {
    this.setState({ unsavedChanges: true });
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
    if (this.state.packageID !== "") {
      showLoader("package_frm", "class");
    }

    this.loadCategories();
  }
  //  getPacakgeCategories;
  componentWillReceiveProps(nextProps) {
    if (
      this.state.cover_image !== nextProps.uploadfiles.web &&
      typeof nextProps.uploadfiles.web !== undefined &&
      typeof nextProps.uploadfiles.web !== "undefined" &&
      this.state.purpose_coveimage
    ) {
      this.setState({ cover_image: nextProps.uploadfiles.web });
    }
    if (
      this.state.cover_image_mobile !== nextProps.uploadfiles.mobile &&
      typeof nextProps.uploadfiles.mobile !== undefined &&
      typeof nextProps.uploadfiles.mobile !== "undefined" &&
      this.state.purpose_coveimage
    ) {
      this.setState({ cover_image_mobile: nextProps.uploadfiles.mobile });
    }
    if (
      this.state.gallery_image !== nextProps.uploadfiles.web &&
      typeof nextProps.uploadfiles.web !== undefined &&
      typeof nextProps.uploadfiles.web !== "undefined" &&
      this.state.purpose_galleryimage &&
      !this.state.purpose_coveimage
    ) {
      var uploadedImages = this.state.gallery_image;
      uploadedImages.push(nextProps.uploadfiles.web);
      this.setState({ gallery_image: uploadedImages });
    }
    if (
      this.state.gallery_image_mobile !== nextProps.uploadfiles.mobile &&
      typeof nextProps.uploadfiles.mobile !== undefined &&
      typeof nextProps.uploadfiles.mobile !== "undefined" &&
      this.state.purpose_galleryimage &&
      !this.state.purpose_coveimage
    ) {
      var uploadedImages = this.state.gallery_image_mobile;
      uploadedImages.push(nextProps.uploadfiles.mobile);
      this.setState({ gallery_image_mobile: uploadedImages });
    }
    this.setState({ purpose_coveimage: false, purpose_galleryimage: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.gallery_image !== this.state.gallery_image) {
      this.setState({ unsavedChanges: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.onpopstate = () => {};
  }
  handleBeforeUnload = (e) => {
    if (this.state.unsavedChanges === false) {
      var confirmationMessage = "Changes that you may not be saved";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };
  _backConfirm = async () => {
    let event = window.confirm("Changes that you may not be saved.");
    if (event) {
      window.history.pushState(null, "", window.location.href);
    }
  };
  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    if (name === "includedList") {
      var checked = e.target.checked;
      var includedList = this.state.includedList;
      if (includedList.indexOf(value) >= 0) {
        const index = includedList.indexOf(value);
        if (index > -1) {
          includedList.splice(index, 1);
        }
      } else {
        includedList.push(value);
      }
      this.setState({ includedList: includedList });
    } else {
      this.setState({ [name]: value, [name + "_error"]: "" });
    }
  }
  onChange(name, value) {
    this.setState({ [name]: value });
  }
  loadCategories() {
    axios
      .get(apiUrl + "package/getPacakgeCategories", {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        var categoryList = [];
        if (res.data.status === "ok") {
          res.data.result_set.map((item) => {
            categoryList.push({
              value: item.categor_id,
              label: item.category_title,
            });
          });
        }
        this.setState({ categoryList: categoryList }, function () {
          if (this.state.packageID !== "") {
            this.packagedetails();
          }
        });
      });
  }
  packagedetails() {
    axios
      .get(
        apiUrl +
          "package/packageInfo?creator_id=" +
          this.state.CUserId +
          "&package_id=" +
          this.state.packageID,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var package_details = res.data.result_set.package;
          var package_keywords =
            package_details.creator_package_keywords !== "" &&
            package_details.creator_package_keywords !== null
              ? package_details.creator_package_keywords.split("#$")
              : [];
          var selectedcategory =
            package_details.creator_package_category !== "" &&
            package_details.creator_package_category !== null
              ? {
                  value: package_details.creator_package_category,
                  label: package_details.category_title,
                }
              : [];
          var package_included =
            package_details.creator_package_included !== "" &&
            package_details.creator_package_included !== null
              ? package_details.creator_package_included.split(",")
              : [];
          var package_thumbnail =
            package_details.creator_package_thumbnail !== "" &&
            package_details.creator_package_thumbnail !== null
              ? package_details.creator_package_thumbnail
              : "";

          package_details.creator_package_thumbnail !== null
            ? package_details.creator_package_thumbnail.split(",")
            : [];

          var creator_package_id =
            package_details.creator_package_id !== "" &&
            package_details.creator_package_id !== null
              ? package_details.creator_package_id
              : "";
          var package_gallery =
            package_details.creator_package_gallery !== "" &&
            package_details.creator_package_gallery !== null
              ? package_details.creator_package_gallery.split(",")
              : [];
          var package_description =
            package_details.creator_package_description !== "" &&
            package_details.creator_package_description !== null
              ? package_details.creator_package_description
              : "";
          var whomgoing =
            package_details.creator_package_travelling !== "" &&
            package_details.creator_package_travelling !== null
              ? {
                  value: package_details.creator_package_travelling,
                  label: package_details.creator_package_travelling,
                }
              : [];
          var airport = [];
          var airportlist = res.data.result_set.airport;
          Object.keys(airportlist).map((item) => {
            airport.push({
              value: airportlist[item].airport_id,
              label:
                airportlist[item].airport_name +
                " - " +
                airportlist[item].airport_code,
            });
          });
          var selectedairport =
            package_details.creator_package_airport_id !== "" &&
            package_details.creator_package_airport_id !== null
              ? {
                  value: package_details.creator_package_airport_id,
                  label: package_details.airport_name,
                }
              : [];
          this.setState({
            package_name: package_details.creator_package_name,
            selectedcategory: selectedcategory,
            keyword_list: package_keywords,
            package_description: stripslashes(package_description),
            includedList: package_included,
            cover_image: package_thumbnail,
            creator_package_id: creator_package_id,
            gallery_image: package_gallery,
            numberofdays: package_details.creator_package_total_days,
            packagecountry: package_details.country_name,
            whomgoing: whomgoing,
            airport: airport,
            selectedairport: selectedairport,
            address: package_details.creator_package_location,
            pacakgeLat: package_details.creator_package_latitude,
            packageLon: package_details.creator_package_longitude,
            webbeds_city_code: package_details.webbeds_city_code,
          });
        }
        hideLoader("package_frm", "class");
      });
  }

  setKeyWords() {
    if (this.state.keywords !== "") {
      var keyword_list = this.state.keyword_list;
      keyword_list.push(this.state.keywords);
      this.setState({
        keyword_list: keyword_list,
        keywords: "",
      });
    }
  }
  removeKeyWord(removeID) {
    var keywordlist = [];
    if (this.state.keyword_list.length > 0) {
      this.state.keyword_list.map((item, index) => {
        if (removeID !== index) {
          keywordlist.push(item);
        }
      });
    }
    this.setState({ keyword_list: keywordlist });
  }

  createPackage() {
    console.log("called")
    var error = 0;
    if (this.state.package_name === "") {
      error++;
      this.setState({ error_package_name: true });
    }
    if (
      this.state.numberofdays === "" ||
      !/^[0-9]*$/.test(this.state.numberofdays)
    ) {
      error++;
      this.setState({ error_number_of_days: true });
    } else {
      const numberOfDays = parseInt(this.state.numberofdays, 10);

      if (numberOfDays < 2 || numberOfDays > 15) {
        error++;
        this.setState({ error_number_of_days: true });
      } else {
        this.setState({ error_number_of_days: false });
      }
    }

    if (Object.keys(this.state.selectedcategory).length === 0) {
      error++;
      this.setState({ error_selectedcategory: true });
    }
    if (this.state.package_description === "") {
      error++;
      this.setState({ error_package_description: true });
    }
    console.log(error, "error")
    if (error === 0) {
      showLoader("create_package", "class");
      var keywords = "";
      if (this.state.keyword_list.length > 0) {
        keywords = this.state.keyword_list.join("#$");
      }
      var included = "";
      if (this.state.includedList.length > 0) {
        included = this.state.includedList.join(",");
      }
      var galleryImage = "";
      if (this.state.gallery_image.length > 0) {
        galleryImage = this.state.gallery_image.join(",");
      }
      var galleryImageMobile = "";
      if (this.state.gallery_image_mobile.length > 0) {
        galleryImageMobile = this.state.gallery_image_mobile.join(",");
      }

      var postObject = {
        creator_id: this.state.CUserId,
        package_name: this.state.package_name,
        country_name: this.state.packagecountry,
        packageCity: this.state.packageCity,
        package_location: this.state.address,
        package_latitude: this.state.pacakgeLat,
        package_longitude: this.state.packageLon,
        travelling: this.state.whomgoing.value,
        package_category: this.state.selectedcategory.value,
        package_keywords: keywords,
        package_thumbnail: this.state.cover_image,
        package_thumbnail_web: this.state.cover_image,
        package_thumbnail_mobile: this.state.cover_image_mobile,
        package_gallery: galleryImage,
        package_gallery_web: galleryImage,
        package_gallery_mobile: galleryImageMobile,
        package_included: included,
        package_description: this.state.package_description,
        package_total_days: this.state.numberofdays,
        package_airport: this.state.selectedairport.value,
        videoContent: this.state.videoContent,
        newPackage: this.state.newPackage,
      };
      console.log(postObject, "postObject")

      var packageUrl = "";
      if (this.state.editpackage === true) {
        postObject["package_id"] = this.state.packageID;
        packageUrl = "updatePackage";
      } else {
        packageUrl = "createPackage";
      }
      axios
        .post(
          apiUrl + "package/" + packageUrl,
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          hideLoader("create_package", "class");
          if (res.data.status === "ok") {
            showAlert(
              "Success",
              "Your package information has been saved successfully. Please select your package itineraries."
            );
            if (this.state.editpackage === true) {
              this.props.history.push("/myaccount/creator-myproducts");
            } else {
              /*  this.props.history.push("/package-step/" + res.data.package_id); */
              this.props.history.push({
                pathname: "/package-step/" + res.data.package_id,
                state: postObject,
              });
            }
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
    this.setState({ unsavedChanges: true });
  }

  async uploadCoverImage(event) {
    var selectedFile = event.target.files[0];
    console.log(event, selectedFile)
    var formData = new FormData();
    formData.append("file", selectedFile);
    this.props.GetUploadFiles(formData);
    this.setState({ purpose_coveimage: true });
  }

  async uploadGalleryImage(event) {
    var selectedFile = event.target.files[0];
    var formData = new FormData();
    formData.append("file", selectedFile);
    this.props.GetUploadFiles(formData);
    this.setState({ purpose_galleryimage: true });
  }

  async uploadVideo(event) {
    var selectedFile = event.target.files;
    var uploadedVideo = "";
    const params = {
      Bucket: "fizotametaimages",
      Key: `${cookie.load("CUserId")}/${selectedFile.name}`,
      Body: selectedFile,
    };
    const { Location } = await s3.upload(params).promise();
    uploadedVideo.push(Location);

    this.setState({
      videoContent: uploadedVideo,
    });
  }
  removeGallery(ImageName) {
    var postObject = {
      package_image: ImageName,
    };
    axios
      .post(
        apiUrl + "package/removePackageImage",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var gallery_images = [];
          this.state.gallery_image.map((item) => {
            if (item !== ImageName) {
              gallery_images.push(item);
            }
          });
          this.setState({ gallery_image: gallery_images });
        }
      });
  }
  handleDeletePackage = () => {
    var formData = new FormData();
    formData.append("package_id", this.state.creator_package_id);

    axios
      .post(apiUrl + `package/deletePackage`, formData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          showAlert("Success", "Package deleted successfully");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/myaccount/creator-myproducts");
        } else {
          showAlert("Error", "Somthing is wrong. Please try again");

          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting package:", error);
      });
  };
  packageDeletePopup() {
    $.magnificPopup.open({
      items: {
        src: "#remove_package",
      },
      type: "inline",
    });
  }
  handleEditClick = (event) => {
    var keywords = "";
    if (this.state.keyword_list.length > 0) {
      keywords = this.state.keyword_list.join("#$");
    }
    var included = "";
    if (this.state.includedList.length > 0) {
      included = this.state.includedList.join(",");
    }
    var galleryImage = "";
    if (this.state.gallery_image.length > 0) {
      galleryImage = this.state.gallery_image.join(",");
    }

    var postObject = {
      creator_id: this.state.CUserId,
      package_name: this.state.package_name,
      country_name: this.state.packagecountry,
      packageCity: this.state.packageCity,
      package_location: this.state.address,
      package_latitude: this.state.pacakgeLat,
      package_longitude: this.state.packageLon,
      travelling: this.state.whomgoing.value,
      package_category: this.state.selectedcategory.value,
      package_keywords: keywords,
      package_thumbnail: this.state.cover_image,
      package_gallery: galleryImage,
      package_included: included,
      package_description: this.state.package_description,
      package_total_days: this.state.numberofdays,
      package_airport: this.state.selectedairport.value,
      webbeds_city_code: this.state.webbeds_city_code,
      newPackage: this.state.newPackage,
    };

    this.props.history.push({
      pathname: "/package-step/" + this.state.packageID,
      state: postObject,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  render() {
    return (
      <>
        <section className="innersection package_frm">
                  <div className="container">
                    <div className="title-with-nav">
                      <div className="title-with-description title-with-back-icon">
                        <a href={void 0} onClick={this.goBack}>
                          <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <h2>
                          Create unique<span> packages</span>
                        </h2>
                        <p>Complete the steps below </p>
                      </div>

                      {this.state.creator_package_id && (
                        <div className="title-nav">
                          <a
                            href={void 0}
                            className="button ghost-button remove-btn"
                            onClick={this.packageDeletePopup}
                          >
                            Delete package
                          </a>
                          <a
                            href={void 0}
                            className="button"
                            onClick={this.handleEditClick}
                          >
                            Edit Itinerary
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="unique-package">
                      <div className="unique-package-title">
                        {this.state.numberofdays !== "" && (
                          <span>
                            {this.state.numberofdays}D |{" "}
                            {this.state.packagecountry}
                          </span>
                        )}
                      </div>
                      <div className="unique-package-lhs">
                        <div className="form-group">
                          <label className="control-label">
                            Product title <em>*</em>
                          </label>
                          <input
                            type="text"
                            placeholder="Eg: Fun vacation in Singapore"
                            value={this.state.package_name}
                            name="package_name"
                            onChange={this.handleChange.bind(this)}
                          />
                          {this.state.error_package_name === true && (
                            <div className="error">This field is required</div>
                          )}
                        </div>
                        {this.state.editpackage === true && (
                          <>
                            <div className="fg-half">
                              <div className="form-group">
                                <label className="control-label">
                                  Address <em>*</em>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Eg: Fun vacation in Singapore"
                                  value={this.state.address}
                                  name="address"
                                  onChange={this.handleChange.bind(this)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Number of days <em>*</em>
                                </label>
                                <input
                                  type="text"
                                  placeholder="Eg: Please enter number of days"
                                  name="numberofdays"
                                  value={this.state.numberofdays}
                                  onChange={this.handleChange.bind(this)}
                                />
                                {this.state.error_number_of_days === true && (
                                  <div className="error">
                                    Please enter a number between 2 to 15
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="fg-half">
                              <div className="form-group">
                                <label className="control-label">
                                  "Whom you are going with? <em>*</em>
                                </label>
                                <Select
                                  value={this.state.whomgoing}
                                  placeholder="Select"
                                  onChange={this.onChange.bind(
                                    this,
                                    "whomgoing"
                                  )}
                                  options={[
                                    {
                                      value: "Couple",
                                      label: "Couple",
                                    },
                                    {
                                      value: "Family",
                                      label: "Family",
                                    },
                                    {
                                      value: "Friends",
                                      label: "Friends",
                                    },
                                    {
                                      value: "Solo",
                                      label: "Solo",
                                    },
                                  ]}
                                />
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Airport <em>*</em>
                                </label>

                                <Select
                                  value={this.state.selectedairport}
                                  placeholder="Select Airport"
                                  onChange={this.onChange.bind(
                                    this,
                                    "selectedairport"
                                  )}
                                  options={this.state.airport}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="fg-half">
                          <div className="form-group">
                            <label className="control-label">
                              Category <em>*</em>
                            </label>
                            <Select
                              value={this.state.selectedcategory}
                              placeholder="Select Category"
                              onChange={this.onChange.bind(
                                this,
                                "selectedcategory"
                              )}
                              options={this.state.categoryList}
                            />
                            {this.state.error_selectedcategory === true && (
                              <div className="error">
                                This field is required
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Keyword <em>*</em>
                            </label>
                            <input
                              type="text"
                              placeholder="Eg: Please enter your keywords"
                              name="keywords"
                              value={this.state.keywords}
                              onChange={this.handleChange.bind(this)}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  this.setKeyWords();
                                }
                              }}
                            />
                            {this.state.keyword_list.length > 0 && (
                              <ul className="product-middle-uldiv">
                                {this.state.keyword_list.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item}{" "}
                                      <a
                                        href={void 0}
                                        onClick={this.removeKeyWord.bind(
                                          this,
                                          index
                                        )}
                                      >
                                        <img src={closeImg} />
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="form-group inline-chkbox">
                          <label className="control-label">
                            What's <span>Included</span>
                            <em>*</em>
                          </label>
                          <ul>
                            {/* <li>
                      <input
                        type="checkbox"
                        name="includedList"
                        defaultChecked={true}
                        value="Flight"
                        disabled={true}
                      />
                      <span>Flight</span>
                    </li> */}
                            <li>
                              <input
                                type="checkbox"
                                name="includedList"
                                defaultChecked={true}
                                value="Stay"
                                disabled={true}
                              />
                              <span>Stay</span>
                            </li>
                            {/* <li>
                      <input
                        type="checkbox"
                        name="includedList"
                        value="Transport"
                        checked={
                          this.state.includedList.indexOf("Transport") >= 0
                            ? true
                            : false
                        }
                        onChange={this.handleChange.bind(this)}
                      />
                      <span>Transport</span>
                    </li> */}
                            <li>
                              <input
                                type="checkbox"
                                name="includedList"
                                value="Things to do"
                                checked={
                                  this.state.includedList.indexOf(
                                    "Things to do"
                                  ) >= 0
                                    ? true
                                    : false
                                }
                                onChange={this.handleChange.bind(this)}
                              />
                              <span>Things to do</span>
                            </li>

                            {/* <li>
                      <input
                        type="checkbox"
                        name="includedList"
                        value="Guide"
                        disabled={true}
                      />
                      <span>Guide</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="includedList"
                        value="Insurance"
                        disabled={true}
                      />
                      <span>Insurance</span>
                    </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="unique-package-rhs">
                        <div className="form-group">
                          <label className="control-label">
                            Product description <em>*</em>
                          </label>
                          <textarea
                            id="textarea"
                            placeholder="Write short description about the package here.."
                            value={this.state.package_description}
                            name="package_description"
                            onChange={this.handleChange.bind(this)}
                          ></textarea>
                          {this.state.error_package_description === true && (
                            <div className="error">This field is required</div>
                          )}
                          <div className="unique-package-info">
                            Please describe your product in detail. You can
                            write description anywhere between 150 to 300 words.
                          </div>
                        </div>
                      </div>
                      <div className="unique-package-info">
                        * Package should minimum consist of flight and stay.
                      </div>

                      <div className="p-deail-col-rhs prd_imguplod">
                        <div className="cover-div">
                          <div className="focus-out focused form-group">
                            <label className="control-label">
                              Cover image{" "}
                              <span>
                                <em>*</em>
                              </span>{" "}
                            </label>
                            <input
                              type="file"
                              className="form-control input-focus"
                              placeholder="Upload single cover image"
                              name="cover_image"
                              id="cover_image"
                              multiple={true}
                              onChange={this.uploadCoverImage.bind(this)}
                            />
                            <div className="gallerybig-img">
                              <img src={this.state.cover_image} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="cover-div">
                          <div className="focus-out focused form-group">
                            <label className="control-label">
                              Additional image{" "}
                              <span>
                                <em>*</em>
                              </span>{" "}
                            </label>
                            <input
                              type="file"
                              className="form-control input-focus"
                              placeholder="Upload multiple images"
                              name="additional_image"
                              id="additional_image"
                              multiple={true}
                              onChange={this.uploadGalleryImage.bind(this)}
                            />
                            {this.state.gallery_image.length > 0 && (
                              <ul className="gallery-img">
                                {this.state.gallery_image.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <a
                                        href={void 0}
                                        onClick={this.removeGallery.bind(
                                          this,
                                          item
                                        )}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-6 h-6"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </a>
                                      <img src={item} alt="" />
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>

                        {/* <div className="cover-div">
                  <div className="focus-out focused form-group">
                    <label className="control-label">
                      Video content{" "}
                      <span>
                        <em>*</em>
                      </span>{" "}
                    </label>
                    <input
                      type="file"
                      accept="video/*"
                      className="form-control input-focus"
                      placeholder="Upload your video"
                      name="video content"
                      id="video content"
                      onChange={this.uploadVideo.bind(this)}
                    />
                    <div>
                      {this.state.videoContent !== "" && (
                        <video width="640" height="360" controls>
                          <source
                            src={this.state.videoContent}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  </div>
                </div> */}
                      </div>
                    </div>
                    <div className="textcenter center-btn">
                      <a
                        href={void 0}
                        onClick={this.createPackage.bind(this)}
                        className="button create_package"
                      >
                        {this.state.editpackage === true
                          ? "Update"
                          : "Save and continue"}
                      </a>
            </div>
          </div>
        </section>
        <div id="remove_package" className="mfp-hide common-popup">
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h2 className="text-uppercase">Warning</h2>
                  <p>Are you sure want to Delete</p>
                  <div
                    className="alt_btns"
                    style={{ gap: "10px", display: "inline-flex" }}
                  >
                    <a
                      className="button confirm-button"
                      onClick={this.handleDeletePackage}
                    >
                      Yes
                    </a>
                    <a
                      href={void 0}
                      className="button popup-modal-dismiss disbl_href_action"
                    >
                      No
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var uploadfiles = Array();
  if (Object.keys(state.uploadfiles).length > 0) {
    if (state.uploadfiles[0].status === "ok") {
      uploadfiles = state.uploadfiles[0];
    }
  }
  return {
    uploadfiles: uploadfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
