/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import cookie from "react-cookies";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import jwt_decode from "jwt-decode";
import { format } from "date-fns";
import logo from "../../common/images/bluelogo.svg";
import eye from "../../common/images/eye-slash.svg";
import eyeopen from "../../common/images/eye-open.svg";
import humburger from "../../common/images/humburger.svg";
import close from "../../common/images/close-icon.svg";
import dropdown from "../../common/images/dropdown.svg";
import search from "../../common/images/search.svg";
import avplace from "../../common/images/av-placeholder.jpg";
import searchlight from "../../common/images/search-light.svg";
import getCityList from "../Home/getCityList.json"
import mobsearch from "../../common/images/search-blue.svg";
import promen from "../../common/images/profile_men.svg";
import GoogleloginComponent from "./GoogleloginComponent";
import {
  GET_GLOBAL_SETTINGS,
  GET_CREATOR_LOGINDATA,
  GET_FBLOGINDATA,
  GET_GOOGLELOGINDATA,
  GET_GOOGLECREATORLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CREATOR_REGISTRATION,
  GET_CUSTOMER_LOGINDATA,
  GET_CUSTOMER_REGISTRATION,
  GET_CREATOR_FBLOGINDATA,
  GET_MYSTIFLYREVALIDATE,
  GET_MYSTIFLYCHEAPESTREVALIDATE,
} from "../../actions";

import {
  Creatorlogin,
  Forgotpassword,
  Creatorsignup,
  Creatorforgotpassword,
  Login,
  Signup,
} from "../../components/Myaccount/Index";
import { showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import {
  apiUrl,
  apiglobaltixUrl,
  siteUrl,
  fbAppId,
  googleAppId,
  authUser,
  authPassword,
  googleAppIduser,
  cookieConfig,
  creatorUi,
} from "../Helpers/Config";
import NewSignup from "../Myaccount/NewSignup";
import NewSignupOTP from "../Myaccount/NewSignupOTP";
import NewCreatorSignup from "../Myaccount/NewCreatorSignup";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import moment from "moment";
import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";
import CurrencyDialog from "./CurrencyDialog/CurrencyDialog";

var qs = require("qs");
var base64 = require("base-64");
var Parser = require("html-react-parser");

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#4258BF",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      // backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 8,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 8,
  },
}));
const priceFilterOptions = [
  {
    label: "Below S$500",
    value: "500-",
  },
  {
    label: "S$500 - S$1000",
    value: "500-1000",
  },
  {
    label: "S$2000 - S$5000",
    value: "2000-5000",
  },
  {
    label: "Above S$5000",
    value: "-5000",
  },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.creatorloginGoogle = this.creatorloginGoogle.bind(this);
    this.loginGoogle = this.loginGoogle.bind(this);
    console.log(this.props, "this.props");
    this.state = {
      fields: {
        email: "",
        password: "",
      },
      regstatus: "initiating",
      fieldscreatorsignup: {
        customer_name: "",
        handle_name: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        countrycode: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscreatorfgtpassword: {
        email: "",
      },
      customerfields: {
        email: "",
        password: "",
      },
      customerregstatus: "initiating",
      fieldscustomersignup: {
        customer_name: "",
        email: "",
        password: "",
        rePassword: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
        countrycode: "",
      },
      triggerLogin: false,
      returnloginresult: false,
      googlelogin: "No",
      googlelogincreator: "No",
      trigerGlogin: false,
      fbstatus: "ok",
      showsearchList: false,
      displaySearchCountry: "",
      displaySearchProducts: "",
      navBg: false,
      showSearchBox: false,
      pathname: decodeURI(window.location.pathname),
      isMobile: window.innerWidth <= 480,
      cityList: [],
      mainCityList: [],
      searchCityKeyWord: "",
      searchCityList: [],
      searchCountryList: [],
      user: "",
      continuestep: false,
      redirected: true,
      isFlight: false,
      isActivities: false,
      isVacation: false,
      showHelmet: false,
      flightList: [],
      selectedflight: "",
      flightPriceType: "",
      custFormType: "signup",
      custsignupData: "",
      otpTimer: 0,
      showOTPError: "",
      creatorFormType: "signup",
      creatorsignupData: "",
      resetField: false,
      signupError: "",
      opendestinationList: false,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      showCurrency: false,
      anchorEl: null,
      onlyGuest: false,
      openCurrencyDialog: false,
      onlyGuest: false,
    };
    
  }
  componentDidMount() {
    if (
      cookie.load("acccesstoken") === "" ||
      typeof cookie.load("acccesstoken") === undefined ||
      typeof cookie.load("acccesstoken") === "undefined"
    ) {
      var postObject = {
        user_name: authUser,
        password: base64.encode(authPassword),
      };
      axios
        .post(
          apiUrl + "settings/authlogin",
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            cookie.save("acccesstoken", res.data.token, cookieConfig);
            setTimeout(() => {
              this.props.getGlobalSettings();
              this.loadCity();
            }, 200);
          }
        });
    }else {
      this.props.getGlobalSettings();
      this.loadCity();
    }
    $("html, body").animate({ scrollTop: 0 }, 500);
    /* var currentNew = this;
    google.accounts.id.initialize({
      client_id: googleAppId,
      callback: (data) => this.handleCallbackResponseCreator(currentNew, data),
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv1"), {
      theme: "outline",
      size: "large",
    });
    google.accounts.id.initialize({
      client_id: googleAppIduser,
      callback: (data) => this.handleCallbackResponse(currentNew, data),
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv2"), {
      theme: "outline",
      size: "large",
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv3"), {
      theme: "outline",
      size: "large",
    }); */

    var currentThis = this;
    $(document).click(function (e) {
      if (
        !$(e.target).is(
          ".search-place-top, .search-glass, .search-glass *, .search-place, .search-place * "
        )
      ) {
        if ($(".search-place.active").length > 0) {
          currentThis.setState({ showsearchList: false });
        }
      }
      if (!$(e.target).is(".currency-list, .currency-list *")) {
        currentThis.setState({ showCurrency: false });
      }
    });

    $("body").on("click", "#open_mobile_menu", function () {
      $(".mobile-menu").addClass("active");
    });
    $("body").on("click", "#close_mobile_menu", function () {
      $(".mobile-menu").removeClass("active");
    });

    window.addEventListener("scroll", this.changeNavBg);

    

    if (this.state.flightList.length === 0) {
      if (this.props.flightList !== this.state.flightList) {
        if (this.props.flightList.length > 0) {
          this.setState(
            {
              flightList: this.props.flightList,
              selectedflight: this.props.selectedflight,
              flightPriceType: this.props.flightPriceType,
            },
            function () {
              if (cookie.load("flightStatus") === "Started") {
                cookie.save("flightStatus", "End", cookieConfig);
                this.loadMystiflyRevalidate();
              }
            }
          );
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showDestinationPopup !== this.props.showDestinationPopup) {
      if (this.props.showDestinationPopup) {
        this.openPopup("#destination-popup");
      }
    }
    if (prevProps.openSearchPopup !== this.props.openSearchPopup) {
      if (this.props.openSearchPopup) {
        this.openPopup("#search-popup");
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavBg);
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.fbloginData !== this.props.fbloginData) {
      this.doLogin(PropsDt.fbloginData);
    }

    if (this.state.googlestatus_creator === "loading") {
      if (PropsDt.googlelogincreator !== undefined) {
        if (PropsDt.googlelogincreator.length > 0) {
          this.setState({ googlestatus_creator: "ok" }, function () {
            this.doLogin(PropsDt.googlelogincreator[0]);
          });
        }
      }
    }
    if (this.state.fbstatus === "loading") {
      if (PropsDt.fblogin !== undefined) {
        if (PropsDt.fblogin.length > 0) {
          this.setState({ fbstatus: "ok" }, function () {
            this.doLogin(PropsDt.fblogin[0]);
          });
        }
      }
    }
    if (PropsDt.creatorlogindata !== this.props.creatorlogindata) {
      this.doLogin(PropsDt.creatorlogindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.creatorregistration !== undefined) {
        this.setState({ regstatus: "ok" });
        this.showMessage(PropsDt.creatorregistration[0], "creator-signup");
      }
    }
    if (
      PropsDt.pagestate !== "" &&
      typeof PropsDt.pagestate !== undefined &&
      typeof PropsDt.pagestate !== "undefined"
    ) {
      if (
        PropsDt.pagestate.triggerLogin !== "" &&
        typeof PropsDt.pagestate.triggerLogin !== undefined &&
        typeof PropsDt.pagestate.triggerLogin !== "undefined"
      ) {
        if (this.state.triggerLogin !== PropsDt.pagestate.triggerLogin) {
          if (PropsDt.pagestate.triggerLogin === true) {
            this.openPopup("#login-popup");
            this.props.sateValChange("triggerLogin", false);
          }
        }
      }
      if (
        PropsDt.pagestate.onlyGuest !== "" &&
        typeof PropsDt.pagestate.onlyGuest !== undefined &&
        typeof PropsDt.pagestate.onlyGuest !== "undefined"
      ) {
        if (this.state.onlyGuest !== PropsDt.pagestate.onlyGuest) {
          if (PropsDt.pagestate.onlyGuest === true) {
            this.setState({
              onlyGuest: true,
            });
          }
        }
      }
    }
    if (
      PropsDt.returnloginresult !== "" &&
      typeof PropsDt.returnloginresult !== undefined &&
      typeof PropsDt.returnloginresult !== "undefined"
    ) {
      if (this.state.returnloginresult !== PropsDt.returnloginresult) {
        this.setState(
          { returnloginresult: PropsDt.returnloginresult },
          function () {
            this.props.sateValChange("triggerLogin", false);
          }
        );
      }
    }

    if (PropsDt.customerlogindata !== this.props.customerlogindata) {
      this.doCustomerLogin(PropsDt.customerlogindata[0]);
    }
    if (this.state.googlestatus_user === "loading") {
      if (PropsDt.googlelogin !== undefined) {
        if (PropsDt.googlelogin.length > 0) {
          this.setState({ googlestatus_user: "ok" }, function () {
            this.doCustomerLogin(PropsDt.googlelogin[0]);
          });
        }
      }
    }
    if (this.state.customerregstatus === "loading") {
      if (PropsDt.customerregistration !== undefined) {
        this.setState({ customerregstatus: "ok" });
        this.showMessage(PropsDt.customerregistration[0], "customer-signup");
      }
    }

    if (PropsDt.flightList !== this.state.flightList) {
      if (PropsDt.flightList.length > 0) {
        this.setState(
          {
            flightList: PropsDt.flightList,
            selectedflight: PropsDt.selectedflight,
            flightPriceType: PropsDt.flightPriceType,
          },
          function () {
            if (cookie.load("flightStatus") === "Started") {
              cookie.save("flightStatus", "End", cookieConfig);
              this.loadMystiflyRevalidate();
            }
          }
        );
      }
    }
  }

  changeNavBg = () => {
    const height = this.state.pathname === "/" ? 50 : 10;
    window.scrollY >= height
      ? this.setState({ navBg: true })
      : this.setState({ navBg: false });
    if (this.state.pathname === "/") {
      window.scrollY >= 260
        ? this.setState({ showSearchBox: true })
        : this.setState({ showSearchBox: false });
    }
  };

  /*  handleCallbackResponse(cuttentthis, response) {
    if (
      response.credential !== "" &&
      typeof response.credential !== undefined &&
      typeof response.credential !== "undefined"
    ) {
      var profile = jwt_decode(response.credential);
      var resultRes = {
        name: profile.given_name,
        email: profile.email,
        picture: profile.picture,
        id: profile.sub,
      };
      cuttentthis.loginGoogle(resultRes);
    }
  }
  handleCallbackResponseCreator(cuttentthis, response) {
    if (
      response.credential !== "" &&
      typeof response.credential !== undefined &&
      typeof response.credential !== "undefined"
    ) {
      var profile = jwt_decode(response.credential);
      var resultRes = {
        name: profile.given_name,
        email: profile.email,
        picture: profile.picture,
        id: profile.sub,
      };
      cuttentthis.creatorloginGoogle(resultRes);
    }
  } */

  diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  openPopup(popupID) {
    if (popupID === "#customer-login-popup") {
      this.setState({ continuestep: true });
    }
    if (this.props.match.path !== "/creator" && popupID === "#login-popup") {
      popupID = "#customer-login-popup";
    }
    /*   if (popupID === "#destination-popup") {
        this.props.history.push("/");
      } */

    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      closeOnBgClick: false,
      showCloseBtn: true,
      midClick: true,
      mainClass: this.state.isMobile ? "package-info" : "",
      callbacks: {
        open: function () {
          $("body").css("overflow", "hidden");
        },
        close: () => {
          this.props?.showDestinationPopup &&
            this.props?.closeDestinationPopup();
          this.props?.openSearchPopup && this.props?.closeSearchPopup();
          $("body").css("overflow", "");
        },
      },
    });
  }
  /* Create Section  Start */
  /* for Creator signup - start*/
  fieldChangeCreatorSignup = (field, value) => {
    this.setState(
      update(this.state, { fieldscreatorsignup: { [field]: { $set: value } } })
    );
  };

  handleCreatorSignup = () => {
    const formPayload = this.state.fieldscreatorsignup;
    this.setState({ regstatus: "loading" });
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.mobile !== "" && formPayload.mobile !== null) {
      var mobile = formPayload.mobile.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }

    var postObject = {
      creator_name: formPayload.customer_name,
      creator_handle_name: formPayload.handle_name,
      creator_email: formPayload.email,
      creator_password: base64.encode(formPayload.password),
      creator_phone: mobileNo,
      creator_phone_code: mobile_code,
      creator_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
    };
    showLoader("signup_submit", "class");
    this.props.getCreatorRegistration(qs.stringify(postObject));
  };
  /* for Creator signup - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var postObject = {
      login_username: formPayload.email,
      login_password: base64.encode(formPayload.password),
    };
    showLoader("login_submit", "class");
    this.props.getCreatorLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }

      var name = response.first_name;
      if (
        response.last_name !== "" &&
        typeof response.last_name !== undefined &&
        typeof response.last_name !== "undefined"
      ) {
        name += " " + response.last_name;
      }

      var postObject = {
        login_firstname: name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };
  CreatorresponseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }

      var name = response.first_name;
      if (
        response.last_name !== "" &&
        typeof response.last_name !== undefined &&
        typeof response.last_name !== "undefined"
      ) {
        name += " " + response.last_name;
      }

      var postObject = {
        login_firstname: name,
        login_username: response.email,
        creator_fb_id: response.id,
        creator_gender: postGender,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbCreatorLoginData(qs.stringify(postObject));
    }
  };

  loginGoogle = (response) => {
    console.log(response, "response");
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        login_firstname: response.name,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        /* customer_photo: photo, */
      };
      this.setState({ googlestatus_user: "loading" });
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  };
  creatorloginGoogle = (response) => {
    console.log(response, "response");
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        login_firstname: response.name,
        login_lastname: lastname,
        login_username: response.email,
        creator_google_id: response.id,
        /* customer_photo: photo, */
      };
      this.setState({ googlestatus_creator: "loading" });
      this.props.getGoogleCreatorLoginData(qs.stringify(postObject));
    }
  };

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      cookie.remove("UserId", { path: "/" });
      cookie.save("CUserId", fbloginData.result_set.creator_id, { path: "/" });

      showAlert("Success", "Logged in Successfully!");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });

      const { history } = this.props;
      localStorage.setItem("currentpage", "dashboard");
      history.push("/myaccount/dashboard");
    } else {
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /*forget password  - start*/
  fieldcreatorforgot = (field, value) => {
    this.setState(
      update(this.state, {
        fieldscreatorfgtpassword: { [field]: { $set: value } },
      })
    );
  };

  creatorforgotpassword = () => {
    this.setState({ fpstatus: "loading" });

    const formPayload = this.state.fieldscreatorfgtpassword;

    var qs = require("qs");
    var postObject = {
      email_address: formPayload.email,
      site_url: siteUrl,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject), "creators");
  };
  /*forget password  - end*/
  /* Creator Section End */

  /* Customer Section Start */

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });

    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      email_address: formPayload.email,
      site_url: siteUrl,
    };

    showLoader("forgotpasswordbtn");
    this.props.getForgetPassword(qs.stringify(postObject), "customer");
  };

  /* for Creator signup - start*/
  fieldChangeCustomerSignup = (field, value) => {
    this.setState(
      update(this.state, { fieldscustomersignup: { [field]: { $set: value } } })
    );
  };

  handleCustomerSignup = () => {
    const formPayload = this.state.fieldscustomersignup;
    this.setState({ customerregstatus: "loading" });
    var qs = require("qs");
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.mobile !== "" && formPayload.mobile !== null) {
      var mobile = formPayload.mobile.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }

    var postObject = {
      customer_name: formPayload.customer_name,
      customer_email: formPayload.email,
      customer_password: base64.encode(formPayload.password),
      customer_phone: mobileNo,
      customer_phone_code: mobile_code,
      customer_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
    };
    showLoader("signup_submit", "class");
    this.props.getCustomerRegistration(qs.stringify(postObject));
  };
  /* for Creator signup - end*/

  /* signin - start*/
  fieldCustomerChange = (field, value) => {
    this.setState(
      update(this.state, { customerfields: { [field]: { $set: value } } })
    );
  };
  /*   handleGuest = () => {
    $.magnificPopup.close();
    var bookingdetails = {
      addFlight: this.props?.location?.state?.addFlight,
      addnewflight: this.props?.location?.state?.addnewflight,
      emptyFlight: this.props?.location?.state?.emptyFlight,
      flightList: this.props?.location?.state?.flightList,
      flightOnly: this.props?.location?.state?.flightOnly,
      flightPrice: this.props?.location?.state?.flightPrice,
      flightcheapestPrice: this.props?.location?.state?.flightPriceType,
      flightPriceType: this.props?.location?.state?.flightPriceType,
      packagedetails: "",
      removeFlight: this.props?.location?.state?.removeFlight,
      searchdata: this.props?.location?.state?.searchdata,
      selectedflight: this.props?.location?.state?.selectedflight,
    };

    this.props.history.push({
      pathname: "/booking",
      state: bookingdetails,
    });
  } */

  handleCustomerSignin = () => {
    const formPayload = this.state.customerfields;
    var postObject = {
      login_username: formPayload.email,
      login_password: base64.encode(formPayload.password),
    };
    showLoader("login_submit", "class");
    this.props.getCustomerLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  doCustomerLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      cookie.remove("CUserId", { path: "/" });
      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });

      showAlert("Success", "Logged in Successfully!");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      if (
        this.props.match.path === "/search-package/:packageSlug" ||
        this.props.match.path === "/search-package/:packageSlug/share" ||
        this.props.match.path === "/search-package/:packageSlug/creatorshare" ||
        this.props.match.path === "/:packageSlug/package-activities" ||
        this.props.match.path ===
          "/search-package/:packageSlug/flight-details" ||
        this.props.match.path ===
          "/search-package/:packageSlug/all-activities/:country/:productslug" ||
        this.props.match.path ===
          "/search-package/:packageSlug/:packageType/share"
      ) {
        if (this.state.continuestep === false) {
          this.props.sateValChange("proceedtocontinue", "Yes");
          this.props.sateValChange(
            "userID",
            fbloginData.result_set.customer_id
          );
        } else {
          this.props.sateValChange(
            "userID",
            fbloginData.result_set.customer_id
          );
        }
      } else if (this.props.match?.path === "/search-package") {
        this.props.sateValChange("searchpackage", "Yes");
        $("#customer-login-popup .mfp-close").trigger("click");
      } else {
        const { history } = this.props;
        history.push("/profile");
      }
    } else {
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /* Customer Section End */

  /* show message */
  showMessage(response, successType = "") {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    hideLoader("forgotpasswordbtn");
    hideLoader("signup_submitss", "class");
    hideLoader("signup_submits", "class");
    this.setState({ custFormType: "signup", creatorFormType: "signup" });
    if (response.status === "ok") {
      if (successType !== "") {
        successType = successType + "-success";
      }

      showAlert("Success", response.message, "No", successType);
    } else {
      if (successType !== "") {
        successType = successType + "-failed";
      }
      if (response.form_error) {
        showAlert("Error", response.form_error, "No", successType);
      } else {
        showAlert("Error", response.message, "No", successType);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  openSearch() {
    this.setState({ showsearchList: !this.state.showsearchList });
  }

  handleTextChange(event) {
    this.setState({ searchCityKeyWord: event.target.value }, function () {
      this.filterSearchCity();
    });
  }

  filterSearchCity() {
    var searchCityList = [];
    var searchCountryList = [];
    var cityList = this.state.cityList;

    if (Object.keys(cityList).length > 0) {
      Object.keys(cityList).map((item) => {
        var selectedCountry = cityList[item];
        if (selectedCountry.city.length > 0) {
          selectedCountry.city.map((cityItem) => {
            if (cityItem.package_available === "Y") {
              if (
                cityItem.city_name
                  .toLowerCase()
                  .indexOf(this.state.searchCityKeyWord.toLowerCase()) >= 0 ||
                cityItem.country_name
                  .toLowerCase()
                  .indexOf(this.state.searchCityKeyWord.toLowerCase()) >= 0 ||
                this.state.searchCityKeyWord === ""
              ) {
                if (searchCountryList.indexOf(cityItem.country_name) < 0) {
                  searchCountryList.push(cityItem.country_name);
                }

                searchCityList.push({
                  airport_id: cityItem.airport_id,
                  airport_address: cityItem.airport_address,
                  airport_code: cityItem.airport_code,
                  airport_id: cityItem.airport_id,
                  airport_latitude: cityItem.airport_latitude,
                  airport_longitude: cityItem.airport_longitude,
                  airport_name: cityItem.airport_name,
                  city_code: cityItem.city_code,
                  city_id: cityItem.city_id,
                  city_name: cityItem.city_name,
                  country_code: cityItem.country_code,
                  country_name: cityItem.country_name,
                  images: cityItem.images,
                  thumbnail: cityItem.thumbnail,
                  webbeds_city_code: cityItem.webbeds_city_code,
                });
              }
            }
          });
        }
      });
    }
    this.setState({
      searchCityList: searchCityList,
      searchCountryList: searchCountryList,
    });
  }

  displaySearch(result) {
    var displaySearchCountry = "";
    var displaySearchProducts = "";
    if (result.country.length > 0) {
      displaySearchCountry = result.country.map((item, index) => {
        var countryName = item.replace(" ", "-").toLowerCase();
        return (
          <li key={index}>
            <Link to={"/activities/" + countryName}>
              <figure>
                {" "}
                <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
              </figure>
              <div className="search-show">
                <p>{item}</p>
              </div>
            </Link>
          </li>
        );
      });
    }

    if (result.product_list.length > 0) {
      displaySearchProducts = result.product_list.map((item, index) => {
        return (
          <li key={index}>
            <Link to={"/product-details/" + item.product_slug}>
              <figure>
                {" "}
                <img
                  src={
                    item.product_thumbnail !== "" &&
                    item.product_thumbnail !== null
                      ? apiglobaltixUrl + "image?name=" + item.product_thumbnail
                      : avplace
                  }
                  alt="fizotalogo"
                />{" "}
              </figure>
              <div className="search-show">
                <div className="search-show-inner">
                  <div className="search-show-product">
                    <p>{item.products_name}</p>
                    <span>{item.country_name}</span>
                  </div>
                  <div className="search-price">
                    <em>From</em>
                    <strong>SGD ${item.product_payable_amount}</strong>
                  </div>
                </div>{" "}
              </div>
            </Link>
          </li>
        );
      });
    }

    this.setState({
      displaySearchCountry: displaySearchCountry,
      displaySearchProducts: displaySearchProducts,
      showsearchList: true,
    });
  }

  loadCity() {
 
    if (getCityList?.status === "ok") {
        this.setState(
          {
            cityList: getCityList?.result_set,
          mainCityList: getCityList?.main_city,
          },
          function () {
            this.filterSearchCity();
          }
        );
        if (
          this.props.match?.path === "/" ||
          this.props.match?.path === "/myaccount/package" ||
          this.props.match?.path === "/myaccount/dashboard"
        ) {
          this.props.sateValChange("cityList", getCityList);
        }
      }
  
  }
  selectNewDestination(Destinationdetails) {
    $(".mobile-menu").removeClass("active");
    if (this.props.match.path === "/") {
      var searchdata = {
        start_date: new Date(),
        end_date: new Date(),
        selectedMonth: "",
        search_days: "2-5",
        adults: 1,
        child: "",
        infant: "",
        room_count: 1,
        source_airport_code: "BLR",
        location_source:
          "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
        source_airport_id: "2800",
        destinationWebbedsCity: Destinationdetails?.webbeds_city_code,
        creator_package_total_days: 3,
        source_airport_address: "BLR",
        source_airport_lat: "12.994112",
        source_airport_lon: "80.1708668",
        destination_airport_lat: Destinationdetails?.airport_latitude,
        destination_airport_lon: Destinationdetails?.airport_longitude,
        destination_airport_address: Destinationdetails?.airport_address,
        destinationCity: Destinationdetails?.city_name,
        destinationCountry: Destinationdetails?.country_name,
        destination_airport_code: Destinationdetails?.airport_code,
        destination_airport_id: Destinationdetails?.airport_id,
        location_destination: Destinationdetails?.airport_address,
        thumbnail: "",
        images: "",
        childAgeList: [],
        trip_type: "Return",
        transfer_trip_type: "Return",
        sourceCountry: "India",
        sourceCity: "",
        checkinTime: "",
        activityList: "",
        total_activities: "",
        /*   homePackFlow: homePackFlow, */
        packageType: "uop",
      };

      $.magnificPopup.close();
      this.props.history.push({
        pathname: `/packages/${Destinationdetails.country_name}/${Destinationdetails.city_name}`,
        state: searchdata,
      });
      /*  this.props.sateValChange(
          "selectedDestinationdetails",
          Destinationdetails
        ); */
    } else {
      /*  var packagedetails = {
           selectedDestinationdetails: Destinationdetails,
         }; */
      $.magnificPopup.close();
      this.props.history.push({
        pathname: `/packages/${Destinationdetails.country_name}/${Destinationdetails.city_name}`,
        state: Destinationdetails,
      });
    }
  }
  changeItinery = (value) => {
    if (value === "flight") {
      this.setState({ isFlight: true }, () =>
        this.props.sateValChange("isFlight", true)
      );
    } else if (value === "activities") {
      this.setState({ isActivities: true }, () =>
        this.props.sateValChange("isActivities", true)
      );
    } else if (value === "stay") {
      this.setState({ isStay: true }, () =>
        this.props.sateValChange("isStay", true)
      );
    } else {
      this.setState({ isVacation: true }, () =>
        this.props.sateValChange("isVacation", true)
      );
    }
  };
  handleFormSubmit = (formData) => {
    this.setState({ signupError: "" });
    showLoader("signup_submits", "class");
    var postObject = {
      customer_name: formData.name,
      customer_email: formData.email,
      site_url: siteUrl,
    };
    axios
      .post(apiUrl + "customer/sendOTP", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        hideLoader("signup_submits", "class");
        if (res.data.status === "ok") {
          this.setState(
            { otpTimer: 60, custFormType: "custotp", custsignupData: formData },
            function () {
              this.startTimer();
            }
          );
        } else {
          var message = res.data.message;
          if (message !== "") {
            this.setState({ signupError: Parser(message) }, () => {
              var _this = this;
              setTimeout(function () {
                _this.setState({ signupError: "" });
              }, 4000);
            });
          }
        }
      });
  };
  startTimer() {
    var currentThis = this;
    setTimeout(function () {
      var otpTimer = currentThis.state.otpTimer - 1;
      if (otpTimer >= 0) {
        currentThis.setState({ otpTimer: otpTimer });
        currentThis.startTimer();
      }
    }, 1000);
  }
  resendCustOTP() {
    if (this.state.otpTimer === 0) {
      this.setState({ signupError: "" });
      var formData = this.state.custsignupData;
      showLoader("signup_submits", "class");
      var postObject = {
        customer_name: formData.name,
        customer_email: formData.email,
        site_url: siteUrl,
      };
      axios
        .post(apiUrl + "customer/sendOTP", qs.stringify(postObject), {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          hideLoader("signup_submits", "class");
          if (res.data.status === "ok") {
            this.setState(
              { custsignupData: formData, otpTimer: 60 },
              function () {
                this.startTimer();
              }
            );
          } else {
            var message = res.data.message;
            if (message !== "") {
              this.setState({ signupError: Parser(message) }, () => {
                var _this = this;
                setTimeout(function () {
                  _this.setState({ signupError: "" });
                }, 4000);
              });
            }
          }
        });
    }
  }

  verifyOTP = (formData) => {
    var custsignupData = this.state.custsignupData;
    showLoader("signup_submits", "class");
    var postObject = {
      otp: formData.otp,
      customer_email: custsignupData.email,
      site_url: siteUrl,
    };
    var apiPath = "customer";
    if (this.props.match.path === "/creator") {
      apiPath = "creators";
      var creatorsignupData = this.state.creatorsignupData;
      postObject = {
        otp: formData.otp,
        creator_email: creatorsignupData.email,
        site_url: siteUrl,
      };
    }
    axios
      .post(
        apiUrl + apiPath + "/verifyOTP",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (this.props.match.path === "/creator") {
            this.Creator_Signup(this.state.creatorsignupData);
            this.setState({ creatorFormType: "creatorotp" });
          } else {
            this.customerSignup(this.state.custsignupData);
            this.setState({ custFormType: "custotp" });
          }
        } else {
          hideLoader("signup_submits", "class");
          var currentThis = this;
          this.setState({ showOTPError: res.data.message }, function () {
            setTimeout(function () {
              currentThis.setState({ showOTPError: "" });
            }, 5000);
          });
        }
      });
  };
  customerSignup = (formData) => {
    this.setState({ customerregstatus: "loading" });
    var qs = require("qs");
    var mobileNo = "";
    var mobile_code = "";
    // if (formData.phone !== "" && formData.phone !== null) {
    //   var mobile = formData.phone.split(" ");
    //   var newMobile = "";
    //   if (mobile.length > 0) {
    //     mobile.map((item, index) => {
    //       if (index !== 0) {
    //         newMobile += item;
    //       }
    //     });
    //   }
    //   mobileNo = newMobile.replace("-", "");
    //   mobile_code = mobile[0];
    // }

    var postObject = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_password: base64.encode(formData.confirmPassword),
      // customer_phone: mobileNo,
      // customer_phone_code: mobile_code,
      customer_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
    };
    showLoader("signup_submits", "class");
    this.props.getCustomerRegistration(qs.stringify(postObject));
  };

  CreatorSignup = (formData) => {
    this.setState({ signupError: "" });
    showLoader("signup_submitss", "class");
    var postObject = {
      creator_name: formData.name,
      creator_email: formData.email,
      site_url: siteUrl,
    };
    axios
      .post(apiUrl + "creators/sendOTP", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        hideLoader("signup_submitss", "class");
        if (res.data.status === "ok") {
          this.setState(
            {
              otpTimer: 60,
              creatorFormType: "creatorotp",
              creatorsignupData: formData,
            },
            function () {
              this.startTimer();
            }
          );
        } else {
          var message = res.data.message;
          if (message !== "") {
            this.setState({ signupError: Parser(message) }, () => {
              var _this = this;
              setTimeout(function () {
                _this.setState({ signupError: "" });
              }, 4000);
            });
          }
        }
      });
  };

  resendCreatorOTP() {
    if (this.state.otpTimer === 0) {
      this.setState({ signupError: "" });
      var formData = this.state.creatorsignupData;
      showLoader("signup_submits", "class");
      var postObject = {
        creator_name: formData.name,
        creator_email: formData.email,
        site_url: siteUrl,
      };
      axios
        .post(apiUrl + "creators/sendOTP", qs.stringify(postObject), {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        })
        .then((res) => {
          hideLoader("signup_submits", "class");
          if (res.data.status === "ok") {
            this.setState({ otpTimer: 60 }, function () {
              this.startTimer();
            });
          } else {
            var message = res.data.message;
            if (message !== "") {
              this.setState({ signupError: Parser(message) }, () => {
                var _this = this;
                setTimeout(function () {
                  _this.setState({ signupError: "" });
                }, 4000);
              });
            }
          }
        });
    }
  }

  Creator_Signup = (formData) => {
    this.setState({ regstatus: "loading" });
    var mobileNo = "";
    var mobile_code = "";
    if (formData.phone !== "" && formData.phone !== null) {
      var mobile = formData.phone.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }

    var postObject = {
      creator_name: formData.name,
      creator_email: formData.email,
      creator_password: base64.encode(formData.confirmPassword),
      creator_phone: mobileNo,
      creator_phone_code: mobile_code,
      creator_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
      creator_handle_name: formData.handlename,
    };
    showLoader("signup_submitss", "class");
    this.props.getCreatorRegistration(qs.stringify(postObject));
  };

  loadMystiflyRevalidate() {
    if (this.state.selectedflight !== "") {
      var postObject = {
        faresourcecode:
          this.state.flightList[this.state.selectedflight].FareSourceCode,
      };
      this.props.getMystiflyRevalidate(qs.stringify(postObject));
    }
    if (this.state.flightPriceType !== "") {
      if (this.state.flightPriceType.cheapestselectedflight !== "") {
        var postObject = {
          faresourcecode:
            this.state.flightList[
              this.state.flightPriceType.cheapestselectedflight
            ].FareSourceCode,
        };
        this.props.getMystiflyCheapestRevalidate(qs.stringify(postObject));
      }
    }
  }
  sateValChange = (field, value) => {
    if (field === "resetField") {
      this.setState({ resetField: value });
    }
  };
  selectDestination(item) {
    this.props.clearSearchkeywords();
    this.setState({
      opendestinationList: false,
      searchCityKeyWord: item.city_name,
    });
    this.props.selectNewDestination(item, 3);
  }
  opendSearch() {
    if (this.props.location.pathname === "/") {
      $.magnificPopup.open({
        items: {
          src: "#search-popup",
        },
        type: "inline",
        closeOnBgClick: true,
        showCloseBtn: true,
        midClick: true,
        mainClass: this.state.isMobile ? "package-info" : "",
      });
    } else {
      cookie.save("openSearch", "Yes", cookieConfig);
      this.props.history.push("/");
    }
  }
  showCurrency() {
    this.setState({ showCurrency: !this.state.showCurrency });
  }
  selectCurrency(currency) {
    this.setState({ currentCurrency: currency, showCurrency: false }, () => {
      cookie.save("currentCurrency", currency, cookieConfig);
      this.props.sateValChange("currentCurrency", currency);
    });
    this.setState({ anchorEl: null });
  }
  handleSelectCurrencyOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  guestContinue = () => {
    this.props.sateValChange("guest", true);
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    return (
      <>
        {this.props.match.path !== "/packages/:cityName" &&
          this.props.match.path !== "/packages/:countryName/:cityName" &&
          this.props.match.path !== "/" && (
            <Helmet>
              <title>Fizota - Book your vacation in under 2 minutes!</title>
              <meta
                name="description"
                content="Get inspired from your favourite creators or craft your own vacation packages. Experiences | Vacation | Weekend getaway and more.."
              />
              <meta property="og:url" content="https://www.fizota.com/" />
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content=" Fizota - Book your vacation in under 2 minutes!"
              />
              <meta
                property="og:description"
                content="Get inspired from your favourite creators or craft your own vacation packages. Experiences | Vacation | Weekend getaway and more.."
              />
              <meta
                property="og:image"
                content="https://fizotametaimages.s3.ap-southeast-1.amazonaws.com/meta/Fizota+meta+image.jpg"
              />
              {/* Twitter (below) */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta property="twitter:domain" content="fizota.com" />
              <meta property="twitter:url" content="https://www.fizota.com/" />
              <meta
                name="twitter:title"
                content=" Fizota - Book your vacation in under 2 minutes!"
              />
              <meta
                name="twitter:description"
                content="Get inspired from your favourite creators or craft your own vacation packages. Experiences | Vacation | Weekend getaway and more.."
              />
              <meta
                name="twitter:image"
                content="https://fizotametaimages.s3.ap-southeast-1.amazonaws.com/meta/Fizota+meta+image.jpg"
              />
              <meta
                name="thumbnail"
                content="https://fizotametaimages.s3.ap-southeast-1.amazonaws.com/meta/Fizota+meta+image.jpg"
              />
            </Helmet>
          )}

        <header
          className={`${this.state.navBg ? "" : "header-transparent"} ${
            this.props.match?.path !== "/" ? "innerpage" : "header-bg"
          } ${
            this.state.pathname === "/" ? "header-initial" : "header-sticky"
          }`}
        >
          <div className="container flex">
            {(!this.state.showSearchBox && this.state.isMobile) ||
            !this.state.isMobile ? (
              <div className="header-lhs">
                {!cookie.load("CUserId") ? (
                  <Link to={"/"}>
                    {" "}
                    <img src={logo} alt="logo" />{" "}
                  </Link>
                ) : (
                  <Link to={"/myaccount/dashboard"}>
                    {" "}
                    <img src={logo} alt="logo" />{" "}
                  </Link>
                )}
              </div>
            ) : null}
            {this.state.showSearchBox && this.state.isMobile ? (
              <div className="cc-search">
                <input
                  type="text"
                  className="m-search"
                  placeholder="Your experience starts from here"
                  onClick={() => this.props.openBottomSheet("header")}
                />
                <img className="cc-img" src={mobsearch} />
              </div>
            ) : null}
            <div className="header-rhs">
              <CurrencyDialog
                open={this.state.openCurrencyDialog}
                handleClose={() => this.setState({ openCurrencyDialog: false })}
                selectCurrency={(val) => this.selectCurrency(val)}
              />
              {this.state.currentCurrency && this.state.isMobile && (
                <>
                  <a
                    href={void 0}
                    className="search-icon"
                    onClick={this.props.handleSearchVacation}
                  >
                    <SearchIcon />
                  </a>
                  <div className="currency-filter">
                    <div>
                      <a
                        href={void 0}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={() =>
                          this.setState({ openCurrencyDialog: true })
                        }
                      >
                        {this.state.currentCurrency}
                      </a>
                      {/* <Menu
                        id="basic-menu"
                        anchorEl={this.state.anchorEl}
                        open={open}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={this.selectCurrency.bind(
                            this,
                            "SGD"
                          )}
                        >
                          SGD
                        </MenuItem>
                        <MenuItem
                          onClick={this.selectCurrency.bind(
                            this,
                            "USD"
                          )}
                        >
                          USD
                        </MenuItem>
                        <MenuItem
                          onClick={this.selectCurrency.bind(
                            this,
                            "INR"
                          )}
                        >
                          INR
                        </MenuItem>
                      </Menu> */}
                    </div>
                  </div>
                </>
              )}
              <div className="navigation">
                <ul className="menu">
                  {/* <li>
                    {" "}
                    <a href={void 0} onClick={this.openSearch.bind(this)}>
                      <span className="search-glass">
                        <img src={search} alt="logo" /> Search
                      </span>
                    </a>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <span>Explore</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/activities/singapore">Singapore</Link>
                      </li>
                      <li>
                        <a href={void 0}>Bali</a>
                      </li>
                      <li>
                        <a href={void 0}>Hong Kong</a>
                      </li>
                      <li>
                        <a href={void 0}>Malaysia</a>
                      </li>
                      <li>
                        <a href={void 0}>Thailand</a>
                      </li>
                    </ul>
                  </li> */}
                  <>
                    <li>
                      <a href={void 0} onClick={this.opendSearch.bind(this)}>
                        <SearchIcon />
                        <span>Search</span>
                      </a>
                    </li>
                  </>
                  {/* {!cookie.load("CUserId") &&
                    this.props.match.path !== "/creator" && (
                      <>
                        <li>
                          <a
                            href={void 0}
                            onClick={this.openPopup.bind(
                              this,
                              "#destination-popup"
                            )}
                          >
                            <span>Destination</span>
                          </a>
                        </li>
                      </>
                    )} */}
                  {/* 
                  <li>
                    <a
                      href={void 0}
                      onClick={() => this.changeItinery("vacation")}
                    >
                      <span>Vacation</span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      target="_blank"
                      onClick={() => this.changeItinery("flight")}
                    >
                      <span>Flight</span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      target="_blank"
                      onClick={() => this.changeItinery("stay")}
                    >
                      <span>Stay</span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      target="_blank"
                      onClick={() => this.changeItinery("activities")}
                    >
                      <span>Activities</span>
                    </a>
                  </li> */}
                  <li>
                    {" "}
                    <Link to={"/helpcenter"} target={"_blank"}>
                      {" "}
                      <span>Help</span>
                    </Link>
                  </li>
                  <li>
                    <a href="https://www.fizota.com/blog/" target="_blank">
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a href={creatorUi} target="_blank">
                      Became a creator
                    </a>
                  </li>
                  {this.state.currentCurrency && (
                    <li>
                      <div className="currency-filter">
                        <div>
                          <a
                            href={void 0}
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={this.handleSelectCurrencyOpen}
                          >
                            {this.state.currentCurrency}
                          </a>
                          <Menu
                            id="basic-menu"
                            anchorEl={this.state.anchorEl}
                            open={open}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={this.selectCurrency.bind(this, "SGD")}
                            >
                              SGD
                            </MenuItem>
                            <MenuItem
                              onClick={this.selectCurrency.bind(this, "USD")}
                            >
                              USD
                            </MenuItem>
                            <MenuItem
                              onClick={this.selectCurrency.bind(this, "INR")}
                            >
                              INR
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </li>
                  )}
                  {!cookie.load("UserId") && !cookie.load("CUserId") && (
                    <li>
                      <a
                        href={void 0}
                        onClick={this.openPopup.bind(
                          this,
                          this.props.match.path === "/creator"
                            ? "#creator-signup-popup"
                            : "#customer-signup-popup"
                        )}
                      >
                        Signup
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="header-links">
                <ul className="show-info-full">
                  {!cookie.load("UserId") ? (
                    !cookie.load("CUserId") ? (
                      <li className="list-button">
                        <a
                          href={void 0}
                          className="button button-fill"
                          onClick={this.openPopup.bind(
                            this,
                            this.props.match.path === "/creator"
                              ? "#login-popup"
                              : "#customer-login-popup"
                          )}
                        >
                          Login
                        </a>
                      </li>
                    ) : (
                      <li className="list-button">
                        <Link
                          to={"/myaccount/dashboard"}
                          className="button button-fill"
                        >
                          My Account
                        </Link>
                      </li>
                    )
                  ) : (
                    <li className="list-button">
                      <Link to={"/profile"} className="button button-fill">
                        My Account
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              {/* <div className="header-links">
                <a href={() => false} onClick={this.showCurrency.bind(this)}>
                  {this.state.currentCurrency}
                </a>
                {this.state.showCurrency && (
                  <ul className="show-info-full currency-list">
                    {this.state.currentCurrency !== "SGD" && (
                      <li>
                        <a
                          href={() => false}
                          onClick={this.selectCurrency.bind(this, "SGD")}
                        >
                          SGD
                        </a>
                      </li>
                    )}
                    {this.state.currentCurrency !== "INR" && (
                      <li>
                        <a
                          href={() => false}
                          onClick={this.selectCurrency.bind(this, "INR")}
                        >
                          INR
                        </a>
                      </li>
                    )}
                    {this.state.currentCurrency !== "USD" && (
                      <li>
                        <a
                          href={() => false}
                          onClick={this.selectCurrency.bind(this, "USD")}
                        >
                          USD
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div> */}
              <div className="nav-top">
                <div id="open_mobile_menu" className="rel humbur fr">
                  <a
                    href={void 0}
                    className="toggle-button trigger_menunav_act toggle"
                  >
                    <img src={humburger} alt="NAV" />
                  </a>
                </div>
                <div className="mobile-menu">
                  <div className="mobile-menu-header">
                    <div className="mobile-menu-close" id="close_mobile_menu">
                      <span id="close_mobile_menu">
                        {" "}
                        <img src={close} alt="Close" />{" "}
                      </span>
                    </div>
                    <div className="mobile-menu-logo">
                      <Link
                        to={"/"}
                        onClick={(e) => {
                          $(".mobile-menu").removeClass("active");
                        }}
                      >
                        <img src={logo} alt="Logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="mobile-menu-body">
                    <ul className="mobile-navw">
                      {/* <li>
                        {" "}
                        <Link to={"/"}>Search</Link>{" "}
                      </li>
                      <li>
                        <Link to={"/"}>Explore </Link>
                        <span>
                          {" "}
                          <img src={dropdown} alt="Arrow" />{" "}
                        </span>
                        <ul>
                          <li>
                            <Link to="/activities/singapore">Singapore</Link>
                          </li>
                          <li>
                            <a href={void 0}>Bali</a>
                          </li>
                          <li>
                            <a href={void 0}>Hong Kong</a>
                          </li>
                          <li>
                            <a href={void 0}>Malaysia</a>
                          </li>
                          <li>
                            <a href={void 0}>Thailand</a>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        {" "}
                        <a
                          href={void 0}
                          onClick={this.openPopup.bind(
                            this,
                            "#destination-popup"
                          )}
                        >
                          Destination
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          target="_blank"
                          id="close_mobile_menu"
                          onClick={() => this.changeItinery("flight")}
                        >
                          Flight
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          target="_blank"
                          id="close_mobile_menu"
                          onClick={() => this.changeItinery("activities")}
                        >
                          Activities
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          target="_blank"
                          id="close_mobile_menu"
                          onClick={() => this.changeItinery("vacation")}
                        >
                          Vacation
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a target="_blank" id="close_mobile_menu" href="/blog">
                          Blog
                        </a>
                      </li>
                      <li>
                        <Link to={"/help"}>Help</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="mobile-footer">
                    <div className="mobile-login">
                      {!cookie.load("UserId") ? (
                        <a
                          href={void 0}
                          onClick={this.openPopup.bind(
                            this,
                            "#customer-login-popup"
                          )}
                          className="button button-fill"
                        >
                          Login
                        </a>
                      ) : (
                        <Link to={"/profile"} className="button button-fill">
                          My Account
                        </Link>
                      )}
                      <p>
                        Don't have an account?{" "}
                        <a
                          href={void 0}
                          onClick={this.openPopup.bind(
                            this,
                            "#customer-signup-popup"
                          )}
                        >
                          Signup
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              this.state.showsearchList === true
                ? "search-place search-place-top active"
                : "search-place search-place-top"
            }
          >
            <div className="top-search-place">
              <div className="banner-form-inner">
                <input
                  type="text"
                  placeholder="Search your activities and attractions.."
                  /*   onChange={this.handleTextChange.bind(this)} */
                />
                <button type="submit" className="button">
                  <img src={searchlight} />
                </button>
              </div>
            </div>
            <ul>
              <ul>
                {this.state.displaySearchCountry}
                {this.state.displaySearchProducts}
              </ul>
            </ul>
          </div>
        </header>

        <div id="login-popup" className="mfp-hide common-popup">
          <div className="cp-header">
            <h3>Login as a creator</h3>
            <p>Login or Signup to access your creator account</p>
          </div>
          <div className="cp-form">
            <div className="cp-social">
              <div className="google-btn">
                <div id="signInDiv">
                  <GoogleloginComponent
                    logintype="creator"
                    divid="signInDiv"
                    creatorloginGoogle={this.creatorloginGoogle}
                  />
                </div>
                <span>Sign in with Google</span>
              </div>

              <div className="fb-btn">
                {/*<FacebookLogin
                  appId={fbAppId}
                  fields="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.CreatorresponseFacebook}
                  scope="public_profile,email,user_birthday"
                  cssclassName="fa fa-facebook"
                  redirectUri={siteUrl}
                  icon="fa-facebook"
                  textButton="Sign in with Facebook"
                />*/}
              </div>
            </div>
            <div className="or-break">Or login with</div>
            <Creatorlogin
              fields={this.state.fields}
              onChange={this.fieldChange}
              onValid={this.handleSignin}
              onInvalid={(e) => console.log(e, "Form invalid!")}
            />
          </div>
          <div className="cp-form-footer">
            <p>
              {" "}
              Don't have an account?{" "}
              <a
                href={void 0}
                onClick={this.openPopup.bind(this, "#creator-signup-popup")}
                className="link"
              >
                Signup
              </a>
            </p>
          </div>
        </div>

        <div
          id="creator-signup-popup"
          className="mfp-hide common-popup creator-signup-popup"
        >
          {this.state.creatorFormType === "creatorotp" ? (
            <>
              <div className="cp-header">
                <h3>OTP Verification</h3>
                <p>Please check your email for OTP.</p>
              </div>
              <div className="cp-form">
                <NewSignupOTP
                  onFormSubmit={this.verifyOTP}
                  sateValChange={this.sateValChange}
                  signupError={this.state.signupError}
                />
                <div className="otp-error">
                  {this.state.showOTPError !== "" && (
                    <span className="error otp-error">
                      {this.state.showOTPError}
                    </span>
                  )}
                </div>
              </div>
              <div className="cp-form-footer">
                <p>
                  Still not received?
                  <a
                    href={void 0}
                    className={`link${
                      this.state.otpTimer > 0 ? "-disabled" : ""
                    }`}
                    onClick={this.resendCreatorOTP.bind(this)}
                  >
                    Resend OTP.
                  </a>
                  <span>
                    {this.state.otpTimer > 0 ? this.state.otpTimer : ""}
                  </span>
                  {this.state.otpTimer > 0 ? "s" : ""}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="cp-header">
                <h3>Sign up as a creator</h3>
                <p>Enter your details to signup</p>
              </div>
              <div className="cp-form">
                <div className="cp-social">
                  <div className="google-btn">
                    <div id="signInDiv1">
                      <GoogleloginComponent
                        logintype="creator"
                        divid="signInDiv1"
                        creatorloginGoogle={this.creatorloginGoogle}
                      />
                    </div>
                    <span>Sign up with Google</span>
                  </div>

                  <div className="fb-btn">
                    {/*<FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture,first_name,last_name,birthday,gender"
                      callback={this.CreatorresponseFacebook}
                      scope="public_profile,email,user_birthday"
                      cssclassName="fa fa-facebook"
                      redirectUri={siteUrl}
                      icon="fa-facebook"
                      textButton="Sign up with Facebook"
                    />*/}
                  </div>
                </div>
                <div className="or-break">Or sign up with</div>
                {/* <Creatorsignup
              fields={this.state.fieldscreatorsignup}
              onChange={this.fieldChangeCreatorSignup}
              onValid={this.handleCreatorSignup}
              onInvalid={(error) => console.log("Form invalid!", error)}
            /> */}
                <NewCreatorSignup
                  onFormSubmit={this.CreatorSignup}
                  resetField={this.state.resetField}
                  sateValChange={this.sateValChange}
                  signupError={this.state.signupError}
                />
              </div>
              <div className="cp-form-footer">
                <p>
                  By creating account you agree to our{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    target="_blank"
                    className="link"
                  >
                    Terms &amp; conditions,
                  </Link>{" "}
                  <Link
                    to={"/cancellation-and-refund"}
                    target="_blank"
                    className="link"
                  >
                    Cancellation and Refund
                  </Link>{" "}
                  and{" "}
                  <Link to={"/privacy-policy"} target="_blank" className="link">
                    Privacy policy
                  </Link>
                </p>
              </div>
            </>
          )}
        </div>

        <div id="forget-popup" className="mfp-hide common-popup">
          <div className="cp-header">
            <h3>Forgot password</h3>
            <p>Confirm your email to reset the password</p>
          </div>
          <Creatorforgotpassword
            fields={this.state.fieldscreatorfgtpassword}
            onChange={this.fieldcreatorforgot}
            onValid={this.creatorforgotpassword}
            onInvalid={() => console.log("Form invalid!")}
          />
        </div>

        <div id="resetpwt-popup" className="mfp-hide common-popup">
          <div className="cp-header">
            <h3>Set password</h3>
            <p>Enter your new password</p>
          </div>
          <div className="cp-form">
            <div className="cp-form-inner">
              <div className="form-group pwd-group">
                <label className="control-label">Password</label>
                <div className="pwd-group">
                  <input type="password" placeholder="Min 8 characters" />
                  <span>
                    <img src={eye} alt="Eye" /> <img src={eyeopen} alt="Eye" />
                  </span>
                </div>
              </div>
              <div className="form-group pwd-group no-margin-btm">
                <label className="control-label">Confirm Password</label>
                <div className="pwd-group">
                  <input type="password" placeholder="Re-enter your password" />
                  <span>
                    <img src={eye} alt="Eye" /> <img src={eyeopen} alt="Eye" />
                  </span>
                </div>
              </div>
              <div className="form-button no-padd-btm">
                <input
                  type="submit"
                  className="button"
                  value="Submit password"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Customer Login Start */}
        <div id="customer-login-popup" className="mfp-hide common-popup">
          <div className="cp-header">
            <h3>Login</h3>
            <p>Login or Signup to access your account</p>
          </div>
          <div className="cp-form">
            <div className="cp-social">
              <div className="google-btn">
                <div id="signInDiv2">
                  <GoogleloginComponent
                    logintype="user"
                    divid="signInDiv2"
                    loginGoogle={this.loginGoogle}
                  />
                </div>
                <span>Sign in with Google</span>
              </div>

              <div className="fb-btn">
                {/*<FacebookLogin
                  appId={fbAppId}
                  fields="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.responseFacebook}
                  scope="public_profile,email,user_birthday"
                  cssclassName="fa fa-facebook"
                  redirectUri={siteUrl}
                  icon="fa-facebook"
                  textButton="Sign in with Facebook"
                />*/}
              </div>
            </div>
            <div className="or-break">Or login with</div>
            <Login
              flight={this.state.onlyGuest}
              fields={this.state.customerfields}
              onChange={this.fieldCustomerChange}
              onValid={this.handleCustomerSignin}
              onInvalid={(e) => console.log(e, "Form invalid!")}
              guestlogin={this.guestContinue}
            />
          </div>
          <div className="cp-form-footer">
            <p>
              {" "}
              Don't have an account?{" "}
              <a
                href={void 0}
                onClick={this.openPopup.bind(this, "#customer-signup-popup")}
                className="link"
              >
                Signup
              </a>
            </p>
          </div>
        </div>

        <div
          id="customer-signup-popup"
          className="mfp-hide common-popup customer-signup-popup"
        >
          {this.state.custFormType === "custotp" ? (
            <>
              <div className="cp-header">
                <h3>OTP Verification</h3>
                <p>Please check your email for OTP.</p>
              </div>
              <div className="cp-form">
                <NewSignupOTP
                  onFormSubmit={this.verifyOTP}
                  sateValChange={this.sateValChange}
                  signupError={this.state.signupError}
                />
                <div className="otp-error">
                  {this.state.showOTPError !== "" && (
                    <span className="error">{this.state.showOTPError}</span>
                  )}
                </div>
              </div>
              <div className="cp-form-footer">
                <p>
                  Still not received?
                  <a
                    href={void 0}
                    className={`link${
                      this.state.otpTimer > 0 ? "-disabled" : ""
                    }`}
                    onClick={this.resendCustOTP.bind(this)}
                  >
                    Resend OTP.
                  </a>
                  <span>
                    {this.state.otpTimer > 0 ? this.state.otpTimer + "s" : ""}
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="cp-header">
                <h3>Sign up</h3>
                <p>Enter your details to signup</p>
              </div>
              <div className="cp-form">
                <div className="cp-social">
                  <div className="google-btn">
                    <div id="signInDiv3">
                      <GoogleloginComponent
                        logintype="user"
                        divid="signInDiv3"
                        loginGoogle={this.loginGoogle}
                      />
                    </div>
                    <span>Sign up with Google</span>
                  </div>

                  <div className="fb-btn">
                    {/*<FacebookLogin
                      appId={fbAppId}
                      fields="name,email,picture,first_name,last_name,birthday,gender"
                      callback={this.responseFacebook}
                      scope="public_profile,email,user_birthday"
                      cssclassName="fa fa-facebook"
                      redirectUri={siteUrl}
                      icon="fa-facebook"
                      textButton="Sign up with Facebook"
                    />*/}
                  </div>
                </div>
                <div className="or-break">Or sign up with</div>
                {/* <Signup
              fields={this.state.fieldscustomersignup}
              onChange={this.fieldChangeCustomerSignup}
              onValid={this.handleCustomerSignup}
              onInvalid={(e) => console.log(e, "Form invalid!")}
            /> */}
                <NewSignup
                  onFormSubmit={this.handleFormSubmit}
                  resetField={this.state.resetField}
                  sateValChange={this.sateValChange}
                  signupError={this.state.signupError}
                />
              </div>
              <div className="cp-form-footer">
                <p>
                  By creating account you agree to our{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    target="_blank"
                    className="link"
                  >
                    Terms &amp; conditions,
                  </Link>{" "}
                  <Link
                    to={"/cancellation-and-refund"}
                    target="_blank"
                    className="link"
                  >
                    Cancellation and Refund
                  </Link>{" "}
                  and{" "}
                  <Link to={"/privacy-policy"} target="_blank" className="link">
                    Privacy policy
                  </Link>
                </p>
              </div>
            </>
          )}
        </div>

        <div id="customer-forget-popup" className="mfp-hide common-popup">
          <div className="cp-header">
            <h3>Forgot password</h3>
            <p>Confirm your email to reset the password</p>
          </div>
          <Forgotpassword
            fields={this.state.fieldsfgtpassword}
            onChange={this.fieldforgot}
            onValid={this.forgotpassword}
            onInvalid={() => console.log("Form invalid!")}
          />
        </div>

        <div
          id="destination-popup"
          className="mfp-hide common-popup destination-popup"
        >
          <div className="destin-title">
            <h3 className="color-header">
              Where would you like to <span>go?</span>
            </h3>
            <div className="search-pop">
              <input
                type="text"
                placeholder="Search your dream destination.."
                onChange={this.handleTextChange.bind(this)}
              />
              <img src={mobsearch} />
            </div>
          </div>
          {this.state.searchCountryList.length > 0 && (
            <div className="destin-reult-show">
              <div className="force-overflow">
                {this.state.searchCountryList.map((item, index) => {
                  return (
                    <div className="drs-parent" key={index}>
                      <h4>{item}</h4>
                      {this.state.searchCityList.length > 0 && (
                        <ul>
                          {this.state.searchCityList.map((city, cityIndex) => {
                            if (city.country_name === item) {
                              return (
                                <li key={cityIndex}>
                                  <a
                                    href={void 0}
                                    onClick={() => {
                                      if (this.state.isMobile) {
                                        // this.props.openBottomSheet("dest");
                                        this.selectNewDestination(city);
                                      } else this.selectNewDestination(city);
                                    }}
                                  >
                                    <img
                                      src={
                                        (city.thumbnail !== "") &
                                        (city.thumbnail !== null)
                                          ? city.thumbnail
                                          : avplace
                                      }
                                    />
                                    <span>{city.city_name}</span>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {this.props.location.pathname === "/" && (
          <div id="search-popup" className="mfp-hide common-popup search-popup">
            <div className="destin-title">
              <div className="search-pop">
                <input
                  type="text"
                  id="search_city"
                  placeholder="Search for Destinations"
                  onChange={this.handleTextChange.bind(this)}
                  onClick={() => {
                    this.setState(
                      {
                        opendestinationList: true,
                      },
                      () => {
                        this.props.filterSearchCity();
                      }
                    );
                  }}
                  value={this.state.searchCityKeyWord}
                />
                <img src={mobsearch} />
              </div>
              {this.props.error_location_source === true && (
                <span class="error">Please Select Your destination</span>
              )}
              {this.state.opendestinationList === true &&
                this.state.searchCityList !== "" && (
                  <div className="search-results">
                    <div className="search-with-padd">
                      <div className="search-with-img-result mCustomScrollbar">
                        {this.state.searchCityList !== "" && (
                          <ul>
                            {this.state.searchCityList.map((item, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href={void 0}
                                    onClick={this.selectDestination.bind(
                                      this,
                                      item
                                    )}
                                  >
                                    <img
                                      src={
                                        item.thumbnail !== "" &&
                                        item.thumbnail !== null
                                          ? item.thumbnail
                                          : item.images !== "" &&
                                            item.images !== null
                                          ? item.images
                                          : destim
                                      }
                                    />
                                    <span>
                                      <strong>{item.city_name}</strong>
                                      <em>{item.country_name}</em>
                                    </span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.props.searchkeywords === "" &&
                !this.state.opendestinationList && (
                  <>
                    <div>
                      <div className="title">Package type</div>
                      <div className="option-row">
                        <div
                          className={`option ${
                            this.props.packageType === "vacation"
                              ? "active"
                              : ""
                          }`}
                          onClick={this.props.selectPacakgeType.bind(
                            this,
                            "vacation"
                          )}
                        >
                          Customizable
                        </div>
                        <div
                          className={`option ${
                            this.props.packageType === "uop" ? "active" : ""
                          }`}
                          onClick={this.props.selectPacakgeType.bind(
                            this,
                            "uop"
                          )}
                        >
                          Curated
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="title">Duration of vacation</div>
                      <div className="option-row">
                        <div
                          className={`option ${
                            this.props.start_date !== "" ? "active" : ""
                          }`}
                          onClick={() =>
                            this.props.openPopup("#calendar-date-popup")
                          }
                        >
                          {this.props.start_date !== ""
                            ? moment(this.props.start_date).format(
                                "DD MMMM YYYY"
                              ) +
                              " to " +
                              moment(this.props.end_date).format("DD MMMM YYYY")
                            : "Start Date - End Date"}
                        </div>
                      </div>
                      {this.props.error_start_date === true && (
                        <span class="error">
                          Please Select Your vacation date
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="title">Price range</div>
                      <div className="option-row1">
                        {priceFilterOptions?.map((item, index) => (
                          <div
                            className={`option ${
                              item.value === this.props.priceVal ? "active" : ""
                            }`}
                            onClick={this.props.sateValChange.bind(
                              this,
                              "price",
                              item.value
                            )}
                            key={index}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="actions">
                      <Button
                        variant="text"
                        className="clearBtn"
                        onClick={this.props.clearSearch.bind(this)}
                      >
                        Clear all
                      </Button>
                      <Button
                        variant="contained"
                        className="applyBtn"
                        onClick={this.props.continueExplore.bind(this)}
                      >
                        Search
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}

        {/* Customer Login End */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var mystiflyFlightList = [];
  var mystiflyFlightstatus = "";
  var selectedflight = "";
  var flightPriceType = "";

  if (Object.keys(state.searchflight).length > 0) {
    var resultSetArr = !("result_set" in state.searchflight[0])
      ? Array()
      : state.searchflight[0].result_set;
    if (
      state.searchflight[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      mystiflyFlightList = resultSetArr.flight;
      mystiflyFlightstatus = "success";
      selectedflight = resultSetArr.selected_flight;
      flightPriceType = resultSetArr.flightPriceType;
    } else {
      mystiflyFlightstatus = "failure";
    }
  }
  return {
    creatorlogindata: state.creatorlogin,
    fblogin: state.fblogin,
    googlelogin: state.googlelogin,
    googlelogincreator: state.googlelogincreator,
    forgetpassword: state.forgetpassword,
    creatorregistration: state.creatorregistration,
    customerlogindata: state.customerlogin,
    customerregistration: state.customerregistration,
    flightList: mystiflyFlightList,
    selectedflight: selectedflight,
    flightPriceType: flightPriceType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorLoginData: (formPayload) => {
      dispatch({ type: GET_CREATOR_LOGINDATA, formPayload });
    },
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },
    getFbCreatorLoginData: (formPayload) => {
      dispatch({ type: GET_CREATOR_FBLOGINDATA, formPayload });
    },
    getGoogleLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLELOGINDATA, formPayload });
    },
    getGoogleCreatorLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLECREATORLOGINDATA, formPayload });
    },
    getCreatorRegistration: (formPayload) => {
      dispatch({ type: GET_CREATOR_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload, forgottype) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload, forgottype });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCustomerLoginData: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_LOGINDATA, formPayload });
    },
    getCustomerRegistration: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_REGISTRATION, formPayload });
    },
    getMystiflyRevalidate: (formPayload) => {
      dispatch({ type: GET_MYSTIFLYREVALIDATE, formPayload });
    },
    getMystiflyCheapestRevalidate: (formPayload) => {
      dispatch({ type: GET_MYSTIFLYCHEAPESTREVALIDATE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Header);
