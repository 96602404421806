/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_CREATOR_FBLOGINDATA, SET_CREATOR_FBLOGINDATA } from "../actions";
import { userapiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCreatorFbLoginData = function* () {
  yield takeEvery(GET_CREATOR_FBLOGINDATA, workerGetCreatorFbLoginData);
};

function* workerGetCreatorFbLoginData({ formPayload }) {
  try {
    const result = yield call(getFbCreatorLoginData, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CREATOR_FBLOGINDATA, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getFbCreatorLoginData(formPayload) {
  return Axios.post(
    userapiUrl + "creators/fbloginapp",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }
  );
}
