/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl } from "../Helpers/Config";
import {
  stripslashes,
  showAlert,
  showLoader,
  hideLoader,
  showPriceValue,
  loadRatingNumber,
} from "../Helpers/SettingHelper";
import { information, radult } from "../Helpers/Images";
import { Button, Divider } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
var stayslider = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  margin: 25,
  stagePadding: 150,
  responsive: {
    0: {
      margin: 10,
      stagePadding: 50,
    },
    580: {
      margin: 15,
      stagePadding: 100,
    },
    980: {
      margin: 20,
      items: 1,
    },
  },
};
var staygallery = {
  items: 1,
  loop: true,
  dots: false,
  nav: true,
};
var qs = require("qs");
var base64 = require("base-64");
var Parser = require("html-react-parser");
class Customerstaydetail extends Component {
  constructor(props) {
    super(props);
    var loadhotelID = "";
    if (
      this.props.match.params.hotelID !== "" &&
      typeof this.props.match.params.hotelID !== undefined &&
      typeof this.props.match.params.hotelID !== "undefined"
    ) {
      loadhotelID = this.props.match.params.hotelID;
    }
    var selectedRoom =
      this.props.location.state.selectedRoom !== "" &&
      typeof this.props.location.state.selectedRoom !== undefined &&
      typeof this.props.location.state.selectedRoom !== "undefined"
        ? this.props.location.state.selectedRoom
        : [];
    var roomCount =
      this.props.location.state.searchdata.room_count !== "" &&
      typeof this.props.location.state.searchdata.room_count !== undefined &&
      typeof this.props.location.state.searchdata.room_count !== "undefined"
        ? this.props.location.state.searchdata.room_count
        : 0;
    var selectedHotelID =
      this.props.location.state.selectedHotelID !== "" &&
      typeof this.props.location.state.selectedHotelID !== undefined &&
      typeof this.props.location.state.selectedHotelID !== "undefined"
        ? this.props.location.state.selectedHotelID
        : "";
    var hotelAdded =
      this.props.location.state.hotelAdded !== "" &&
      typeof this.props.location.state.hotelAdded !== undefined &&
      typeof this.props.location.state.hotelAdded !== "undefined"
        ? this.props.location.state.hotelAdded
        : "No";
    var searchdata = "";
    var country = "";
    var city = "";
    var city_code = "";
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      country = searchdata.destinationCountry?.toLowerCase();
      city = searchdata.destinationCity?.toLowerCase();
      city_code = searchdata.destinationWebbedsCity;
    } else {
      this.props.history.push("/");
    }

    var rooms = this.props.location?.state?.rooms || [
      { adults: 1, children: 0, childAges: [] },
    ];

    this.state = {
      customerdetail: "",
      country: country,
      city: city,
      city_code: city_code,
      hotelID: loadhotelID,
      hotelResult: "",
      selectedRoom: selectedRoom,
      sub_total: 0,
      grand_total: 0,
      viewmoreAboutInfo: false,
      viewmorePolicyInfo: false,
      tariff_notes: "",
      cancellation_rules: [],
      room_special: [],
      hotel_amenities: [],
      isMobile: window.innerWidth <= 480,
      showFareBreakdown: false,
      currentRoom: 0,
      roomCount: roomCount,
      selectedHotelID: selectedHotelID,
      oldselectedHotelID: selectedHotelID,
      hotelAdded: hotelAdded,
      selectedPrice: 0,
      availablemsg: "",
      availableRooms: false,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      rooms: rooms,
    };
  }

  componentDidMount() {
    /* $(document).ready(function () {
      function disablePrev() {
        window.history.forward();
      }
      window.onload = disablePrev();
      window.onpageshow = function (evt) {
        if (evt.persisted) disableBack();
      };
    }); */
    this.loadHotelDetails();
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }

  componentDidUpdate() {
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }

  componentWillUnmount() {
    if (this.state.isMobile) {
      $("#freshworks-container").show();
    }
  }

  loadHotelDetails() {
    var searchdata = this.props.location.state.searchdata;
    var start_date = new Date(searchdata.start_date);
    var end_date = new Date(searchdata.end_date);
    var search_days = searchdata.search_days.split("-");
    var adults = searchdata.adults;
    var child = searchdata.child;
    var childAgeList = searchdata.childAgeList;
    var room_count = searchdata.room_count;
    var startDate =
      start_date.getFullYear() +
      "-" +
      this.pad(parseInt(start_date.getMonth() + 1)) +
      "-" +
      this.pad(start_date.getDate());
    if (searchdata.end_date === "") {
      end_date = new Date(searchdata.start_date);
      end_date.setDate(
        parseInt(end_date.getDate()) + parseInt(search_days[1] - 1)
      );
    }
    var endDate =
      end_date.getFullYear() +
      "-" +
      this.pad(parseInt(end_date.getMonth() + 1)) +
      "-" +
      this.pad(end_date.getDate());
    var passengers = [];

/*     var totalSelectedRoomcount = room_count;
    var splitcount = Math.floor(adults / totalSelectedRoomcount);
    var balanceAdult = adults - splitcount * totalSelectedRoomcount;
    for (let index = 1; index <= totalSelectedRoomcount; index++) {
      if (index == 1) {
        var childList = [];
        if (parseInt(child) > 0 && childAgeList.length > 0) {
          childAgeList.map((item) => {
            childList.push(item.value);
          });
        }
        var updatedAdultCount = splitcount;
        if (balanceAdult > 0) {
          updatedAdultCount = updatedAdultCount + balanceAdult;
        }
        passengers.push({ adult: updatedAdultCount, child: childList });
      } else {
        passengers.push({ adult: splitcount, child: [] });
      }
    } */

      var passengers = this.state.rooms?.map((room) => ({
        adult: room.adults,
        child: room.childAges?.map((age) => age.value),
      }));

    var postObject = {
      hotel_id: base64.decode(this.state.hotelID),
      country: this.state.country,
      city: this.state.city,
      from_date: startDate,
      to_date: endDate,
      passenger_data: JSON.stringify(passengers),
      city_code: this.state.city_code,
    };

    axios
      .post(
        apiUrl + "hotelbooking/getRooms",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data.result.hotelRoomInfo, "sds");

          this.setState(
            {
              hotelResult: res.data.result.hotelRoomInfo,
              hotel_amenities:
                res.data.result.hotelRoomInfo.hotel_amenities !== "" &&
                res.data.result.hotelRoomInfo.hotel_amenities !== null
                  ? res.data.result.hotelRoomInfo.hotel_amenities.split("#@#")
                  : [],
              loadingHotel: false,
              availableRooms: true,
            },
            () => {
              if (this.state.selectedRoom.length > 0) {
                if (
                  this.state.selectedRoom[0].hotel_name ===
                  this.state.hotelResult.hotel_name
                ) {
                  this.calculatePrice();
                } else {
                  this.setState({ selectedRoom: [] });
                }
              }
            }
          );
        } else if (res.data.status === "error") {
          console.log(res.data.result.hotelRoomInfo, "checkinghotelinfo");
          this.setState(
            {
              hotelResult: res.data.result.hotelRoomInfo,
              availablemsg: res.data.message,
              hotel_amenities:
                res.data.result.hotelRoomInfo.hotel_amenities !== "" &&
                res.data.result.hotelRoomInfo.hotel_amenities !== null &&
                typeof res.data.result.hotelRoomInfo.hotel_amenities !==
                  undefined &&
                typeof res.data.result.hotelRoomInfo.hotel_amenities !==
                  "undefined"
                  ? res.data.result.hotelRoomInfo.hotel_amenities.split("#@#")
                  : [],
              loadingHotel: false,
              availableRooms: false,
            },
            () => {
              if (this.state.selectedRoom.length > 0) {
                if (
                  this.state.selectedRoom[0].hotel_name ===
                  this.state.hotelResult.hotel_name
                ) {
                  this.calculatePrice();
                } else {
                  this.setState({ selectedRoom: [] });
                }
              }
            }
          );
        }
      });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  loadRatingstar(points, type = "") {
    if (points !== "" && points !== null) {
      if (type === "hotel") {
        if (points === "Economy*") {
          points = "1.0";
        } else if (points === "Budget **") {
          points = "2.0";
        } else if (points === "Standard ***") {
          points = "3.0";
        } else if (points === "Superior ****") {
          points = "4.0";
        } else if (points === "Luxury *****") {
          points = "5.0";
        } else if (points === "Serviced Apartment") {
          points = "0.0";
        } else if (points === "Unrated") {
          points = "0.0";
        }
      }

      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                key={index}
                aria-hidden="true"
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" key={index} aria-hidden="true"></i>
            );
          }
        }
      });
    }
  }

  selectRoom(roomID, roomIndex, item, roomMainIndex, selectedType, hotel_name) {
    // Get the value of the selected radio button within the specified room
    var selectedRmooPriceID = $(
      "#room_" +
        roomMainIndex +
        "_" +
        roomID +
        " .room_charge_list input:radio:checked"
    ).val();
    // Initialize variables for price index and ID
    var priceIndex = "";
    var priceID = "";
    // Split the selected room price ID to get its components
    if (selectedRmooPriceID !== "") {
      var pricesplit = selectedRmooPriceID.split("_");
      priceID = pricesplit[0];
      priceIndex = pricesplit[1];
    }
    // Get the current selectedRoom state from the component
    var selectedRoom = this.state.selectedRoom;
    var newselectedRoom = [];
    var existSelection = 0;
    //Loop through the selectedRoom array to manage room selections
    if (selectedRoom.length > 0) {
      selectedRoom.map((item) => {
        if (
          item.roomIndex === roomIndex &&
          roomMainIndex === item.roomMainIndex
        ) {
          if (selectedType === 0) {
            newselectedRoom.push(item);
          } else {
            existSelection++;
          }
        } else {
          newselectedRoom.push(item);
        }
      });
    }
    // If a selection doesn't exist, update the newselectedRoom array
    if (existSelection === 0) {
      var selectedRoomDetails = item.room_charges[priceIndex];
      newselectedRoom.push({
        hotel_name: hotel_name,
        roomMainIndex: roomMainIndex,
        roomIndex: roomIndex,
        room_code: roomID,
        priceID: priceID,
        priceIndex: priceIndex,
        room_total_amount: item.room_charges[priceIndex].room_total_amount,
        status: "unchecked",
        room_type_code: item.room_type_code,
        allocation_details: selectedRoomDetails.allocation_details,
      });
    }
    this.setState(
      { selectedRoom: newselectedRoom, selectedHotelID: this.state.hotelID },
      function () {
        this.calculatePrice();
        var currentRoom = this.state.currentRoom === 1 ? 0 : 1;
        if (this.state.hotelResult.room_info.length > 1) {
          if (currentRoom === 1 && existSelection === 0) {
            this.selectMainRoom(currentRoom);
            $("html, body").animate(
              {
                scrollTop: $("#preferred-room").offset().top - 100,
              },
              1000
            );
          }
        }
      }
    );
  }
  calculatePrice() {
    var hotelResult = this.state.hotelResult.room_info;
    var sub_total = 0;
    var grand_total = 0;
    if (this.state.selectedRoom.length > 0) {
      this.state.selectedRoom.map((item) => {
        var selRoom =
          hotelResult[item.roomMainIndex] !== "" &&
          typeof hotelResult[item.roomMainIndex] !== undefined &&
          (typeof hotelResult[item.roomMainIndex] !== "undefined") &
            (hotelResult[item.roomMainIndex][item.roomIndex] !== "") &&
          typeof hotelResult[item.roomMainIndex][item.roomIndex] !==
            undefined &&
          typeof hotelResult[item.roomMainIndex][item.roomIndex] !== "undefined"
            ? hotelResult[item.roomMainIndex][item.roomIndex]
            : "";
        if (selRoom !== "") {
          var selRoomPrice =
            selRoom.room_charges[item.priceIndex] !== "" &&
            typeof selRoom.room_charges[item.priceIndex] !== undefined &&
            typeof selRoom.room_charges[item.priceIndex] !== "undefined"
              ? selRoom.room_charges[item.priceIndex]
              : "";
          if (selRoomPrice !== "") {
            sub_total =
              parseFloat(sub_total) +
              parseFloat(selRoomPrice.room_total_amount);
          }
        }
      });
    }
    grand_total = sub_total;
    this.setState({ sub_total: sub_total, grand_total: grand_total });
  }
  continueRoom() {
    var hotelResult = this.state.hotelResult.room_info;

    var searchdata = this.props.location.state.searchdata;
    if (
      this.state.selectedRoom.length > 0 &&
      this.state.selectedRoom.length === searchdata.room_count
    ) {
      showLoader("check_room_avail", "class");
      var adults = searchdata.adults;
      var child = searchdata.child;
      var totalSelectedRoomcount = this.state.selectedRoom.length;
      var splitcount = Math.floor(adults / totalSelectedRoomcount);
      var balanceAdult = adults - splitcount * totalSelectedRoomcount;

      var passengers = this.state.selectedRoom.map((item, index) => {
        var selRoom = hotelResult[item.roomMainIndex]?.[item.roomIndex];
        var selRoomPrice = selRoom?.room_charges?.[item.priceIndex] ?? {};
  
        var childList = this.state.rooms[index]?.childAges.map((age) => age.value) || [];
  
        return {
          adult: this.state.rooms[index]?.adults,
          child: childList, 
          room_type_code: item.room_type_code, 
          rate_id: selRoomPrice.rate_id,
          allocation_details: item.allocation_details,
        };
      });

      var start_date = new Date(searchdata.start_date);
      var end_date = new Date(searchdata.end_date);

      var startDate =
        start_date.getFullYear() +
        "-" +
        this.pad(parseInt(start_date.getMonth() + 1)) +
        "-" +
        this.pad(start_date.getDate());

      var endDate =
        end_date.getFullYear() +
        "-" +
        this.pad(parseInt(end_date.getMonth() + 1)) +
        "-" +
        this.pad(end_date.getDate());

      var postObject = {
        hotel_id: base64.decode(this.state.hotelID),
        country: this.state.country,
        city: this.state.city,
        from_date: startDate,
        to_date: endDate,
        passenger_data: JSON.stringify(passengers),
        city_code: this.state.city_code,
      };

      axios
        .post(
          apiUrl + "hotelbooking/getRoomBlock",
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            var resultSet = res.data.result.hotelRoomInfo.room_info;
            var finalRoom = this.state.selectedRoom;
            this.state.selectedRoom.map((item, index) => {
              if (
                resultSet[item.roomMainIndex][item.roomIndex] !== "" &&
                typeof resultSet[item.roomMainIndex][item.roomIndex] !==
                  undefined &&
                typeof resultSet[item.roomMainIndex][item.roomIndex] !==
                  "undefined"
              ) {
                if (
                  resultSet[item.roomMainIndex][item.roomIndex].room_charges[
                    parseInt(item.priceIndex)
                  ] !== "" &&
                  typeof resultSet[item.roomMainIndex][item.roomIndex]
                    .room_charges[parseInt(item.priceIndex)] !== undefined &&
                  typeof resultSet[item.roomMainIndex][item.roomIndex]
                    .room_charges[parseInt(item.priceIndex)] !== "undefined"
                ) {
                  finalRoom[index]["status"] =
                    resultSet[item.roomMainIndex][item.roomIndex].room_charges[
                      parseInt(item.priceIndex)
                    ].status;
                  finalRoom[index]["allocation_details"] =
                    resultSet[item.roomMainIndex][item.roomIndex].room_charges[
                      parseInt(item.priceIndex)
                    ].allocation_details;
                  finalRoom[index]["names_required_forbooking"] =
                    resultSet[item.roomMainIndex][item.roomIndex].room_charges[
                      parseInt(item.priceIndex)
                    ].names_required_forbooking;
                }
              }
            });
            this.setState(
              { selectedRoom: finalRoom, hotelAdded: "Yes" },
              function () {
                this.checkingRoomStatus();
                hideLoader("check_room_avail", "class");
              }
            );
          }
        });
    } else {
      showAlert("Error", "Please select " + searchdata.room_count + " room");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  checkingRoomStatus() {
    var roomStatus = 0;
    if (this.state.selectedRoom.length > 0) {
      this.state.selectedRoom.map((item) => {
        if (item.status === "unchecked") {
          roomStatus++;
        }
      });
    }
    if (roomStatus === 0) {
      var packagedetails = this.props.location.state;
      packagedetails["selectedRoom"] = this.state.selectedRoom;
      packagedetails["selectedHotelID"] = this.state.hotelID;
      packagedetails["selectedHotel"] = this.state.hotelResult;
      if (this.props.match.params.packageSlug === "create-own-package") {
        packagedetails["emptyhotel"] = "No";
        var stayList = [
          {
            address: this.state.hotelResult.address,
            city_name: this.state.hotelResult.city_name,
            classification_name: "",
            creator_stay_id: this.state.hotelResult.hotel_id,
            hotel_amenities: "Air Conditioning#@#Baby Sitting#@#Banquet Hall",
            hotel_id: this.state.hotelResult.hotel_id,
            hotel_name: this.state.hotelResult.hotel_name,
            latitude: this.state.hotelResult.latitude,
            longitude: this.state.hotelResult.longitude,
            location: this.state.hotelResult.location,
            rating: this.state.hotelResult.rating,
            room_count: "",
            thumbnail_image: this.state.hotelResult.thumbnail_image,
          },
        ];
        packagedetails["stayList"] = stayList;
      }

      var allowChangeDistance = "Yes";
      if (this.state.selectedHotelID === this.state.oldselectedHotelID) {
        allowChangeDistance = "No";
      }

      if (
        this.state.hotelResult.longitude !== "" &&
        this.state.hotelResult.latitude !== "" &&
        allowChangeDistance === "Yes"
      ) {
        var postObject = {
          stayLatLong:
            this.state.hotelResult.latitude +
            "," +
            this.state.hotelResult.longitude,
          activities: JSON.stringify(packagedetails.activities),
        };

        axios
          .post(
            apiUrl + "package/getActivityDistance",
            qs.stringify(postObject),
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
          )
          .then((res) => {
            if (res.data.status === "ok") {
              packagedetails["activities"] = res.data.activities;
              this.props.history.push({
                pathname:
                  "/search-package/" + this.props.match.params.packageSlug,
                state: packagedetails,
              });
            }
          });
      } else {
        this.props.history.push({
          pathname: "/search-package/" + this.props.match.params.packageSlug,
          state: packagedetails,
        });
      }
    } else {
      showAlert("Error", "This room not available");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  loadAmenities(amenities) {
    if (amenities !== "" && amenities !== null) {
      var amenitiesList = amenities.split("#@#");
      if (amenitiesList.length > 0) {
        return amenitiesList.map((item, index) => {
          return (
            <li key={index}>
              {(() => {
                if (item.toLowerCase().indexOf("telivision") >= 0) {
                  return <i className="fa fa-bed" aria-hidden="true"></i>;
                } else if (item.toLowerCase().indexOf("bed") >= 0) {
                  return <i className="fa fa-bed" aria-hidden="true"></i>;
                } else if (item.toLowerCase().indexOf("bar") >= 0) {
                  return <i className="fa fa-glass" aria-hidden="true"></i>;
                } else if (item.toLowerCase().indexOf("wifi") >= 0) {
                  return <i className="fa fa-wifi" aria-hidden="true"></i>;
                } else {
                  return (
                    <i className="fa fa-dot-circle-o" aria-hidden="true"></i>
                  );
                }
              })()}
              <span>{item.charAt(0).toUpperCase() + item.slice(1)}</span>
            </li>
          );
        });
      }
    }
  }
  loadHotelImages(imageList) {
    if (
      imageList !== "" &&
      typeof imageList !== undefined &&
      typeof imageList !== "undefined"
    ) {
      var imageListsplit = imageList.split(",");
      if (imageListsplit.length > 0) {
        return imageListsplit.map((item, index) => {
          var itemsplit = item.split("#@#");
          if (
            itemsplit[2] !== "" &&
            itemsplit[2] !== null &&
            typeof itemsplit[2] !== undefined &&
            typeof itemsplit[2] !== "undefined"
          ) {
            return (
              <div key={index}>
                <div className="stayholder">
                  <img src={itemsplit[2]} alt={itemsplit[1]} />
                </div>
              </div>
            );
          }
        });
      }
    }
  }
  checkRoomSelection(roomIndex, roomMainIndex) {
    var activeRoom = 0;
    if (this.state.selectedRoom.length > 0) {
      this.state.selectedRoom.map((item) => {
        if (
          item.roomIndex === roomIndex &&
          roomMainIndex === item.roomMainIndex
        ) {
          activeRoom++;
        }
      });
    }
    return activeRoom;
  }
  goback() {
    var packagedetails = this.props.location.state;
    this.props.history.push({
      pathname: "/search-package/" + this.props.match.params.packageSlug,
      state: packagedetails,
    });
  }
  showRoomPrice = (item, index) => {
    var price = item.room_charges[index].room_total_amount;
    return (
      <>
        {showPriceValue(
          price,
          this.state.currentCurrency,
          this.state.currencyRate
        )}
      </>
    );
  };
  selectRoomPrice(roomcode, price, item, index, roomIndex) {
    $("#room_" + roomIndex + "_" + roomcode + " .selected_room_price").html(
      "SGD $" + price
    );
  }
  viewmoreAboutInfo() {
    this.setState({ viewmoreAboutInfo: !this.state.viewmoreAboutInfo });
  }
  viewmorePolicyInfo() {
    this.setState({ viewmorePolicyInfo: !this.state.viewmorePolicyInfo });
  }

  cancellationPolicy(roomdetails) {
    var seletedRoomPrice = $(
      /*  "#room_" +
        roomdetails.room_type_code + */
      ' .room_charge_list input[type="radio"]:checked'
    ).attr("data-itemindex");
    if (
      roomdetails.room_charges[seletedRoomPrice] !== "" &&
      typeof roomdetails.room_charges[seletedRoomPrice] !== undefined &&
      typeof roomdetails.room_charges[seletedRoomPrice] !== "undefined"
    ) {
      this.setState(
        {
          tariff_notes: roomdetails.room_charges[seletedRoomPrice].tariff_notes,
          cancellation_rules:
            roomdetails.room_charges[seletedRoomPrice].cancellation_rules,
        },
        function () {
          $.magnificPopup.open({
            items: {
              src: "#hotel_moredetails_popup",
            },
            type: "inline",
          });
        }
      );
    }
  }

  honeymoonPopup(roomdetails) {
    var seletedRoomPrice = $(
      "#room_" +
        roomdetails.room_type_code +
        ' .room_charge_list input[type="radio"]:checked'
    ).attr("data-itemindex");
    this.setState(
      {
        room_special: roomdetails.room_special[0],
      },
      function () {
        $.magnificPopup.open({
          items: {
            src: "#hotel_honeymoon_popup",
          },
          type: "inline",
        });
      }
    );
  }
  handleViewMoreClick = (roomdetails) => {
    $.magnificPopup.open({
      items: {
        src: "#" + roomdetails,
      },
      type: "inline",
    });
  };

  openroomcapacityerror() {
    $.magnificPopup.open({
      items: {
        src: "#roomerror",
      },
      type: "inline",
    });
  }
  selectMainRoom(roomIndex) {
    this.setState({ currentRoom: roomIndex });
  }

  disableRoom(index, roomIndex) {
    var selectedRoom = this.state.selectedRoom;
    if (this.state.selectedRoom.length > 0) {
      if (selectedRoom[roomIndex] !== undefined) {
        if (roomIndex === selectedRoom[roomIndex].roomMainIndex) {
          if (index === selectedRoom[roomIndex].roomIndex) {
            return false;
          } else {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };

  disableRadio = (index, roomIndex) => {
    var selectedRoom = this.state.selectedRoom;
    var roomCount = this.state.roomCount;
    if (roomCount === 1) {
      return selectedRoom.length !== 0;
    }
    if (roomCount === 2) {
      if (selectedRoom.length > 1) {
        return selectedRoom.length !== 0;
      } else {
        return false;
      }
    }
  };
  sateValChange = (field, value) => {
    this.setState({ [field]: value });
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        {this.state.hotelResult !== "" ? (
          <>
            {!this.state.isMobile && (
              <div className="bread-crumbs">
                <div className="container">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href={void 0} onClick={this.goBack}>
                        {this.state.hotelResult.city_name}
                      </a>
                    </li>
                    <li>{stripslashes(this.state.hotelResult.hotel_name)}</li>
                  </ul>
                </div>
              </div>
            )}
            <section className="innersection">
              <div className="container">
                <div className="detail-top-header pb30">
                  <h1 className="color-header">
                    Your stay for the <span>trip</span>
                  </h1>
                </div>
                <div className="package-details">
                  <div className="package-details-lhs">
                    <div className="user-stay-select">
                      <h5 className="color-header mb15">
                        Stay at <span> {this.state.country}</span>
                      </h5>

                      <div className="uss-inner-top">
                        <h4>
                          {this.state.hotelResult.hotel_name}{" "}
                          <span>{this.state.hotelResult.address}</span>
                        </h4>
                        <div className="star-rating-review">
                          <div className="star-rating">
                            {this.loadRatingstar(
                              this.state.hotelResult.rating,
                              "hotel"
                            )}
                          </div>
                          <div className="sr-review">
                            <strong>
                              {" "}
                              {loadRatingNumber(this.state.hotelResult.rating)}
                            </strong>
                          </div>
                        </div>
                        {this.state.hotelResult.images !== "" &&
                          this.state.hotelResult.images !== null && (
                            <div className="uss-inner-slider">
                              <OwlCarousel options={stayslider}>
                                {this.loadHotelImages(
                                  this.state.hotelResult.images
                                )}
                              </OwlCarousel>
                            </div>
                          )}
                      </div>

                      {this.state.hotelResult.room_info.length > 0 && (
                        <div className="uss-choice">
                          {this.state.roomCount > 1 ? (
                            <h3>Select your preferred rooms</h3>
                          ) : (
                            <h3>Select your preferred room</h3>
                          )}
                          {this.state.availableRooms === true ? (
                            <>
                              {" "}
                              <div
                                className="preferred-room"
                                id="preferred-room"
                              >
                                <ul>
                                  {this.state.hotelResult.room_info.map(
                                    (item, index) => {
                                      return (
                                        <li
                                          className={
                                            this.state.currentRoom === index
                                              ? "active"
                                              : ""
                                          }
                                          key={index}
                                        >
                                          {this.state.roomCount > 1 && (
                                            <a
                                              href={void 0}
                                              onClick={this.selectMainRoom.bind(
                                                this,
                                                index
                                              )}
                                            >
                                              Room {parseInt(index) + 1} options
                                            </a>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                              {this.state.hotelResult.room_info.map(
                                (roomItem, roomIndex) => {
                                  return (
                                    <ul
                                      key={roomIndex}
                                      style={{
                                        display:
                                          this.state.currentRoom !== roomIndex
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      {roomItem.map((item, index) => {
                                        var checkRoomSelection =
                                          this.checkRoomSelection(
                                            index,
                                            roomIndex
                                          );
                                        return (
                                          <li
                                            key={index}
                                            id={
                                              "room_" +
                                              roomIndex +
                                              "_" +
                                              item.room_type_code
                                            }
                                            className={
                                              checkRoomSelection > 0
                                                ? "active"
                                                : ""
                                            }
                                          >
                                            <div className="room-parent">
                                              <div className="room-choice-img">
                                                <OwlCarousel
                                                  options={staygallery}
                                                >
                                                  {this.loadHotelImages(
                                                    this.state.hotelResult
                                                      .images
                                                  )}
                                                </OwlCarousel>
                                              </div>
                                              <div className="room-choice-desc">
                                                <div className="room-choice-one">
                                                  <h4>{item.room_type_name}</h4>
                                                  {item.room_amenities !== "" &&
                                                    item.room_amenities !==
                                                      null && (
                                                      <ul>
                                                        {this.loadAmenities(
                                                          item.room_amenities
                                                        )}
                                                      </ul>
                                                    )}

                                                  <div>
                                                    <div className="room-view-right">
                                                      {this.state
                                                        .hotel_amenities
                                                        .length > 0 && (
                                                        <a
                                                          href={void 0}
                                                          onClick={this.handleViewMoreClick.bind(
                                                            this,
                                                            "hotel_info_popup_" +
                                                              roomIndex +
                                                              "_" +
                                                              item.room_type_code
                                                          )}
                                                        >
                                                          View more
                                                        </a>
                                                      )}
                                                    </div>
                                                    {
                                                      // this.state.viewMorePopup &&
                                                      this.state.hotel_amenities
                                                        .length > 0 && (
                                                        <div
                                                          id={
                                                            "hotel_info_popup_" +
                                                            roomIndex +
                                                            "_" +
                                                            item.room_type_code
                                                          }
                                                          className="mfp-hide common-popup hotel-info-popup"
                                                        >
                                                          <div className="cp-header textcenter">
                                                            <h4>
                                                              Hotel Amenities
                                                            </h4>
                                                            <a
                                                              className="date-package-popup"
                                                              href={void 0}
                                                            >
                                                              <img
                                                                src={close}
                                                              />
                                                            </a>
                                                          </div>
                                                          <div className="cp-body">
                                                            <ul>
                                                              {this.state.hotel_amenities.map(
                                                                (
                                                                  amenity,
                                                                  index
                                                                ) => (
                                                                  <li
                                                                    key={index}
                                                                  >
                                                                    {amenity}
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      )
                                                    }
                                                  </div>
                                                </div>
                                                {(item.room_special[0]
                                                  .condition !== "" ||
                                                  item.room_special[0]
                                                    .description !== "" ||
                                                  item.room_special[0].notes !==
                                                    "" ||
                                                  item.room_special[0]
                                                    .special_name !== "" ||
                                                  item.room_special[0]
                                                    .special_type !== "") && (
                                                  <div className="honeymoon-policy">
                                                    <a
                                                      href={void 0}
                                                      onClick={this.honeymoonPopup.bind(
                                                        this,
                                                        item
                                                      )}
                                                    >
                                                      <span>
                                                        {item.room_special[0]
                                                          .special_type !==
                                                          "" &&
                                                          item.room_special[0]
                                                            .special_type}
                                                      </span>{" "}
                                                    </a>
                                                  </div>
                                                )}
                                                <div className="room-capacity">
                                                  <h5
                                                    onClick={this.openroomcapacityerror.bind(
                                                      this
                                                    )}
                                                  >
                                                    Room Capacity{" "}
                                                  </h5>
                                                  <ul>
                                                    {parseInt(item.max_adult) >
                                                      0 && (
                                                      <li>
                                                        <img
                                                          src={radult}
                                                          alt="adult"
                                                        />
                                                        <span>
                                                          {parseInt(
                                                            item.max_adult
                                                          )}{" "}
                                                          Adult
                                                          {parseInt(
                                                            item.max_adult
                                                          ) > 1
                                                            ? "s"
                                                            : ""}
                                                        </span>
                                                      </li>
                                                    )}
                                                    {parseInt(
                                                      item.max_children
                                                    ) > 0 && (
                                                      <li>
                                                        <img
                                                          src={radult}
                                                          alt="adult"
                                                        />
                                                        <span>
                                                          {parseInt(
                                                            item.max_children
                                                          )}{" "}
                                                          Child
                                                          {parseInt(
                                                            item.max_children
                                                          ) > 1
                                                            ? "s"
                                                            : ""}
                                                        </span>
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                                {item.room_charges.length >
                                                  0 && (
                                                  <>
                                                    <div className="room-choice-two">
                                                      <h5>
                                                        Extra Options{" "}
                                                        <img
                                                          src={information}
                                                          alt="info"
                                                        />
                                                      </h5>

                                                      <ul className="room_charge_list">
                                                        {item.room_charges.map(
                                                          (
                                                            chargeItem,
                                                            chargeIndex
                                                          ) => {
                                                            return (
                                                              <li
                                                                key={
                                                                  chargeIndex
                                                                }
                                                              >
                                                                <input
                                                                  type="radio"
                                                                  className="room_charge"
                                                                  name={
                                                                    "room_charge_" +
                                                                    roomIndex +
                                                                    "_" +
                                                                    item.room_type_code
                                                                  }
                                                                  data-itemindex={
                                                                    chargeIndex
                                                                  }
                                                                  value={
                                                                    chargeItem.rate_id +
                                                                    "_" +
                                                                    chargeIndex
                                                                  }
                                                                  defaultChecked={
                                                                    chargeIndex ===
                                                                    0
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onChange={this.selectRoomPrice.bind(
                                                                    this,
                                                                    item.room_type_code,
                                                                    chargeItem.room_total_amount,
                                                                    item,
                                                                    chargeIndex,
                                                                    roomIndex
                                                                  )}
                                                                  disabled={this.disableRadio(
                                                                    index,
                                                                    roomIndex
                                                                  )}
                                                                />
                                                                <span>
                                                                  {
                                                                    chargeItem.rate_description
                                                                  }
                                                                </span>
                                                                <strong>
                                                                  {showPriceValue(
                                                                    chargeItem.room_total_amount,
                                                                    this.state
                                                                      .currentCurrency,
                                                                    this.state
                                                                      .currencyRate
                                                                  )}
                                                                </strong>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </div>

                                                    <div className="details-policy">
                                                      <a
                                                        href={void 0}
                                                        onClick={this.cancellationPolicy.bind(
                                                          this,
                                                          item
                                                        )}
                                                      >
                                                        <span>
                                                          More details on
                                                          cancellation policy
                                                        </span>{" "}
                                                        <img
                                                          src={information}
                                                          alt="info"
                                                        />
                                                      </a>
                                                    </div>
                                                    <div className="room-choice-four">
                                                      <div className="rcf-lhs">
                                                        <p>
                                                          <strong className="selected_room_price">
                                                            {this.showRoomPrice(
                                                              item,
                                                              0
                                                            )}
                                                          </strong>{" "}
                                                          Total
                                                        </p>
                                                        <span>
                                                          *Excludes Taxes and
                                                          Fees
                                                        </span>
                                                      </div>
                                                      <div className="rcf-rhs">
                                                        <button
                                                          className={
                                                            checkRoomSelection >
                                                            0
                                                              ? "button ghost-button rmv-button"
                                                              : "button ghost-button"
                                                          }
                                                          onClick={this.selectRoom.bind(
                                                            this,
                                                            item.room_type_code,
                                                            index,
                                                            item,
                                                            roomIndex,
                                                            checkRoomSelection,
                                                            this.state
                                                              .hotelResult
                                                              .hotel_name
                                                          )}
                                                          disabled={this.disableRoom(
                                                            index,
                                                            roomIndex
                                                          )}
                                                        >
                                                          {checkRoomSelection >
                                                          0
                                                            ? "Remove"
                                                            : "Select"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            this.state.availablemsg
                          )}
                        </div>
                      )}
                      <div
                        className={
                          this.state.viewmoreAboutInfo === true
                            ? "uss-about pb10 mar-adjust active"
                            : "uss-about pb10 mar-adjust"
                        }
                      >
                        <h3 className="mb15">About</h3>
                        {this.state.hotelResult.description1 !== "" && (
                          <p>{Parser(this.state.hotelResult.description1)}</p>
                        )}
                        {this.state.hotelResult.description2 !== "" && (
                          <p>{Parser(this.state.hotelResult.description2)}</p>
                        )}
                      </div>
                      <div className="more-less">
                        <a
                          href={void 0}
                          onClick={this.viewmoreAboutInfo.bind(this)}
                        >
                          {this.state.viewmoreAboutInfo === true
                            ? "View Less"
                            : "View More"}
                        </a>
                      </div>
                      <div
                        className={
                          this.state.viewmorePolicyInfo === true
                            ? "uss-about pb10 mar-adjust active"
                            : "uss-about pb10 mar-adjust"
                        }
                      >
                        <h3 className="mb15">Hotel policy</h3>
                        {this.state.hotelResult.description1 !== "" && (
                          <p>{Parser(this.state.hotelResult.description1)}</p>
                        )}
                      </div>
                      <div className="more-less">
                        <a
                          href={void 0}
                          onClick={this.viewmorePolicyInfo.bind(this)}
                        >
                          {this.state.viewmorePolicyInfo === true
                            ? "View Less"
                            : "View More"}
                        </a>
                      </div>
                    </div>
                  </div>
                  {!this.state.isMobile && (
                    <div className="package-details-rhs">
                      <h5 className="color-header mb15 vb-hidden">
                        Stay at <span> {this.state.country}</span>
                      </h5>
                      <div className="detail-bottom-rhs-price rhs-white-box">
                        <h5>Room Price details</h5>
                        <ul>
                          {this.state.selectedRoom.length > 0 ? (
                            <ul>
                              {this.state.selectedRoom.map((item, index) => {
                                return (
                                  <li>
                                    <span className="service-content">
                                      <strong>
                                        {this.state.selectedRoom.length > 1 ? (
                                          <span>
                                            Room {parseInt(index) + 1}
                                          </span>
                                        ) : (
                                          <span>Room Price</span>
                                        )}
                                      </strong>
                                    </span>
                                    <span className="service-amount">
                                      <strong>
                                        {this.state.sub_total !== ""
                                          ? "" +
                                            showPriceValue(
                                              item.room_total_amount,
                                              this.state.currentCurrency,
                                              this.state.currencyRate
                                            )
                                          : "-"}
                                      </strong>
                                    </span>
                                  </li>
                                );
                              })}
                              <li>
                                <span className="service-content">Total</span>
                                <span className="service-amount">
                                  {" "}
                                  {this.state.grand_total !== ""
                                    ? "" +
                                      showPriceValue(
                                        this.state.grand_total,
                                        this.state.currentCurrency,
                                        this.state.currencyRate
                                      )
                                    : "-"}
                                </span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <span className="service-content">
                                  <strong>Room Price</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      0,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                              </li>

                              <li>
                                <span className="service-content">Total</span>
                                <span className="service-amount">
                                  {" "}
                                  {showPriceValue(
                                    0,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </span>
                              </li>
                            </ul>
                          )}
                        </ul>
                      </div>
                      <div className="booknow-btm two-btn-merge">
                        <a
                          href={void 0}
                          onClick={this.goback.bind(this)}
                          className="button ghost-button"
                        >
                          Back
                        </a>
                        <a
                          className={
                            this.state.selectedRoom.length > 0
                              ? "button check_room_avail"
                              : "button disabled-link"
                          }
                          href={void 0}
                          onClick={this.continueRoom.bind(this)}
                        >
                          Continue
                        </a>
                      </div>

                      <div>
                        {this.state.hotelResult.latitude !== "" &&
                          this.state.hotelResult.latitude !== null &&
                          this.state.hotelResult.longitude !== "" &&
                          this.state.hotelResult.longitude !== null && (
                            <div className="detail-bottom-rhs-map">
                              <iframe
                                width="100%"
                                height="400"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src={
                                  "https://maps.google.com/maps?q=" +
                                  this.state.hotelResult.latitude +
                                  "," +
                                  this.state.hotelResult.longitude +
                                  "&hl=en&z=14&output=embed"
                                }
                              ></iframe>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="innersection">
            <div className="container">
              <ContentLoader height={140} speed={1} viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
              </ContentLoader>
            </div>
          </section>
        )}

        <div
          id="hotel_moredetails_popup"
          className="mfp-hide common-popup hotel-moredetails-popup"
        >
          <div className="cp-header textcenter">
            <a className="date-package-popup" href={void 0}>
              <img src={close} />
            </a>
          </div>
          <div className="cp-body">
            <h4>Cancellation and Refund</h4>
            {this.state.cancellation_rules.length > 0 &&
              this.state.cancellation_rules.map((item, index) => {
                return (
                  <div>
                    {(() => {
                      if (index === 0) {
                        return (
                          <div>
                            <h5>Before {item.toDateDetails}</h5>
                            <p>
                              Cancellation and amendment charge is S$
                              {item.cancelCharge} & S${item.amendCharge}
                            </p>
                          </div>
                        );
                      } else if (index === 1) {
                        return (
                          <div>
                            {item.fromDateDetails !== "" &&
                              typeof item.fromDateDetails !== undefined &&
                              typeof item.fromDateDetails !== "undefined" &&
                              item.toDateDetails !== "" &&
                              typeof item.toDateDetails !== undefined &&
                              typeof item.toDateDetails !== "undefined" && (
                                <>
                                  <h5>
                                    Between {item.fromDateDetails},{" "}
                                    {item.toDateDetails}
                                  </h5>
                                  <p>
                                    Cancellation and amendment charge is S$
                                    {item.cancelCharge !== "" &&
                                    typeof item.cancelCharge !== undefined &&
                                    typeof item.cancelCharge !== "undefined"
                                      ? parseFloat(item.cancelCharge).toFixed(
                                          "2"
                                        )
                                      : "0"}{" "}
                                    & S$
                                    {item.amendCharge !== "" &&
                                    typeof item.amendCharge !== undefined &&
                                    typeof item.amendCharge !== "undefined"
                                      ? parseFloat(item.amendCharge).toFixed(
                                          "2"
                                        )
                                      : "0"}
                                  </p>
                                </>
                              )}
                          </div>
                        );
                      } else if (index === 2) {
                        return (
                          <div>
                            <h5>After {item.fromDateDetails}</h5>
                            {item.amendRestricted === "true" &&
                              item.cancelRestricted === "true" && (
                                <p>Non refundable</p>
                              )}
                            {item.amendRestricted === "true" &&
                              item.cancelRestricted === "false" && (
                                <p>
                                  Cancellation charge is S$
                                  {item.cancelCharge !== ""
                                    ? parseFloat(item.cancelCharge).toFixed("2")
                                    : "0"}
                                </p>
                              )}
                            {item.amendRestricted === "false" &&
                              item.cancelRestricted === "true" && (
                                <p>
                                  Amendmentable charge is S$
                                  {item.amendCharge !== ""
                                    ? parseFloat(item.amendCharge).toFixed("2")
                                    : "0"}
                                </p>
                              )}
                          </div>
                        );
                      } else if (index === 3 && item.noShowPolicy === "true") {
                        return (
                          <div>
                            <h5>No show policy</h5>
                            <p>
                              No show charge is S$
                              {item.charge !== ""
                                ? parseFloat(item.charge).toFixed("2")
                                : "0"}{" "}
                            </p>
                          </div>
                        );
                      }
                    })()}
                  </div>
                );
              })}
            {this.state.tariff_notes !== "" && (
              <>
                <br />
                <h4>Hotel important infromation</h4>
                {Parser(this.state.tariff_notes)}
              </>
            )}
          </div>
        </div>

        <div
          id="hotel_honeymoon_popup"
          className="mfp-hide common-popup hotel-moredetails-popup"
        >
          <div className="cp-header textcenter">
            <h3>
              {this.state.room_special.special_type !== "" &&
                this.state.room_special.special_type}
            </h3>
            <a className="date-package-popup" href={void 0}>
              <img src={close} />
            </a>
          </div>
          <div className="cp-body">
            {Object.keys(this.state.room_special).length > 0 && (
              <div>
                <div>
                  <h4>{this.state.room_special.special_name}</h4>

                  {this.state.room_special.description !== "" &&
                  this.state.room_special.description !== null
                    ? Parser(
                        '<ul class="honeymoon-list"><li>' +
                          this.state.room_special.description.replace(
                            "\n-",
                            "</li><li>"
                          ) +
                          "</li></ul>"
                      )
                    : ""}
                </div>

                {this.state.room_special.condition !== "" &&
                  this.state.room_special.condition !== null && (
                    <div>
                      <h4>Condition</h4>
                      <ul className="honeymoon-list">
                        <li>{this.state.room_special.condition}</li>
                      </ul>
                    </div>
                  )}
                {this.state.room_special.notes !== "" &&
                  this.state.room_special.notes !== null && (
                    <div>
                      <h4>Notes</h4>
                      <ul className="honeymoon-list">
                        <li>{this.state.room_special.notes}</li>
                      </ul>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
        <div id="roomerror" className="mfp-hide common-popup roomerror">
          <div className="cp-header textcenter">
            <h3>Room Capacity exceeded</h3>
            <a className="date-package-popup" href={void 0}>
              <img src={close} />
            </a>
          </div>
          <div className="cp-body textcenter">
            The selected room cannot accommodate all the <br />
            travelers. Please select additional rooms.
          </div>
        </div>

        <Footer {...this.props} />
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row">
                <div className="col-1">
                  <div className="title">
                    Total stay <span>fare</span>
                  </div>
                  <div className="price">
                    {this.state.grand_total !== ""
                      ? "" +
                        showPriceValue(
                          this.state.grand_total,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )
                      : "-"}
                  </div>

                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>
                </div>
                <div className="col-2">
                  <Button
                    className="action"
                    onClick={this.continueRoom.bind(this)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              <li>
                <span className="service-content">
                  <strong>Room Price</strong>
                </span>
                <span className="service-amount">
                  <strong>
                    {this.state.sub_total !== ""
                      ? "" +
                        showPriceValue(
                          this.state.sub_total,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )
                      : "-"}
                  </strong>
                </span>
              </li>
            </ul>
            <Divider />
            <div className="details">
              <div className="title">
                Total <span>fare</span>
              </div>
              <div className="col-2">
                <div className="price">
                  {this.state.grand_total !== ""
                    ? "" +
                      showPriceValue(
                        this.state.grand_total,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )
                    : "-"}
                </div>
              </div>
            </div>
            <div className="booknow-btm two-btn-merge">
              <a
                href={void 0}
                onClick={this.goback.bind(this)}
                className="button ghost-button"
              >
                Back
              </a>
              <a
                className="button check_room_avail"
                href={void 0}
                onClick={this.continueRoom.bind(this)}
              >
                Continue
              </a>
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }
}

export default Customerstaydetail;
