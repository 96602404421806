/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import cookie from "react-cookies";
import Axios from "axios";
import { apiUrl, awsCredentials } from "../../Helpers/Config";
import { hideLoader, showAlert } from "../../Helpers/SettingHelper";
import profileeditImg from "../../../common/images/edit-pen.svg";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import SideBarCreator from "../SideBarCreator/SideBarCreator";
import {
  GET_CREATOR_DETAIL,
  GET_UPDATECREATORPROFILE,
  GET_UPLOAD_FILES,
} from "../../../actions";
import CreatorForm from "../CreatorForm";
import Topmenu from "../../Layout/Topmenu";
import Avatar from "react-avatar";
var qs = require("qs");
var base64 = require("base-64");

class Listpromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creatordetail: "",
      fields: {
        creator_name: "",
        email: "",
        phone: "",
        password: "",
        gender: "",
        phone_country: "",
        phone_code: "",
      },
      imageurl: "",
      editprofile: false,
      uploadfiles: "",
    };
    if (cookie.load("CUserId") == "" || cookie.load("CUserId") == undefined) {
      props.history.push("/");
    }
    var params = "creator_id=" + base64.encode(cookie.load("CUserId"));
    this.props.getCreatorDetail(params);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uploadfiles.status === "ok") {
    if (
      this.state.imageurl !== nextProps.uploadfiles.web &&
      typeof nextProps.uploadfiles.web !== undefined &&
      typeof nextProps.uploadfiles.web !== "undefined"
    ) {
      console.log(this.state.imageurl, nextProps.uploadfiles.web, "images");
      this.setState({ imageurl: nextProps.uploadfiles.web }, function () {
        if (this.state.imageurl) {
          var formData = new FormData();
          formData.append("creator_image", this.state.imageurl);

          formData.append("creator_id", cookie.load("CUserId"));

          Axios.post(
            apiUrl + "creators/updateprofileimage",
            formData,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
          ).then((res) => {
            hideLoader("myaccount_update", "class");
            if (res.data.status === "ok") {
              showAlert("Success", res.data.message);
              $("#profile_image").val("");
              this.setState({ imageurl: res.data.imageurl });
             /*  $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              }); */
            } else {
              var errormsg =
                res.data.form_error !== ""
                  ? res.data.form_error
                  : res.data.message;
              showAlert("Error", errormsg);
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
            }
          });
        }
      });
    }
    }
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      /*  this.setState({
        creatordetail: creatorDetails,
      }); */
      var genderLable = {};
      if (creatorDetails.creator_gender == "M") {
        genderLable = { value: "M", label: "Male" };
      } else if (creatorDetails.creator_gender == "F") {
        genderLable = { value: "M", label: "Female" };
      } else if (creatorDetails.creator_gender == "O") {
        genderLable = { value: "O", label: "Others" };
      }
      this.setState(
        update(this.state, {
          creatordetail: {
            $set: creatorDetails,
          },
          imageurl: {
            $set:
              creatorDetails.creator_image !== null
                ? creatorDetails.creator_image
                : "",
          },
          fields: {
            creator_name: {
              $set:
                creatorDetails.creator_name !== null
                  ? creatorDetails.creator_name
                  : "",
            },
            email: {
              $set:
                creatorDetails.creator_email !== null
                  ? creatorDetails.creator_email
                  : "",
            },
            phone: {
              $set:
                creatorDetails.creator_phone !== null
                  ? creatorDetails.creator_phone_code +
                    " " +
                    creatorDetails.creator_phone
                  : "",
            },
            gender: {
              $set: genderLable,
            },
            phone_country: {
              $set:
                creatorDetails.creator_phone_country !== null
                  ? creatorDetails.creator_phone_country
                  : "",
            },
            phone_code: {
              $set:
                creatorDetails.creator_phone_code !== null
                  ? creatorDetails.creator_phone_code
                  : "",
            },
          },
        })
      );
    }
    if (nextProps.updatecreatorprofile !== this.props.updatecreatorprofile) {
      const { updatecreatorprofile } = nextProps;
      if (updatecreatorprofile[0].status === "ok") {
        var params = "creator_id=" + base64.encode(cookie.load("CUserId"));
        this.props.getCreatorDetail(params);
        $(".myaccount_update .gloading_img").remove();
        showAlert("success", updatecreatorprofile[0].message);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        $(".myaccount_update .gloading_img").remove();
        if (updatecreatorprofile[0].form_error) {
          showAlert("error", updatecreatorprofile[0].form_error);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          showAlert("error", updatecreatorprofile[0].message);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      }
    }
  }
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };
  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");

      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    var postObject = {
      creator_name: formPayload.creator_name,
      creator_phone: mobileNo,
      creator_phone_code: mobile_code,
      creator_countryCode: $("#profilecountryCode").val(),
      creator_email: formPayload.email,
      creator_gender: formPayload.gender.value,
      creator_password: formPayload.password !== "" ? formPayload.password : "",
      creator_id: cookie.load("CUserId"),
    };
    console.log(formPayload, postObject, "formPayload");
    // return false;
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    /* this.props.getUpdateCreatorProfile(qs.stringify(postObject)); */
  };

  openPopup(popupID) {
    this.setState({ editprofile: true }, function () {
      $.magnificPopup.open({
        items: {
          src: popupID,
        },
        type: "inline",
        midClick: true,
      });
    });
  }
  async uplaodFiles() {
    var formData = new FormData();
    var imagefile = document.querySelector("#profile_image");
    const file = imagefile.files[0];
    const formPayload = this.state.fields;
    var mobileNo = "";
    var mobile_code = "";
    if (formPayload.phone !== "" && formPayload.phone !== null) {
      var mobile = formPayload.phone.split(" ");
      var newMobile = "";
      if (mobile.length > 0) {
        mobile.map((item, index) => {
          if (index !== 0) {
            newMobile += item;
          }
        });
      }
      mobileNo = newMobile.replace("-", "");
      mobile_code = mobile[0];
    }
    var postObject = {
      creator_name: formPayload.creator_name,
      creator_phone: mobileNo,
      creator_phone_code: mobile_code,
      creator_countryCode: $("#profilecountryCode").val(),
      creator_email: formPayload.email,
      creator_gender: formPayload.gender.value,
      creator_password: formPayload.password !== "" ? formPayload.password : "",
      /*   creator_id: cookie.load("CUserId"), */
      creator_photo: file,
    };
    console.log(formPayload, postObject, "formPayload");
    // return false;
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    this.props.getUpdateCreatorProfile(qs.stringify(postObject));
    var formData = new FormData();
    if (imagefile.files.length > 0) {
      formData.append("file", file);
      this.props.GetUploadFiles(formData);
    }
  }
  handleRemoveImage = async () => {
    var file = this.state.imageurl;
    try {
      if (!file) {
        console.log("No image to delete.");
        return;
      }
      console.log(file, "creator image");
      const deleteParams = {
        Bucket: "fizotametaimages",
        Key: `${cookie.load("CUserId")}/${file.split("/").pop()}`,
      };
      const s3 = new AWS.S3();
      s3.deleteObject(deleteParams).promise();

      console.log("Image removed from S3");
    } catch (err) {
      console.error("Error removing image from S3:", err);
    }
    var postObject = {
      creator_image: this.state.imageurl,
      creator_id: cookie.load("CUserId"),
    };
    console.log(this.state.imageurl, "postobject");
    Axios.post(
      apiUrl + "creators/removeProfileImage",
      qs.stringify(postObject),
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }
    ).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ imageurl: "", showRemoveButton: false });
        console.log("Image removed from the backend");
      }
    });
    this.setState({ imageurl: "", showRemoveButton: false });
  };
  handleEditProfile = () => {
    this.props.history.push("/myaccount/creatordetails-edit");
  };
  handleNameClick = () => {
    var cid = this.state.creatordetail.creator_id;
    this.props.history.push("/public_profile", cid);
  };

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };
  render() {
    return (
      <>

        {Object.keys(this.state.creatordetail) > 0 !== "" && (
          <section className="innersection">
            <div className="container">
              <div className="admin-profile creator-profile">
                <SideBarCreator pageName="account" />
                <div className="admin-profile-rhs">
                  <div className="profile-detail-innerdiv">
                    <div className="profile-detail-left">
                      <div className="profile-imgdiv">
                        <a href={void 0}>
                          {this.state.imageurl !== "" &&
                          this.state.imageurl !== null ? (
                            <img src={this.state.imageurl} alt="" />
                          ) : (
                            <Avatar
                              name={this.state.creatordetail.creator_name}
                              size="150"
                              round={true}
                              color="#4258bf"
                            />
                          )}
                          <span>
                            <form
                              action="#"
                              method="post"
                              encType="multipart/form-data"
                            >
                              <input
                                type="file"
                                id="profile_image"
                                className="custom-file-input"
                                onChange={(event) => {
                                  this.uplaodFiles(event);
                                  return false;
                                }}
                              />
                            </form>
                          </span>
                        </a>
                      </div>
                      <h5>
                        <a
                          href="/public_profile"
                          onClick={this.handleNameClick}
                        >
                          <span>
                            {" "}
                            {this.state.creatordetail.creator_handle_name}
                          </span>
                        </a>
                      </h5>
                      <div className="cfie-rate">
                        <div className="cfie-rate-cre">Top rated creator</div>
                      </div>
                    </div>

                    {/** profile Detail Left End **/}

                    <div className="profile-detail-right">
                      <h3 className="color-header">
                        Creator <span>details</span>
                      </h3>
                      <ul>
                        <li>
                          <div className="profile-Infield">Name</div>
                          <div className="profile-Outfield">
                            {this.state.creatordetail.creator_name}
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">E-mail address</div>
                          <div className="profile-Outfield">
                            <a href={void 0}>
                              {this.state.creatordetail.creator_email}
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Gender</div>
                          <div className="profile-Outfield">
                            {(() => {
                              if (
                                this.state.creatordetail.creator_gender === "M"
                              ) {
                                return <span>Male</span>;
                              } else if (
                                this.state.creatordetail.creator_gender === "F"
                              ) {
                                return <span>Female</span>;
                              } else if (
                                this.state.creatordetail.creator_gender === "O"
                              ) {
                                return <span>Others</span>;
                              } else {
                                return <span>-</span>;
                              }
                            })()}
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Contact</div>
                          <div className="profile-Outfield">
                            <a href={void 0}>
                              {this.state.creatordetail.creator_phone}
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="profile-Infield">Password</div>
                          <div className="profile-Outfield">
                            <span> ........</span>
                          </div>
                        </li>
                      </ul>
                      <div className="profile-edtbtn">
                        <button
                          className="button"
                          /* onClick={this.openPopup.bind(
                            this,
                            "#editprofile-popup"
                          )} */
                          onClick={this.handleEditProfile}
                        >
                          <img src={profileeditImg} alt="edit-pen" /> Edit Details
                        </button>
                      </div>
                    </div>
                    {/** profile Detail Right End **/}
                  </div>

                  <div className="profile-detail-footer">
                    {/*  <p>
                      By deleting account you agree the{" "}
                      <a href="#">Term & Condition</a> and{" "}
                      <a href="#">privacy Policy</a>
                    </p>
                    <button type="Delete Account" className="button">
                      Delete Account
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div id="editprofile-popup" className="mfp-hide common-popup">
              <div className="cp-header">
                <h3>Your Profile</h3>
                <p>Update your profile below.</p>
              </div>
              {this.state.editprofile === true && (
                <CreatorForm
                  {...this.props}
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleFormSubmit}
                  onInvalid={() => console.log("Form invalid!")}
                />
              )}
            </div>
          </section>
        )}

        {/* <Footer {...this.props} /> */}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  var uploadfiles = Array();
  if (state.creatordetail && Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  if (Object.keys(state.uploadfiles).length > 0) {
    if (state.uploadfiles[0].status === "ok") {
      uploadfiles = state.uploadfiles[0];
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
    updatecreatorprofile: state.updatecreatorprofile,
    uploadfiles: uploadfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
    getUpdateCreatorProfile: (formPayload) => {
      dispatch({ type: GET_UPDATECREATORPROFILE, formPayload });
    },
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
