import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export function LeftArrow() {
  const visibility = React.useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      &nbsp;&nbsp;
      <ArrowBackIosIcon htmlColor="#4258bf" />
    </Arrow>
  );
}

export function RightArrow() {
  const visibility = React.useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => visibility.scrollNext()}>
      <ArrowForwardIosIcon htmlColor="#4258bf" />
    </Arrow>
  );
}

export function LeftArrow1() {
  const visibility = React.useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow1
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      &nbsp;&nbsp;
      <ArrowBackIosIcon htmlColor="#4258bf" />
    </Arrow1>
  );
}

export function RightArrow1() {
  const visibility = React.useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow1 disabled={isLastItemVisible} onClick={() => visibility.scrollNext()}>
      <ArrowForwardIosIcon htmlColor="#4258bf" />
    </Arrow1>
  );
}

export function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

function Arrow({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        right: "1%",
        zIndex: 1,
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        width: "50%",
        borderRadius: "50%",
        marginTop: "8px",
        background: "transparent",
        border: "none",
        boxShadow: "none",
        alignItems: "center",
      }}
    >
      {children}
    </button>
  );
}

function Arrow1({ children, disabled, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        right: "1%",
        zIndex: 1,
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        width: "50%",
        borderRadius: "50%",
        background: "transparent",
        border: "none",
        boxShadow: "none",
        alignItems: "center",
        height: 0,
        margin: 0
      }}
    >
      {children}
    </button>
  );
}
