/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import {
  GET_GOOGLECREATORLOGINDATA,
  SET_GOOGLECREATORLOGINDATA,
} from "../actions";
import { userapiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetGoogleLoginDataCreator = function* () {
  yield takeEvery(GET_GOOGLECREATORLOGINDATA, workerGetGoogleCreatorLoginData);
};

function* workerGetGoogleCreatorLoginData({ formPayload }) {
  try {
    const result = yield call(getGoogleCreatorLoginData, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_GOOGLECREATORLOGINDATA, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getGoogleCreatorLoginData(formPayload) {
  return Axios.post(
    userapiUrl + "creators/googleloginapp",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }
  );
}
