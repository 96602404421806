import React from "react";
import "./VideoPlayer.scss";
import { Dialog } from "@mui/material";
import ReactPlayer from "react-player/lazy";

const VideoPlayer = (props) => {
  const { handleClose, open } = props;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="video-player-dialog-main"
    >
      <div className="video-player-dialog-main__content">
        <ReactPlayer
          url="https://youtu.be/f0kXtGMx-ls"
          controls
          width="100%"
          height="100%"
        />
      </div>
    </Dialog>
  );
};

export default VideoPlayer;
