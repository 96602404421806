import React from "react";
import "./Tabs.scss";
import flightDisabled from "../../../common/images/Flight-Disabled.svg";
import flightEnabled from "../../../common/images/Flight-Enabled.svg";
import VacationEnabled from "../../../common/images/Vacation-Enabled.svg";
import VacationDisabled from "../../../common/images/Vacation-Disabled.svg";
import TTDEnabled from "../../../common/images/TTD-Enabled.svg";
import TTDDisabled from "../../../common/images/TTD-Disabled.svg";
import TransferDisabled from "../../../common/images/Transfer-Disabled.svg";
import StayDisabled from "../../../common/images/Stay-Disabled.svg";

const Tabs = (props) => {
  const { selectedTab, setSelectedTab } = props;

  return (
    <div className="tab-row">
      <div
        className={`tab-row__tab-col ${
          selectedTab === "vacation" ? "selected" : ""
        }`}
        onClick={() => setSelectedTab("vacation")}
      >
        <img
          src={selectedTab === "vacation" ? VacationEnabled : VacationDisabled}
        />
        <div className="tab-row__tab-col__text">Vacation</div>
      </div>
      <div
        className={`tab-row__tab-col ${
          selectedTab === "flight" ? "selected" : ""
        }`}
        onClick={() => setSelectedTab("flight")}
      >
        <img src={selectedTab === "flight" ? flightEnabled : flightDisabled} />
        <div className="tab-row__tab-col__text">Flights</div>
      </div>
      <div
        className={`tab-row__tab-col ${
          selectedTab === "ttd" ? "selected" : ""
        }`}
        onClick={() => setSelectedTab("ttd")}
      >
        <img src={selectedTab === "ttd" ? TTDEnabled : TTDDisabled} />
        <div className="tab-row__tab-col__text">Things to do</div>
      </div>
      <div
        className={`tab-row__tab-col ${
          selectedTab === "stay" ? "selected" : ""
        }`}
      >
        <img src={selectedTab === "stay" ? StayDisabled : StayDisabled} />
        <div className="tab-row__tab-col__comingsoon">Coming soon</div>
        <div className="tab-row__tab-col__text">Stay</div>
      </div>
      <div
        className={`tab-row__tab-col ${
          selectedTab === "transfer" ? "selected" : ""
        }`}
      >
        <img
          src={selectedTab === "transfer" ? TransferDisabled : TransferDisabled}
        />
        <div className="tab-row__tab-col__comingsoon">Coming soon</div>
        <div className="tab-row__tab-col__text">Airport Transfer</div>
      </div>
    </div>
  );
};

export default Tabs;
