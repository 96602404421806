/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Axios from "axios";
import { apiUrl } from "../Helpers/Config";
import chain from "../../common/images/link-chain.svg";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import avholder from "../../common/images/placeholder.jpg";
import medal from "../../common/images/medal.svg";
import facebook from "../../common/images/facebook.svg";
import insta from "../../common/images/instagram.svg";
import ContentLoader from "react-content-loader";
import { showPriceValue } from "../Helpers/SettingHelper";
import { Country, State, City } from "country-state-city";
import { Link } from "react-router-dom";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CREATOR_DETAIL } from "../../actions";
var base64 = require("base-64");
var qs = require("qs");

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "props");
    this.state = {
      creatordetail: "",
      creatorHandleName: "",
      creatorType: "",
      creatorCountry: "",
      creator_name: "",
      creator_image: "",
      creator_coverimage: "",
      creator_instagram_link: "",
      creator_tiktok_link: "",
      creator_facebook_link: "",
      creator_website_link: "",
      creator_Youtube_link: "",
      creator_packages: [],
      flagUrl: "",
      creator_description: "",
      packageLoading: true,
    };
  }

  async componentDidMount() {
    var cid = base64.encode(this.props.location.state.cid);
    try {
      const response = await Axios.get(
        `${apiUrl}creators/creatordetail?creator_id=${cid}`,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );

      if (response.data.status === "ok") {
        const { result_set } = response.data;
        console.log("profile");

        this.setState({
          creatorHandleName: result_set.creator_handle_name,
          creator_name: result_set.creator_name,
          creatorType: result_set.creator_type,
          creatorCountry: result_set.creator_country,
          creator_image: result_set.creator_image,
          creator_coverimage: result_set.creator_coverimage,
          creator_instagram_link: result_set.creator_instagram_link,
          creator_tiktok_link: result_set.creator_tiktok_link,
          creator_facebook_link: result_set.creator_facebook_link,
          creator_website_link: result_set.creator_website_link,
          creator_Youtube_link: result_set.creator_Youtube_link,
          creator_description: result_set.creator_description,
        });

        const countryName = result_set.creator_country;
        this.fetchFlag(countryName);
      } else {
        console.log("creator details failed in mount");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    var postObject = {
      creator_id: base64.encode(this.props.location.state.cid),
      no_of_people: JSON.stringify([{ people: "Adult", quantity: 1 }]),
      trip_date: "2023-12-05",
    };

    try {
      const response = await Axios.post(
        `${apiUrl}package/searchProfilePackage?`,
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );

      if (response.data.status === "ok") {
        const { result_set } = response.data;

        this.setState({
          packageLoading: false,
          creator_packages: result_set.packages,
        });
      } else {
        console.log("creator details failed in mount");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async fetchFlag(countryName) {
    try {
      const response = await Axios(
        `https://restcountries.com/v3.1/name/${countryName}`
      );
      const data = response.data;
      const country = data[0];

      if (country && country.flags) {
        const flagUrl = country.flags.svg;
        this.setState({ flagUrl });

        console.log("Flag URL:", flagUrl);
      } else {
        console.error("Country not found or no flag data available.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
  }
  /* var trendoption = {
    items: 2,
    loop: true,
    dots: false,
    nav: false,
    margin: 40,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      580: {
        items: 2,
        margin: 30,
      },
      980: {
        margin: 40,
      },
    },
  }; */

  viewPackage(item) {
    var packagedetails = {
      searchdata: this.props.location.state.searchdata,
      hotel_check_in_time: this.props.location.state.hotel_check_in_time,
      creator_package_total_days:
        this.props.location.state.creator_package_total_days,
    };
    console.log(packagedetails, "props");
    this.props.history.push({
      pathname: "/search-package/" + item.creator_package_slug,
      state: packagedetails,
    });
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    var creator_packages = this.state.creator_packages;
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="gradient-pro">
          {/* <img src={} alt="Image" /> */}
        </section>
        <section className="innersection pro-spacer">
          <div className="container">
            <div className="traveler-pinfo">
              <div className="traveler-pinfo-lhs">
                <div className="circle-pro-img">
                  <img src={this.state.creator_image} alt="Image" />
                </div>
                <div className="pr-main-info">
                  <h3>{this.state.creatorHandleName}</h3>
                  <span>
                    <img src={this.state.flagUrl} alt="Image" />{" "}
                    {this.state.creatorCountry} | {this.state.creatorType}
                  </span>
                </div>
              </div>
              <div className="traveler-pinfo-rhs-full">
                <div className="about-profiler">
                  <h5>About</h5>
                  <p>{this.state.creator_description}</p>
                </div>
              </div>
            </div>

            <div className="traveler-main-package">
              <h4 className="color-header">
                {this.state.creatorHandleName}'s <span>Packages</span>
              </h4>
              {this.state.packageLoading === true ? (
                <div className="search-loader">
                  {Array(1, 2, 3).map((item, index) => {
                    return (
                      <ContentLoader
                        viewBox="0 0 380 280"
                        height={280}
                        width={380}
                        speed={3}
                        key={index}
                      >
                        <rect
                          x="3"
                          y="3"
                          rx="10"
                          ry="10"
                          width="380"
                          height="180"
                        />
                        <rect
                          x="6"
                          y="190"
                          rx="0"
                          ry="0"
                          width="380"
                          height="20"
                        />
                        <rect
                          x="4"
                          y="215"
                          rx="0"
                          ry="0"
                          width="320"
                          height="20"
                        />
                        <rect
                          x="4"
                          y="242"
                          rx="0"
                          ry="0"
                          width="380"
                          height="20"
                        />
                      </ContentLoader>
                    );
                  })}
                </div>
              ) : (
                <div>
                  {creator_packages.length > 0 ? (
                    <div className="traveler-main-package-list">
                      <ul>
                        {creator_packages.map((item, index) => {
                          return (
                            <li key={index}>
                              <div
                                className="product-list-parent"
                                // onClick={
                                //   this.state.isMobile && this.viewPackage.bind(this, item)
                                // }
                                onClick={this.viewPackage.bind(this, item)}
                              >
                                <div className="product-img">
                                  <a href={void 0} className="like">
                                    <img src={heart} alt="heart" />{" "}
                                  </a>
                                  <a
                                    className="pt-img"
                                    href={void 0}
                                    //  onClick={this.viewPackage.bind(this, item)}
                                  >
                                    <img
                                      src={
                                        creator_packages[index]
                                          .creator_package_thumbnail !== "" &&
                                        creator_packages[index]
                                          .creator_package_thumbnail !==
                                          undefined
                                          ? creator_packages[index]
                                              .creator_package_thumbnail
                                          : actinfo1
                                      }
                                      alt=""
                                    />
                                  </a>
                                  <div className="ptd-day">
                                    {" "}
                                    {
                                      creator_packages[index]
                                        .creator_package_total_days
                                    }{" "}
                                    Days
                                  </div>
                                  <div className="new-tags">
                                    <div className="c-tags">Couple</div>
                                    <div className="e-tags">Entertainment</div>
                                  </div>
                                </div>
                                <div className="product-description">
                                  <div className="product-description-title">
                                    <h5>
                                      <a
                                        href={void 0}
                                        //  onClick={this.viewPackage.bind(this, item)}
                                      >
                                        {
                                          creator_packages[index]
                                            .creator_package_name
                                        }
                                      </a>{" "}
                                    </h5>
                                  </div>
                                  <div className="product-created-content">
                                    <div className="created-av">
                                      <img
                                        src={
                                          this.state.creator_image !== "" &&
                                          this.state.creator_image !== null
                                            ? this.state.creator_image
                                            : avholder
                                        }
                                        alt={item.creator_image}
                                      />
                                    </div>
                                    <div className="created-av-title">
                                      <h4>
                                        <span>Created by</span>
                                        {this.state.creator_name}{" "}
                                      </h4>
                                      <div className="creator-badge">
                                        <span>
                                          <img src={medal} alt="Medal" />
                                        </span>{" "}
                                        <strong>
                                          Level <em>01</em>
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sites-content">
                                    <ul>
                                      <li className="sc1">
                                        <figure>
                                          <img src={flight} alt="flight" />
                                        </figure>
                                        <figcaption>Flight (add on)</figcaption>
                                      </li>
                                      <li className="sc4">
                                        <figure>
                                          {" "}
                                          <img src={car} alt="car" />{" "}
                                        </figure>
                                        <figcaption>
                                          Transfer (add on)
                                        </figcaption>
                                      </li>
                                      {/* {item.stay.length > 0 && ( */}
                                      <li className="sc2">
                                        <figure>
                                          <img src={hotel} alt="hotel" />
                                        </figure>
                                        <figcaption> hotel options</figcaption>
                                      </li>
                                      {/* )} */}

                                      {/* {item.total_activities > 0 && ( */}
                                      <li className="sc3">
                                        <figure>
                                          <img src={ticketact} alt="ticket" />
                                        </figure>
                                        <figcaption>Things to do</figcaption>
                                      </li>
                                      {/* )} */}
                                    </ul>
                                  </div>
                                  <div className="product-description-review">
                                    <div className="pdr-lhs">
                                      {creator_packages[index]
                                        .creator_package_rating !== "" &&
                                        creator_packages[index]
                                          .creator_package_rating !== null && (
                                          <span>
                                            <strong>
                                              {
                                                creator_packages[index]
                                                  .creator_package_rating
                                              }
                                            </strong>{" "}
                                            (
                                            {
                                              creator_packages[index]
                                                .creator_package_total_rating
                                            }
                                            ) Reviews
                                          </span>
                                        )}
                                      <div className="star-rating">
                                        {/* {this.loadRatingstar(item.creator_package_rating)} */}
                                      </div>
                                    </div>
                                    <div className="pdr-rhs">
                                      <em>Starts from </em>
                                      <strong>
                                        {item.creator_package_price === null
                                          ? showPriceValue(0)
                                          : showPriceValue(
                                              item.creator_package_price
                                            )}

                                        <span> Per Adult</span>
                                      </strong>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <p>No packages found </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
