/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_SEARCHFLIGHT, SET_SEARCHFLIGHT } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import { showAlert } from "../components/Helpers/SettingHelper";
export const watchGetSearchFlight = function* () {
  yield takeEvery(GET_SEARCHFLIGHT, workerGetSearchFlight);
};

function* workerGetSearchFlight({ formPayload }) {
  try {
    if (formPayload?.resetData === "Yes") {
      var resultArr = [];
    } else {
      const result = yield call(getSearchFlight, formPayload);
      var resultArr = [];
      resultArr.push(result.data);
    }
    yield put({ type: SET_SEARCHFLIGHT, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getSearchFlight(formPayload) {
  return Axios.post(apiUrl + "package/searchflight", formPayload, {
    headers: {
      Authorization: cookie.load("acccesstoken"),
    }
  });
}
