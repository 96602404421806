/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import bprofile from "../../common/images/banner-profile.svg";
import bcal from "../../common/images/calender-banner.svg";
import searchlight from "../../common/images/search-light.svg";
import wifiicon from "../../common/images/wifi-icon.svg";
import parkicon from "../../common/images/park-icon.svg";
import baricon from "../../common/images/bar-icon.svg";
import discounticon from "../../common/images/discount-icon.svg";
import stickclose from "../../common/images/stick-close.svg";
import dollarblue from "../../common/images/dollar1.svg";
import stario from "../../common/images/ios-star.svg";
import amenities from "../../common/images/amenities.svg";
import close from "../../common/images/close-icon.svg";
import star from "../../common/images/star-icon.svg";
import halfstar from "../../common/images/starhalf-icon.png";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl } from "../Helpers/Config";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import {
  stripslashes,
  showAlert,
  showLoader,
  hideLoader,
  loadRatingNumber,
} from "../Helpers/SettingHelper";
var qs = require("qs");
import { format, add } from "date-fns";
class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props(CreatorStay)");
    var packageID = "";
    if (this.props.match.params.packageID !== "") {
      packageID = this.props.match.params.packageID;
    }
    var country = "";
    if (this.props.match.params.country !== "") {
      country = this.props.match.params.country;
    }
    var city = "";
    if (this.props.match.params.city !== "") {
      city = this.props.match.params.city;
    }

    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    }
    var searchResult = "";
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== undefined &&
      this.props.location.state !== "undefined" &&
      this.props.location.state.searchResult !== "" &&
      this.props.location.state.searchResult !== undefined &&
      this.props.location.state.searchResult !== "undefined"
    ) {
      searchResult = this.props.location.state.searchResult;
    }
    var webbeds_city_code = "";
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== undefined &&
      this.props.location.state !== "undefined" &&
      this.props.location.state.webbeds_city_code !== "" &&
      this.props.location.state.webbeds_city_code !== undefined &&
      this.props.location.state.webbeds_city_code !== "undefined"
    ) {
      webbeds_city_code = this.props.location.state.webbeds_city_code;
    }

    var newpackage = "";
    if (
      this.props.location.state.newpackage !== "" &&
      typeof this.props.location.state.newpackage !== undefined &&
      typeof this.props.location.state.newpackage !== "undefined"
    ) {
      newpackage = this.props.location.state.newpackage;
    }

    this.state = {
      CUserId: CUserId,
      packageID: packageID,
      country: country,
      city: city,
      searchResult: searchResult,
      webbeds_city_code: webbeds_city_code,
      displayHotel: "",
      loadingHotel: true,
      selectedHotelIDs: [],
      initialPackageStay: true,
      selectedHotelList: [],
      amenities_List: [],
      checkboxStates: {},
      selectedrating: "",
      filteredHotels: [],
      pricelist: [],
      amenitiesFilter: [],
      showAmenitiesPopup: false,
      startPrice: "",
      endPrice: "",
      displayTotalHotel: 0,
      minValue: "",
      maxValue: "",
      searchInput: "",
      initialHotels: [],
      suggestions: [],
      filteredHotels: [],
      isSuggestionsOpen: false,

      newpackage: newpackage,
    };
    this.suggestionsRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);

    // if (this.state.searchResult !== "") {
    //   this.displayHotelList();
    // } else {
    this.loadHotel();
    // }

    /* var currentThis = this;
console.log(currentThis, "current this");
$(function () {
$("#rang").ionRangeSlider({
min: currentThis.state.startPrice,
max: currentThis.state.endPrice,
from: currentThis.state.startPrice,
to: currentThis.state.endPrice,
type: "double",
grid_num: 10,
onFinish: function (data) {
currentThis.setState(
{ startPrice: data.from, endPrice: data.to },
function () {
this.displayHotelList();
}
);
},
});
$("#rangmobile").ionRangeSlider({
min: currentThis.state.startPrice,
max: currentThis.state.endPrice,
from: currentThis.state.startPrice,
to: currentThis.state.endPrice,
type: "double",
grid_num: 10,
onFinish: function (data) {
currentThis.setState(
{ startPrice: data.from, endPrice: data.to },
function () {
this.displayHotelList();
}
);
},
});
}); */
  }
  componentWillReceiveProps(nextProps) {}
  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }
  loaddayPackageStay() {
    this;
    axios
      .get(
        apiUrl +
          "package/packageStayList?package_id=" +
          this.state.packageID +
          "&creator_id=" +
          this.state.CUserId,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((res) => {
        var existHotel = [];
        var existHotelList = [];
        if (res.data.status === "ok") {
          existHotel = res.data.result_set;
          existHotelList = res.data.staylist;
        }
        this.setState(
          {
            selectedHotelIDs: existHotel,
            selectedHotelList: existHotelList,
          },
          function () {
            this.displayHotelList();
          }
        );
      });
  }
  loadHotel() {
    var todayDate = new Date();

    const startDate = format(
      add(new Date(), {
        months: 2,
      }),
      "yyyy-MM-dd"
    );
    const endDate = format(
      add(new Date(), {
        months: 2,
        days: 2,
      }),
      "yyyy-MM-dd"
    );

    console.log(startDate, endDate, "endDateendDate");
    var passengers = [];
    passengers.push({ adult: 1 });
    var postObject = {
      from_date: startDate,
      to_date: endDate,
      country: this.state.country,
      city: this.state.city,
      passenger_data: JSON.stringify(passengers),
      city_code: this.state.webbeds_city_code,
    };

    axios
      .post(
        apiUrl + "hotelbooking/searchFilterHotel",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        console.log(res.data, "res.data");
        if (res.data.status === "success") {
          this.setState({ loadingHotel: true });
          const pricelist = res.data.result.hotelInfo.map(
            (item) => item.room_charge
          );
          const minValue = Math.min(...pricelist);
          const maxValue = Math.max(...pricelist);
          this.setState(
            {
              searchResult: res.data.result.hotelInfo,
              initialHotels: res.data.result.hotelInfo,
              pricelist: pricelist,
              startPrice: minValue,
              endPrice: maxValue,
            },
            function () {
              var currentThis = this;
              $(function () {
                $("#rang, #rangmobile").ionRangeSlider({
                  min: currentThis.state.startPrice,
                  max: currentThis.state.endPrice,
                  from: currentThis.state.startPrice,
                  to: currentThis.state.endPrice,
                  type: "double",
                  grid_num: 10,
                  onFinish: function (data) {
                    currentThis.setState({ loadingHotel: true });
                    currentThis.setState(
                      { startPrice: data.from, endPrice: data.to },
                      function () {
                        this.displayHotelList();
                      }
                    );
                  },
                });
              });
              var amenitiesList = [];
              if (this.state.searchResult.length > 0) {
                this.state.searchResult.map((item) => {
                  if (
                    item.hotel_amenities !== "" &&
                    item.hotel_amenities !== null
                  ) {
                    item.hotel_amenities.split("#@#").map((amini) => {
                      amenitiesList.push(amini);
                    });
                  }
                });
              }
              console.log(this.state.searchResult, "searchresult");
              var amenities_List = [...new Set(amenitiesList)];
              this.setState({ amenities_List: amenities_List }, function () {
                var checkboxStates = {};
                amenities_List.forEach((amenity) => {
                  checkboxStates[amenity] = false;
                });
                this.setState({ checkboxStates }, function () {
                  this.displayHotelList();
                });
              });
            }
          );
        } else {
          this.setState({ loadingHotel: false });
        }
      })
      .catch((error) => {
        showAlert('Error', "Something went wrong, please try again later")
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        console.error("Error fetching data:", error);
      });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  displayHotelList() {
    var displayHotel = "";
    var displayTotalHotel = 0;
    if (this.state.searchResult.length > 0) {
      var displayHotel = this.state.searchResult.map((item, index) => {
        var checkFilter = this.checkFilter(item);
        var hotel_amenities = item.hotel_amenities
          ? item.hotel_amenities.split("#@#")
          : [];

        if (checkFilter === true) {
          displayTotalHotel++;
          return (
            <li
              key={index}
              className={
                this.state.selectedHotelIDs.indexOf(item.hotel_id) >= 0
                  ? "active"
                  : ""
              }
            >
              <div className="hsr-lhs">
                <div className="hsr-lhs-img">
                  <img
                    src={
                      item.thumbnail_image !== "" &&
                      item.thumbnail_image !== null
                        ? item.thumbnail_image
                        : "https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/no+hotel+image+found.jpg"
                    }
                    alt="no hotel image found"
                  />
                </div>
                <div className="hsr-lhs-desc">
                  <div className="star-rating-review">
                    <div className="star-rating">
                      {this.loadRatingstar(item.rating)}
                    </div>
                    <div className="sr-review">
                      <strong> {loadRatingNumber(item.rating)}</strong>
                    </div>
                  </div>
                  <h4>{stripslashes(item.hotel_name)}</h4>
                  <span>{stripslashes(item.address)}</span>
                  <ul>
                    {hotel_amenities
                      .map((amenity) => {
                        let icon = null;
                        let icon_limit = 0;
                        switch (amenity) {
                          case "High Speed Internet":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Car Parking - Onsite Paid":
                          case "Car Parking - Onsite Free":
                            icon = <img src={parkicon} alt="wifi" />;
                            break;
                          case "Bar":
                            icon = <img src={baricon} alt="wifi" />;
                            break;
                          /* case "Business Centre":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Elevators":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Restaurant":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Room Service - 24 Hours":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break; */
                          default:
                            break;
                        }
                        return icon;
                      })
                      .filter((icon, index) => index < 8)
                      .map((icon, index) => icon && <li>{icon}</li>)}
                  </ul>
                  {/* <div className="free-box">
                    Free Cancellation till check-in
                  </div> */}
                </div>
              </div>
              <div className="hsr-rhs">
                {/* <a href={void 0} className="like">
                  {" "}
                  <img src={heart} alt="" /> <img src={heartfill} alt="" />
                </a> */}
                {/* <figure>
                  <img src={discounticon} alt="" /> 30% offer
                </figure> */}
                <p>
                  ${item.room_charge} <span>total</span>
                </p>
                <a
                  href={void 0}
                  onClick={this.selectHotel.bind(
                    this,
                    item,
                    item.hotel_id,
                    index
                  )}
                  className={
                    this.state.selectedHotelIDs.indexOf(item.hotel_id) >= 0
                      ? "button ghost-button remove-btn"
                      : "button"
                  }
                >
                  {this.state.selectedHotelIDs.indexOf(item.hotel_id) >= 0
                    ? "Remove"
                    : "Add"}
                </a>
                <a
                  href={void 0}
                  onClick={this.viewHotel.bind(
                    this,
                    item,
                    item.hotel_id,
                    index
                  )}
                  className="button ghost-button"
                >
                  View
                </a>
              </div>
            </li>
          );
        }
      });
    }
    var initialPackageStay = this.state.initialPackageStay;
    this.setState(
      {
        displayHotel: displayHotel,
        /* loadingHotel: false, */
        initialPackageStay: false,
        displayTotalHotel: displayTotalHotel,
      },
      function () {
        if (initialPackageStay === true) {
          this.loaddayPackageStay();
        }
      }
    );
  }
  showAmenitiesPopup = () => {
    this.setState({ showAmenitiesPopup: true });
  };
  handleInputClick = () => {
    this.setState({ isSuggestionsOpen: true });
  };
  handleSearchChange = (event) => {
    const searchInput = event.target.value;
    console.log(searchInput, "search input");

    const { initialHotels } = this.state;
    const filteredSuggestions = initialHotels.filter((item) => {
      const hotelName = item.hotel_name.toLowerCase();
      const address = item.address.toLowerCase();
      const thumbnailImage = item.thumbnail_image;
      const room_charge = item.room_charge;
      const inputValue = searchInput.toLowerCase();
      return hotelName.includes(inputValue) || address.includes(inputValue);
    });
    this.setState(
      {
        searchInput,
        suggestions: filteredSuggestions,
        searchResult: this.state.initialHotels,
        isSuggestionsOpen: true,
      },
      this.displayHotelList
    );
  };
  handleSearchClick = (suggestion) => {
    var { searchInput } = this.state;
    if (searchInput !== "") {
      var filteredHotels = this.state.searchResult.filter((hotel) => {
        return (
          hotel.hotel_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          hotel.address.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      this.setState(
        { searchResult: filteredHotels, searchInput: suggestion.hotel_name },
        () => {
          this.displayHotelList();
        }
      );
    } else {
      this.setState(
        { searchResult: this.state.initialHotels, searchInput },
        () => {
          console.log(this.state.searchResult, "search Result");
          this.displayHotelList();
        }
      );
    }
    this.setState(
      {
        suggestions: [],
      },
      () => {
        console.log(this.state.searchResult, "search Result");
        this.displayHotelList();
      }
    );
  };

  checkFilter(item) {
    var amintiFli = true;
    if (this.state.amenitiesFilter.length > 0) {
      if (Object.values(this.state.checkboxStates).every((x) => x === false)) {
        return true;
      }
      const selectedAmenities = Object.keys(this.state.checkboxStates).filter(
        (key) => this.state.checkboxStates[key]
      );
      var amenitiesList = item.hotel_amenities.split("#@#");
      if (
        selectedAmenities.some((amenity) => amenitiesList.includes(amenity))
      ) {
        amintiFli = false;
      }
    }
    var hotelPrice = true;
    if (this.state.startPrice > 0 && item.room_charge < this.state.startPrice) {
      hotelPrice = false;
    }
    if (this.state.endPrice > 0 && item.room_charge > this.state.endPrice) {
      hotelPrice = false;
    }
    var rating = true;
    if (this.state.selectedrating.length > 0) {
      if (this.state.selectedrating.indexOf(item.rating) >= 0) {
        rating = true;
      } else {
        rating = false;
      }
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
    if (amintiFli && hotelPrice && rating === true) {
      return console.log(true);
    } else {
      return false;
    }
  }

  viewHotel(item, hotel_id, index) {
    var path = this.props.location.pathname;
    this.props.history.push({
      pathname: `${path}/` + hotel_id,
      state: { searchResult: item },
    });
  }

  checkFilter(item) {
    const selectedAmenities = Object.keys(this.state.checkboxStates).filter(
      (key) => this.state.checkboxStates[key]
    );

    /*   if (
      selectedAmenities.length === 0 &&
      this.state.startPrice < 0 &&
      this.state.endPrice < 0 &&
      this.state.selectedrating.length === 0
    ) {
      console.log(true, "not selected");
      return true;
    } */

    var amenitiesList = item.hotel_amenities.split("#@#");
    var amintiFli = true;

    if (selectedAmenities.length > 0) {
      if (
        !selectedAmenities.some((amenity) => amenitiesList.includes(amenity))
      ) {
        amintiFli = false;
      }
    }
    var hotelPrice = true;
    if (
      Math.round(this.state.startPrice) > 0 &&
      Math.round(item.room_charge) < Math.round(this.state.startPrice)
    ) {
      hotelPrice = false;
    }
    if (
      Math.round(this.state.endPrice) > 0 &&
      Math.round(item.room_charge) > Math.round(this.state.endPrice)
    ) {
      hotelPrice = false;
    }
    var rating = true;
    if (this.state.selectedrating.length > 0) {
      if (this.state.selectedrating.indexOf(item.rating) >= 0) {
        rating = true;
      } else {
        rating = false;
      }
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
    if (amintiFli && hotelPrice && rating === true) {
      return true;
    } else {
      return false;
    }
  }

  filterByRating(rating) {
    this.setState({ loadingHotel: true });
    var selectedrating = "";
    if (
      this.state.selectedrating !== "" &&
      this.state.selectedrating === rating
    ) {
      selectedrating = "";
    } else {
      selectedrating = rating;
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);

    this.setState({ selectedrating: selectedrating }, function () {
      this.displayHotelList();
    });
  }
  resetFilter(filterType) {
    this.setState({ loadingHotel: true });

    if (filterType === "rating") {
      this.setState({ selectedrating: "" }, () => {
        this.displayHotelList();
      });
    } else if (filterType === "price") {
      this.setState({ startPrice: 0, endPrice: 0 }, () => {
        this.displayHotelList();
      });
    } else if (filterType === "amenities") {
      this.setState({ amenitiesFilter: [], checkboxStates: {} }, () => {
        this.displayHotelList();
      });
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
  }
  clearAllFilters() {
    const minPrice = Math.min(
      ...this.state.searchResult.map((item) => item.room_charge)
    );
    const maxPrice = Math.max(
      ...this.state.searchResult.map((item) => item.room_charge)
    );
    this.setState(
      {
        loadingHotel: true,
        selectedrating: "",
        startPrice: minPrice,
        endPrice: maxPrice,
        amenitiesFilter: [],
        checkboxStates: {},
      },
      () => {
        this.displayHotelList();
        const sliderElement = $("#rang");
        sliderElement.data("ionRangeSlider").update({
          from: minPrice,
          to: maxPrice,
        });
      }
    );
  }

  selectHotel(hotel, hotel_id, index) {
    var checkingselectedHotelIDs = this.state.selectedHotelIDs;
    var checkingselectedHotelList = this.state.selectedHotelList;
    var getindex = checkingselectedHotelIDs.indexOf(hotel_id);
    if (getindex >= 0) {
      checkingselectedHotelIDs.splice(getindex, 1);
      checkingselectedHotelList.splice(getindex, 1);
    } else {
      if (checkingselectedHotelIDs.length >= 3) {
        showAlert("Error", "Maximum 3 hotel only allow to add");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }
      checkingselectedHotelIDs.push(hotel_id);
      checkingselectedHotelList.push(hotel);
    }
    this.setState(
      {
        selectedHotelIDs: checkingselectedHotelIDs,
        selectedHotelList: checkingselectedHotelList,
      },
      function () {
        this.displayHotelList();
      }
    );
  }
  removeSelectedHotel(index) {
    var checkingselectedHotelIDs = this.state.selectedHotelIDs;
    var checkingselectedHotelList = this.state.selectedHotelList;
    checkingselectedHotelList.splice(index, 1);
    checkingselectedHotelIDs.splice(index, 1);
    this.setState(
      {
        selectedHotelList: checkingselectedHotelList,
        selectedHotelIDs: checkingselectedHotelIDs,
      },
      () => {
        this.displayHotelList();
      }
    );
  }

  loadRatingstar(points) {
    var ratingNumber = loadRatingNumber(points);
    var numStars = Math.floor(ratingNumber);
    var hasHalfStar = ratingNumber % 1 !== 0;
    var starImg = <img src={star} alt="star" />;
    var halfStarImg = <img src={halfstar} alt="halfstar" />;
    var fullStars = Array.from({ length: numStars }, () => starImg);
    // console.log(fullStars, "stars");
    var halfStar = hasHalfStar ? halfStarImg : null;
    return (
      <div>
        {fullStars}
        {halfStar}
      </div>
    );
  }

  continueStay() {
    if (this.state.selectedHotelIDs.length > 0) {
      showLoader("continue_stay", "class");
      var postObject = {
        hotel_id: JSON.stringify(this.state.selectedHotelIDs),
        package_id: this.state.packageID,
        creator_id: this.state.CUserId,
      };
      axios
        .post(
          apiUrl + "package/selecthotel",
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          hideLoader("continue_stay", "class");
          if (res.data.status === "ok") {
            let packageDetails = this.props?.location?.state || {};
            this.props.history.push({
              pathname: "/package-step/" + this.state.packageID,
              state: packageDetails,
            });
          }
        });
    } else {
      showAlert("Error", "Please select any one hotel");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  handleChange(type, event) {
    this.setState({ loadingHotel: true });
    if (type === "amenities") {
      const value = event.target.value;
      const checked = event.target.checked;
      const newCheckboxStates = { ...this.state.checkboxStates };
      newCheckboxStates[value] = checked;
      var amenitiesFilter = this.state.amenitiesFilter;
      if (amenitiesFilter.indexOf(event.target.value) >= 0) {
        amenitiesFilter.splice(event.target.value, 1);
      } else {
        amenitiesFilter.push(event.target.value);
      }
      setTimeout(() => {
        this.setState({ loadingHotel: true });
      }, 2000);
      this.setState(
        {
          amenitiesFilter: amenitiesFilter,
          checkboxStates: newCheckboxStates,
        },
        function () {
          this.displayHotelList();
        }
      );
    }
  }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");

    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  handleDocumentClick = (event) => {
    if (
      this.suggestionsRef.current &&
      this.state.isSuggestionsOpen &&
      !this.suggestionsRef.current.contains(event.target)
    ) {
      this.setState({ isSuggestionsOpen: false });
    }
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />

        <section className="innersection">
          <div className="container">
            <div className="header-back-continue">
              <div className="color-header cheader-with-text">
                <h3>
                  Choose <span>stay</span>
                </h3>
                <p>You can add upto 3 stay options</p>
              </div>
              <div className="hbc-rhs">
                <Link
                  className="button ghost-button continue_stay"
                  to={{
                    pathname: "/package-step/" + this.state.packageID,
                    state: this.props.location.state,
                  }}
                >
                  Back
                </Link>
                <a
                  href={void 0}
                  className="button continue_stay"
                  onClick={this.continueStay.bind(this)}
                >
                  Continue
                </a>
              </div>
            </div>

            <div className="sticky-options">
              <div className="sticky-options-lhs">
                {this.state.selectedHotelList.length > 0 && (
                  <ul>
                    {this.state.selectedHotelList.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="sop-img">
                            <img
                              src={
                                item.thumbnail_image !== "" &&
                                item.thumbnail_image !== null
                                  ? item.thumbnail_image
                                  : "https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/no+hotel+image+found+small.jpg"
                              }
                            />
                          </div>
                          <div className="sop-text">
                            <h5>
                              <a href={void 0}>{item.hotel_name}</a>
                            </h5>
                          </div>
                          <div className="remove-sticky">
                            {" "}
                            <img
                              src={stickclose}
                              onClick={() => this.removeSelectedHotel(index)}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            <div className="flight-way-search choose-stay ">
              <div className="flight-search rel">
                <div className="choose-loc">
                  <input
                    type="text"
                    placeholder="Enter your hotelname or address of hotel"
                    value={this.state.searchInput}
                    onChange={this.handleSearchChange}
                    onFocus={() => this.setState({ isSuggestionsOpen: true })}
                    onClick={this.handleInputClick}
                  />
                  {this.state.isSuggestionsOpen &&
                    this.state.suggestions.length > 0 &&
                    this.state.searchInput !== "" && (
                      <div ref={this.suggestionsRef}>
                        <ul className="suggestion-list active">
                          {this.state.suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => this.handleSearchClick(suggestion)}
                            >
                              <figure>
                                {" "}
                                <img src={suggestion.thumbnail_image} />{" "}
                              </figure>
                              <div className="search-show">
                                <div className="search-show-inner">
                                  <div className="search-show-product">
                                    {" "}
                                    <p> {suggestion.hotel_name}</p>{" "}
                                    <span>{suggestion.address}</span>
                                  </div>
                                  <div className="search-price">
                                    <strong>
                                      SGD ${suggestion.room_charge}{" "}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>

                {/* <div className="fs-stay">
                  <input type="text" placeholder="Check-In" />
                  <img src={bcal} />
                </div>
                <div className="fs-stay">
                  <input type="text" placeholder="Check-out" />
                  <img src={bcal} />
                </div>
                <div className="fs-whom">
                  <input type="text" placeholder="Members" />
                  <img src={bprofile} />
                </div> */}
                <button
                  type="button"
                  className="button fg-btn"
                  onClick={this.handleSearchClick}
                >
                  <img src={searchlight} />
                </button>
              </div>
            </div>

            <div className="outlet-listing-inner">
              <div className="outlet-listing-lhs">
                <h4>Filters</h4>
                {this.state.amenities_List.length > 0 && (
                  <div className="filter-option flight-option">
                    <h5
                      className="accordion-header"
                      onClick={this.toggleAccordion}
                    >
                      <img src={amenities} alt="amenities" />
                      Amenities
                    </h5>
                    <ul>
                      {this.state.amenities_List
                        .slice(0, 5)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                className="tag_filter"
                                value={item}
                                checked={this.state.checkboxStates[item]}
                                onChange={this.handleChange.bind(
                                  this,
                                  "amenities"
                                )}
                              />
                              <label>{item}</label>
                            </li>
                          );
                        })}
                      {this.state.amenities_List.length > 10 && (
                        <li>
                          <div className="clearall">
                            <a href={void 0} onClick={this.showAmenitiesPopup}>
                              See More
                            </a>
                          </div>
                        </li>
                      )}
                    </ul>
                    <a
                      href={void 0}
                      onClick={this.resetFilter.bind(this, "amenities")}
                      className="button ghost-button"
                    >
                      Reset
                    </a>
                  </div>
                )}

                <div className="filter-option pricerange-option">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={dollarblue} alt="dollar" />
                    Price range
                  </h5>
                  <p>
                    SGD ${Math.ceil(this.state.startPrice)} to SGD $
                    {Math.ceil(this.state.endPrice)}+
                  </p>
                  <div>
                    <input
                      type="text"
                      id="rang"
                      name="rang"
                      value=""
                      data-min={Math.round(this.state.startPrice)}
                      data-max={Math.round(this.state.endPrice)}
                      data-from={this.state.startPrice}
                      data-to={this.state.endPrice}
                      data-type="double"
                      data-prefix="$"
                      data-grid="false"
                      data-grid-num="10"
                    />
                  </div>
                  <a
                    href={void 0}
                    onClick={this.resetFilter.bind(this, "price")}
                    className="button ghost-button"
                  >
                    Reset
                  </a>
                </div>
                <div className="filter-option rating-option no-margin-btm">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={stario} alt="star" />
                    Rating
                  </h5>
                  <ul>
                    <li
                      className={
                        this.state.selectedrating === "Luxury *****"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Luxury *****")}
                      >
                        5 Excellent (28)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Superior ****"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(
                          this,
                          "Superior ****"
                        )}
                      >
                        4 Good (48)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Standard ***"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Standard ***")}
                      >
                        3 Okay (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Budget **"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Budget **")}
                      >
                        2 Poor (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Economy*" ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Economy*")}
                      >
                        1 Terrible (79)
                      </a>
                    </li>
                  </ul>
                  <a
                    href={void 0}
                    onClick={this.resetFilter.bind(this, "rating")}
                    className="button ghost-button"
                  >
                    Reset
                  </a>
                </div>
                <div className="clearall">
                  <a href={void 0} onClick={this.clearAllFilters.bind(this)}>
                    Clear all
                  </a>
                </div>
              </div>
              {this.state.showAmenitiesPopup && (
                <div className="popup-ame">
                  <div className="popup-inner-ame">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <h5>Amenities</h5>
                      <a
                        href={void 0}
                        onClick={() =>
                          this.setState({ showAmenitiesPopup: false })
                        }
                      >
                        <img src={close} />
                      </a>
                    </div>
                    <ul>
                      {this.state.amenities_List.map((item, index) => {
                        return (
                          <li key={index}>
                            <input
                              type="checkbox"
                              className="tag_filter"
                              value={item}
                              checked={this.state.checkboxStates[item]}
                              onChange={this.handleChange.bind(
                                this,
                                "amenities"
                              )}
                            />
                            <label>{item}</label>
                          </li>
                        );
                      })}
                    </ul>
                    <div>
                      <button
                        onClick={() =>
                          this.setState({ showAmenitiesPopup: false })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="outlet-listing-rhs flight-search-parent">
                <div className="search-number">
                  <span>Search results</span>
                  {this.state.loadingHotel === false && (
                    <span>
                      {this.state.displayTotalHotel} of{" "}
                      {this.state.searchResult.length} hotels
                    </span>
                  )}
                </div>
                <div className="hotel-search-result">
                  <ul>
                    {this.state.loadingHotel === true
                      ? Array(1, 2, 3).map((item) => {
                          return (
                            <li key={item}>
                              <ContentLoader
                                height={140}
                                speed={1}
                                viewBox="0 0 380 70"
                              >
                                <rect
                                  x="0"
                                  y="0"
                                  rx="5"
                                  ry="5"
                                  width="70"
                                  height="70"
                                />
                                <rect
                                  x="80"
                                  y="17"
                                  rx="4"
                                  ry="4"
                                  width="300"
                                  height="13"
                                />
                                <rect
                                  x="80"
                                  y="40"
                                  rx="3"
                                  ry="3"
                                  width="250"
                                  height="10"
                                />
                              </ContentLoader>
                            </li>
                          );
                        })
                      : this.state.displayHotel}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
