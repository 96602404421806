/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import "./BookingDetail.scss";
import { useHistory, useLocation } from "react-router-dom";
import backarrow from "../../../common/images/back-arrow-front.svg";
import { Button } from "@mui/material";
import "./BookingDetail.scss";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import Axios from "axios";
import { UopUrl, apiUrl } from "../../Helpers/Config";
import html2pdf from "html2pdf.js";
import ModalPopup from "../ModalPopup/ModalPopup";
import cookie from "react-cookies";
import logo from "../../../common/images/logo.svg";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let base64 = require("base-64");

const BookingDetail = (props) => {
  const location = useLocation();
  const propsValue = location.state;
  const pdfRef = useRef(null);
  console.log(props);
  let CUserId = "";
  if (
    cookie.load("CUserId") !== "" &&
    typeof cookie.load("CUserId") !== undefined &&
    typeof cookie.load("CUserId") !== "undefined"
  ) {
    CUserId = cookie.load("CUserId");
  } else {
    props.history.push("/");
  }
  const history = useHistory();
  const [navbar, setNavbar] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerVerify, setTriggerVerify] = useState(false);
  const [triggerCancel, setTriggerCancel] = useState(false);
  const [triggerContact, setTriggerContact] = useState(false);
  const [state, setState] = useState({
    password: "",
    cancReason: "",
    cancDetail: "",
    title: "",
    contactDetail: "",
  });
  const [creator, setCreator] = useState([]);
  const [packageDetails, setPackageDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [respMsg, setRespMsg] = useState("");
  const [viewItinerary, setViewItinerary] = useState(null);
  const queryParams = new URLSearchParams(history.location.search);

  const changeBackground = () => {
    if (window.scrollY > 0) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    fetchCreaterData();
    fetchPackageDetails();
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const fetchCreaterData = async () => {
    await Axios.get(
      `${apiUrl}dashboard/activity?creator_id=${CUserId}&days=7days`,
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }
    )
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data, "creator");
          setCreator(response.data.creator_details);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPackageDetails = async () => {
    await Axios.get(
      `${UopUrl}/booking/detail?id=${queryParams.get("id")}`,
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }
    )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setPackageDetails(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const goBack = () => {
    history.goBack();
  };

  const DownloadPDF = () => {
    try {
      setHidden(true);
      // const element = document.getElementById("pdf-content");

      var opt = {
        margin: 0.5,
        filename: "Booking-details.pdf",
        enableLinks: false,
        // pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] },
        pagebreak: { mode: "avoid-all" },
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      html2pdf()
        .from(pdfRef.current)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          //print current pdf width & height to console
          console.log("getHeight:" + pdf.internal.pageSize.getHeight());
          console.log("getWidth:" + pdf.internal.pageSize.getWidth());
          // for (var i = 1; i <= totalPages; i++) {
          //   // pdf.setPage(i);
          //   // pdf.setFontSize(10);
          //   // pdf.setTextColor(150);
          //   pdf.text(15, 15, "[SULIT]");
          //   // pdf.text('Tarikh Cetakan: ');
          //   //divided by 2 to go center
          //   // pdf.text(
          //   //   "Page " + i + " of " + totalPages,
          //   //   pdf.internal.pageSize.getWidth() / 2,
          //   //   pdf.internal.pageSize.getHeight() / 2
          //   // );
          // }
        })
        .save();
      // const doc = new jsPDF({
      //   orientation: "p",
      //   format: "a4",
      //   unit: "pt",
      // });

      // // Adding the fonts.
      // // doc.setFont("Arial");

      // doc.html(pdfRef.current, {
      //   html2canvas: {
      //     scale: 0.553,
      //   },
      //   callback: function (doc) {
      //     setTimeout(() => {
      //       doc.save("Booking_Details");
      //     }, 1000);
      //   },
      // });
      // Get the element to be converted to PDF (replace 'your-page-id' with the actual ID or class of your page)
      // const element = document.getElementById("pdf-content");

      // // Configuration for html2pdf
      // const options = {
      //   margin: 10,
      //   filename: "booking-details.pdf",
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: {},
      //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      //   header: {
      //     fontSize: 12,
      //     margin: [10, 10, 0, 0],
      //     height: "20mm", // Set header height
      //     contents: () => {
      //       return '<div style="text-align: center;">Header Content - Page </div>';
      //     },
      //   },
      //   footer: {
      //     fontSize: 12,
      //     margin: [10, 10, 0, 0],
      //     height: "15mm", // Set footer height
      //     contents: () => {
      //       return '<div style="text-align: right;">Copyright © 2024 Your Company</div>';
      //     },
      //   },
      // };

      // // Use html2pdf to generate and download the PDF
      // html2pdf(element, options);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAuth = async () => {
    try {
      const loginFormData = new FormData();
      loginFormData.append("login_username", creator.creator_email);
      loginFormData.append("login_password", base64.encode(state.password));
      Axios.post(`${apiUrl}creators/login`, loginFormData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
        .then((res) => {
          console.log(res);
          if (res.data.status === "ok") {
            setTriggerVerify(false);
            setTriggerCancel(true);
            setErrorMsg("");
          } else {
            setErrorMsg("wrong password");
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const handleContact = async () => {
    setIsLoading(true);
    try {
      let contactDetails = {
        customer_name: packageDetails.customer_name,
        customer_email: packageDetails.customer_email,
        subject: state.title,
        message: state.contactDetail,
      };
      const res = await Axios.post(
        `${UopUrl}/booking/contact-traveler`,
        contactDetails,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );
      console.log(res, "res");
      if (res.status === 200) {
        // setRespMsg(res.data.message);
        // setRespStatus(true);
        setTriggerCancel(false);
        setIsLoading(false);
        toast(res.data.message, { type: "success" });
      }
    } catch (err) {
      console.log(err);
      setRespMsg(err.response.data.message);
      setRespStatus(true);
      setTriggerCancel(false);
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    var value = editor.getData();
    console.log(value);
    setState((prevState) => ({
      ...prevState,
      contactDetail: value,
    }));
  };

  const handlePopup = (popupType, action) => {
    switch (popupType) {
      case "verify":
        action ? setTriggerVerify(true) : setTriggerVerify(false);
        break;
      case "cancel":
        action ? setTriggerCancel(true) : setTriggerCancel(false);
        break;
      case "contact":
        action ? setTriggerContact(true) : setTriggerContact(false);
        break;
      case "respMsg":
        action ? setRespStatus(true) : setRespStatus(false);
        break;
      default:
        break;
    }
  };

  const handleCancellation = async () => {
    setIsLoading(true);
    try {
      const payload = {
        creator_name: packageDetails.creator_name,
        customer_name: packageDetails.customer_name,
        customer_email: packageDetails.customer_email,
        creator_package_name: packageDetails.creator_package_name,
        subject: state.cancReason,
        message: state.cancDetail,
      };

      const res = await Axios.post(
        `${UopUrl}/booking/cancellation`,
        payload,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      );
      console.log(res);
      // setRespMsg(res.data.message);
      // setRespStatus(true);
      setTriggerVerify(false);
      toast(res.data.message, { type: "success" });
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setRespMsg(err.response.data.message);
      setRespStatus(true);
      setTriggerVerify(false);
      setIsLoading(false);
    }
  };

  const formatDate = (inputDate) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");

    return `${day} ${month} ${year}`;
  };

  const showPassengers = (adult, child, infant) => {
    console.log(adult, child, infant);
    if (adult !== 0 && adult !== undefined) {
      if (child !== 0 && child !== undefined) {
        if (infant !== 0 && infant !== undefined) {
          return `${adult} Adults ${child} Child ${infant} Infant`;
        } else {
          return `${adult} Adults ${child} Child`;
        }
      } else {
        return `${adult} Adults`;
      }
    }
  };
  const ModalRes = () => {
    setRespStatus(false);
    setTriggerCancel(false);
    setTriggerContact(false);
    setTriggerVerify(false);
  };
  console.log(packageDetails, "packageDetails");

  return (
    <>
      <div className="booking-detail-main">
        <div
          className="booking-detail-main__header"
          style={{ background: navbar ? "#fff" : "transparent" }}
        >
          <div className="booking-detail-main__header__col1">
            <a href={void 0} className="arrow-big" onClick={() => goBack()}>
              {" "}
              <img src={backarrow} />{" "}
            </a>
            <span className="title">Back to booking list</span>
          </div>
          <div className="booking-detail-main__header__col2">
            <Button
              variant="outline"
              className="booking-detail-main__header__col2__downloadBtn"
              onClick={DownloadPDF}
            >
              Download
            </Button>
            <Button
              variant="outline"
              className="booking-detail-main__header__col2__cancelBtn"
              onClick={() => handlePopup("verify", true)}
            >
              Cancel booking
            </Button>
            <Button
              variant="outline"
              className="booking-detail-main__header__col2__contactBtn"
              onClick={() => handlePopup("contact", true)}
            >
              Contact traveller
            </Button>
          </div>
        </div>
        <div style={{ visibility: "hidden", height: 0 }}>
          <div ref={pdfRef} className="booking-detail-main__content">
            <div className="booking-detail-main__content__header">
              <img src={logo} alt="logo" />
            </div>
            <div className="booking-detail-main__content__row">
              <div className="booking-detail-main__content__row__col1">
                <div className="booking-detail-main__content__row__col__label">
                  Product booked
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {packageDetails.creator_package_name}
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Product Id
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {packageDetails?.product_id}
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Confirmation Id
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {packageDetails?.booking_reference_number}
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Revenue
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {packageDetails.revenue}
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="booking-detail-main__content__row">
              <div className="booking-detail-main__content__row__col1">
                <div className="booking-detail-main__content__row__col__label">
                  Travelers
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {packageDetails?.adult} Adult {packageDetails?.child} Child
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Booking date
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {moment(packageDetails?.booking_created_on).format(
                    "DD MMM YYYY"
                  )}
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Tour start date
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {moment(packageDetails.tour_start_date).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="booking-detail-main__content__row__col">
                <div className="booking-detail-main__content__row__col__label">
                  Tour end date
                </div>
                <div className="booking-detail-main__content__row__col__val">
                  {moment(packageDetails.tour_end_date).format("DD MMM YYYY")}
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="booking-detail-main__content__title">
              Lead travelers details
            </div>
            <div className="booking-detail-main__content__box">
              {packageDetails?.lead_traveller?.length && (
                <div>
                  {packageDetails?.lead_traveller[0]?.first_name}{" "}
                  {packageDetails?.lead_traveller[0]?.last_name}
                </div>
              )}
              <div>Adult</div>
              <div>
                {packageDetails?.lead_traveller?.length &&
                  moment(packageDetails?.lead_traveller[0]?.dob).format(
                    "DD MMM YYYY"
                  )}
              </div>
              <div>
                {packageDetails?.lead_traveller?.length &&
                  packageDetails?.lead_traveller[0]?.gender}
              </div>
              <div>
                {packageDetails.customer_phone_code +
                  packageDetails.customer_phone}
              </div>
              <div>{packageDetails.customer_email}</div>
            </div>
            <br />
            <div className="booking-detail-main__content__title">
              Travelers details{" "}
            </div>
            <div className="booking-detail-main__content__box">
              {packageDetails.booking_request?.adultList.map((list, index) => (
                <>
                  <div>Traveller {index + 1}</div>
                  <div>
                    {list.first_name} {list.last_name}
                  </div>
                  <div>Adult</div>
                  <div>{moment(list.dateofbirth).format("DD MMM YYYY")}</div>
                  <div>{list.gender.label}</div>
                </>
              ))}
              {packageDetails.booking_request?.childList?.length > 0 &&
                packageDetails.booking_request?.childList.map((list, index) => (
                  <>
                    <div>Traveller {index + 1}</div>
                    <div>
                      {list.first_name} {list.last_name}
                    </div>
                    <div>Child</div>
                    <div>{moment(list.dateofbirth).format("DD MMM YYYY")}</div>
                    <div>{list.gender.label}</div>
                  </>
                ))}
            </div>
            <br />
            <div className="booking-detail-main__content__title">
              Itinerary details{" "}
            </div>
            <br />
            {console.log(packageDetails, "packageDetailsvpackageDetails")}
            {packageDetails?.flight === "yes" && (
              <div className="booking-detail-main__content__box1">
                <div className="booking-detail-main__content__box1__tag1">
                  Onward information
                </div>
                <br />
                <div className="booking-detail-main__content__box1__title">
                  {packageDetails.depature}
                </div>
                <br />
                <ul>
                  <li>Arrival: {packageDetails.depature_date}</li>
                  <li>
                    Flight number is {packageDetails.depature_flight_number}
                  </li>
                  <li>Airlines: {packageDetails.depature_airline_name}</li>
                  <li>Terminal: {}</li>
                </ul>
                <br />
                <div className="booking-detail-main__content__box1__tag1">
                  Return information{" "}
                </div>
                <br />
                <div className="booking-detail-main__content__box1__title">
                  {packageDetails.return_depature}
                </div>
                <br />
                <ul>
                  <li>Arrival: {packageDetails.return_depature_date}</li>
                  <li>
                    Flight number is{" "}
                    {packageDetails.return_depatureflight_number}
                  </li>
                  <li>
                    Airlines: {packageDetails.return_Depature_airline_name}
                  </li>
                  <li>Terminal: {}</li>
                </ul>
              </div>
            )}
            {packageDetails?.itinerary?.map((item, index) => (
              <div key={index}>
                <div className="booking-detail-main__content__headerRow ">
                  <div>Day {item?.day} - Arrival day</div>
                </div>

                <div className="booking-detail-main__content__box1">
                  <div className="booking-detail-main__content__box1__tagRow">
                    <div className="booking-detail-main__content__box1__tag1">
                      Your stay{" "}
                    </div>
                    <div className="booking-detail-main__content__box1__tagRow">
                      {item?.guide_included && (
                        <div className="booking-detail-main__content__box1__tag2">
                          Guide included
                        </div>
                      )}
                      {item?.transfer_included && (
                        <div className="booking-detail-main__content__box1__tag3">
                          Transfer included
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="booking-detail-main__content__box1__row">
                    <div className="booking-detail-main__content__box1__col1">
                      <div className="booking-detail-main__content__box1__title">
                        {item?.stay_name}
                      </div>
                      <div className="booking-detail-main__content__box1__loc">
                        {item?.stay_address}
                      </div>
                      <br />
                      <ul>
                        <li>Check-In: {item?.check_in}</li>
                        {item?.room_type?.length &&
                          item?.room_type?.map((item, index) => (
                            <li>Room type:{item?.item}</li>
                          ))}
                      </ul>
                      <br />
                      {item?.food_beverage?.length && (
                        <>
                          <br />
                          <div className="booking-detail-main__content__box1__tag1">
                            Your Food & Beverage
                          </div>
                          <br />
                          <ul>
                            {item?.food_beverage?.map((item, index) => (
                              <li key={index}>
                                {item?.type} - {item?.item}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {item?.things_to_do?.length && (
                        <>
                          <br />
                          <div className="booking-detail-main__content__box1__tag1">
                            Your Things to do{" "}
                          </div>
                          <br />
                          <ul>
                            {item?.things_to_do?.map((item, index) => (
                              <li key={index}>{item?.item}</li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <div className="booking-detail-main__content__box1__col2">
                      {item?.media &&
                        item?.media
                          ?.split(",")
                          .map((url, index) => <img src={url} key={index} />)}
                    </div>
                  </div>
                  <br />
                  <div
                    className="booking-detail-main__content__box1__desp"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id="pdf-content" className="booking-detail-main__content">
          <div className="booking-detail-main__content__row">
            <div className="booking-detail-main__content__row__col1">
              <div className="booking-detail-main__content__row__col__label">
                Product booked
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {packageDetails.creator_package_name}
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Product Id
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {packageDetails.product_id}
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Confirmation Id
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {packageDetails.booking_reference_number}
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Revenue
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {packageDetails.revenue}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="booking-detail-main__content__row">
            <div className="booking-detail-main__content__row__col1">
              <div className="booking-detail-main__content__row__col__label">
                Travelers
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {packageDetails?.adult} Adult {packageDetails?.child} Child
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Booking date
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {moment(packageDetails?.booking_created_on).format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Tour start date
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {moment(packageDetails.tour_start_date).format("DD MMM YYYY")}
              </div>
            </div>
            <div className="booking-detail-main__content__row__col">
              <div className="booking-detail-main__content__row__col__label">
                Tour end date
              </div>
              <div className="booking-detail-main__content__row__col__val">
                {moment(packageDetails.tour_end_date).format("DD MMM YYYY")}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="booking-detail-main__content__title">
            Lead travelers details
          </div>
          <div className="booking-detail-main__content__box">
            {packageDetails?.lead_traveller?.length && (
              <div>
                {packageDetails?.lead_traveller[0]?.first_name}{" "}
                {packageDetails?.lead_traveller[0]?.last_name}
              </div>
            )}
            {packageDetails?.lead_traveller?.length && (
              <div>{packageDetails?.lead_traveller[0]?.type} </div>
            )}
            <div>
              {packageDetails?.lead_traveller?.length &&
                moment(packageDetails?.lead_traveller[0]?.dob).format(
                  "DD MMM YYYY"
                )}
            </div>
            <div>
              {packageDetails?.lead_traveller?.length &&
                packageDetails?.lead_traveller[0]?.gender}
            </div>
            <div>
              {packageDetails.customer_phone_code +
                packageDetails.customer_phone}
            </div>
            <div>{packageDetails.customer_email}</div>
          </div>
          <br />
          {packageDetails?.lead_traveller?.length > 1 && (
            <div className="booking-detail-main__content__title">
              Travelers details{" "}
            </div>
          )}
          {packageDetails?.lead_traveller?.length > 1 && (
            <div className="booking-detail-main__content__box">
              {packageDetails?.lead_traveller.slice(1).map((list, index) => (
                <>
                  <div>
                    {list?.first_name}
                    {list?.last_name}
                  </div>
                  <div>{list?.type}</div>
                  <div>{moment(list?.dob).format("DD MMM YYYY")}</div>
                  <div>{list?.gender}</div>
                  <div>
                    {packageDetails.customer_phone_code +
                      packageDetails.customer_phone}
                  </div>
                  <div>{packageDetails.customer_email}</div>
                </>
              ))}
            </div>
          )}
          <br />
          <div className="booking-detail-main__content__title">
            Itinerary details{" "}
          </div>
          <br />
          {packageDetails?.flight === "yes" && (
            <div className="booking-detail-main__content__box1">
              <div className="booking-detail-main__content__box1__tag1">
                Onward information
              </div>
              <br />
              <div className="booking-detail-main__content__box1__title">
                {packageDetails.depature}
              </div>
              <br />
              <ul>
                <li>Arrival: {packageDetails.depature_date}</li>
                {packageDetails.depature_flight_number && (
                  <li>
                    Flight number is {packageDetails.depature_flight_number}
                  </li>
                )}
                <li>Airlines: {packageDetails.depature_airline_name}</li>
                {/*   <li>Terminal: {}</li> */}
              </ul>
              <br />
              <div className="booking-detail-main__content__box1__tag1">
                Return information{" "}
              </div>
              <br />
              <div className="booking-detail-main__content__box1__title">
                {packageDetails.return_depature}
              </div>
              <br />
              <ul>
                <li>Arrival: {packageDetails.return_depature_date}</li>
                {packageDetails.return_depatureflight_number && (
                  <li>
                    Flight number is{" "}
                    {packageDetails.return_depatureflight_number}
                  </li>
                )}
                <li>Airlines: {packageDetails.return_depature_airline_name}</li>
                {/* <li>Terminal: {}</li> */}
              </ul>
            </div>
          )}
          {packageDetails?.itinerary?.map((item, index) => (
            <div key={index}>
              <div className="booking-detail-main__content__headerRow ">
                <div>Day {item?.day} - Arrival day</div>
                <Button
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={() => setViewItinerary(index)}
                >
                  {viewItinerary !== index ? "Show" : "Hide"}
                </Button>
              </div>
              {viewItinerary === index && (
                <div className="booking-detail-main__content__box1">
                  <div className="booking-detail-main__content__box1__tagRow">
                    <div className="booking-detail-main__content__box1__tag1">
                      Your stay{" "}
                    </div>
                    <div className="booking-detail-main__content__box1__tagRow">
                      {item?.guide_included && (
                        <div className="booking-detail-main__content__box1__tag2">
                          Guide included
                        </div>
                      )}
                      {item?.transfer_included && (
                        <div className="booking-detail-main__content__box1__tag3">
                          Transfer included
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="booking-detail-main__content__box1__row">
                    <div className="booking-detail-main__content__box1__col1">
                      <div className="booking-detail-main__content__box1__title">
                        {item?.stay_name}
                      </div>
                      <div className="booking-detail-main__content__box1__loc">
                        {item?.stay_address}
                      </div>
                      <br />
                      <ul>
                        <li>Check-In: {item?.check_in}</li>
                        {item?.room_type?.length &&
                          item?.room_type?.map((item, index) => (
                            <li>Room type:{item?.item}</li>
                          ))}
                      </ul>
                      <br />
                      {item?.food_beverage?.length && (
                        <>
                          <br />
                          <div className="booking-detail-main__content__box1__tag1">
                            Your Food & Beverage
                          </div>
                          <br />
                          <ul>
                            {item?.food_beverage?.map((item, index) => (
                              <li key={index}>
                                {item?.type} - {item?.item}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {item?.things_to_do?.length && (
                        <>
                          <br />
                          <div className="booking-detail-main__content__box1__tag1">
                            Your Things to do{" "}
                          </div>
                          <br />
                          <ul>
                            {item?.things_to_do?.map((item, index) => (
                              <li key={index}>{item?.item}</li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <div className="booking-detail-main__content__box1__col2">
                      {item?.media &&
                        item?.media
                          ?.split(",")
                          .map((url, index) => <img src={url} key={index} />)}
                    </div>
                  </div>
                  <br />
                  <div
                    className="booking-detail-main__content__box1__desp"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <ModalPopup
        type="verifyPass"
        open={triggerVerify}
        handleClose={() => handlePopup("verify", false)}
        handleChange={handleChange}
        handleSubmit={handleAuth}
        errorMsg={errorMsg}
      />

      <ModalPopup
        type="cancel"
        open={triggerCancel}
        isLoading={isLoading}
        handleClose={() => handlePopup("cancel", false)}
        handleChange={handleChange}
        handleSubmit={handleCancellation}
      />

      <ModalPopup
        type="contact"
        open={triggerContact}
        handleClose={() => handlePopup("contact", false)}
        handleChange={handleChange}
        isLoading={isLoading}
        handleEditorChange={handleEditorChange}
        handleSubmit={handleContact}
      />
      <ModalPopup
        type="respMsg"
        open={respStatus}
        handleClose={() => handlePopup("respMsg", false)}
        // handleChange={handleChange}
        // handleEditorChange={handleEditorChange}
        handleSubmit={ModalRes}
        respMsg={respMsg}
      />
      <ToastContainer />
    </>
  );
};

export default BookingDetail;
