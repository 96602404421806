/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import cookie from "react-cookies";
import Avatar from "react-avatar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  apiUrl,
  headerconfig,
  apiglobaltixUrl,
  apiglobaltixMediaUrl,
} from "../Helpers/Config";
import axios from "axios";
import Dashboard from "./Dashboard";
import Bookings from "./Bookings/Bookings";
import BookingDetail from "./BookingDetail/BookingDetail";
import Earnings from "../Myaccount/Earnings";
import Profilecreator from "../Myaccount/ProfileCreator/Profilecreator";
import Creatordetailsedit from "../Myaccount/Creatordetailsedit";
import PayoutDetails from "../Myaccount/PayoutDetails";
import CreatorMyproducts from "./CreatorMyproducts";
import AddProducts from "./AddProducts";
import { HelpCenter } from "@mui/icons-material";
import Package from "../Package/Package";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import Packagedetails from "../Package/Packagedetails";
import AddPhotos from "./AddPhotos/AddPhotos";
import ItineraryList from "./ItineraryList";
import Availability from "./Availability/Availability";
import ItineraryActivity from "./ItineraryActivity/ItineraryActivity";
import Pricing from "./Pricing/Pricing";
import WorkingHours from "./WorkingHours/WorkingHours";
import Terms from "./Terms/Terms";

const Routes = () => {
  return (
    <>
      <Route path="/myaccount/dashboard" component={Dashboard} />
      <Route path="/myaccount/bookings" component={Bookings} />
      <Route path="/myaccount/booking-detail" component={BookingDetail} />
      <Route exact path="/myaccount/commission" component={Earnings} />
      <Route
        exact
        path="/myaccount/profilecreator"
        component={Profilecreator}
      />
      <Route
        path="/myaccount/creatordetails-edit"
        component={Creatordetailsedit}
      />
      <Route exact path="/myaccount/payoutdetails" component={PayoutDetails} />
      <Route
        path="/myaccount/creator-myproducts"
        component={CreatorMyproducts}
      />
      <Route
        exact
        path="/myaccount/create-package"
        component={Packagedetails}
      />
      <Route path="/myaccount/add-myproducts" component={AddProducts} />
      <Route path="/myaccount/helpcenter" component={HelpCenter} />
      <Route path="/myaccount/package" component={Package} />
      <Route path="/myaccount/general-info" component={GeneralInfo} />
      <Route path="/myaccount/add-photos" component={AddPhotos} />
      <Route path="/myaccount/itinerary-list" component={ItineraryList} />
      <Route path="/myaccount/hours" component={WorkingHours} />
      <Route path="/myaccount/pricing" component={Pricing} />
      <Route path="/myaccount/availability" component={Availability} />
      <Route path="/myaccount/terms" component={Terms} />
      <Route
        path="/myaccount/itinerary-activity"
        component={ItineraryActivity}
      />
    </>
  );
};

class MyAccountLayout extends Component {
  constructor(props) {
    super(props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }
    //this.logoutPopup = this.logoutPopup.bind(this);
    //this.confirmLogout = this.confirmLogout.bind(this);

    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      creator: [],
      selectedOption: "7days",
      address: "",
      packagecountry: "",
      pacakgeLat: "",
      packageLon: "",
      packageCity: "",
      cityList: "",
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    this.fetchData();
  }

  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) >= 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }

  fetchData() {
    axios
      .get(
        apiUrl +
          "dashboard/activity?creator_id=" +
          this.state.CUserId +
          "&days=" +
          this.state.selectedOption,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
      )
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data, "creator");
          this.setState({
            creator: response.data,
            chartData: response.data.package_click_history,
            totalclicks:
              response.data.statistics_result.packageclick_percentage_diff,

            totalbooking:
              response.data.statistics_result.bookingclick_percentage_diff,
            totalrevenue:
              response.data.statistics_result.commission_percentage_diff,
            bookingrate:
              response.data.statistics_result.bookingrate_percentage_diff,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "address") {
      if (value !== "") {
        var address = $("#pac-input").val();
        var addressdetails = value.split("~~");
        this.setState(
          {
            address: address,
            packagecountry: addressdetails[0],
            pacakgeLat: addressdetails[1],
            packageLon: addressdetails[2],
            packageCity: addressdetails[3],
          },
          function () {
            if (this.state.packagecountry !== "") {
              this.loadAirport();
            }
          }
        );
      }
    }
    if (field === "cityList" && value !== "") {
      var cityList = [];
      if (Object.keys(value.result_set).length > 0) {
        Object.keys(value.result_set).map((item) => {
          var selectedCountry = value.result_set[item];
          selectedCountry.city.map((city) => {
            cityList.push({
              label: city.city_name + ", " + city.country_name,
              value:
                city.airport_address +
                "~" +
                city.airport_code +
                "~" +
                city.airport_id +
                "~" +
                city.airport_latitude +
                "~" +
                city.airport_longitude +
                "~" +
                city.airport_name +
                "~" +
                city.city_code +
                "~" +
                city.city_id +
                "~" +
                city.city_name +
                "~" +
                city.country_code +
                "~" +
                city.country_name +
                "~" +
                city.webbeds_city_code,
            });
          });
        });
      }
      this.setState(
        {
          cityList: cityList,
        },
        function () {}
      );
    }
  };

  render() {
    console.log("this.props", this.props);
    const { children } = this.props;
    // const childrenWithProps = React.Children.map(this.props.children, (child) =>
    //   React.cloneElement(child, { creator: this.state.creator })
    // );
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div>
          <section className="main-blue-bg">
            <div className="container-full">
              <div className="creator-board">
                <div className="creator-board-lhs">
                  {this.state.creator?.creator_details !== undefined && (
                    <div className="creator-box">
                      <div className="creator-img">
                        {this.state?.creator?.creator_details?.creator_image !==
                          "" &&
                        this.state?.creator?.creator_details?.creator_image !==
                          null ? (
                          <img
                            src={
                              this.state.creator?.creator_details?.creator_image
                            }
                            alt=""
                          />
                        ) : (
                          <Avatar
                            name={
                              this.state.creator?.creator_details
                                ?.creator_handle_name
                            }
                            size="50"
                            round={true}
                            color="#4258bf"
                          />
                        )}
                      </div>
                      <div className="creator-def">
                        <a
                          href={void 0}
                          onClick={() => {
                            var cid =
                              this.state.creator?.creator_details?.creator_id;
                            this.props.history.push("/public_profile", cid);
                          }}
                        >
                          {" "}
                          {
                            this.state.creator?.creator_details
                              ?.creator_handle_name
                          }
                        </a>
                        <p> Level 1 creator</p>
                        <span>
                          <strong>
                            {
                              this.state.creator?.creator_details
                                ?.creator_total_rating
                            }
                          </strong>{" "}
                          (1) Reviews
                        </span>
                        <div className="star-rating">
                          {/* <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star-half-o" aria-hidden="true"></i> */}

                          {this.loadRatingstar(
                            this.state.creator?.creator_details?.creator_rating
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <Topmenu {...this.props} currentpage="dashboard" />
                  <div id="remove_popup" className="mfp-hide common-popup">
                    <div className="custom_alert textcenter">
                      <h2 className="text-uppercase">Warning</h2>
                      <p>Are you sure want to logout </p>
                      <div
                        className="alt_btns"
                        style={{ gap: "10px", display: "inline-flex" }}
                      >
                        <a
                          className="button confirm-button ghost-button"
                          onClick={this.confirmLogout}
                        >
                          Yes
                        </a>
                        <a
                          href={void 0}
                          className="button popup-modal-dismiss disbl_href_action"
                        >
                          No
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="creator-board-rhs cx-box">
                  <Routes />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(MyAccountLayout);
