/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import axios from "axios";
import ContentLoader from "react-content-loader";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import stayplaceholder from "../../common/images/stay-slider.jpg";
import stayplaceholder1 from "../../common/images/stay-slider1.jpg";
import binfo from "../../common/images/info-black.svg";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl } from "../Helpers/Config";
import { loadRatingNumber } from "../Helpers/SettingHelper";

import radult from "../../common/images/room-adult.svg";
import { GET_CUSTOMER_DETAIL } from "../../actions";

var stayslider = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  margin: 25,
  stagePadding: 150,
  responsive: {
    0: {
      margin: 10,
      stagePadding: 50,
    },
    580: {
      margin: 15,
      stagePadding: 100,
    },
    980: {
      margin: 20,
      items: 1,
    },
  },
};
var staygallery = {
  items: 1,
  loop: true,
  dots: false,
  nav: true,
};
var qs = require("qs");
class Staydetail extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    var packageID = "";
    if (this.props.match.params.packageID !== "") {
      packageID = this.props.match.params.packageID;
    }
    var country = "";
    if (this.props.match.params.country !== "") {
      country = this.props.match.params.country;
    }
    var city = "";
    if (this.props.match.params.city !== "") {
      city = this.props.match.params.city;
    }
    var hotelID = "";
    if (this.props.match.params.hotelID !== "") {
      hotelID = this.props.match.params.hotelID;
    }
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    }
    var searchResult = "";
    if (
      this.props.location.state.searchResult !== "" &&
      this.props.location.state.searchResult !== undefined &&
      this.props.location.state.searchResult !== "undefined"
    ) {
      searchResult = this.props.location.state.searchResult;
    }
    this.state = {
      CUserId: CUserId,
      packageID: packageID,
      country: country,
      city: city,
      hotelID: hotelID,
      hotelResult: "",
      searchResult: searchResult,
    };
  }
  componentDidMount() {
    this.loadHotelDetails();
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
  }
  loadHotelDetails() {
    var todayDate = new Date();
    var startDate =
      todayDate.getFullYear() +
      "-" +
      this.pad(todayDate.getMonth() + 2) +
      "-" +
      this.pad(todayDate.getDate());
    var NextDate = new Date();
    NextDate.setDate(parseInt(NextDate.getDate() + 1));
    var endDate =
      NextDate.getFullYear() +
      "-" +
      this.pad(NextDate.getMonth() + 2) +
      "-" +
      this.pad(NextDate.getDate());
    var passengers = [];
    passengers.push({ adult: 1 });

    var postObject = {
      hotel_id: 504815,
      country: this.state.country,
      city: this.state.city,
      from_date: startDate,
      to_date: endDate,
      passenger_data: JSON.stringify(passengers),
      city_code: 22394,
    };

    axios
      .post(
        apiUrl + "hotelbooking/getRooms",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          }
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({
            hotelResult: res.data.result.hotelRoomInfo,
            loadingHotel: false,
          });
        } else {
          this.setState({ loadingHotel: false });
        }
      });
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  loadRatingstar(points, type = "") {
    if (points !== "" && points !== null) {
      if (type === "hotel") {
        if (points === "Economy*") {
          points = "1.0";
        } else if (points === "Budget **") {
          points = "2.0";
        } else if (points === "Standard ***") {
          points = "3.0";
        } else if (points === "Superior ****") {
          points = "4.0";
        } else if (points === "Luxury *****") {
          points = "5.0";
        } else if (points === "Serviced Apartment") {
          points = "0.0";
        } else if (points === "Unrated") {
          points = "0.0";
        }
      }

      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                key={index}
                aria-hidden="true"
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" key={index} aria-hidden="true"></i>
            );
          }
        }
      });
    }
  }

  gobackStay(detailsLink) {
    console.log(detailsLink, "detailsLink");
    this.props.history.push({
      pathname: detailsLink,
      state: { webbeds_city_code: this.state.searchResult.city_code },
    });
  }
  goBack = () => {
    this.props.history.goBack();
    var hotel = this.state.hotelResult;
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        {this.state.hotelResult !== "" ? (
          <>
            <div className="bread-crumbs">
              <div className="container">
                <ul>
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>
                    <a href={void 0} onClick={this.goBack}>
                      {this.state.country}
                    </a>
                  </li>
                  <li>{this.state.hotelResult.hotel_name}</li>
                </ul>
              </div>
            </div>

            <section className="innersection">
              <div className="container">
                <div className="package-details">
                  <div className="package-details-lhs">
                    <div className="detail-top-header pb30">
                      <h1 className="color-header">
                        Your stay for the <span>trip</span>
                      </h1>
                      <div className="product-description-action">
                        <a
                          href={void 0}
                          onClick={this.gobackStay.bind(
                            this,
                            "/creator-stay/" +
                              this.state.country +
                              "/" +
                              this.state.city +
                              "/" +
                              this.state.packageID
                          )}
                          className="button"
                        >
                          Back
                        </a>
                      </div>
                    </div>

                    <div className="user-stay-select">
                      <h5 className="color-header mb15">
                        Stay at <span> {this.state.hotelResult.city_name}</span>
                      </h5>
                      <div className="uss-inner-top">
                        <h4>
                          {this.state.hotelResult.hotel_name}{" "}
                          <span>{this.state.hotelResult.address}</span>
                        </h4>
                        <div className="star-rating-review">
                          <div className="star-rating">
                            {this.loadRatingstar(
                              this.state.hotelResult.rating,
                              "hotel"
                            )}
                          </div>
                          <div className="sr-review">
                            <strong>
                              {loadRatingNumber(this.state.hotelResult.rating)}
                            </strong>
                          </div>
                        </div>
                        <div className="uss-inner-slider">
                          <OwlCarousel options={stayslider}>
                            <div>
                              <div className="stayholder">
                                <img src={stayplaceholder} alt="stay" />
                              </div>
                            </div>
                            <div>
                              <div className="stayholder">
                                <img src={stayplaceholder1} alt="stay" />
                              </div>
                            </div>
                            <div>
                              <div className="stayholder">
                                <img src={stayplaceholder} alt="stay" />
                              </div>
                            </div>
                            <div>
                              <div className="stayholder">
                                <img src={stayplaceholder1} alt="stay" />
                              </div>
                            </div>
                          </OwlCarousel>
                        </div>
                      </div>

                      <div className="uss-choice">
                        <h3>Room Choices</h3>
                        {this.state.hotelResult.room_info !== undefined && (
                          <div className="uss-choice">
                            <div className="preferred-room" id="preferred-room">
                              <ul>
                                {this.state.hotelResult.room_info.map(
                                  (item, index) => {
                                    return (
                                      <li className="active">Room options</li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                            {this.state.hotelResult.room_info.map(
                              (roomItem, roomIndex) => {
                                return (
                                  <ul key={roomIndex}>
                                    {roomItem.map((item, index) => {
                                      return (
                                        <li
                                          key={index}
                                          id={
                                            "room_" +
                                            roomIndex +
                                            "_" +
                                            item.room_type_code
                                          }
                                          className="active"
                                        >
                                          <div className="room-parent">
                                            <div className="room-choice-img">
                                              <OwlCarousel
                                                options={staygallery}
                                              >
                                                <div>
                                                  <div className="stayholder">
                                                    <img
                                                      src={stayplaceholder}
                                                      alt="stay"
                                                    />
                                                  </div>
                                                </div>
                                              </OwlCarousel>
                                            </div>
                                            <div className="room-choice-desc">
                                              <div className="room-choice-one">
                                                <h4>{item.room_type_name}</h4>
                                                {item.room_amenities !== "" &&
                                                  item.room_amenities !==
                                                    null && (
                                                    <ul>item.room_amenities</ul>
                                                  )}

                                                <div>
                                                  <div className="room-view-right">
                                                    <a href={void 0}>
                                                      View more
                                                    </a>
                                                  </div>

                                                  <div
                                                    id={
                                                      "hotel_info_popup_" +
                                                      roomIndex +
                                                      "_" +
                                                      item.room_type_code
                                                    }
                                                    className="mfp-hide common-popup hotel-info-popup"
                                                  >
                                                    <div className="cp-header textcenter">
                                                      <h4>Hotel Amenities</h4>
                                                      <a
                                                        className="date-package-popup"
                                                        href={void 0}
                                                      >
                                                        <img src={close} />
                                                      </a>
                                                    </div>
                                                    <div className="cp-body"></div>
                                                  </div>
                                                </div>
                                              </div>
                                              {(item.room_special[0]
                                                .condition !== "" ||
                                                item.room_special[0]
                                                  .description !== "" ||
                                                item.room_special[0].notes !==
                                                  "" ||
                                                item.room_special[0]
                                                  .special_name !== "" ||
                                                item.room_special[0]
                                                  .special_type !== "") && (
                                                <div className="honeymoon-policy">
                                                  <a href={void 0}>
                                                    <span>
                                                      {item.room_special[0]
                                                        .special_type !== "" &&
                                                        item.room_special[0]
                                                          .special_type}
                                                    </span>{" "}
                                                  </a>
                                                </div>
                                              )}
                                              <div className="room-capacity">
                                                <h5>Room Capacity </h5>
                                                <ul>
                                                  {parseInt(item.max_adult) >
                                                    0 && (
                                                    <li>
                                                      <img
                                                        src={radult}
                                                        alt="adult"
                                                      />
                                                      <span>
                                                        {parseInt(
                                                          item.max_adult
                                                        )}{" "}
                                                        Adult
                                                        {parseInt(
                                                          item.max_adult
                                                        ) > 1
                                                          ? "s"
                                                          : ""}
                                                      </span>
                                                    </li>
                                                  )}
                                                  {parseInt(item.max_children) >
                                                    0 && (
                                                    <li>
                                                      <img
                                                        src={radult}
                                                        alt="adult"
                                                      />
                                                      <span>
                                                        {parseInt(
                                                          item.max_children
                                                        )}{" "}
                                                        Child
                                                        {parseInt(
                                                          item.max_children
                                                        ) > 1
                                                          ? "s"
                                                          : ""}
                                                      </span>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                              {item.room_charges.length > 0 && (
                                                <>
                                                  <div className="room-choice-two">
                                                    <h5>
                                                      Extra Options{" "}
                                                      <img
                                                        src={binfo}
                                                        alt="info"
                                                      />
                                                    </h5>

                                                    <ul className="room_charge_list">
                                                      {item.room_charges.map(
                                                        (
                                                          chargeItem,
                                                          chargeIndex
                                                        ) => {
                                                          return (
                                                            <li
                                                              key={chargeIndex}
                                                            >
                                                              <input
                                                                type="radio"
                                                                className="room_charge"
                                                                name={
                                                                  "room_charge_" +
                                                                  roomIndex +
                                                                  "_" +
                                                                  item.room_type_code
                                                                }
                                                                data-itemindex={
                                                                  chargeIndex
                                                                }
                                                                value={
                                                                  chargeItem.rate_id +
                                                                  "_" +
                                                                  chargeIndex
                                                                }
                                                                defaultChecked={
                                                                  chargeIndex ===
                                                                  0
                                                                    ? true
                                                                    : false
                                                                }
                                                              />
                                                              <span>
                                                                {
                                                                  chargeItem.rate_description
                                                                }
                                                              </span>
                                                              <strong>
                                                                SGD 1000
                                                              </strong>
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>

                                                  <div className="details-policy">
                                                    <a href={void 0}>
                                                      <span>
                                                        More details on
                                                        cancellation policy
                                                      </span>{" "}
                                                      <img
                                                        src={binfo}
                                                        alt="info"
                                                      />
                                                    </a>
                                                  </div>
                                                  <div className="room-choice-four">
                                                    <div className="rcf-lhs">
                                                      <p>
                                                        <strong className="selected_room_price">
                                                          SGD 1000
                                                        </strong>{" "}
                                                        Total
                                                      </p>
                                                      <span>
                                                        *Excludes Taxes and Fees
                                                      </span>
                                                    </div>
                                                    <div className="rcf-rhs">
                                                      <button className="button ghost-button">
                                                        Select
                                                      </button>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="innersection">
            <div className="container">
              <ContentLoader height={140} speed={1} viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
              </ContentLoader>
            </div>
          </section>
        )}
        <div className="uss-about pb10">
          <h3 className="mb15">About</h3>
          <p>
            Our luxury Penthouse is located in the quaint village of Nerul,
            overlooking green paddy fields and Nerul River. It has a sprawling
            bedroom with stunning views and an en-suite bathroom. There is also
            a sleek and well styled kitchen with modern fixtures and is fully
            equipped with all the essentials. The best part of this Penthouse is
            the gorgeous plunge pool, for your private use, and a lovely and
            spacious terrace to enjoy those amazing sunsets. The perfect
            romantic getaway!
          </p>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Staydetail);
