/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_CREATOR_DETAIL, SET_CREATOR_DETAIL } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCreatorDetail = function* () {
  yield takeEvery(GET_CREATOR_DETAIL, workerGetCreatorDetail);
};

function* workerGetCreatorDetail({ params }) {
  try {
    const uri = apiUrl + "creators/creatordetail?" + params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CREATOR_DETAIL, value: resultArr });
  } catch {
    console.log("Get creator Failed");
  }
}
