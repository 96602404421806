/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Axios from "axios";
import { apiUrl, headerconfig } from "../Helpers/Config";
import { hideLoader, showAlert, showLoader } from "../Helpers/SettingHelper";
import profileImg from "../../common/images/profile_img.png";
import profilepencile from "../../common/images/white-pen.svg";
import profileeditImg from "../../common/images/edit-pen.svg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import SidebarCreator from "./SideBarCreator/SideBarCreator";
import Topmenu from "../Layout/Topmenu";
import { Country, State, City } from "country-state-city";
import { GET_CREATOR_DETAIL, GET_UPDATECREATORPROFILE } from "../../actions";
import Form from "./Form";
var qs = require("qs");
var base64 = require("base-64");
class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log("payoutDetails", this.props);
    this.state = {
      bankDetails: {
        account_name: "",
        select_bank: "",
        account_number: "",
        confirm_account_number: "",
        ifsc: "",
        account_type: "",
        currency: "",
        country: "",
        password: "",
      },
      error_account_name: false,
      error_account_name_msg: "",
      error_select_bank: false,
      error_select_bank_msg: "",
      error_account_number: false,
      error_account_number_msg: "",
      error_confirm_account_number: false,
      error_confirm_account_number_msg: "",
      error_ifsc: false,
      error_ifsc_msg: "",
      error_account_type: false,
      error_account_type_msg: "",
      error_currency: false,
      error_currency_msg: "",
      error_country: false,
      error_country_msg: "",
      matching: false,
      incorrectPass: false,
      creatordetail: "",
      fields: {
        creator_name: "",
        email: "",
        phone: "",
        password: "",
        gender: "",
        phone_country: "",
        phone_code: "",
      },
      imageurl: "",
      editprofile: false,
    };
    if (cookie.load("CUserId") == "" || cookie.load("CUserId") == undefined) {
      props.history.push("/");
    }
    var params = "creator_id=" + base64.encode(cookie.load("CUserId"));
    this.props.getCreatorDetail(params);
  }

  componentDidMount() {
    Axios.get(
      apiUrl + "creators/getPayoutDetails?creator_id=" + cookie.load("CUserId"),
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }
    ).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          bankDetails: {
            account_name: res.data.payout_details.creator_name,
            select_bank: res.data.payout_details.creator_place,
            account_number: base64.decode(res.data.payout_details.creator_work),

            ifsc: base64.decode(res.data.payout_details.creator_code),
            account_type: res.data.payout_details.creator_type,
            currency: res.data.payout_details.creator_currency,
            country: res.data.payout_details.creator_country,
          },
        });
      } else {
        console.log("getPayoutDetails failed in mount");
      }
    });
  }

  componentDidUpdate(nextProps) {
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
  }

  fieldChange = (field, value) => {
    const { bankDetails } = this.state;
    let updatedBankDetails = { ...bankDetails };

    if (field === "account_number" || field === "confirm_account_number") {
      updatedBankDetails = {
        ...updatedBankDetails,
        [field]: value.replace(/\D/g, ""),
      };
    } else {
      updatedBankDetails[field] = value;
    }

    const matching =
      field === "account_number"
        ? bankDetails.confirm_account_number === value
        : field === "confirm_account_number"
        ? updatedBankDetails.account_number === value
        : this.state.matching;

    const errorFieldMsg =
      field === "confirm_account_number"
        ? "Account no should be the same"
        : "This field is required";

    // if (field !== "confirm_account_number") {
    this.setState({
      bankDetails: updatedBankDetails,
      matching,
      [`error_${field}`]: !updatedBankDetails[field],
      [`error_${field}_msg`]: errorFieldMsg,
    });
    // }

    if (field === "confirm_account_number") {
      if (!matching) {
        this.setState({
          error_confirm_account_number: true,
        });
      } else {
        this.setState({
          error_confirm_account_number: false,
        });
      }
    }
  };

  openPopup(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#payoutconfirm",
      },
      type: "inline",
      midClick: true,
    });
  }

  openPopupOk(event) {
    $.magnificPopup.open({
      items: {
        src: "#success-popup",
      },
      type: "inline",
      midClick: true,
    });
  }
  closePopup() {
    $.magnificPopup.close();
  }
  savePayoutDetails() {
    const loginFormData = new FormData();
    const bankFormData = new FormData();
    const formPayload = this.state.bankDetails;
    loginFormData.append(
      "login_username",
      this.state.creatordetail.creator_email
    );
    loginFormData.append("login_password", base64.encode(formPayload.password));
    bankFormData.append("creator_bank_account_name", formPayload.account_name);
    bankFormData.append("creator_bank", formPayload.select_bank);
    bankFormData.append(
      "creator_account_number",
      base64.encode(formPayload.account_number)
    );
    bankFormData.append(
      "creator_confirm_account_number",
      base64.encode(formPayload.confirm_account_number)
    );
    bankFormData.append("creator_ifsc", base64.encode(formPayload.ifsc));
    bankFormData.append("creator_account_type", formPayload.account_type);
    bankFormData.append("creator_currency", formPayload.currency);
    bankFormData.append("creator_bank_country", formPayload.country);
    bankFormData.append("creator_id", cookie.load("CUserId"));
    if (loginFormData) {
      Axios.post(apiUrl + "creators/login", loginFormData, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      }).then(
        (res) => {
          if (res.data.status === "ok") {
            if (bankFormData) {
              Axios.post(
                apiUrl + "creators/updatePayoutDetails",
                bankFormData,
                {
                  headers: {
                    Authorization: cookie.load("acccesstoken"),
                  }
                }
              ).then((res) => {
                if (res.data.status === "ok") {
                  console.log("passed");
                  this.openPopupOk();
                } else {
                  console.log("failed");
                }
              });
            }
          } else {
            this.setState({ incorrectPass: true });
            console.log("Incorrect Password");
          }
        }
      );
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  render() {
    const {
      account_name,
      account_number,
      account_type,
      confirm_account_number,
      country,
      currency,
      ifsc,
      select_bank,
    } = this.state.bankDetails;

    const allFieldsFilled =
      account_name !== "" &&
      account_number !== "" &&
      account_type !== "" &&
      confirm_account_number !== undefined &&
      country !== "" &&
      currency !== "" &&
      ifsc !== "" &&
      select_bank !== "";
    console.log(allFieldsFilled, account_name);
    var countries = Country.getAllCountries().map((country) => country.name);
    var currencies = Country.getAllCountries().map(
      (country) => country.currency
    );
    return (
      <>

        {Object.keys(this.state.creatordetail) > 0 !== "" && (
          <section className="innersection">
            <div className="container">
              <SidebarCreator pageName="payoutdetails" />
              <div className="admin-profile creator-profile">
                <div className="admin-profile-rhs">
                  <div className="payout-full">
                    <div className="unique-package creator-details-edit">
                      <div className="title-with-nav">
                        <div className="title-with-description">
                          <h2>
                            Payout <span> details</span>
                          </h2>
                        </div>
                      </div>
                      <form onSubmit={this.openPopup.bind(this)}>
                        <div className="unique-package-lhs upl-full">
                          <div className="fg-half">
                            <div className="form-group">
                              <label className="control-label">
                                Account name <em>*</em>
                              </label>
                              <input
                                type="text"
                                value={account_name ? account_name : ""}
                                placeholder="Account name"
                                onChange={(event) =>
                                  this.fieldChange(
                                    "account_name",
                                    event.target.value.replace(/[^A-Za-z\s]/g, "")
                                  )
                                }
                              />
                              {this.state.error_account_name && (
                                <div className="error">
                                  {this.state.error_account_name_msg}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label className="control-label">
                                Bank name <em>*</em>
                              </label>
                              <input
                                type="text"
                                value={select_bank}
                                placeholder="Bank name"
                                onChange={(event) =>
                                  this.fieldChange(
                                    "select_bank",
                                    event.target.value
                                  )
                                }
                              />
                              {this.state.error_select_bank && (
                                <div className="error">
                                  {this.state.error_select_bank_msg}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="fg-half">
                            <div className="form-group">
                              <label className="control-label">
                                Account number <em>*</em>
                              </label>
                              <input
                                type="text"
                                value={account_number}
                                placeholder="Account no"
                                onChange={(event) =>
                                  this.fieldChange(
                                    "account_number",
                                    event.target.value
                                  )
                                }
                              />
                              {this.state.error_account_number && (
                                <div className="error">
                                  {this.state.error_account_number_msg}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Confirm account number <em>*</em>
                              </label>
                              <input
                                type="password"
                                placeholder=" Confirm account number"
                                onChange={(event) =>
                                  this.fieldChange(
                                    "confirm_account_number",
                                    event.target.value
                                  )
                                }
                              />
                              {this.state.error_confirm_account_number && (
                                <div className="error">
                                  {this.state.error_confirm_account_number_msg}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="fg-half">
                            <div className="fg-half fg-quarter">
                              <div className="form-group">
                                <label className="control-label">
                                  IFSC <em>*</em>
                                </label>
                                <input
                                  type="text"
                                  value={ifsc}
                                  placeholder="Ifsc code"
                                  onChange={(event) =>
                                    this.fieldChange("ifsc", event.target.value)
                                  }
                                />
                                {this.state.error_ifsc && (
                                  <div className="error">
                                    {this.state.error_ifsc_msg}
                                  </div>
                                )}
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  Account type<em>*</em>
                                </label>
                                <select
                                  name="account type"
                                  value={account_type}
                                  onChange={(event) =>
                                    this.fieldChange(
                                      "account_type",
                                      event.target.value
                                    )
                                  }
                                >
                                  <option value="Business">Business</option>
                                  <option value="Personal">Personal</option>
                                </select>
                                {this.state.error_account_type && (
                                  <div className="error">
                                    {this.state.error_account_type_msg}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="fg-half fg-quarter">
                              <div className="form-group">
                                <label className="control-label">
                                  Currency <em>*</em>
                                </label>
                                <select
                                  name="currency"
                                  value={currency}
                                  onChange={(event) =>
                                    this.fieldChange(
                                      "currency",
                                      event.target.value
                                    )
                                  }
                                >
                                  {currencies.map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                                {this.state.error_currency && (
                                  <div className="error">
                                    {this.state.error_currency_msg}
                                  </div>
                                )}
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  Country <em>*</em>
                                </label>
                                <select
                                  name="country"
                                  value={country}
                                  onChange={(event) =>
                                    this.fieldChange(
                                      "country",
                                      event.target.value
                                    )
                                  }
                                >
                                  {countries.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                                {this.state.error_country && (
                                  <div className="error">
                                    {this.state.error_country_msg}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="without-chk-save">
                          <p>
                            <span>*</span> By clicking "Save changes" you agree,
                            that you have understood the consequences of
                            changing your creator profile details as it may
                            affect your package performances, commissions and
                            payouts.
                          </p>
                          <button
                            className={
                              this.state.matching && allFieldsFilled
                                ? "button"
                                : "disabled-link"
                            }
                          >
                            Save changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="payoutconfirm"
              className="mfp-hide common-popup textcenter"
            >
              <h4>
                Enter your creator account password below to confirm the payout
                details change.
              </h4>
              <div className="pt-crm-form">
                <div className="form-group">
                  <label className="control-label">
                    Enter password <em>*</em>
                  </label>
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(event) =>
                      this.fieldChange("password", event.target.value)
                    }
                  />
                  {this.state.incorrectPass && <p>Incorrect password</p>}
                </div>
                <a
                  href={void 0}
                  className="button"
                  onClick={this.savePayoutDetails.bind(this)}
                >
                  Save changes
                </a>
              </div>
              <div className="small-by">
                <span>*</span> By clicking "Save changes" you agree, that you
                have understood the consequences of changing your creator
                profile details as it may affect your package performances,
                commissions and payouts.
              </div>
            </div>
            <div
              id="success-popup"
              className="mfp-hide common-popup textcenter"
            >
              <h4>Your Account Has Been Updated Successfully</h4>
              <button onClick={this.closePopup.bind(this)}>Ok</button>
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
    updatecreatorprofile: state.updatecreatorprofile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreatorDetail: (params) => {
      dispatch({ type: GET_CREATOR_DETAIL, params });
    },
    getUpdateCreatorProfile: (formPayload) => {
      dispatch({ type: GET_UPDATECREATORPROFILE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
