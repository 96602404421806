import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./InfoCarousel.scss";
import curatedIcon from "../../../common/images/curated.svg";
import affordableIcon from "../../../common/images/affordable.svg";
import customizeIcon from "../../../common/images/customize.svg";

const infoContents = [
  {
    title: "Hyper Customize",
    desp: "Your vacation online",
    img: require('../../../common/images/customize.svg'),
  },
  {
    title: "Curated Packages",
    desp: "Discover from Top Creators",
    img: require('../../../common/images/curated.svg'),
  },
  {
    title: "All Inclusive Packages",
    desp: "Explore affordable itineraries",
    img: require('../../../common/images/affordable.svg'),
  },
];

const infoSettings = {
  dots: true,
  infinite: true,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: undefined,
  prevArrow: undefined,
};

const InfoCarousel = () => {
  return (
    <Slider {...infoSettings} className="info-carousel">
      {infoContents.map((item, index) => (
        <div key={index}>
          <div className="info-carousel__row">
            <div className="info-carousel__row__col1">
              <img src={index === 0 ? customizeIcon : index === 1 ? curatedIcon: affordableIcon} />
            </div>
            <div className="info-carousel__row__col2">
              <div className="info-carousel__row__col2__title">
                {item?.title}
              </div>
              <div className="info-carousel__row__col2__text">{item?.desp}</div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default InfoCarousel;
