/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import axios from "axios";
import { apiUrl } from "../Helpers/Config";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorlist: [],
      filteredData: [],
    };
  }
  componentDidMount() {
    this.cratorlist();
  }

  cratorlist() {
    axios.get(apiUrl + "creators/getCreators", {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      }
    }).then((res) => {
      console.log(res.data.result_set);
      this.setState({
        creatorlist: res.data.result_set,
      });
      // hideLoader("submit_product", "class");
      // if (res.data.status === "ok") {
      //   this.props.history.push("/package-step/" + this.state.packageID);
      // }
    });
  }
  filterData() {
    console.log(this.state.searchData, "response");
    const { creatorlist, searchText } = this.state;
    if (searchText !== "") {
      const filteredData = creatorlist.filter((item) =>
        item.creator_name.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({ filteredData });
      console.log(filteredData, "filteredData");
    } else {
      this.setState({ filteredData: [] });
    }
  }
  handleChange(e) {
    const text = e.target.value;
    this.setState({ searchText: text }, () => {
      this.filterData();
    });
  }
  searchHelp(category) {
    if (category !== "") {
      var postObject = {
        cid: category.creator_id,
      };
      this.props.history.push("/public_profile", {
        state: postObject,
      });
    }
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div>
          <div>
            <input
              value={this.state.searchText}
              onChange={this.handleChange.bind(this)}
            />
          </div>
        </div>
        <ul>
          {this.state.filteredData.map((item, index) => (
            <li key={index}>
              <div
                className="search-show"
                onClick={this.searchHelp.bind(this, item)}
                style={{ color: "black" }}
              >
                <p>{item.creator_name}</p>
              </div>
            </li>
          ))}
        </ul>
        <section>
          <div className="container">
            <div>
              {this.state.creatorlist.length > 0 &&
                this.state.creatorlist.map((item, index) => {
                  return (
                    <a
                      href={void 0}
                      onClick={() => {
                        this.props.history.push("/public_profile", {
                          cid: item.creator_id,
                        });
                      }}
                    >
                      {" "}
                      <div>
                        <img
                          src={item.creator_image}
                          alt="creator_image"
                          style={{ height: "auto", width: "100px" }}
                        />
                      </div>
                      <div>{item.creator_name}</div>
                      <div>{item.creator_handle_name}</div>
                    </a>
                  );
                })}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
