/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "react-light-accordion/demo/css/index.css";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import {
  apiUrl,
  mediaUrl,
  apiglobaltixMediaUrl,
} from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  jsUcfirstFun,
  calculateAircoins,
  showPriceValue,
} from "../Helpers/SettingHelper";
import {
  dollar,
  mpin,
  loader,
  placeholder,
  closeicon,
  instant_confirmation,
  calday,
} from "../Helpers/Images";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_PRODUCTDETAILS } from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");
import { format } from "date-fns";
var settingsgallery = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
};

class Productdetail extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";
    var existpackage = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existpackage =
        this.props.location.state.selectedpackage !== "" &&
        typeof this.props.location.state.selectedpackage !== undefined &&
        typeof this.props.location.state.selectedpackage !== "undefined"
          ? this.props.location.state.selectedpackage
          : [];
    }
    var existactivities = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existactivities =
        this.props.location.state.selectedpackage !== "" &&
        typeof this.props.location.state.selectedActivities !== undefined &&
        typeof this.props.location.state.selectedActivities !== "undefined"
          ? this.props.location.state.selectedActivities
          : [];
    }
    var selectedProduct = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      selectedProduct =
        this.props.location.state.selectedproduct !== "" &&
        typeof this.props.location.state.selectedproduct !== undefined &&
        typeof this.props.location.state.selectedproduct !== "undefined"
          ? this.props.location.state.selectedproduct
          : [];
    }
    var selectedProductID = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      selectedProductID =
        this.props.location.state.selectedProductID !== "" &&
        typeof this.props.location.state.selectedProductID !== undefined &&
        typeof this.props.location.state.selectedProductID !== "undefined"
          ? this.props.location.state.selectedProductID
          : [];
    }
    var existActivity = "";

    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existActivity =
        this.props.location.state.existActivity !== "" &&
        typeof this.props.location.state.existActivity !== undefined &&
        typeof this.props.location.state.existActivity !== "undefined"
          ? this.props.location.state.existActivity
          : [];
    }
    var updated = "";
    if (
      this.props.location.state.updated !== "" &&
      typeof this.props.location.state.updated !== undefined &&
      typeof this.props.location.state.updated !== "undefined"
    ) {
      updated = this.props.location.state.updated;
    }

    var newPackage = "";
    if (
      this.props.location.state.newPackage !== "" &&
      typeof this.props.location.state.newPackage !== undefined &&
      typeof this.props.location.state.newPackage !== "undefined"
    ) {
      newPackage = this.props.location.state.newPackage;
    }
    this.state = {
      userID: userID,
      existpackage: existpackage,
      existactivities: existactivities,
      selectedproduct: selectedProduct,
      selectedProductID: selectedProductID,
      productdetail: "",
      totalPrice: 0,
      selectedTicketType: [],
      selectedpackage: "",
      selectedpackageId: "",
      showspersion: "",
      displaypersion: "",
      selectedDate: new Date(),
      mindate: new Date(),
      includeDates: [],
      maxdate: "",
      triggerLogin: false,
      returnloginresult: false,
      errormsg: "",
      timeList: "",
      displyaTimeSlot: [],
      eventTime: "",
      loadTimeSlot: false,
      showTimeError: false,
      rating_categories: [],
      writeReview: false,
      ratingCategory: "",
      reatingMessage: "",
      error_rating: "",
      error_ratingCategory: "",
      error_reatingMessage: "",
      allimages: "",
      allgalleryimages: "",
      slidergalleryimages: "",
      enableDateWaring: false,
      durationdate: 0,
      product_slug: this.props.match.params.productslug,
      pouppackage: "",
      poupproduct: "",
      showdateError: "",
      existActivity: existActivity,
      existpackage: [],
      updated: updated,
      newPackage: newPackage,
    };
    var params = {
      product_slug: this.props.match.params.productslug,
      userID: userID,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.props.getProductDetails(params);
    this.loadReviewCategories();
  }

  componentDidMount() {
    $(document).ready(function () {
      $(".lightbox-cats").magnificPopup({
        type: "image",
        gallery: { enabled: true },
        // other options
      });
      $("body").on("click", ".loadmore", function () {
        if ($(this).parents(".package_description").length > 0) {
          if ($(this).parents(".package_description.active").length > 0) {
            $(this).parents(".package_description").removeClass("active");
            var remorethis = this;
            setTimeout(function () {
              $(remorethis)
                .parents(".package_description")
                .find(".loadmore")
                .html("Read More");
            }, 500);
          } else {
            $(this).parents(".package_description").addClass("active");
            $(this)
              .parents(".package_description")
              .find(".loadmore")
              .html("Read Less");
          }
        }
      });
    });
    if (this.state.existActivity.length > 0) {
      var existpackage = [];
      this.state.existActivity.map((item) => {
        item.activities.map((activityItem) => {
          if (activityItem !== "") {
            existpackage.push(activityItem.activity_activity_package_id);
          }
        });
      });
      this.setState({ existpackage: existpackage });
    }
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.productdetail !== this.state.productdetail) {
      this.setState({ productdetail: PropsData.productdetail }, function () {
        this.loadAllImages();
      });
    }
    if (
      this.state.product_slug !== "" &&
      this.state.product_slug !== PropsData.match.params.productslug
    ) {
      this.setState(
        { product_slug: PropsData.match.params.productslug },
        function () {
          var params = {
            product_slug: this.props.match.params.productslug,
            userID: this.state.userID,
          };
          this.handleChangeDate = this.handleChangeDate.bind(this);
          this.props.getProductDetails(params);
        }
      );
    }
  }
  loadReviewCategories() {
    axios
      .get(apiUrl + "products/getReviewCategories", {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var rating_categories = [];
          if (res.data.result_set.length > 0) {
            res.data.result_set.map((item) => {
              rating_categories.push({
                value: item.review_categor_id,
                label: item.review_category_title,
              });
            });
          }
          this.setState({ rating_categories: rating_categories });
        }
      });
  }
  applyQty(member, qtystatus, index, item) {
    var selectedTicketType = this.state.selectedTicketType;
    if (
      selectedTicketType[index] !== "" &&
      typeof selectedTicketType[index] !== undefined &&
      typeof selectedTicketType[index] !== "undefined"
    ) {
      var existQty = selectedTicketType[index].quantity;
      if (qtystatus == "M") {
        existQty = parseInt(existQty) - 1;
      } else {
        existQty = parseInt(existQty) + 1;
      }
      if (parseInt(existQty) > 0) {
        item.quantity = existQty;
        selectedTicketType[index] = item;
      } else {
        selectedTicketType.splice(index, 1);
      }
    } else {
      item.quantity = 1;
      selectedTicketType[index] = item;
    }
    this.setState(
      { selectedTicketType: selectedTicketType, errormsg: "" },
      function () {
        this.calculatePrice();
      }
    );
  }
  calculatePrice() {
    var totalPrice = 0;
    if (Object.keys(this.state.selectedTicketType).length > 0) {
      this.state.selectedTicketType.map((item) => {
        totalPrice += parseFloat(item.package_pricenettPrice) * item.quantity;
      });
    }

    var grandTotal = parseFloat(totalPrice);
    this.setState(
      { totalPrice: totalPrice, grandTotal: grandTotal },
      function () {
        this.loadPersonList();
      }
    );
  }
  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return <i className="fa fa-star-half-o" aria-hidden="true"></i>;
          } else {
            return <i className="fa fa-star" aria-hidden="true"></i>;
          }
        } else {
          return <i className="fa fa-star-o" aria-hidden="true"></i>;
        }
      });
    }
  }
  selectPackage(item) {
    var existProduct = [];
    var existactivities = [];

    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existProduct =
        this.props.location.state.selectedProductID !== "" &&
        typeof this.props.location.state.selectedProductID !== undefined &&
        typeof this.props.location.state.selectedProductID !== "undefined"
          ? this.props.location.state.selectedProductID
          : [];
      existactivities =
        this.props.location.state.selectedActivities !== "" &&
        typeof this.props.location.state.selectedActivities !== undefined &&
        typeof this.props.location.state.selectedActivities !== "undefined"
          ? this.props.location.state.selectedActivities
          : [];
    }

    if (this.state.productdetail.products_id.length > 0) {
      existProduct.push(this.state.productdetail.products_id);
    }
    if (existactivities.indexOf(this.state.selectedproduct) < 0) {
      existactivities.push(this.state.selectedproduct);
    }

    var productdetail = this.state.productdetail;
    var updateActivity = [];
    var dayno = parseInt(this.props.location.state.dayno.replace("day", ""));
    var dayType = parseInt(this.props.location.state.dayType);
    this.state.existActivity.map((actitem) => {
      if (actitem.day === dayno) {
        var newActivty = [];
        actitem.activities.map((activityItem, itemIndex) => {
          if (itemIndex === dayType) {
            var day_type = "";
            if (dayType === 0) {
              day_type = "M";
            } else if (dayType === 1) {
              day_type = "A";
            } else if (dayType === 2) {
              day_type = "E";
            }
            newActivty.push({
              activity_activity_product: productdetail.products_id,
              activity_activity_package_id: item.package_id,
              creator_activity_day: dayno,
              creator_activity_day_type: day_type,
              merchant_id: null,
              product_category_name: productdetail.product_category_name,
              product_payable_amount: productdetail.product_payable_amount,
              product_rating: productdetail.rating,
              product_slug: productdetail.product_slug,
              product_starting_price: productdetail.product_starting_price,
              product_tag: productdetail.categories,
              product_thumbnail: productdetail.product_thumbnail,
              product_total_package: productdetail.product_total_package,
              product_total_review: productdetail.total_reviews,
              products_description: productdetail.products_description,
              products_globaltix_id: productdetail.products_globaltix_id,
              products_id: productdetail.products_id,
              products_keywords: productdetail.products_keywords,
              products_name: productdetail.products_name,
              package_name: item.package_name,
            });
          } else {
            newActivty.push(activityItem);
          }
        });
        updateActivity.push({ day: actitem.day, activities: newActivty });
      } else {
        updateActivity.push(actitem);
      }
    });
    let packageDetails = this.props.location.state || {};
    packageDetails["updateActivity"] = updateActivity;
    let productDetails = {};
    productDetails["creator_id"] = this.state.userID || 12;
    productDetails["package_id"] = item.package_id;
    productDetails["product_package_id"] = item.package_id;
    productDetails["product_id"] = this.state.productdetail.products_id;
    productDetails["dayno"] = dayno;
    axios
      .post(`${apiUrl}package/selectproducts`, productDetails, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        }
      })
      .then((res) => {
        console.log(res);
        if (res) {
          this.props.history.push({
            pathname: "/package-step/" + this.props.location.state.packageID,
            state: packageDetails,
          });
        }
      })
      .catch((err) => console.log(err));

    $.magnificPopup.close();
  }
  closeDate() {
    this.setState(
      {
        selectedpackage: "",
        selectedpackageId: "",
        displaypersion: "",
      },
      function () {
        $.magnificPopup.close();
        $("#freshworks-container").show();
      }
    );
  }
  loadPersonList() {
    var displaypersion = "";

    if (this.state.selectedpackage.package_price.length > 0) {
      displaypersion = this.state.selectedpackage.package_price.map(
        (item, index) => {
          var selectedQty = 0;
          if (
            this.state.selectedTicketType[index] !== "" &&
            typeof this.state.selectedTicketType[index] !== undefined &&
            typeof this.state.selectedTicketType[index] !== "undefined"
          ) {
            selectedQty = this.state.selectedTicketType[index].quantity;
          }
          return (
            <li key={index}>
              <div className="addcart_row">
                <label className="sel-quality">
                  {jsUcfirstFun(item.package_price_name)}
                  <span className="price-select">
                    ${item.package_pricenettPrice}
                    {item.package_show_stickout_price === "1" && (
                      <>
                        {parseFloat(item.package_neworiginalPrice) > 0 ? (
                          <span>${item.package_neworiginalPrice}</span>
                        ) : parseFloat(item.package_originalPrice) > 0 ? (
                          <span>${item.package_originalPrice}</span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </span>
                </label>
                <div className="qty-bx">
                  <span
                    className="qty-minus"
                    onClick={this.applyQty.bind(
                      this,
                      "adults",
                      "M",
                      index,
                      item
                    )}
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    className="qty-input"
                    readOnly
                    value={selectedQty}
                  />
                  <span
                    className="qty-plus"
                    onClick={this.applyQty.bind(
                      this,
                      "adults",
                      "P",
                      index,
                      item
                    )}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </li>
          );
        }
      );
    }
    this.setState({ displaypersion: displaypersion });
  }

  handleChangeDate(datevalue) {
    this.setState({
      selectedDate: datevalue,
      displyaTimeSlot: [],
      showdateError: "",
      loadTimeSlot: false,
      timeList: [],
    });
  }
  confirmDate() {
    var selectedpackage = this.state.selectedpackage;
    if (this.state.timeList.length > 0 && this.state.eventTime === "") {
      this.setState({ showTimeError: true });
      return false;
    } else {
      this.setState({ showTimeError: false });
    }
    this.setState({ showdateError: "" });
    showLoader("choose_date");
    if (this.state.loadTimeSlot === false) {
      var selectedDate = format(this.state.selectedDate, "yyyy-MM-dd");
      var ticketTypeID = "";
      if (
        this.state.productdetail.merchant_id !== "" &&
        this.state.productdetail.merchant_id !== null
      ) {
        ticketTypeID = selectedpackage.package_price[0].package_price_id;
      } else {
        if (selectedpackage.package_price.length > 0) {
          ticketTypeID =
            selectedpackage.package_price[0].package_price_globaltix_id;
        }
      }

      axios
        .get(
          apiUrl +
            "booking/checkAvailabilityDates?package_id=" +
            selectedpackage.package_id +
            "&ticket_type_id=" +
            ticketTypeID +
            "&booking_date=" +
            selectedDate,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              }
            }
        )
        .then((res) => {
          hideLoader("choose_date");
          if (res.data.status === "ok") {
            if (res.data.display_time === "Yes") {
              this.setState(
                { timeList: res.data.time_list, loadTimeSlot: true },
                function () {
                  if (res.data.time_list.length > 1) {
                    this.loadTimeList();
                  } else {
                    this.setState(
                      {
                        eventTime: res.data.time_list[0],
                        showspersion: true,
                        loadTimeSlot: true,
                      },
                      function () {
                        $("#freshworks-container").hide();
                        $.magnificPopup.close();
                      }
                    );
                  }
                }
              );
            } else {
              this.setState(
                { showspersion: true, loadTimeSlot: true },
                function () {
                  $("#freshworks-container").hide();
                  $.magnificPopup.close();
                }
              );
            }
          } else {
            this.setState({ showdateError: res.data.message });
          }
        });
    } else {
      this.setState({ showspersion: true, loadTimeSlot: true }, function () {
        hideLoader("choose_date");
        $.magnificPopup.close();
      });
    }
  }
  loadTimeList() {
    var displyaTimeSlot = [];
    this.state.timeList.map((item) => {
      var splitItem = item.split("~");
      displyaTimeSlot.push({ value: item, label: splitItem[0] });
    });
    this.setState({ displyaTimeSlot: displyaTimeSlot });
  }
  booknow() {
    $("#freshworks-container").show();
    var errormsg = "";
    var error = 0;
    if (this.state.selectedTicketType.length > 0) {
      this.state.selectedTicketType.map((item) => {
        if (item.quantity < item.package_price_minPurchaseQty) {
          errormsg +=
            "Please Select Minumum " +
            item.package_price_minPurchaseQty +
            " " +
            jsUcfirstFun(item.package_price_name) +
            "<br/>";
        }
      });
      if (errormsg !== "") {
        var errormsgs = <span className="error">{Parser(errormsg)}</span>;
        this.setState({ errormsg: errormsgs });
        error++;
      }
    } else {
      errormsg = "Please select any one";
      this.setState({ errormsg: errormsg });
    }

    if (this.state.userID !== "") {
      if (error === 0) {
        var applicablecoin = 0;
        if (
          parseFloat(this.state.grandTotal) > 0 &&
          parseFloat(this.state.productdetail.points) > 0
        ) {
          applicablecoin = this.state.productdetail.points;
        }

        var bookingdetails = {
          selectedpackage: this.state.selectedpackage,
          selectedTicketType: this.state.selectedTicketType,
          totalPrice: this.state.totalPrice,
          selectedDate: this.state.selectedDate,
          eventTime: this.state.eventTime,
          applicablecoin: applicablecoin,
          dbspromo: this.state.productdetail.dbspromo,
          product_not_applicable_points:
            this.state.productdetail.product_not_applicable_points,
          package_not_applicable_points:
            this.state.selectedpackage.package_not_applicable_points,
          merchant_id: this.state.productdetail.merchant_id,
        };
        this.props.history.push({
          pathname: "/checkout",
          state: bookingdetails,
        });
      }
    } else {
      this.setState({ triggerLogin: true, returnloginresult: true });
    }
  }

  sateValChange = (field, value) => {
    this.setState({ [field]: value }, function () {
      if (field === "book") {
        this.setState(
          { returnloginresult: false, userID: cookie.load("UserId") },
          function () {
            this.booknow();
          }
        );
      }
    });
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  handleChange(event) {
    this.setState({ eventTime: event.value, showTimeError: false });
  }

  handleTextChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      ["error_" + event.target.name]: "",
    });
  }
  handleSelectChange(field, event) {
    this.setState({
      [field]: event.value,
      ["error_" + field]: "",
    });
  }

  showWriteReview() {
    this.setState({ writeReview: !this.state.writeReview });
  }

  createRating() {
    var error = 0;
    if ($("#product_rating").val() === "") {
      $("#error_rating").show();
      error++;
    } else {
      $("#error_rating").hide();
    }
    if (this.state.ratingCategory === "") {
      this.setState({ error_ratingCategory: "Yes" });
      error++;
    } else {
      this.setState({ error_ratingCategory: "" });
    }
    if (this.state.reatingMessage === "") {
      this.setState({ error_reatingMessage: "Yes" });
      error++;
    } else {
      this.setState({ error_reatingMessage: "" });
    }
    if (error === 0) {
      showLoader("create_rating");
      var postObject = {
        customer_id: this.state.userID,
        rating: $("#product_rating").val(),
        review_category: this.state.ratingCategory,
        review_comment: this.state.reatingMessage,
        product_id: this.state.productdetail.products_id,
      };

      axios
        .post(
          apiUrl + "products/createReview",
          qs.stringify(postObject),
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            }
          }
        )
        .then((res) => {
          hideLoader("create_rating");
          if (res.data.status === "ok") {
            showAlert("Success", res.data.message);
            this.setState({
              review_category: "",
              review_comment: "",
              writeReview: !this.state.writeReview,
            });
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    }
  }
  loadAllImages() {
    var products = this.state.productdetail;
    if (Object.keys(products).length > 0) {
      var allimages = "";
      var allgalleryimages = "";
      var slidergalleryimages = "";
      if (Object.keys(products.additional_image).length > 0) {
        var additional_image = products.additional_image;
        console.log(additional_image, "additional_image");
        allimages = Object.keys(products.additional_image).map(
          (item, index) => {
            return (
              <a
                key={index}
                href={mediaUrl + "products/gallery/" + additional_image[item]}
                className="lightbox-cats"
              >
                <img
                  src={mediaUrl + "products/gallery/" + additional_image[item]}
                  alt=""
                />
              </a>
            );
          }
        );
      }

      if (products.product_gallery.length > 0) {
        allgalleryimages = products.product_gallery.map((item, index) => {
          return (
            <a
              key={index}
              href={apiglobaltixMediaUrl + item}
              className="lightbox-cats"
            >
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </a>
          );
        });
      }
      if (products.product_gallery.length > 0) {
        slidergalleryimages = products.product_gallery.map((item, index) => {
          return (
            <div key={index}>
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </div>
          );
        });
      }
      this.setState(
        {
          allimages: allimages,
          allgalleryimages: allgalleryimages,
          slidergalleryimages: slidergalleryimages,
        },
        function () {
          setTimeout(function () {
            $(".lightbox-cats").magnificPopup({
              type: "image",
              gallery: { enabled: true },
              // other options
            });
          }, 2000);
        }
      );
    }
  }
  loadImage(products) {
    var firstImg = "";
    var secondImg = "";
    var thirdImg = "";
    var fourthImg = "";

    if (Object.keys(products.additional_image).length > 0) {
      if (
        products.additional_image[1] !== "" &&
        typeof products.additional_image[1] !== undefined &&
        typeof products.additional_image[1] !== "undefined"
      ) {
        firstImg =
          mediaUrl + "products/gallery/" + products.additional_image[1];
      }
      if (
        products.additional_image[2] !== "" &&
        typeof products.additional_image[2] !== undefined &&
        typeof products.additional_image[2] !== "undefined"
      ) {
        secondImg =
          mediaUrl + "products/gallery/" + products.additional_image[2];
      }
      if (
        products.additional_image[3] !== "" &&
        typeof products.additional_image[3] !== undefined &&
        typeof products.additional_image[3] !== "undefined"
      ) {
        thirdImg =
          mediaUrl + "products/gallery/" + products.additional_image[3];
      }
      if (
        products.additional_image[4] !== "" &&
        typeof products.additional_image[4] !== undefined &&
        typeof products.additional_image[4] !== "undefined"
      ) {
        fourthImg =
          mediaUrl + "products/gallery/" + products.additional_image[4];
      }
    }
    if (products.product_gallery.length > 0) {
      if (firstImg === "") {
        firstImg =
          products.merchant_id !== null && products.merchant_id !== "0"
            ? mediaUrl + "products/gallery/" + products.product_gallery[0]
            : apiglobaltixMediaUrl + products.product_gallery[0];
      }
      if (secondImg === "") {
        secondImg =
          products.product_gallery[1] !== "" &&
          typeof products.product_gallery[1] !== undefined &&
          typeof products.product_gallery[1] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[1]
              : apiglobaltixMediaUrl + products.product_gallery[1]
            : "";
      }
      if (thirdImg === "") {
        thirdImg =
          products.product_gallery[2] !== "" &&
          typeof products.product_gallery[2] !== undefined &&
          typeof products.product_gallery[2] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[2]
              : apiglobaltixMediaUrl + products.product_gallery[2]
            : "";
      }
      if (fourthImg === "") {
        fourthImg =
          products.product_gallery[3] !== "" &&
          typeof products.product_gallery[3] !== undefined &&
          typeof products.product_gallery[3] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[3]
              : apiglobaltixMediaUrl + products.product_gallery[3]
            : "";
      }

      if (products.product_gallery.length >= 4) {
        return (
          <section className="det-banner four-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="firstImg" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="secondImg" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="thirdImg" />
                </div>
                <div
                  className="det-banner-rhs-bottom-rhs product-image-more"
                  onClick={this.openGallery.bind(this)}
                >
                  <span>show all photos</span>
                  <img src={fourthImg} alt="fourthImg" />
                </div>
              </div>
            </div>
          </section>
        );
      } else if (products.product_gallery.length === 3) {
        return (
          <section className="det-banner three-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="firstImg" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="secondImg" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="thirdImg" />
                </div>
              </div>
            </div>
          </section>
        );
      } else if (products.product_gallery.length === 2) {
        return (
          <section className="det-banner two-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="firstImg" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="secondImg" />
              </div>
            </div>
          </section>
        );
      } else if (products.product_gallery.length === 1) {
        return (
          <section className="det-banner single-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="firstImg" />
            </div>
          </section>
        );
      }
    } else {
      return (
        <section className="det-banner single-gallery">
          <div className="det-banner-lhs">
            <img src={placeholder} alt="placeholder" />
          </div>
        </section>
      );
    }
  }
  gotoPackage() {
    if ($(".detail-bottom-package").length > 0) {
      $("html, body").animate(
        {
          scrollTop: $(".detail-bottom-package").offset().top - 100,
        },
        1000
      );
    }
  }
  openGallery() {
    $(".lightbox-cats:first-child").trigger("click");
  }
  openPackageInfo(poupproduct, pouppackage, typeofselect) {
    if (typeofselect === "View Info & Select") {
      this.setState(
        {
          poupproduct: poupproduct,
          pouppackage: pouppackage,
        },
        function () {
          $.magnificPopup.open({
            items: {
              src: "#pacakge_popup",
            },
            type: "inline",
          });
        }
      );
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    var totalEarnPotins = 0;
    if (
      parseFloat(this.state.grandTotal) > 0 &&
      parseFloat(this.state.productdetail.points) > 0
    ) {
      totalEarnPotins = calculateAircoins(
        this.state.grandTotal,
        this.state.productdetail.points
      );
    }

    return (
      <>
        <Header
          {...this.props}
          triggerLogin={this.state.triggerLogin}
          returnloginresult={this.state.returnloginresult}
          sateValChange={this.sateValChange}
        />
        <div className="product-more-image">
          {this.state.allimages}
          {this.state.allgalleryimages}
        </div>
        <div className="product-mobile-gallery">
          {this.state.slidergalleryimages !== "" && (
            <OwlCarousel options={settingsgallery}>
              {this.state.slidergalleryimages}
            </OwlCarousel>
          )}
        </div>
        {this.state.productdetail !== "" &&
        Object.keys(this.state.productdetail).length > 0 ? (
          <>
            {this.loadImage(this.state.productdetail)}
            <div className="bread-crumbs">
              <div className="container">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href={void 0} onClick={this.goBack}>
                      {this.props?.location?.state?.country}
                    </a>
                  </li>
                  <li>{this.state.productdetail.products_name}</li>
                </ul>
              </div>
            </div>
            <section className="innersection">
              <div className="container">
                <div className="detail-bottom">
                  <div className="detail-bottom-inner">
                    <div className="detail-bottom-lhs">
                      <div className="detail-top-lhs">
                        <div className="detail-top-header">
                          <h1>{this.state.productdetail.products_name}</h1>
                          {this.state.productdetail.product_category_name !==
                            "" &&
                            this.state.productdetail.product_category_name !==
                              null && (
                              <div className="product-description-tag">
                                <span>
                                  {
                                    this.state.productdetail
                                      .product_category_name
                                  }
                                </span>
                              </div>
                            )}
                        </div>
                        <div className="detail-top-detail">
                          <div className="detail-top-detail-parent">
                            <ul className="detail-top-detail-lhs">
                              {this.state.productdetail.product_package.length >
                                0 && (
                                <li>
                                  <img src={loader} alt="loader" /> Duration:{" "}
                                  {this.state.productdetail
                                    .product_isopendated === "1"
                                    ? "Open Dated"
                                    : "Fixed Date "}
                                </li>
                              )}

                              {this.state.productdetail.products_addressLine !==
                                "" &&
                                this.state.productdetail
                                  .products_addressLine !== null && (
                                  <li>
                                    <img src={mpin} alt="map" />
                                    <strong>Location:</strong>{" "}
                                    {
                                      this.state.productdetail
                                        .products_addressLine
                                    }
                                    -{" "}
                                    {
                                      this.state.productdetail
                                        .products_postalCode
                                    }
                                  </li>
                                )}
                            </ul>
                            <ul className="detail-top-detail-rhs">
                              <li>
                                <img src={dollar} alt="dollar" />
                                {this.state.productdetail.product_package
                                  .length > 0 &&
                                this.state.productdetail.product_package[0]
                                  .package_ticketFormat === "QRCODE"
                                  ? "Cancellable"
                                  : "Non Cancellable"}
                              </li>
                              {this.state.productdetail
                                .product_instantconfirmation === "1" && (
                                <li>
                                  <img
                                    src={instant_confirmation}
                                    alt="confirmation"
                                  />
                                  Instant confirmation
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="detail-top-detail-parent-rating">
                            {this.state.productdetail.rating !== "" &&
                            this.state.productdetail.rating !== null ? (
                              <span>
                                <strong>
                                  {this.state.productdetail.product_rating}
                                </strong>{" "}
                                ({this.state.productdetail.total_reviews})
                                Reviews
                              </span>
                            ) : (
                              <span>No Reviews</span>
                            )}
                            <div className="star-rating">
                              {this.loadRatingstar(
                                this.state.productdetail.rating
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.productdetail.product_package.length > 0 && (
                        <div className="detail-bottom-package">
                          <div className="detail-bottom-package-title">
                            <h4 className="bold-with-blue">
                              Packages for <span>you</span>{" "}
                            </h4>
                          </div>
                          <div className="ttd-package-card ttd-pc-creator">
                            <ul>
                              {this.state.productdetail.product_package.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={
                                        this.state.existpackage.includes(
                                          item.package_id
                                        )
                                          ? "disabled-link"
                                          : ""
                                      }
                                    >
                                      <div className="ttd-p-lhs">
                                        <h4>{item.package_name}</h4>
                                        <div className="vist-info ttd-p-lhs">
                                          <ul>
                                            <li className="vist-date">
                                              Valid upto{" "}
                                              {item.package_definedDuration}{" "}
                                              days
                                            </li>
                                            <li className="aftr-req">
                                              After confirmation required
                                            </li>
                                          </ul>
                                        </div>
                                        {item.package_inclusions !== "" &&
                                          item.package_inclusions !== null && (
                                            <div className="whats-include">
                                              <span>What's included:</span>
                                              <ul>
                                                {item.package_inclusions
                                                  .split("#$")
                                                  .map((incluitem, index3) => {
                                                    return (
                                                      <div
                                                        className="wi-cover"
                                                        key={index3}
                                                      >
                                                        {incluitem}
                                                      </div>
                                                    );
                                                  })}
                                              </ul>
                                            </div>
                                          )}
                                        <div className="ttd-total-pack">
                                          <strong>
                                            Starting price{" "}
                                            <span>
                                              {" "}
                                              {showPriceValue(
                                                parseFloat(
                                                  item.package_main_price
                                                )
                                              )}
                                            </span>
                                          </strong>
                                          <p>
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                            Please add relevant things to do to
                                            the packages.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="ttd-p-rhs">
                                        <div className="include-tt">
                                          <h4>Included ticket-type</h4>
                                          {item.package_price.length > 0 && (
                                            <div className="persons">
                                              {item.package_price.map(
                                                (priceitem, priceIndex) => {
                                                  return (
                                                    <p key={priceIndex}>
                                                      <strong>
                                                        {
                                                          priceitem.package_price_name
                                                        }
                                                      </strong>
                                                      {((priceitem.package_price_maxPurchaseQty !==
                                                        "" &&
                                                        priceitem.package_price_maxPurchaseQty !==
                                                          null) ||
                                                        (priceitem.package_price_minPurchaseQty !==
                                                          "" &&
                                                          priceitem.package_price_minPurchaseQty !==
                                                            null)) && (
                                                        <em>
                                                          {priceitem.package_price_maxPurchaseQty !==
                                                            "" &&
                                                            priceitem.package_price_maxPurchaseQty !==
                                                              null &&
                                                            "Min " +
                                                              priceitem.package_price_maxPurchaseQty}{" "}
                                                          -{" "}
                                                          {priceitem.package_price_minPurchaseQty !==
                                                            "" &&
                                                            priceitem.package_price_minPurchaseQty !==
                                                              null &&
                                                            "Max " +
                                                              priceitem.package_price_minPurchaseQty}
                                                        </em>
                                                      )}
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </div>
                                          )}
                                          <a
                                            href={void 0}
                                            onClick={this.openPackageInfo.bind(
                                              this,
                                              this.state.productdetail,
                                              item,
                                              "View Info & Select"
                                            )}
                                            className="button"
                                          >
                                            View info & select
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      )}

                      <div className="policy-details">
                        <h4 className="bold-with-blue">
                          Essential <span>details</span>
                        </h4>

                        {this.state.selectedpackage !== "" &&
                        Object.keys(this.state.selectedpackage).length > 0 ? (
                          <>
                            {this.state.selectedpackage.package_inclusions !==
                              "" &&
                              this.state.selectedpackage.package_inclusions !==
                                null && (
                                <div className="include-box">
                                  <h5>What's included with the ticket:</h5>
                                  <ul>
                                    {this.state.selectedpackage.package_inclusions
                                      .split("#$")
                                      .map((item, index3) => {
                                        return <li key={index3}>{item}</li>;
                                      })}
                                  </ul>
                                </div>
                              )}

                            {this.state.selectedpackage
                              .package_termsAndConditions !== "" &&
                              this.state.selectedpackage
                                .package_termsAndConditions !== null && (
                                <>
                                  <h4>Terms and conditions:</h4>
                                  <p>
                                    {Parser(
                                      this.state.selectedpackage
                                        .package_termsAndConditions
                                    )}
                                  </p>
                                </>
                              )}
                            {this.state.selectedpackage.package_howtouse !==
                              "" &&
                              this.state.selectedpackage.package_howtouse !==
                                null && (
                                <>
                                  <h4>How to use the ticket:</h4>
                                  <p>
                                    {Parser(
                                      this.state.selectedpackage
                                        .package_howtouse
                                    )}
                                  </p>
                                </>
                              )}

                            {this.state.selectedpackage
                              .package_cancellationNotes !== "" &&
                              this.state.selectedpackage
                                .package_cancellationNotes !== null && (
                                <>
                                  <h4> Cancellation policy:</h4>
                                  {this.state.selectedpackage.package_cancellationNotes
                                    .split("#$")
                                    .map((item, index2) => {
                                      if (item !== "" && item !== null) {
                                        return (
                                          <p key={index2}>{Parser(item)}</p>
                                        );
                                      }
                                    })}
                                </>
                              )}
                          </>
                        ) : (
                          this.state.productdetail.products_description !==
                            "" &&
                          this.state.productdetail.products_description !==
                            null && (
                            <>
                              <h4>Description</h4>
                              <p>
                                {Parser(
                                  this.state.productdetail.products_description
                                )}
                              </p>
                            </>
                          )
                        )}
                        {this.state.productdetail.product_operatingHours !==
                          "" &&
                          this.state.productdetail.product_operatingHours !==
                            null && (
                            <>
                              <h4>Operating Hours</h4>
                              <p>
                                {Parser(
                                  this.state.productdetail
                                    .product_operatingHours
                                )}
                              </p>
                            </>
                          )}
                      </div>
                    </div>

                    <div className="detail-bottom-rhs">
                      {this.state.productdetail.product_latitude !== "" &&
                        this.state.productdetail.product_latitude !== null &&
                        this.state.productdetail.product_longitude !== "" &&
                        this.state.productdetail.product_longitude !== null && (
                          <div className="detail-bottom-rhs-map">
                            <iframe
                              width="350"
                              height="400"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                              src={
                                "https://maps.google.com/maps?q=" +
                                this.state.productdetail.product_latitude +
                                "," +
                                this.state.productdetail.product_longitude +
                                "&hl=en&z=14&output=embed"
                              }
                            ></iframe>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.poupproduct !== "" &&
                this.state.pouppackage !== "" && (
                  <div
                    id="pacakge_popup"
                    className="mfp-hide common-popup pacakge_popup"
                  >
                    <div className="cp-header textcenter">
                      <h3>
                        Package <span>information</span>
                      </h3>
                      <a
                        className="date-package-popup"
                        href={void 0}
                        onClick={this.closeDate.bind(this)}
                      >
                        <img src={closeicon} alt="Close" />
                      </a>
                    </div>

                    <div className="pp-info">
                      <div className="pp-info-blue">
                        <ul>
                          <li>
                            <img src={calday} alt="calday" />
                            {this.state.pouppackage.package_ticketValidity ===
                            "Duration"
                              ? " Open dated valid upto " +
                                this.state.pouppackage.package_definedDuration +
                                " days"
                              : "Fixed Date"}
                          </li>
                          <li>
                            <img src={dollar} alt="dollar" />

                            {this.state.pouppackage.package_ticketFormat ===
                            "QRCODE"
                              ? "Cancellable and refundable"
                              : "Non Cancellable and non refundable"}
                          </li>
                          {this.state.poupproduct
                            .product_instantconfirmation === "1" && (
                            <li>
                              <img
                                src={instant_confirmation}
                                alt="confirmation"
                              />
                              Instant confirmation
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="pp-info-text">
                        <h5>
                          Package <span>title</span>
                        </h5>
                        <p>{this.state.pouppackage.package_name}</p>
                        {this.state.pouppackage.package_description !== "" &&
                          this.state.pouppackage.package_description !==
                            null && (
                            <>
                              <h5>
                                Package <span>Description</span>
                              </h5>
                              {Parser(
                                this.state.pouppackage.package_description
                              )}
                            </>
                          )}

                        {this.state.pouppackage.package_inclusions !== "" &&
                          this.state.pouppackage.package_inclusions !==
                            null && (
                            <>
                              <h5>
                                What's <span>Included</span>
                              </h5>

                              <ul>
                                {this.state.pouppackage.package_inclusions
                                  .split("#$")
                                  .map((item, index3) => {
                                    return <li key={index3}>{item}</li>;
                                  })}
                              </ul>
                            </>
                          )}
                        {this.state.pouppackage.package_howtouse !== "" &&
                          this.state.pouppackage.package_howtouse !== null && (
                            <>
                              <h5>
                                How to <span>Use</span>
                              </h5>
                              <ul>
                                {this.state.pouppackage.package_howtouse
                                  .split("#$")
                                  .map((item, index3) => {
                                    return <li key={index3}>{item}</li>;
                                  })}
                              </ul>
                            </>
                          )}

                        {this.state.pouppackage.package_cancellationNotes !==
                          "" &&
                          this.state.pouppackage.package_cancellationNotes !==
                            null && (
                            <>
                              <h5>
                                Cancellation <span>Details</span>
                              </h5>
                              <ul>
                                {this.state.pouppackage.package_cancellationNotes
                                  .split("#$")
                                  .map((item, index2) => {
                                    if (item !== "" && item !== null) {
                                      return (
                                        <li key={index2}>{Parser(item)}</li>
                                      );
                                    }
                                  })}
                              </ul>
                            </>
                          )}

                        {this.state.pouppackage.package_termsAndConditions !==
                          "" &&
                          this.state.pouppackage.package_termsAndConditions !==
                            null && (
                            <>
                              <h5>
                                Terms & <span>Conditions</span>
                              </h5>

                              {Parser(
                                this.state.pouppackage
                                  .package_termsAndConditions
                              )}
                            </>
                          )}

                        <h5>Location</h5>
                        <ul>
                          <li>
                            {this.state.poupproduct.products_addressLine}-{" "}
                            {this.state.poupproduct.products_postalCode}
                          </li>
                        </ul>
                        {this.state.poupproduct.product_operatingHours !== "" &&
                          this.state.poupproduct.product_operatingHours !==
                            null && (
                            <>
                              <h5>
                                Opening <span>hours</span>
                              </h5>
                              <ul>
                                <li>
                                  {Parser(
                                    this.state.poupproduct
                                      .product_operatingHours
                                  )}
                                </li>
                              </ul>
                            </>
                          )}
                      </div>
                    </div>

                    <div className="avail-btm textcenter">
                      <a
                        href={void 0}
                        className="button"
                        id={
                          "packagebtnpop_" + this.state.pouppackage.package_id
                        }
                        onClick={this.selectPackage.bind(
                          this,
                          this.state.pouppackage
                        )}
                      >
                        Select
                      </a>
                    </div>
                  </div>
                )}
            </section>
          </>
        ) : (
          <div id="dvLoading">
            <div className="loader triangle">
              <svg viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  stroke="black"
                  stroke-width="3"
                  fill="transparent"
                />
              </svg>
            </div>
          </div>
        )}

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var productdetailArr = Array();
  var common = Array();
  if (Object.keys(state.productdetails).length > 0) {
    if (state.productdetails[0].status === "ok") {
      productdetailArr = state.productdetails[0].result_set;
      common = state.productdetails[0].common;
    }
  }

  var settingArr = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      settingArr = state.settings[0].result_set;
    }
  }

  return {
    productdetail: productdetailArr,
    common: common,
    globalsettings: settingArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetails: (params) => {
      dispatch({ type: GET_PRODUCTDETAILS, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Productdetail);
