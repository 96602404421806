/* eslint-disable */
import React, { useState } from 'react'
import { Calendar, DateRange } from "react-date-range";
import {
    Button,

} from "@mui/material";
import moment from "moment";
import bcal from "../../common/images/calender-banner.svg";
import searchlight from "../../common/images/search-light.svg";
import bprofile from "../../common/images/banner-profile.svg";



var current = new Date();
current.setDate(current.getDate() + 1);
var formattedDate = current.toDateString() + " " + current.toTimeString();
var currentDate = new Date(formattedDate);


export default function StaySeparate() {
    const [toCityInput, setToCityInput] = useState('');
    const [isHovered, setIsHovered] = useState('');
    const [toCityList, setToCityList] = useState([]);
    const [destination_airport_code, setDestinationAirportCode] = useState('');
    const [desCityItem, setDesCityItem] = useState(null);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [trip_type, setTripType] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [start_date, setStartDate] = useState(null);
    const [adults, setAdults] = useState(0);
    const [child, setChild] = useState(0);
    const [infant, setInfant] = useState(0);
    const [passengerPopup, setPassengerPopup] = useState(false);
    const handleMouseEnter = (type) => {
        setIsHovered(type);
    };

    const handleCityChange = (e, inputType) => {

    };

    const handleSelectDateRange = (ranges) => {

    };

    const handleSelectDate = (date) => {

    };

    const passengerSelection = () => {

    };

    const searchNewFlight = () => {

    };

    return (
        <>
            <div className="step-banner-rounded flight-search-main flight-bg">
                <div className="container">
                    <div className="hero-content">
                        <div className="hero-content-top">
                            <h1>
                                Explore & book best hotels
                            </h1>
                        </div>
                    </div>
                    <div className={"flight-way-search flight-search-container"}>
                        <div className="flight-search rel">
                            <div className="fs-location">
                                <div className="fs-to">
                                    <input
                                        type="text"
                                        placeholder="To"
                                        onMouseEnter={(e) => handleMouseEnter("CITY_TO")}
                                        value={toCityInput}
                                        onChange={(e) => handleCityChange(e, "toCityInput")}
                                    />
                                    {toCityInput !== "" &&
                                        isHovered === "CITY_TO" &&
                                        toCityList.length > 0 && (
                                            <div className="flight-results ">
                                                {toCityList.map((country) => (
                                                    <div
                                                        key={country.id}
                                                        className="row"
                                                        onClick={() => {
                                                            setDestinationAirportCode(country.airport_code);
                                                            setToCityInput(country.airport_city_name);
                                                            setToCityList([]);
                                                            setDesCityItem(country);
                                                        }}
                                                    >
                                                        <div className="col-1">
                                                            <div className="city">
                                                                {country.airport_name}
                                                            </div>
                                                            <div className="airport">
                                                                {country.airport_city_name}
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            {country.airport_code}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="fs-stay">
                                <input
                                    type="text"
                                    placeholder={
                                        trip_type === "Return"
                                            ? "Start Date - End Date"
                                            : "Start Date"
                                    }
                                    onMouseEnter={(e) => handleMouseEnter("DATE_RANGE")}
                                    value={
                                        trip_type === "Return"
                                            ? moment(
                                                moment(dateRange?.startDate).format("DD-MM-YYYY")
                                            ).isSame(moment().format("DD-MM-YYYY"))
                                                ? null
                                                : `${moment(dateRange?.startDate).format(
                                                    "DD-MM-YYYY"
                                                )} - ${moment(dateRange?.endDate).format(
                                                    "DD-MM-YYYY"
                                                )}`
                                            : start_date
                                                ? moment(start_date).format("DD-MM-YYYY")
                                                : null
                                    }
                                    onClick={() => {
                                        setOpenDateRange(!openDateRange);
                                    }}
                                />
                                <img
                                    alt=''
                                    src={bcal}
                                    onClick={() => {
                                        setOpenDateRange(!openDateRange);
                                    }}
                                />
                                {openDateRange && isHovered === "DATE_RANGE" ? (
                                    <div className="flight-results dateMain">
                                        {trip_type === "Return" ? (
                                            <DateRange
                                                showDateDisplay={false}
                                                startDatePlaceholder={"Start Date"}
                                                endDatePlaceholder={"End Date"}
                                                editableDateInputs={false}
                                                moveRangeOnFirstSelection={false}
                                                ranges={[dateRange]}
                                                onChange={handleSelectDateRange}
                                                rangeColors={["#4258bf"]}
                                                months={2}
                                                minDate={new Date()}
                                                direction="horizontal"
                                            />
                                        ) : (
                                            <Calendar
                                                onChange={handleSelectDate}
                                                minDate={new Date()}
                                                date={start_date}
                                                months={2}
                                                preventSnapRefocus={true}
                                                direction="horizontal"
                                            />
                                        )}
                                        <div className="btnMain">
                                            <Button
                                                variant="contained"
                                                className="doneBtn"
                                                style={{}}
                                                onClick={() => setOpenDateRange(!openDateRange)}
                                            >
                                                Done
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="fs-whom">
                                <input
                                    type="text"
                                    placeholder="Members"
                                    onMouseEnter={(e) => handleMouseEnter("PASSENGER_LIST")}
                                    value={` ${adults +
                                        child +
                                        infant} Passengers`}
                                    onClick={() =>
                                        setPassengerPopup(true)
                                    }
                                />
                                {passengerPopup === true &&
                                    isHovered === "PASSENGER_LIST" && (
                                        <div className="flight-results pass-dropdown step-content-passenger">
                                            {passengerSelection()}
                                            <div className="btnMain">
                                                <Button
                                                    variant="contained"
                                                    className="doneBtn"
                                                    style={{}}
                                                    onClick={() => setPassengerPopup(false)}
                                                >
                                                    Done
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                <img src={bprofile} alt="profile" />
                            </div>

                            <button
                                type="submit"
                                className="button fg-btn search_flight"
                                onClick={searchNewFlight}
                            >
                                <img src={searchlight} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
