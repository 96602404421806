import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BannerCarousel.scss";

const bannerImages = [
  {
    src: require("../../../common/images/Bali.png"),
    countryname: "Indonesia",
    cityName: "Bali",
  },
  {
    src: require("../../../common/images/Bangkok.png"),
    countryname: "Thailand",
    cityName: "Bangkok",
  },
  {
    src: require("../../../common/images/Hanoi.png"),
    countryname: "Vietnam",
    cityName: "Hanoi",
  },
  {
    src: require("../../../common/images/Seim_reap.png"),
    countryname: "Cambodia",
    cityName: "Siem Reap",
  },
  {
    src: require("../../../common/images/Seoul.png"),
    countryname: "South Korea",
    cityName: "Seoul",
  },
  {
    src: require("../../../common/images/Singapore.png"),
    countryname: "Singapore",
    cityName: "Singapore",
  },
  {
    src: require("../../../common/images/Tropical_phuket.png"),
    countryname: "Thailand",
    cityName: "Phuket",
  },
];

const mobileBannerImages = [
  {
    src: require("../../../common/images/M_Bali.png"),
    countryname: "Indonesia",
    cityName: "Bali",
  },
  {
    src: require("../../../common/images/M_Bangkok.png"),
    countryname: "Thailand",
    cityName: "Bangkok",
  },
  {
    src: require("../../../common/images/M_Hanoi.png"),
    countryname: "Vietnam",
    cityName: "Hanoi",
  },
  {
    src: require("../../../common/images/M_Seim_reap.png"),
    countryname: "Cambodia",
    cityName: "Siem Reap",
  },
  {
    src: require("../../../common/images/M_Seoul.png"),
    countryname: "South Korea",
    cityName: "Seoul",
  },
  {
    src: require("../../../common/images/M_Singapore.png"),
    countryname: "Singapore",
    cityName: "Singapore",
  },
  {
    src: require("../../../common/images/M_Tropical_phuket.png"),
    countryname: "Thailand",
    cityName: "Phuket",
  },
];
const BannerCarousel = (props) => {
  const { slectedbanner, isMobile } = props;
  const carouselRef = useRef(null);

  const LeftNavButton = () => (
    <div className="prevBtn" onClick={() => carouselRef.current.slickPrev()}>
      <i className="fa fa-angle-left" aria-hidden="true"></i>
    </div>
  );

  const RightNavButton = () => (
    <div className="nextBtn" onClick={() => carouselRef.current.slickNext()}>
      <i className="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  );

  const bannerSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isMobile ? undefined : <RightNavButton />,
    prevArrow: isMobile ? undefined : <LeftNavButton />,
  };

  const images = isMobile ? mobileBannerImages : bannerImages;
  return (
    <Slider {...bannerSettings} ref={carouselRef} className="banner-carousel">
      {images.map((img, index) => (
        <div key={index}>
          <Link onClick={() => slectedbanner(img.countryname, img.cityName)}>
            <div>
              <img src={img.src} alt={`Banner ${index + 1}`} />
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
};

export default BannerCarousel;
